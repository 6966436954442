/**
 * @author David Roman <david@inarix.com>
 * @file Layout Reducer
 * @desc Created on 2020-12-11 1:40:51 pm
 * @copyright Inarix
 */

import { createSlice } from "@reduxjs/toolkit";
import { deleteUserFromGroup } from "../actions/users/userAsyncActions";

export interface LayoutState {
  sectionName: string;
  locale: string;
  poperAccount: boolean;
  layoutActive: boolean;
  anchorEl: boolean;
  confirmModal: boolean;
  infoConfirmModal: any;
}

export const initialState: LayoutState = {
  sectionName: "/",
  locale: "fr",
  poperAccount: false,
  layoutActive: false,
  anchorEl: false,
  confirmModal: false,
  infoConfirmModal: undefined,
};

export const LayoutSlice = createSlice({
  name: "LayoutReducer",
  initialState,
  reducers: {
    setInfoConfirmModal(state, action) {
      state.infoConfirmModal = action.payload;
    },
    setConfirmModal(state, action) {
      state.confirmModal = action.payload;
    },
    setAnchorEl(state, action) {
      state.anchorEl = action.payload;
    },
    setVisible(state, action) {
      return {
        ...state,
        layoutActive: action.payload,
      };
    },

    setTabNameLayout(state, action) {
      state.sectionName = action.payload;
    },

    setLocale(state, action) {
      state.locale = action.payload;
    },
    disableTabNameLayout(state) {
      state.sectionName = "/";
    },

    layoutLogout() {
      return initialState;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(deleteUserFromGroup.fulfilled, (state) => {
        state.confirmModal = false;
      })
      .addCase(deleteUserFromGroup.rejected, (state) => {
        state.confirmModal = false;
      });
  },
});

export const {
  setAnchorEl,
  setVisible,
  setTabNameLayout,
  setLocale,
  disableTabNameLayout,
  layoutLogout,
  setConfirmModal,
  setInfoConfirmModal,
} = LayoutSlice.actions;

export const layout = LayoutSlice.reducer;
