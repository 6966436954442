import { ThemeProvider } from "@mui/material/styles";

import { Alert, Box, Modal } from "@mui/material";
import {
  DataGridPro,
  GridPaginationModel,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import themeInarix from "../StylesMuiInarix";
import userDevicesColumns from "./userDevicesFetchingColumns";
import { useSelector } from "react-redux";
import { UserDevicesBody } from "./EditDeviceModal";
import { SuccessGeneralModal } from "../SuccessModal";
import { State } from "../../redux/slices";

export function UserDevicesTable(
  t: (arg0: string) => string,
  token: string,
  locale: string,
  setRowsNumber: (value: GridPaginationModel) => void,
  tabName: string
) {
  const {
    devices,
    loading,
    modal,
    error,
    errorPatch,
    deviceName,
    msg,
    loadingPatch,
    deviceId,
  } = useSelector((state: State) => state.DevicesReducer);
  const successBodyModal = SuccessGeneralModal(t, tabName);
  const bodyUserDevices = UserDevicesBody(
    t,
    token,
    devices,
    deviceId,
    deviceName,
    errorPatch,
    loadingPatch
  );

  const customToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  const { columns, devicesRows } = userDevicesColumns(t);

  return (
    <Box sx={{ height: 500, width: 1 }}>
      {error && errorPatch !== 400 && (
        <div className="container alert-content-devices">
          <Alert severity="warning" className="alert-content-devices">
            {t("Fleet_users.table.Error")} : {JSON.stringify(error)}
          </Alert>
        </div>
      )}

      <ThemeProvider theme={themeInarix(locale)}>
        <DataGridPro
          className="table-styles-alone"
          disableColumnSelector={true}
          rows={devicesRows}
          columns={columns}
          rowHeight={78}
          loading={loading}
          pagination
          onPaginationModelChange={(newPageSize) => setRowsNumber(newPageSize)}
          slots={{ toolbar: customToolbar }}
          pageSizeOptions={[25, 50, 100]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
        />
      </ThemeProvider>
      <Modal open={modal}>{bodyUserDevices}</Modal>
      <Modal open={msg}>{successBodyModal}</Modal>
    </Box>
  );
}
