import { styled } from '@mui/styles';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';

export const ListLinks = styled(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: ' center',
  alignItems: 'center',
  marginTop: '-20px',
}));

export const ListLinksOpen = styled(List)(() => ({
  marginLeft: '26.97px',
  marginTop: '-20px',
}));

export const DividerLayoutClose = styled(Divider)(() => ({
  marginLeft: '-15px',
  width: '30px',
  marginTop: '4px',
  borderStyle: 'none',
}));

export const DividerLayoutOpen = styled(Divider)(() => ({
  marginLeft: '-16px',
  width: '230px',
  marginTop: '4px',
  borderStyle: 'none',
}));
