import axios from "axios";
import {
  ChartBarleySoftWheat,
  DashboardLocIdInfo,
  GeoLocInfoData,
} from "./dashboardTypes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../configureStore";
import { API } from "../../../config";
import {
  fetchGeoLocDataSuccess,
  fetchGeoLocDataSuccessActivityTranform,
  setAvgProtBarleyData,
  setAvgProtWheatData,
  setCounOfUserActivityTab,
  setDashboardLoading,
  setMultipleLocations,
  setNewActivityChart,
  setPerBarleyVarietyData,
  setPerSoftVarietyData,
  setRowsActivityTable,
  setRowsPocketlabTable,
} from "../../slices/dashboard/dashboard";
import moment from "moment";

export const totalActionsWB = (WBActions: ChartBarleySoftWheat[]) => {
  return WBActions.reduce(
    (partialSum, actions) => partialSum + actions.actions,
    0
  );
};

export const addHours = (date: Date) => {
  const hoursToAdd = 24 * 60 * 60 * 1000;
  date.setTime(date.getTime() + hoursToAdd);
  return date;
};

export const setZeroHrs = (date: Date) => {
  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const zeroTime = new Date(date).setHours(0, 0, 0, 0);
  return new Date(zeroTime - tzoffset);
};

export const fetchDashboardLocationNames = createAsyncThunk(
  "DashboardReducer/fetchDashboardLocationNames",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    try {
      const response = await axios.get(
        `${API}/users/organization-geo-point?$select[]=name`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": `${locale}`,
          },
        }
      );
      const { data } = response;
      const transform = data.data.map((c: any) => c);
      store.dispatch(setMultipleLocations([...transform]));

      return data.data;
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const fetchGeoLocDataDashboard = createAsyncThunk(
  "DashboardReducer/fetchGeoLocDataDashboard",
  async (arg: { token: string; id?: DashboardLocIdInfo[] }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { locationDateActivityDashboard, allLocations } = (
      store.getState() as RootState
    ).DashboardReducer;

    const fromDate = setZeroHrs(locationDateActivityDashboard[0])
      .toISOString()
      .slice(0, -1);

    const toDate = addHours(setZeroHrs(locationDateActivityDashboard[1]))
      .toISOString()
      .slice(0, -1);

    const idLocationsSelected = arg.id?.map((locations: DashboardLocIdInfo) => {
      return `id[$in][]=${locations.id}&`;
    });

    const idsTransformed = idLocationsSelected?.join("");

    const paramsAllSilos = [
      `activityPerDay[]=${fromDate}`,
      `activityPerDay[]=${toDate}`,
      `actionsPerBarleyVar[]=${fromDate}`,
      `actionsPerBarleyVar[]=${toDate}`,
      `avgProtPerBarleyVar[]=${fromDate}`,
      `avgProtPerBarleyVar[]=${toDate}`,
      `actionsPerSoftWheatVar[]=${fromDate}`,
      `actionsPerSoftWheatVar[]=${toDate}`,
      `avgProtPerSoftWheatVar[]=${fromDate}`,
      `avgProtPerSoftWheatVar[]=${toDate}`,
    ].join("&");

    if ((arg.id && arg.id?.length > 0) || allLocations) {
      try {
        const response = await axios.get(
          !allLocations
            ? `${API}/users/organization-geo-point?${idsTransformed}${paramsAllSilos} `
            : `${API}/users/organization-geo-point?${paramsAllSilos}`,

          {
            headers: {
              Authorization: `Bearer ${arg.token}`,
              "Accept-Language": `${locale}`,
            },
          }
        );

        if (
          response.data.data.map(
            (info: GeoLocInfoData) => info.actionsPerBarleyVar
          )
        ) {
          const actionsPerBarleyVarData = response.data.data
            .map(
              (actionsPerBarleyVariety: GeoLocInfoData) =>
                actionsPerBarleyVariety.actionsPerBarleyVar
            )
            .filter((x: any) => !!x)
            .flat();

          const cacheBarleyActions: Record<string, number> = [
            ...new Set(
              actionsPerBarleyVarData.map(
                ({ variety }: { variety: string }) => variety
              )
            ),
          ].reduce(
            ((previous: Record<string, number>, variety: string) => {
              previous[variety] = 0;
              return previous;
            }) as any,
            {}
          ) as Record<string, number>;

          actionsPerBarleyVarData.forEach(
            ({ actions, variety }: { actions: number; variety: string }) => {
              cacheBarleyActions[variety] += actions;
            }
          );

          const finalArrayBarleyActions = Object.keys(cacheBarleyActions).map(
            (info) => ({
              variety: info,
              actions: cacheBarleyActions[info],
            })
          );

          store.dispatch(setPerBarleyVarietyData(finalArrayBarleyActions));
        } else {
          store.dispatch(setPerBarleyVarietyData([]));
        }

        if (
          response.data.data.map(
            (info: GeoLocInfoData) => info.avgProtPerBarleyVar
          )
        ) {
          const actionsPerBarleyVarData = response.data.data
            .map(
              (actionsPerBarleyVariety: GeoLocInfoData) =>
                actionsPerBarleyVariety.avgProtPerBarleyVar
            )
            .filter((x: any) => !!x)
            .flat();

          const cacheBarleyActions: Record<
            string,
            { sum: number; count: number }
          > = [
            ...new Set(
              actionsPerBarleyVarData.map(
                ({ variety }: { variety: string }) => variety
              )
            ),
          ].reduce(
            ((
              previous: Record<string, { sum: number; count: number }>,
              variety: string
            ) => {
              previous[variety] = { sum: 0, count: 0 };
              return previous;
            }) as any,
            {}
          ) as Record<string, { sum: number; count: number }>;

          actionsPerBarleyVarData.forEach(
            ({ average, variety }: { average: number; variety: string }) => {
              cacheBarleyActions[variety].sum += average;
              cacheBarleyActions[variety].count += 1;
            }
          );

          const finalArrayBarleyAverage = Object.keys(cacheBarleyActions).map(
            (info) => ({
              average:
                cacheBarleyActions[info].sum / cacheBarleyActions[info].count,
              variety: info,
            })
          );

          store.dispatch(setAvgProtBarleyData(finalArrayBarleyAverage));
        } else {
          store.dispatch(setAvgProtBarleyData([]));
        }

        if (
          response.data.data.map(
            (info: GeoLocInfoData) => info.actionsPerSoftWheatVar
          )
        ) {
          const actionsPerSoftWVarData = response.data.data
            .map(
              (actionsPerBarleyVariety: GeoLocInfoData) =>
                actionsPerBarleyVariety.actionsPerSoftWheatVar
            )
            .filter((x: any) => !!x)
            .flat();

          const cacheSoftWActions: Record<string, number> = [
            ...new Set(
              actionsPerSoftWVarData.map(
                ({ variety }: { variety: string }) => variety
              )
            ),
          ].reduce(
            ((previous: Record<string, number>, variety: string) => {
              previous[variety] = 0;
              return previous;
            }) as any,
            {}
          ) as Record<string, number>;

          actionsPerSoftWVarData.forEach(
            ({ actions, variety }: { actions: number; variety: string }) => {
              cacheSoftWActions[variety] += actions;
            }
          );

          const finalArraySoftWActions = Object.keys(cacheSoftWActions).map(
            (info) => ({
              variety: info,
              actions: cacheSoftWActions[info],
            })
          );

          store.dispatch(setPerSoftVarietyData(finalArraySoftWActions as any));
        } else {
          store.dispatch(setPerSoftVarietyData([]));
        }

        if (
          response.data.data.map(
            (info: GeoLocInfoData) => info.avgProtPerSoftWheatVar
          )
        ) {
          const actionsPerSoftWVarData = response.data.data
            .map(
              (actionsPerBarleyVariety: GeoLocInfoData) =>
                actionsPerBarleyVariety.avgProtPerSoftWheatVar
            )
            .filter((x: any) => !!x)
            .flat();

          const cacheSoftWActions: Record<
            string,
            { sum: number; count: number }
          > = [
            ...new Set(
              actionsPerSoftWVarData.map(
                ({ variety }: { variety: string }) => variety
              )
            ),
          ].reduce(
            ((
              previous: Record<string, { sum: number; count: number }>,
              variety: string
            ) => {
              previous[variety] = { sum: 0, count: 0 };
              return previous;
            }) as any,
            {}
          ) as Record<string, { sum: number; count: number }>;

          actionsPerSoftWVarData.forEach(
            ({ average, variety }: { average: number; variety: string }) => {
              cacheSoftWActions[variety].sum += average;
              cacheSoftWActions[variety].count += 1;
            }
          );

          const finalArraySoftWAverage = Object.keys(cacheSoftWActions).map(
            (info) => ({
              variety: info,
              average:
                cacheSoftWActions[info].sum / cacheSoftWActions[info].count,
            })
          );

          store.dispatch(setAvgProtWheatData(finalArraySoftWAverage));
        } else {
          store.dispatch(setAvgProtWheatData([]));
        }
        //// transform data need it for the new activity chart

        const transformData = response.data.data
          .map((loc: GeoLocInfoData) => loc.activity)
          .filter((x: any) => !!x);

        const arrayTransformed = transformData
          .map((record: any) =>
            Object.entries(record).map(([date, count]) => ({
              count: parseInt(`${count}`),
              date,
            }))
          )
          .flat();

        const cache: Record<string, number> = [
          ...new Set(
            arrayTransformed.map(({ date }: { date: string }) => date)
          ),
        ].reduce(
          ((previous: Record<string, number>, date: string) => {
            previous[date] = 0;
            return previous;
          }) as any,
          {}
        ) as Record<string, number>;

        arrayTransformed.forEach(
          ({ count, date }: { count: number; date: string }) => {
            cache[date] += count;
          }
        );

        const finalArray = Object.keys(cache).map((date) => ({
          date: date,
          count: cache[date].toString(),
        }));

        const arrayOfObj = finalArray;

        arrayOfObj.sort(
          (
            a: { count: string; date: string },
            b: { count: string; date: string }
          ): number => {
            if (moment(a.date).isBefore(moment(b.date))) return -1;
            if (moment(a.date).isAfter(moment(b.date))) return 1;
            return 0;
          }
        );

        let fromDateConvert = moment(fromDate);
        const toDateConvert = moment(toDate);
        let i = 0;
        const finalActivity = [];

        while (!fromDateConvert.isSame(toDateConvert, "day")) {
          if (i < arrayOfObj.length) {
            const d = moment(arrayOfObj[i].date);
            if (fromDateConvert.isSame(d, "day")) {
              finalActivity.push(arrayOfObj[i]);
              i++;
            } else {
              finalActivity.push({
                count: "0",
                date: fromDateConvert.toISOString(),
              });
            }
          } else {
            finalActivity.push({
              date: fromDateConvert.toISOString(),
              count: "0",
            });
          }
          fromDateConvert = fromDateConvert.add(1, "days");
        }

        store.dispatch(fetchGeoLocDataSuccessActivityTranform(finalActivity));
      } catch (error) {
        return store.rejectWithValue(error);
      }
    }

    if (arg.id?.length === 0) {
      store.dispatch(fetchGeoLocDataSuccessActivityTranform([]));
      store.dispatch(setPerBarleyVarietyData([]));
      store.dispatch(setAvgProtBarleyData([]));
      store.dispatch(setAvgProtWheatData([]));
      store.dispatch(setPerSoftVarietyData([]));
      store.dispatch(fetchGeoLocDataSuccess({}));
    }
  }
);

export const fetchActivityPocketLabDataDashboard2 = createAsyncThunk(
  "DashboardReducer/fetchActivityPocketLabDataDashboard2",
  async (arg: { token: string; id?: DashboardLocIdInfo[] }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { locationDateActivityDashboard, allLocations } = (
      store.getState() as RootState
    ).DashboardReducer;

    const fromDate = setZeroHrs(locationDateActivityDashboard[0])
      .toISOString()
      .slice(0, -1);

    const toDate = addHours(setZeroHrs(locationDateActivityDashboard[1]))
      .toISOString()
      .slice(0, -1);

    const idLocationsSelected = arg.id?.map((locations: DashboardLocIdInfo) => {
      return `id[$in][]=${locations.id}&`;
    });

    const idsTransformed = idLocationsSelected?.join("");

    const paramsAllLocations = [
      ["dailyActivityThreshold", 1],
      ["activeUserCount", [fromDate, toDate]],
      ["appVersions", [fromDate, toDate]],
      ["actionsPerBarleyVar", [fromDate, toDate]],
      ["actionsPerSoftWheatVar", [fromDate, toDate]],
    ];

    const params = paramsAllLocations
      .map((param) =>
        Array.isArray(param[1])
          ? param[1].map((value) => `${param[0]}[]=${value}`)
          : [`${param[0]}=${param[1]}`]
      )
      .flat()
      .join("&");

    if ((arg.id && arg.id?.length > 0) || allLocations) {
      try {
        const response = await axios.get(
          !allLocations
            ? `${API}/users/organization-geo-point?${idsTransformed}${params}`
            : `${API}/users/organization-geo-point?${params} `,

          {
            headers: {
              Authorization: `Bearer ${arg.token}`,
              "Accept-Language": `${locale}`,
            },
          }
        );

        const appVersion = response?.data?.appVersions;

        const rowsPocketLabTable = appVersion.map(
          (rowToPocketLabTable: any) => ({
            id: `${Math.random()}`,
            username: rowToPocketLabTable.username,
            siteName: rowToPocketLabTable.siteName,
            version: rowToPocketLabTable.version,
            lastActionAt: rowToPocketLabTable.lastActionAt,
          })
        );

        store.dispatch(setRowsPocketlabTable(rowsPocketLabTable));

        const users = response.data.users;
        const totalUsers = users.length;

        const countOfUsersActive = users.filter(
          (users: {
            actionCount: number;
            isActive: boolean;
            username: string;
          }) => users.isActive === true
        );
        const countOfUsersInactive = users.filter(
          (users: {
            actionCount: number;
            isActive: boolean;
            username: string;
          }) => users.isActive === false
        );

        const myCountUserObject = {
          totalUsers,
          countOfUsersActive: countOfUsersActive.length,
          countOfUsersInactive: countOfUsersInactive.length,
        };

        store.dispatch(setCounOfUserActivityTab(myCountUserObject));
      } catch (error) {
        return store.rejectWithValue(error);
      }
    }
  }
);

export const getPocketLabRecentVersion = createAsyncThunk(
  "DashboardReducer/getPocketLabRecentVersion",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    try {
      const response = await axios.get(
        `${API}/users/app-updates/pocket-lab-kotlin-v4`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": `${locale}`,
          },
        }
      );
      return store.fulfillWithValue(response.data.version);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const fetchActivityPocketLabDataDashboard = createAsyncThunk(
  "DashboardReducer/fetchActivityPocketLabDataDashboard",
  async (arg: { token: string; id?: DashboardLocIdInfo[] }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { locationDateActivityDashboard, allLocations } = (
      store.getState() as RootState
    ).DashboardReducer;

    const fromDate = setZeroHrs(locationDateActivityDashboard[0])
      .toISOString()
      .slice(0, -1);

    const toDate = addHours(setZeroHrs(locationDateActivityDashboard[1]))
      .toISOString()
      .slice(0, -1);

    const idLocationsSelected = arg.id?.map((locations: DashboardLocIdInfo) => {
      return `orgGeoPointId[$in][]=${locations.id}&`;
    });

    const idsTransformed = idLocationsSelected?.join("");

    const params = `$joinRelation=[scenarioInstance.scenario,creator,geoPoint]&scenarioInstance:scenario.cerealTypeId[$in][]=1&scenarioInstance:scenario.cerealTypeId[$in][]=4&createdAt[$gte]=${fromDate}&createdAt[$lte]=${toDate}&$select[]=past_action.id&$select[]=scenarioInstance:scenario.cerealTypeId&$select[]=past_action.createdAt&userFacingDeletion=false&userFacingNonValidated=false&$select[]=creator.username&$select[]=geoPoint.name`;
    if ((arg.id && arg.id?.length > 0) || allLocations) {
      try {
        const response = await axios.get(
          !allLocations
            ? `${API}/samples/past-action?${idsTransformed}${params}`
            : `${API}/samples/past-action?${params}`,

          {
            headers: {
              Authorization: `Bearer ${arg.token}`,
              "Accept-Language": `${locale}`,
            },
          }
        );

        const result = response.data.data;
        const limit = response.data.limit;
        const total = response.data.total;

        let current = limit;

        while (current < total) {
          store.dispatch(setDashboardLoading(true));
          const subResult = await axios.get(
            !allLocations
              ? `${API}/samples/past-action?$limit=${limit}&$skip=${current}&${idsTransformed}${params}`
              : `${API}/samples/past-action?$limit=${limit}&$skip=${current}&${params}`,

            {
              headers: {
                Authorization: `Bearer ${arg.token}`,
                "Accept-Language": `${locale}`,
              },
            }
          );

          result.push(subResult.data.data);
          current += subResult.data.limit;
          store.dispatch(setDashboardLoading(false));
        }

        const realResult = result.flat();

        const wheatActions = realResult.filter(
          (cereal: any) => cereal.cerealTypeId === 4
        );

        const barleyActions = realResult.filter(
          (cereal: any) => cereal.cerealTypeId === 1
        );

        const dataForTheWheatChart = wheatActions.map(
          (wheat: { createdAt: any; username: string }) => ({
            date: moment(wheat.createdAt).format("l"),
            count: 1,
            username: wheat.username,
          })
        );

        const dataForTheBarleyChart = barleyActions.map((barley: any) => ({
          date: moment(barley.createdAt).format("l"),
          count: 1,
          username: barley.username,
        }));

        const rawRowsActivity = sumCerealForChart(realResult);

        const rowsActivityTable = rawRowsActivity.map(
          (rowToActivityTable: any) => ({
            id: `${Math.random()}`,
            username: rowToActivityTable.username,
            geoLocation: rowToActivityTable.name,
            numberOfActions: rowToActivityTable.total,
            totalBarleyActions: rowToActivityTable.barley,
            totalWheatActions: rowToActivityTable.wheat,
          })
        );

        const wheat = sumOfCountActivity(
          dataForTheWheatChart,
          fromDate,
          toDate
        );

        const barley = sumOfCountActivity(
          dataForTheBarleyChart,
          fromDate,
          toDate
        );
        store.dispatch(setRowsActivityTable(rowsActivityTable));
        store.dispatch(setNewActivityChart([barley, wheat]));
      } catch (error) {}
    }
  }
);

export interface DataTranformedToCharts {
  date: string;
  count: number;
}

export const sumCerealForChart = (cerealChart: any[]) => {
  const result = Object.values(
    cerealChart.reduce((a: any, { username, cerealTypeId, name }: any) => {
      let key = `${username}`;

      a[key] = a[key] || {
        username,
        total: 0,
        name,
        wheat: 0,
        barley: 0,
      };
      a[key].total++;
      if (cerealTypeId === 1) {
        a[key].barley++;
      } else {
        a[key].wheat++;
      }
      return a;
    }, {})
  );
  return result;
};

export const sumCerealForChart2 = (cerealChart: any[]) => {
  const result = Object.values(
    cerealChart.reduce((a: any, { username }: any) => {
      let key = `${username}`;
      a[key] = a[key] || { username, count: 0 };
      a[key].count++;
      return a;
    }, {})
  );
  return result;
};

export const sumOfCountActivity = (
  dataTranformedToCharts: DataTranformedToCharts[],
  fromDate: string,
  toDate: string
) => {
  const cache: Record<string, number> = [
    ...new Set(
      dataTranformedToCharts.map(({ date }: { date: string }) => date)
    ),
  ].reduce(
    ((previous: Record<string, number>, date: string) => {
      previous[date] = 0;
      return previous;
    }) as any,
    {}
  ) as Record<string, number>;

  dataTranformedToCharts.forEach(
    ({ count, date }: { count: number; date: string }) => {
      cache[date] += count;
    }
  );

  const finalArray = Object.keys(cache).map((date) => ({
    date: date,
    count: cache[date].toString(),
  }));

  const arrayOfObj = finalArray;
  arrayOfObj.sort(
    (
      a: { count: string; date: string },
      b: { count: string; date: string }
    ): number => {
      if (moment(a.date).isBefore(moment(b.date))) return -1;
      if (moment(a.date).isAfter(moment(b.date))) return 1;
      return 0;
    }
  );

  let fromDateConvert = moment(fromDate);
  const toDateConvert = moment(toDate);
  let i = 0;
  const finalActivity = [];

  while (!fromDateConvert.isSame(toDateConvert, "day")) {
    if (i < arrayOfObj.length) {
      const d = moment(arrayOfObj[i].date);
      if (fromDateConvert.isSame(d, "day")) {
        finalActivity.push(arrayOfObj[i]);
        i++;
      } else {
        finalActivity.push({
          count: "0",
          date: fromDateConvert.toISOString(),
        });
      }
    } else {
      finalActivity.push({
        date: fromDateConvert.toISOString(),
        count: "0",
      });
    }
    fromDateConvert = fromDateConvert.add(1, "days");
  }

  return finalActivity;
};
