import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../layout/Layout";

import { Modal } from "@mui/material";
import { State } from "../../redux/slices";
import { useTranslation } from "react-i18next";

import { UserDevicesTable } from "./UserDevicesTable";
import { setNumberRowsUsers } from "../../redux/slices/fleet/users";
import { GridPaginationModel } from "@mui/x-data-grid";
import { setTabNameLayout } from "../../redux/slices/layout";
import {
  cleanGeneralDeviceError,
  disableErrorSuccessDevices,
  setDevicesModal,
  setNewDeviceName,
  setUserDeviceId,
} from "../../redux/slices/fleet/devices";
import { fetchDevices } from "../../redux/actions/devices/devicesAsyncAction";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { errorsGeneralModal } from "../ErrorModal";
import NotFound from "../NotFound";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setRowsNumber: (rows: GridPaginationModel) =>
      dispatch(setNumberRowsUsers(rows)),
    disableError: () => dispatch(cleanGeneralDeviceError()),

    resetDeviceSection: (
      rowsNumber: GridPaginationModel,
      sectionName: string,
      token: string
    ) => {
      dispatch(fetchDevices(token));
      dispatch(setNumberRowsUsers(rowsNumber));
      dispatch(setTabNameLayout(sectionName));
      dispatch(setUserDeviceId(""));
      dispatch(setNewDeviceName(""));
      dispatch(setDevicesModal(false));
      dispatch(disableErrorSuccessDevices());
    },
  };
};

const UsersDevices: FC = () => {
  const [t] = useTranslation("global");
  const { setRowsNumber, resetDeviceSection, disableError } = SetActions();
  const { user, isViewerOnly } = useSelector(
    (state: State) => state.Authentication
  );

  const { locale, sectionName } = useSelector(
    (state: State) => state.LayoutReducer
  );
  const { error } = useSelector((state: State) => state.DevicesReducer);
  
  const token = user?.token ?? "";

  const errorsDevices = error;
  const concatErrors = error;
  const bodyErrors = errorsGeneralModal(
    JSON.stringify(concatErrors),
    disableError,
    t
  );

  useEffect(() => {
    resetDeviceSection({ page: 1, pageSize: 25 }, "/devices", token);
  }, []);

  const globalComponentsUserDevices = () => (
    <>
      <div className="fleet-table-container">
        <div>
          {UserDevicesTable(t, token, locale, setRowsNumber, sectionName)}
        </div>
      </div>
      <Modal open={!!errorsDevices}>{bodyErrors}</Modal>
    </>
  );

  return (
    <Layout
      title={!isViewerOnly ? t("Fleet_users_devices.My_devices") : ""}
      component={!isViewerOnly ? globalComponentsUserDevices() : <NotFound />}
    />
  );
};

export default UsersDevices;
