import { createSlice } from "@reduxjs/toolkit";
import { ProjectsResponse } from "../../actions/projects/projectsTypes";
import {
  deleteProject,
  downloadFileProjects,
  fetchFilesProjects,
  fetchProjectName,
  fetchProjects,
  patchFileLocation,
  uploadFileProjects,
} from "../../actions/projects/ProjectsAsyncActions";

export interface ProjectsState {
  projects: ProjectsResponse | null;
  projectsInfo: any[];
  loading: boolean;
  loadingUpload: boolean;
  errorFetchingProject: string | null;
  errorNewFile: string | null;
  error: string;
  projectId: number;
  modal: boolean;
  fileDownload: any;
  projectName: string | null;
  fileUpload: string | null | boolean;
  rowsToSearch: any[];
  filters: any;

  fileInfo: {
    file: any;
    fileName: string;
    fileDescription: string;
  };

  tableOptions: {
    page: number;
    rowOptions: number;
    totalRows: number | null;
  };
  deletedFile: boolean;
}
export const initialState: ProjectsState = {
  projects: null,
  projectsInfo: [],
  loading: false,
  loadingUpload: false,
  errorFetchingProject: null,
  errorNewFile: null,
  error: "",
  projectId: 0,
  modal: false,
  fileDownload: null,
  projectName: null,
  fileUpload: false,
  rowsToSearch: [],
  filters: { items: [] },
  fileInfo: {
    file: null,
    fileName: "",
    fileDescription: "",
  },
  tableOptions: {
    page: 0,
    rowOptions: 25,
    totalRows: null,
  },
  deletedFile: false,
};

export const ProjectsSlice = createSlice({
  name: "ProjectsReducer",
  initialState,
  reducers: {
    setProjectId(state, action) {
      return {
        ...state,
        projectId: action.payload,
      };
    },

    disableProjectsError(state) {
      return {
        ...state,
        error: "",
        errorFetchingProject: null,
        errorNewFile: null,
      };
    },

    setTableProjectsOptions(state, action) {
      return {
        ...state,
        tableOptions: action.payload,
      };
    },

    setFilters(state, action) {
      return {
        ...state,
        filters: action.payload,
      };
    },

    setProjectsModal(state, action) {
      return {
        ...state,
        modal: action.payload,
      };
    },

    setFileValues(state, action) {
      return {
        ...state,
        fileInfo: action.payload,
      };
    },

    resetProjectErrors(state) {
      return {
        ...state,
        error: "",
        errorFetchingProject: null,
        errorNewFile: null,
      };
    },

    resetProjectsInfo(state) {
      return {
        ...state,
        projectsInfo: initialState.projectsInfo,
      };
    },

    resetUploadSuccess(state) {
      return {
        ...state,
        fileUpload: initialState.fileUpload,
      };
    },

    setProjectName(state, action) {
      return {
        ...state,
        projectName: action.payload,
      };
    },

    resetDeletedFile(state) {
      return {
        ...state,
        deletedFile: false,
      };
    },

    logoutProjects() {
      return initialState;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = action.payload;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.loading = false;
        state.projects = initialState.projects;
        state.error = action.payload as string;
      });

    builder
      .addCase(fetchFilesProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFilesProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.projectsInfo = action.payload;
      })
      .addCase(fetchFilesProjects.rejected, (state, action) => {
        state.loading = false;
        state.errorFetchingProject = action.payload as string;
        state.error = action.payload as string;
      });

    builder
      .addCase(fetchProjectName.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchProjectName.fulfilled, (state, action) => {
        state.loading = false;
        state.projectName = action.payload;
      })
      .addCase(fetchProjectName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        state.projectName = initialState.projectName;
      });

    builder
      .addCase(patchFileLocation.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(patchFileLocation.fulfilled, (state, action) => {
        state.loading = false;
        state.fileUpload = JSON.stringify(action.payload);
      })
      .addCase(patchFileLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    builder
      .addCase(uploadFileProjects.pending, (state) => {
        state.loadingUpload = true;
        state.errorNewFile = null;
      })
      .addCase(uploadFileProjects.fulfilled, (state, action) => {
        state.loadingUpload = false;
        state.fileUpload = true;
      })
      .addCase(uploadFileProjects.rejected, (state, action) => {
        state.loadingUpload = false;
        state.errorNewFile = action.payload as string;
      });

    builder
      .addCase(downloadFileProjects.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(downloadFileProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.fileDownload = action.payload;
      })
      .addCase(downloadFileProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    builder
      .addCase(deleteProject.pending, (state) => {
        state.loading = true;
        state.deletedFile = false;
        state.error = "";
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.loading = false;
        state.deletedFile = true;
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const {
  resetDeletedFile,
  setProjectId,
  disableProjectsError,
  setTableProjectsOptions,
  setFilters,
  setProjectsModal,
  setFileValues,
  resetProjectErrors,
  resetUploadSuccess,
  resetProjectsInfo,
  logoutProjects,
  setProjectName,
} = ProjectsSlice.actions;

export const Projects = ProjectsSlice.reducer;
