import axios from "axios";
import { API } from "../../../config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../configureStore";
import {
  resetProjectsInfo,
  setTableProjectsOptions,
} from "../../slices/fleet/projects";
import { ProjectFile } from "./projectsTypes";
import mimeTypes from "mime-types";
import fileDownload from "js-file-download";

export const fetchProjects = createAsyncThunk(
  "ProjectsReducer/fetchProjects",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;

    try {
      const response = await axios.get(`${API}/users/project`, {
        headers: {
          "Accept-Language": `${locale}`,
          Authorization: `Bearer ${token}`,
        },
      });

      const { data } = response;
      return data;
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const fetchProjectName = createAsyncThunk(
  "ProjectsReducer/fetchProjectName",
  async (arg: { token: string; projectId: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    try {
      const response = await axios.get(
        `${API}/users/project/${arg.projectId}`,
        {
          headers: {
            "Accept-Language": `${locale}`,
            Authorization: `Bearer ${arg.token}`,
          },
        }
      );
      const { data } = response;

      return data.customerFacingName;
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const deleteProject = createAsyncThunk(
  "ProjectsReducer/deleteProject",
  async (arg: { token: string; projectFileId: string }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { projectId } = (store.getState() as RootState).ProjectsReducer;
    try {
      const response = await axios.delete(
        `${API}/users/project-file/${arg.projectFileId}`,
        {
          headers: {
            "Accept-Language": `${locale}`,
            Authorization: `Bearer ${arg.token}`,
          },
        }
      );
      const { data } = response;

      store.dispatch(fetchFilesProjects({ token: arg.token, id: projectId }));

      return data.customerFacingName;
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const fetchFilesProjects = createAsyncThunk(
  "ProjectsReducer/fetchFilesProjects",
  async (arg: { token: string; id: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { tableOptions, filters } = (store.getState() as RootState)
      .ProjectsReducer;

    const limit = tableOptions.rowOptions;
    const skip = limit * tableOptions.page;
    const filterLength = filters?.items?.length;
    const timing = filters?.items[0]?.value;

    const withFilter = `${API}/users/project-file?projectId=${arg.id}&$limit=${limit}&$skip=${skip}&createdAt[$gt]=${timing}`;
    const normal = `${API}/users/project-file?projectId=${arg.id}&$limit=${limit}&$skip=${skip}`;

    if (filterLength < 1) {
      store.dispatch(resetProjectsInfo());
    }

    try {
      const response = await axios.get(filterLength > 0 ? withFilter : normal, {
        headers: {
          "Accept-Language": `${locale}`,
          Authorization: `Bearer ${arg.token}`,
        },
      });

      const { data } = response;

      store.dispatch(fetchProjectName({ token: arg.token, projectId: arg.id }));
      store.dispatch(
        setTableProjectsOptions({ ...tableOptions, totalRows: data.total })
      );
      const projectsRows = data?.data?.map((projects: ProjectFile) => {
        const rows = {
          userFileName: projects.userFileName,
          createdAt: projects.createdAt,
          id: projects.id,
        };

        return rows;
      });

      const projectsRowsSorted = projectsRows?.sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      return projectsRowsSorted;
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const uploadFile = (presignedUrl: string, file: any): Promise<void> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    let contentType = mimeTypes.contentType(file.name);

    if (!contentType) {
      contentType = "text/plain";
    }

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve();
        } else {
          reject(new Error(`Upload failed with status code: ${xhr.status}`));
        }
      }
    };
    xhr.open("PUT", presignedUrl);
    xhr.setRequestHeader("Content-Type", contentType);
    xhr.send(file);
  });
};

export const patchFileLocation = createAsyncThunk(
  "ProjectsReducer/patchFileLocation",
  async (arg: { token: string; fileProjectId: string }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;

    try {
      const response = await axios.patch(
        `${API}/samples/file-location/${arg.fileProjectId}`,
        { isUploaded: true },
        {
          headers: {
            "Accept-Language": `${locale}`,
            Authorization: `Bearer ${arg.token}`,
          },
        }
      );
      const { data } = response;
      return data;
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const uploadFileProjects = createAsyncThunk(
  "ProjectsReducer/uploadFileProjects",
  async (
    arg: { token: string; fileName: string; fileDescription?: string | null },
    store
  ) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { projectId, fileInfo } = (store.getState() as RootState)
      .ProjectsReducer;
    const compoFileName = fileInfo.file.name.split(".");
    const ext = compoFileName[compoFileName.length - 1];
    const body: any = {
      projectId: projectId,
      fileName:
        arg.fileName.length > ext.length + 1 &&
        arg.fileName.substr(
          arg.fileName.length - (ext.length + 1),
          ext.length + 1
        ) === `.${ext}`
          ? arg.fileName
          : `${arg.fileName}.${ext}`,
      fileDescription: arg.fileDescription,
    };

    try {
      const response = await axios.post(
        `${API}/users/project/upload-file`,
        body,
        {
          headers: {
            "Accept-Language": `${locale}`,
            Authorization: `Bearer ${arg.token}`,
          },
        }
      );
      const { data } = response;
      await uploadFile(data.signedRequest, fileInfo.file);

      store.dispatch(
        patchFileLocation({
          token: arg.token,
          fileProjectId: data.fileLocationId,
        })
      );
      store.dispatch(fetchFilesProjects({ token: arg.token, id: projectId }));

      return store.fulfillWithValue(data);
    } catch (error: any) {
      return store.rejectWithValue(error.message);
    }
  }
);

export const downloadFileProjects = createAsyncThunk(
  "ProjectsReducer/downloadFileProjects",
  async (arg: { token: string; projectFileId: string }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { projectId } = (store.getState() as RootState).ProjectsReducer;

    try {
      const response = await axios.get(
        `${API}/users/project/download-file/${arg.projectFileId}?projectId=${projectId}`,
        {
          headers: {
            "Accept-Language": `${locale}`,
            Authorization: `Bearer ${arg.token}`,
          },
        }
      );
      const { data } = response;
      const { signedRequest, fileName } = data;

      const awsDownloadRes = await axios({
        url: signedRequest,
        method: "GET",
        responseType: "blob",
      });

      fileDownload(awsDownloadRes.data, fileName);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);
