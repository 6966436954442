import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import TableDashboardColumns from "./FetchingColumns";
import themeInarix from "../../StylesMuiInarix";
import { DataGrid } from "@mui/x-data-grid";

export function PocketLabTable(t: (arg0: string) => string, locale: string) {
  const { pocketLabColumnValues, pocketLabResults } = TableDashboardColumns(t);

  return (
    <Box
      sx={{
        height: 444,
        minWidth: 700,
        ["@media(max-width: 1330px)"]: {
          width: 1,
          minWidth: 400,
          maxWidth: 698,
        },
      }}
    >
      <ThemeProvider theme={themeInarix(locale)}>
        <DataGrid
          rows={pocketLabResults}
          columns={pocketLabColumnValues}
          pageSizeOptions={[25, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          rowHeight={23.5}
          sx={{ border: "none" }}
        />
      </ThemeProvider>
    </Box>
  );
}
