import DataInputSearch from "./dataPanelOptions/DataSearchInput";

import { ExportButtonIcon } from "./dataPanelOptions/DataExportButtons";
import { ExtraFiltersButton } from "./dataPanelOptions/DataExtraFiltersbutton";
import DataDateRangePickerWithButtonField from "./dataPanelOptions/rangePicker";
import { ColumnsButton } from "./dataPanelOptions/DataColumnsButton";
import SaveViews from "./dataViews/DataSaveView";
import { GridApi } from "@mui/x-data-grid-pro";

const TopDataPannel = (
  t: (arg0: string) => string,
  token: string,
  apiRef: React.MutableRefObject<GridApi>
) => {
  return (
    <div className="panel-options">
      <div className="options-left-data-panel">
        <div className="data-date-range-picker">
          {DataDateRangePickerWithButtonField()}
        </div>
        <div className="data-extra-filters">{ExtraFiltersButton(t)}</div>
        <div className="data-extra-filters">{ColumnsButton(t, token)}</div>
        <div className="data-extra-filters">{DataInputSearch(t, token)}</div>
        {/* <div className="data-extra-filters">{SaveViews(apiRef)}</div> */}
      </div>
      <div className="options-right-data-panel">
        <div>{ExportButtonIcon(t)}</div>
      </div>
    </div>
  );
};

export default TopDataPannel;
