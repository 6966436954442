import React, { FormEvent } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useStyles } from "../../modals/modalStyles";

import { State } from "../../../redux/slices";
import { Alert, TextField } from "@mui/material";

import { uploadFileProjects } from "../../../redux/actions/projects/ProjectsAsyncActions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  initialState,
  resetProjectErrors,
  setFileValues,
  setProjectsModal,
} from "../../../redux/slices/fleet/projects";
import {
  ButtonCancelModals,
  ButtonConfirmModals,
} from "../../userGroups/UserTabsStyles";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    resetValuesProjects: () => {
      dispatch(setProjectsModal(false));
      dispatch(setFileValues(initialState.fileInfo));
    },
    disableError: () => dispatch(resetProjectErrors()),

    setFileValues: (values: {
      file: any;
      fileName: string;
      fileDescription: string;
    }) => dispatch(setFileValues(values)),

    addNewFile: (
      token: string,
      fileName: string,
      fileDescription?: string | null
    ) => {
      dispatch(uploadFileProjects({ token, fileName, fileDescription }));
    },
  };
};

export const BodyProjects = (
  t: (arg0: string) => string,
  token: string
): JSX.Element => {
  const {
    resetValuesProjects,
    disableError,

    addNewFile,
    setFileValues,
  } = SetActions();

  const { error, errorNewFile, loadingUpload, fileInfo } = useSelector(
    (state: State) => state.ProjectsReducer
  );

  const classes = useStyles();

  const errorModalMsg = () => {
    if (errorNewFile || error) {
      setTimeout(() => {
        disableError();
      }, 10000);
      return (
        <div className="mb-2">
          <Alert severity="warning">
            {JSON.stringify(errorNewFile || error)}
          </Alert>
        </div>
      );
    }
  };

  const submitHandler = (event: FormEvent) => {
    event.preventDefault();
    addNewFile(token, fileInfo.fileName, fileInfo.fileDescription);
  };

  const onChange = (e: React.SyntheticEvent<EventTarget>): void => {
    const fileRef = (e.target as any).files[0];
    setFileValues({ ...fileInfo, file: fileRef, fileName: fileRef.name });
  };

  return (
    <section>
      <div className={classes.confirmModal}>
        <form onSubmit={submitHandler}>
          <div>
            {errorModalMsg()}
            <span className="delete-title mb-5">
              {t("Projects.Body.New_file")}
            </span>
            <input type="file" className="mt-2" onChange={onChange} required />
            <TextField
              name="fileName"
              autoComplete="off"
              value={fileInfo.fileName}
              onChange={(e) =>
                setFileValues({ ...fileInfo, fileName: e.currentTarget.value })
              }
              label={t("Projects.Body.File_name")}
              className="form-control input-pass mt-5"
              required
            />
            <TextField
              name="fileDescription"
              autoComplete="off"
              value={fileInfo.fileDescription}
              onChange={(e) =>
                setFileValues({
                  ...fileInfo,
                  fileDescription: e.currentTarget.value,
                })
              }
              label={t("Projects.Body.File_description")}
              className="form-control input-pass mt-5 mb-5"
            />

            <div className="buttons-position-options">
              <ButtonCancelModals
                type="button"
                onClick={resetValuesProjects}
                disabled={loadingUpload}
                size="large"
                variant="outlined"
                className="mr-2"
              >
                {t("Fleet_overview.overview_panel.deleteLocation.cancel")}
              </ButtonCancelModals>

              <ButtonConfirmModals
                type="submit"
                variant="contained"
                disabled={loadingUpload}
              >
                {t("Projects.Body.Add_new_file")}
              </ButtonConfirmModals>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default BodyProjects;
