import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { useDispatch, useSelector } from "react-redux";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { Box, Paper, FormControlLabel, Divider } from "@mui/material";
import { inarixColors } from "inarix-theme";

import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import { useTheme } from "@mui/material/styles";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import Typography from "@mui/material/Typography";
import { ThunkDispatch } from "@reduxjs/toolkit";

import { DashboardLocIdInfo } from "../../../redux/actions/dashboard/dashboardTypes";
import {
  DataSBFilterValues,
  DataSBFilters,
  DataTableRow,
  setExtraFiltersValuesAutocomplete,
} from "../../../redux/slices/data/data";

import { State } from "../../../redux/slices";

import {
  fetchSBActionsNames,
  fetchSBLocationNames,
  fetchSBUserNames,
} from "../../../redux/actions/data/DataSBExtraFiltersAsyncActions";
import { FiltersDataTableObject } from "../dataHelpers/DataProgress";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    fetchActionsAndLocations: (token: string, typeOfAutocomplete: string) => {
      if (typeOfAutocomplete === "locations") {
        dispatch(fetchSBLocationNames(token));
      }
      if (typeOfAutocomplete === "actions") {
        dispatch(fetchSBActionsNames(token));
      }
      if (typeOfAutocomplete === "users") {
        dispatch(fetchSBUserNames(token));
      }
    },

    selectLocations: (
      value: DashboardLocIdInfo[],
      dataSBFilters: DataSBFilters,
      typeOfAutocomplete: string,
      map: Record<keyof DataTableRow, keyof DataTableRow>
    ) => {
      if (typeOfAutocomplete === "locations") {
        const values = value?.map((l: any) => {
          return l.id;
        });

        dispatch(
          setExtraFiltersValuesAutocomplete({
            ...dataSBFilters,
            locations: { valuesSelected: value },
            filtersLocation: FiltersDataTableObject(
              map,
              "geoPointName",
              values,
              "="
            ),
          })
        );
      }
      if (typeOfAutocomplete === "actions") {
        const values = value?.map((l: any) => {
          return l.id;
        });
        dispatch(
          setExtraFiltersValuesAutocomplete({
            ...dataSBFilters,
            actions: { valuesSelected: value },
            filterActions: FiltersDataTableObject(
              map,
              "actionName",
              values,
              "="
            ),
          })
        );
      }

      if (typeOfAutocomplete === "users") {
        const values = value?.map((l) => {
          return l.name;
        });
        dispatch(
          setExtraFiltersValuesAutocomplete({
            ...dataSBFilters,
            users: { valuesSelected: value },
            filterUsers: FiltersDataTableObject(map, "username", values, "="),
          })
        );
      }
    },

    setSelectAll: (
      setSilos: boolean,
      dataSBFilters: DataSBFilters,
      value: DashboardLocIdInfo[],
      typeOfAutocomplete: string,
      map: Record<keyof DataTableRow, keyof DataTableRow>
    ) => {
      if (typeOfAutocomplete === "locations") {
        dispatch(
          setExtraFiltersValuesAutocomplete({
            ...dataSBFilters,
            locations: {
              allValuesSelected: setSilos,
              valuesSelected: value,
            },
            filtersLocation: FiltersDataTableObject(
              map,
              "geoPointName",
              value.map((l: any) => l.id),
              "="
            ),
          })
        );
      }
      if (typeOfAutocomplete === "actions") {
        dispatch(
          setExtraFiltersValuesAutocomplete({
            ...dataSBFilters,
            actions: {
              allValuesSelected: setSilos,
              valuesSelected: value,
            },

            filterActions: FiltersDataTableObject(
              map,
              "actionName",
              value.map((l: any) => l.id),
              "="
            ),
          })
        );
      }
      if (typeOfAutocomplete === "users") {
        dispatch(
          setExtraFiltersValuesAutocomplete({
            ...dataSBFilters,
            users: {
              allValuesSelected: setSilos,
              valuesSelected: value,
            },
            filterUsers: FiltersDataTableObject(
              map,
              "username",
              value.map((l: any) => l.id),
              "="
            ),
          })
        );
      }
    },

    resetSelectAll: (
      dataSBFilters: DataSBFilters,
      typeOfAutocomplete: string
    ) => {
      if (typeOfAutocomplete === "locations") {
        dispatch(
          setExtraFiltersValuesAutocomplete({
            ...dataSBFilters,
            locations: {
              allValuesSelected: false,
              valuesSelected: [],
            },
            filtersLocation: undefined,
          })
        );
      }
      if (typeOfAutocomplete === "actions") {
        dispatch(
          setExtraFiltersValuesAutocomplete({
            ...dataSBFilters,
            actions: {
              allValuesSelected: false,
              valuesSelected: [],
            },
            filterActions: undefined,
          })
        );
      }
      if (typeOfAutocomplete === "users") {
        dispatch(
          setExtraFiltersValuesAutocomplete({
            ...dataSBFilters,
            users: {
              allValuesSelected: false,
              valuesSelected: [],
            },
            filterUsers: undefined,
          })
        );
      }
    },
  };
};

const LISTBOX_PADDING = 8;

export function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;

  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={dataSet[2]}
      />
      {`${dataSet[1]}`}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
export const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: any[] = [];
  (children as any[]).forEach((item: any) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: any) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: any) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const AutocompleteDataExtraFiltersInput = (
  token: string,
  typeOfAutocomplete: string,
  dataOptions: DashboardLocIdInfo[],
  allValuesSelected: boolean,
  valuesSelected: DataSBFilterValues[],
  t: (arg0: string) => string
) => {
  const {
    selectLocations,
    setSelectAll,
    resetSelectAll,
    fetchActionsAndLocations,
  } = SetActions();
  const { dataSBFilters, filterMap, dataSamplesLoading } = useSelector(
    (state: State) => state.DataReducer
  );

  const TypeOfLabel = () => {
    if (typeOfAutocomplete === "locations") {
      return t("Data.Search_sites");
    }
    if (typeOfAutocomplete === "actions") {
      return t("Data.Search_actions");
    }
    if (typeOfAutocomplete === "users") {
      return t("Data.Search_users");
    }
  };

  const transform = dataOptions?.map((c: DashboardLocIdInfo) => c);

  return (
    <Autocomplete
      sx={{
        overflowY: valuesSelected.length > 2 ? "scroll" : "hidden",
        height: valuesSelected.length > 2 ? "120px" : "60px",
        border: 1,
        borderColor: inarixColors.secondary_text_inarix,
        borderRadius: "5px",
        width: 350,

        "&:hover": {
          border: `1.3px solid ${inarixColors.light_inarix}`,
          color: "gray",
        },
      }}
      disabled={dataSamplesLoading}
      className="autocomplete-dashboard mb-5"
      id={"autocomplete_with_select_all"}
      multiple
      disableListWrap={true}
      options={dataOptions}
      disableCloseOnSelect
      ListboxComponent={ListboxComponent}
      value={valuesSelected as any}
      getOptionLabel={(option: DashboardLocIdInfo) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_e, value, reason) => {
        if (reason === "clear" || reason === "removeOption")
          resetSelectAll(dataSBFilters, typeOfAutocomplete);

        if (reason === "selectOption" && value.length === dataOptions.length)
          setSelectAll(
            true,
            dataSBFilters,
            transform,
            typeOfAutocomplete,
            filterMap
          );
        selectLocations(value, dataSBFilters, typeOfAutocomplete, filterMap);

        if (reason === "selectOption" && dataOptions.length === 0)
          console.log("");
      }}
      renderInput={(params) => (
        <TextField
          sx={{
            "& .MuiInput-underline:after": {
              borderColor: "transparent",
            },

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "transparent",
              },
              "&:hover fieldset": {
                borderColor: "transparent",
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent",
              },
            },
          }}
          {...params}
          onClick={() => fetchActionsAndLocations(token, typeOfAutocomplete)}
          placeholder={TypeOfLabel()}
        />
      )}
      renderOption={(props, option, { selected }) =>
        [props, option.name, selected] as React.ReactNode
      }
      PaperComponent={(paperProps) => {
        const { children, ...restPaperProps } = paperProps;
        return (
          <Paper {...restPaperProps}>
            <Box onMouseDown={(e) => e.preventDefault()} pl={1.5} py={0.5}>
              <FormControlLabel
                onClick={(e) => {
                  if (dataOptions.length >= 1) {
                    e.preventDefault();
                    !allValuesSelected
                      ? setSelectAll(
                          true,
                          dataSBFilters,
                          transform,
                          typeOfAutocomplete,
                          filterMap
                        )
                      : resetSelectAll(dataSBFilters, typeOfAutocomplete);
                  }
                }}
                disabled={dataOptions.length === 0}
                label={t("Dashboard.setAllLocations")}
                control={
                  <Checkbox
                    disabled={dataOptions.length === 0}
                    id="select-all-checkbox"
                    checked={allValuesSelected}
                  />
                }
              />
            </Box>
            <Divider />
            {children}
          </Paper>
        );
      }}
      renderGroup={(params) => params as unknown as React.ReactNode}
    />
  );
};
export default AutocompleteDataExtraFiltersInput;
