import axios from "axios";
import { API } from "../../../config";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../configureStore";

import { setSelectionModelUsers } from "../../slices/fleet/users";
import { UserData } from "./userTypes";

export const fetchUsersNewRequest = createAsyncThunk(
  "UsersReducer/fetchUsersNewRequest",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;

    const queryParams = [
      "$eager=[groups,associatedGeoPoint]",
      "$modifyEager[groups.deletedAt]=null",
    ].join("&");

    try {
      const response = await axios.get(`${API}/users/user?${queryParams}`, {
        headers: {
          "Accept-Language": `${locale}`,
          Authorization: `Bearer ${token}`,
        },
      });
      const { data } = response;
      return store.fulfillWithValue(data);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const fetchGroups = createAsyncThunk(
  "UsersReducer/fetchGroups",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    try {
      const response = await axios.get(
        `${API}/users/organization-user-group?deletedAt=null&$limit=50`,
        {
          headers: {
            "Accept-Language": `${locale}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response;

      return store.fulfillWithValue(data);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const moveTogroup = async (
  token: string,
  userId: string,
  groupId: number,
  locale: string
): Promise<UserData> => {
  const res = await axios({
    method: "POST",
    url: `${API}/users/user-group-membership`,
    data: { userId: userId, groupId: groupId },
    headers: {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = res;
  return data;
};

export const moveAllGroup = createAsyncThunk(
  "UsersReducer/moveAllGroup",
  async (token: string, store) => {
    const { usersSelected, groupId } = (store.getState() as RootState)
      .UsersReducer;
    const { locale } = (store.getState() as RootState).LayoutReducer;
    try {
      const userPromises = usersSelected?.map((elt: string) => {
        const convert = elt.toString();
        return moveTogroup(token, convert, groupId, locale);
      });
      const move = await Promise.all(userPromises);

      store.fulfillWithValue(move);
      store.dispatch(fetchGroups(token));
      store.dispatch(fetchUsersNewRequest(token));
      store.dispatch(setSelectionModelUsers([]));
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const deleteSingleUserFromGroup = async (
  token: string,
  userId: string,
  groupId: number
): Promise<UserData> => {
  const res = await axios({
    method: "DELETE",
    url: `${API}/users/user-group-membership?userId=${userId}&groupId=${groupId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = res;
  return data;
};

export const deleteUserFromGroup = createAsyncThunk(
  "UsersReducer/deleteUserFromGroup",
  async (arg: { token: string; userId: string; groupId: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    try {
      const response = await axios.delete(
        `${API}/users/user-group-membership?userId=${arg.userId}&groupId=${arg.groupId}`,
        {
          headers: {
            "Accept-Language": `${locale}`,
            Authorization: `Bearer ${arg.token}`,
          },
        }
      );

      store.fulfillWithValue(response);
      store.dispatch(fetchGroups(arg.token));
      store.dispatch(fetchUsersNewRequest(arg.token));
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const deleteMultipleUsersFromGroup = createAsyncThunk(
  "UsersReducer/deleteMultipleUsersFromGroup",
  async (token: string, store) => {
    const { usersSelected, groupId } = (store.getState() as RootState)
      .UsersReducer;

    try {
      const userPromises = usersSelected.map((elt: string) => {
        const convert = elt.toString();
        return deleteSingleUserFromGroup(token, convert, groupId);
      });
      const remove = await Promise.all(userPromises);

      store.fulfillWithValue(remove);
      store.dispatch(fetchGroups(token));
      store.dispatch(fetchUsersNewRequest(token));
      store.dispatch(setSelectionModelUsers([]));
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const moveRemoveAddLocToUser = async (
  token: string,
  userId: string,
  geoPointId: number,
  method: string,
  locale: string
): Promise<UserData> => {
  const dataInfo = () => {
    if (method === "addLoc" || method === "editLoc") {
      return geoPointId;
    }
    if (method === "removeLoc") {
      return null;
    }
  };

  const res = await axios({
    method: "PATCH",
    url: `${API}/users/user/${userId}`,
    headers: {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    },
    data: {
      associatedGeoPointId: dataInfo(),
    },
  });

  const { data } = res;
  return data;
};

export const modifyAllUserToLoc = createAsyncThunk(
  "UserReducer/modifyAllUserToLoc",
  async (token: string, store) => {
    const { usersSelected, groupId, optionToModify } = (
      store.getState() as RootState
    ).UsersReducer;

    const { locale } = (store.getState() as RootState).LayoutReducer;

    try {
      const userPromises = usersSelected.map((elt: string) => {
        const convert = elt.toString();

        return moveRemoveAddLocToUser(
          token,
          convert,
          groupId,
          optionToModify,
          locale
        );
      });
      const move = await Promise.all(userPromises);

      store.fulfillWithValue(move);
      store.dispatch(fetchGroups(token));
      store.dispatch(fetchUsersNewRequest(token));
      store.dispatch(setSelectionModelUsers([]));
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);
