import { FC, useEffect } from "react";
import { State } from "../../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import NotFound from "../../NotFound";
import Layout from "../../layout/Layout";
import DataSampleViewLeft from "./DataSampleLeftSide";
import DataSampleViewRight from "./DataSampleRightSide";
import { Modal } from "@mui/material";
import { BodyCarouselPhotos } from "./DataSampleViewCarouselModal";
import {
  initialState,
  setAnnotationValuesToEdit,
  setModalEditAnnotation,
  setOpenCloseModalPhotos,
} from "../../../redux/slices/data/dataSampleView";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { sampleDetails } from "../../../redux/actions/data/dataSampleViewAsyncActions";
import { BodyEditDeleteAnnotation } from "./DataSampleViewAnnotationModal";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { goBackFunction } from "../dataHelpers/DataProgress";
import { BodyLoadingSampleViewModal } from "./DataSampleViewLoadingModal";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    disableErrorActionView: (navigate: any) => {
      navigate(`/data`);
    },

    setOpenCloseModalPhotos: (visible: boolean) => {
      dispatch(setOpenCloseModalPhotos(visible));
    },
    fetchSampleDetails: (token: string, locale: string, pastAction: string) => {
      dispatch(sampleDetails({ token, locale, pastAction }));
    },

    setModalEditAnnotation: (value: any) => {
      dispatch(setAnnotationValuesToEdit(value));
    },

    closeModal: () => {
      dispatch(setModalEditAnnotation(false));
    },
  };
};

const DataSampleView: FC = () => {
  const [t] = useTranslation("global");
  const { id } = useParams();
  const bodyModalLoadingSampleView = BodyLoadingSampleViewModal();
  const navigate = useNavigate();
  const { user, isViewerOnly } = useSelector(
    (state: State) => state.Authentication
  );
  const token = user?.token ?? "";
  const {
    sampleViewInfo,
    modalPhotos,
    modalEditAnnotation,
    error,
    loadSampleDetails,
  } = useSelector((state: State) => state.DataSampleViewReducer);
  const { locale } = useSelector((state: State) => state.LayoutReducer);

  const bodyCarouselPhotosValues = BodyCarouselPhotos();
  const bodyEditDeleteAnnotation = BodyEditDeleteAnnotation(t, token);

  const {
    setOpenCloseModalPhotos,
    fetchSampleDetails,
    setModalEditAnnotation,
    closeModal,
    disableErrorActionView,
  } = SetActions();

  const showError = () => {
    disableErrorActionView(navigate);
  };

  if (error) {
    showError();
  }
  const resetEdit = () => {
    closeModal();
    setModalEditAnnotation(initialState.annotationSelectedValuesToEdit);
  };

  const headerInfo = () => {
    return (
      <div className="sample-header">
        {goBackFunction(
          navigate,
          t,
          "Data.title",
          `/data`,
          "Data.table.Action"
        )}
        <div className="sample-info-container">
          <div className="sample-id-title">{`${t(
            "Data.table.Action"
          )} ${sampleViewInfo?.id?.slice(0, 8)}`}</div>
          <div className="sample-inarix-id-title">{sampleViewInfo?.id}</div>
        </div>
      </div>
    );
  };

  const globalComponentsSampleView = () => (
    <div>
      <div className="sample-view-container">
        {headerInfo()}
        <div className="left-right-contain">
          <DataSampleViewLeft />
          <DataSampleViewRight />
        </div>
      </div>
      <Modal open={modalPhotos} onClose={() => setOpenCloseModalPhotos(false)}>
        {bodyCarouselPhotosValues}
      </Modal>
      <Modal open={modalEditAnnotation} onClose={resetEdit}>
        {bodyEditDeleteAnnotation}
      </Modal>

      <Modal open={loadSampleDetails}>{bodyModalLoadingSampleView}</Modal>
    </div>
  );

  useEffect(() => {
    fetchSampleDetails(token, locale, id as string);
  }, []);

  return (
    <Layout
      title={""}
      component={!isViewerOnly ? globalComponentsSampleView() : <NotFound />}
    />
  );
};

export default DataSampleView;
