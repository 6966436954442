import { createSlice } from "@reduxjs/toolkit";
import { OrgGeoPointData } from "../../actions/locations/locationsTypes";
import { fetchLocationsWithActivity } from "../../actions/map/mapAsyncActions";
import { ValuesCreateEditLocation } from "../map/mapTypes";
import { patchLocationName } from "../../actions/locations/locationsAsyncActions";

export interface LocationsState {
  locations: OrgGeoPointData[];
  locationCurrentName: OrgGeoPointData | null;
  locationId: number;
  locationNewName: string;
  error: string;
  errorPatch: string;
  loading: boolean;
  loadingPatch: boolean;
  msg: string;
  modal: boolean;
  initRows: any[];
  editLocation: ValuesCreateEditLocation;
  rowLocations: any[];
}

export const initialState: LocationsState = {
  locations: [],
  locationCurrentName: null,
  locationId: 0,
  locationNewName: "",
  error: "",
  errorPatch: "",
  loading: false,
  loadingPatch: false,
  msg: "",
  modal: false,
  initRows: [],

  editLocation: {
    name: "",
    type: "",
    primaryContactName: "",
    telephone: "",
    email: "",
    address: "",
    notes: "",
    remoteId: "",
    countryName: "",
  },
  rowLocations: [],
};

export const LocationsSlice = createSlice({
  name: "LocationsReducer",
  initialState,
  reducers: {
    setLoadingUserLoc(state, action) {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setFilterFromLocToUserTabs(state, action) {
      return {
        ...state,
        filterModelLocation: {
          items: [
            {
              columnField: "location",
              operatorValue: "contains",
              value: action.payload,
            },
          ],
        },
      };
    },

    setModalLocations(state, action) {
      return {
        ...state,
        modal: action.payload,
      };
    },
    setUserLocationName(state, action) {
      return {
        ...state,
        locationCurrentName: action.payload,
      };
    },
    setUserLocationId(state, action) {
      return {
        ...state,
        locationId: action.payload,
      };
    },

    setNewLocationName(state, action) {
      return {
        ...state,
        locationNewName: action.payload,
      };
    },
    cleanUserLocationMessage(state) {
      return {
        ...state,
        msg: "",
        errorPatch: "",
        error: "",
      };
    },
    setNewValuesUpdateLocation(state, action) {
      return {
        ...state,
        editLocation: action.payload,
      };
    },

    resetLocationsState() {
      return initialState;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchLocationsWithActivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLocationsWithActivity.fulfilled, (state, action) => {
        state.loading = false;
        state.locations = action.payload;
      })
      .addCase(fetchLocationsWithActivity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    builder
      .addCase(patchLocationName.pending, (state) => {
        state.loadingPatch = true;
      })
      .addCase(patchLocationName.fulfilled, (state, action) => {
        state.loadingPatch = false;
        state.msg = action.payload as unknown as string;
      })
      .addCase(patchLocationName.rejected, (state, action) => {
        state.loadingPatch = false;
        state.errorPatch = action.payload as string;
      });
  },
});

export const {
  setLoadingUserLoc,
  setFilterFromLocToUserTabs,
  setModalLocations,
  setUserLocationName,
  setUserLocationId,
  setNewLocationName,
  cleanUserLocationMessage,
  setNewValuesUpdateLocation,
  resetLocationsState,
} = LocationsSlice.actions;

export const Locations = LocationsSlice.reducer;
