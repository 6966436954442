import { makeStyles, withStyles } from "@mui/styles";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import { inarixColors } from "inarix-theme";

export const AccordionDetails = withStyles({
  root: {
    backgroundColor: inarixColors.background_Components,
    borderRadius: "2px",
    borderBottom: `1px solid ${inarixColors.box_shadow_components}`,
    marginBottom: -1,
    minHeight: 56,
    "@media(max-width: 769px)": {
      width: "350px",
    },
  },
})(MuiAccordionDetails);

export const AccordionSummary = withStyles({
  root: {
    width: "100%",
  },
})(MuiAccordionSummary);

export const useStyles = makeStyles({
  root: {
    padding: "2px 4px",
    margin: "10px",
    display: "flex",
    alignItems: "center",

    width: 350,
    "@media(max-width: 769px)": {
      width: 150,
      height: 40,
    },
  },
  confirmModal: {
    position: "absolute",
    width: 400,
    backgroundColor: inarixColors.contrast_inarix,
    border: `2px solid ${inarixColors.contrast_inarix}`,
    borderRadius: "4px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "24px 24px  24px",
  },

  exportModal: {
    position: "absolute",
    maxWidth: "409px",
    backgroundColor: inarixColors.contrast_inarix,
    border: `2px solid ${inarixColors.contrast_inarix}`,
    borderRadius: "4px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  filterButton: {},
  selector: {
    background: inarixColors.contrast_inarix,
    color: inarixColors.primary_text_inarix,
    fontSize: "12px",
  },
  selector2: {
    background: inarixColors.contrast_inarix,
    marginLeft: 560,
    display: "flex",
    boxShadow: "none",
    color: inarixColors.primary_text_inarix,
    fontSize: "12px",
    marginTop: 13,
    "@media(max-width: 1024px)": {
      marginLeft: 640,
      marginTop: 18,
    },
    "@media(max-width: 768px)": {
      marginLeft: 50,
    },
  },
  iconColumns: {
    fontSize: "19px",
  },
  typography: {
    padding: "2px",
  },
  inputSearch: {
    marginLeft: "1px",
    flex: 1,
  },
  popover: {
    height: "250px",
    marginTop: "190px",
    "@media(max-height: 720px)": {
      marginTop: "150px",
    },
  },

  iconButton: {
    padding: 10,
    cursor: "pointer",
    "@media(max-width: 769px)": {
      width: 15,
      height: 15,
    },
  },
  modalExport: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    backgroundColor: "transparent",
    boxShadow: "5px",
    padding: "2px 4px 3px",
    borderRadius: "10px",
  },
  applyFilter: {
    fontSize: "12px",
    fontWeight: 500,
    marginTop: "2px",
    color: inarixColors.main_inarix,

    cursor: "pointer",

    "@media(max-width: 769px)": {
      fontSize: "12px",
    },
  },

  divider: {
    height: 28,
    margin: 4,
  },

  /**
   * @SampleView
   */

  card: {
    minWidth: 275,
    display: "flex",
    alignItems: "center",
    padding: "10px",
    backgroundColor: inarixColors.background_Components,
  },
  accordion: {
    backgroundColor: inarixColors.background_Components,
    borderRadius: "2px",
    borderBottom: `1px solid ${inarixColors.box_shadow_components}`,
    marginBottom: -1,
    minHeight: 56,
    width: "700px",
    fontSize: "17.5px",
    fontWeight: 700,
    color: inarixColors.primary_text_inarix,
    "@media(max-width: 769px)": {
      width: "350px",
    },
  },
  accordionBadAcquisition: {
    backgroundColor: inarixColors.cancel_button,
    borderRadius: "2px",
    borderBottom: `1px solid ${inarixColors.box_shadow_components}`,
    marginBottom: -1,
    minHeight: 56,
    width: "700px",
    fontSize: "17.5px",
    fontWeight: 700,
    color: inarixColors.primary_text_inarix,
    "@media(max-width: 769px)": {
      width: "350px",
    },
  },
  switches: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  circularProgress: {
    fontSize: "12px",
    width: 1,
    height: 1,
  },
  accordionBadAcquisitionError: {
    backgroundColor: inarixColors.tables_action_color,
    borderRadius: "2px",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    width: "700px",
    fontSize: "17.5px",
    fontWeight: 700,
    color: inarixColors.primary_text_inarix,
    "@media(max-width: 769px)": {
      width: "350px",
    },
  },
  cellTainted: {
    height: "100%",
    width: "100%",
    "& .cold": {
      backgroundColor: inarixColors.action_selected_inarix,
      color: inarixColors.secondary_text_inarix,
    },
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: inarixColors.contrast_inarix,
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "20px",
    padding: "2px 4px 3px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  modalType2: {
    position: "absolute",
    width: 400,
    height: 280,
    backgroundColor: inarixColors.contrast_inarix,
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "20px",
    padding: "2px 4px 3px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    width: 348,
    height: 348,
    overflow: "scroll",
    color:
      theme.palette.mode === "light"
        ? inarixColors.action_selected_inarix
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
