import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetMapModifyLocation,
  setSearchLocationMap,
  setSideBarGeoLocOpenClose,
  setSearchSidebarOpenClose,
  setSidebarViews,
  setViewValues,
} from "../../../redux/slices/map/map";

import StarIcon from "@mui/icons-material/Star";
import SearchIcon from "@mui/icons-material/Search";

import { State } from "../../../redux/slices";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { ChipInarixMap } from "../../overview/OverviewStyles";
import { getMapViews } from "../../../redux/actions/map/mapAsyncActions";
import DateRangePickerWithButtonField from "../mapActivityRangePicker/MapActivityRangePicker";
import { inarixColors } from "inarix-theme";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    modifyLocationFalse: () => dispatch(resetMapModifyLocation()),
    sideBarSearchOpen: () => {
      dispatch(setSearchSidebarOpenClose(true));
      dispatch(setSideBarGeoLocOpenClose(false));
    },

    setValue: (value: string) => dispatch(setSearchLocationMap(value)),

    setSidebarViews: (open: boolean, token: string) => {
      dispatch(setSidebarViews(open));
      dispatch(getMapViews(token));
      dispatch(setViewValues({ nameView: "", viewDescription: "", email: "" }));
    },
  };
};

const OverviewUniversalPanel = (
  t: (arg0: string) => string,
  token: string
): JSX.Element => {
  const {
    modifyLocation,
    createLocationActive,
    sidebarGeoLoc,
    sideBarViews,
    sidebarSearch,
  } = useSelector((state: State) => state.MapReducer);

  const { layoutActive } = useSelector((state: State) => state.LayoutReducer);

  const { sideBarSearchOpen, setSidebarViews } = SetActions();

  const chipsPosition = () => {
    if (!layoutActive) {
      return "position-button-panel";
    } else {
      return "position-button-panel-open";
    }
  };

  const leyendPanel = (color: string, leyend: string) => {
    return (
      <div className="leyend-pos">
        <div className="div-leyend" style={{ background: color }} />
        <span className="leyend-font-style">{leyend}</span>
      </div>
    );
  };
  const panelTool = () => {
    return (
      <div>
        <div className={chipsPosition()}>
          <div className="panel-container-options">
            {!createLocationActive &&
            !sidebarGeoLoc &&
            !sidebarSearch &&
            !sideBarViews &&
            !modifyLocation ? (
              <>
                <div style={{ marginRight: "7px" }}>
                  <ChipInarixMap
                    onClick={sideBarSearchOpen}
                    data-cy="search-button"
                    icon={<SearchIcon className="icon-chip-overview" />}
                    label={t("Fleet_overview.overview_panel.chips.Search")}
                  />
                </div>

                <div style={{ marginRight: "7px" }}>
                  <ChipInarixMap
                    data-cy="views-button"
                    onClick={() => setSidebarViews(true, token)}
                    icon={<StarIcon className="icon-chip-overview" />}
                    label={t("Fleet_overview.overview_panel.chips.My_views")}
                  />
                </div>
              </>
            ) : (
              <div className="spacer-options-map" />
            )}

            <div style={{ marginRight: "7px" }}>
              {DateRangePickerWithButtonField()}
            </div>
            <div className="leyend-panel">
              {leyendPanel(
                inarixColors.locationWithActivityBackground,
                t("Fleet_overview.overview_panel.leyend_active_user")
              )}
              {leyendPanel(
                inarixColors.locationWithoutActivityBackground,
                t("Fleet_overview.overview_panel.leyend_inactive_user")
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="overview-panel-div">
      <div>{panelTool()}</div>
    </div>
  );
};

export default OverviewUniversalPanel;
