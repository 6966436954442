import { createSlice } from "@reduxjs/toolkit";

import {
  AvgBarleyWheatChart,
  ChartActivityInfo,
  ChartBarleySoftWheat,
  DashboardLocIdInfo,
  GeoLocaDashboard,
} from "../../actions/dashboard/dashboardTypes";
import {
  fetchActivityPocketLabDataDashboard2,
  fetchDashboardLocationNames,
  fetchGeoLocDataDashboard,
  getPocketLabRecentVersion,
} from "../../actions/dashboard/dashboardAsyncActions";

export interface DashboardState {
  searchDashboard: string;
  locationsNames: DashboardLocIdInfo[];
  loadingLocationsNames: boolean;
  loadingDashboardInfo: boolean;
  dateRangePickerDashboard: boolean;
  locationDateActivityDashboard: Date[];
  isComingFromMap: boolean;
  geoLocInfo: GeoLocaDashboard;
  error: string;
  geoLocNameSelected: string;
  chartActivity: ChartActivityInfo[];
  chartBarleyVarietyActions: ChartBarleySoftWheat[];
  chartSoftVarietyActions: ChartBarleySoftWheat[];
  avgProtPerBarleyVar: AvgBarleyWheatChart[];
  avgProtPerWheatVar: AvgBarleyWheatChart[];
  allLocations: boolean;
  locationsSelected: DashboardLocIdInfo[];
  pocketlabVersion: string;
  dashboardNewActivityChart: any[];
  pocketLabChart: any[];
  rawRowsActivityTable: any[];
  countOfUsers: {
    countOfUsersActive: number;
    countOfUsersInactive: number;
    totalUsers: number;
  };
  rawRowsPocketLabTable: any[];
  comesFromMap: boolean;
}

export const initialState: DashboardState = {
  locationsNames: [],
  locationDateActivityDashboard: [
    new Date(new Date().setDate(new Date().getDate() - 30)),
    new Date(new Date().setDate(new Date().getDate())),
  ],
  searchDashboard: "",
  loadingLocationsNames: false,
  loadingDashboardInfo: false,
  dateRangePickerDashboard: false,
  pocketlabVersion: "",
  isComingFromMap: false,
  geoLocInfo: {
    data: {
      total: 0,
      data: [{ id: 0, activity: {} }],
    },
  } as GeoLocaDashboard,
  error: "",
  geoLocNameSelected: "",
  chartActivity: [],
  chartBarleyVarietyActions: [
    { actions: 0, variety: "" },
  ] as ChartBarleySoftWheat[],
  chartSoftVarietyActions: [
    { actions: 0, variety: "" },
  ] as ChartBarleySoftWheat[],
  avgProtPerBarleyVar: [
    { actions: 0, variety: "", average: 0 },
  ] as AvgBarleyWheatChart[],
  avgProtPerWheatVar: [
    { actions: 0, variety: "", average: 0 },
  ] as AvgBarleyWheatChart[],
  allLocations: false,
  locationsSelected: [],

  dashboardNewActivityChart: [
    [
      {
        date: new Date(),
        count: 0,
      },
    ],
    [
      {
        date: new Date(),
        count: 0,
      },
    ],
  ],

  pocketLabChart: [["0.0.0", 1]],
  rawRowsActivityTable: [],
  rawRowsPocketLabTable: [],
  countOfUsers: {
    countOfUsersActive: 0,
    countOfUsersInactive: 0,
    totalUsers: 0,
  },

  comesFromMap: false,
};

export const DashboardSlice = createSlice({
  name: "DashboardReducer",
  initialState,
  reducers: {
    setComesFromMap(state, action) {
      return {
        ...state,
        comesFromMap: action.payload,
      };
    },
    setPocketLabChartData(state, action) {
      return {
        ...state,
        pocketLabChart: action.payload,
      };
    },
    setRowsPocketlabTable(state, action) {
      return {
        ...state,
        rawRowsPocketLabTable: action.payload,
      };
    },
    setCounOfUserActivityTab(state, action) {
      return {
        ...state,
        countOfUsers: action.payload,
      };
    },
    setRowsActivityTable(state, action) {
      return {
        ...state,
        rawRowsActivityTable: action.payload,
      };
    },
    setNewActivityChart(state, action) {
      return {
        ...state,
        dashboardNewActivityChart: action.payload,
      };
    },

    setVisibleRangeDashboardPicker(state, action) {
      return {
        ...state,
        dateRangePickerDashboard: action.payload,
      };
    },

    setDateActivityDashboard(state, action) {
      return {
        ...state,
        locationDateActivityDashboard: action.payload,
      };
    },

    /**
     * @Charts
     */
    setPerBarleyVarietyData(state, action) {
      return {
        ...state,
        chartBarleyVarietyActions: action.payload,
      };
    },

    setAvgProtBarleyData(state, action) {
      return {
        ...state,
        avgProtPerBarleyVar: action.payload,
      };
    },

    setPerSoftVarietyData(state, action) {
      return {
        ...state,
        chartSoftVarietyActions: action.payload,
      };
    },

    setAvgProtWheatData(state, action) {
      return {
        ...state,
        avgProtPerWheatVar: action.payload,
      };
    },

    fetchGeoLocDataSuccessActivityTranform(state, action) {
      return {
        ...state,
        chartActivity: action.payload,
      };
    },

    fetchGeoLocDataSuccess(state, action) {
      return {
        ...state,
        loadingDashboardInfo: false,
        geoLocInfo: action.payload,
      };
    },

    setDashboardLoading(state, action) {
      return {
        ...state,
        loadingDashboardInfo: action.payload,
      };
    },

    setLocationNameDashboardInput(state, action) {
      return {
        ...state,
        geoLocNameSelected: action.payload,
      };
    },

    setMultipleLocations(state, action) {
      return {
        ...state,
        locationsSelected: action.payload,
      };
    },

    setAllSilos(state, action) {
      return {
        ...state,
        allLocations: action.payload,
      };
    },

    setDashboardInput(state, action) {
      return {
        ...state,
        searchDashboard: action.payload,
      };
    },

    resetDashboardState(state) {
      return {
        ...state,
        searchDashboard: "",
        loadingLocationsNames: false,
        loadingDashboardInfo: false,
        dateRangePickerDashboard: false,

        isComingFromMap: false,
        geoLocInfo: {
          data: {
            total: 0,
            data: [{ id: 0, activity: {} }],
          },
        } as GeoLocaDashboard,
        error: "",
        geoLocNameSelected: "",
        chartActivity: initialState.chartActivity,
        dashboardNewActivityChart: initialState.dashboardNewActivityChart,
        chartBarleyVarietyActions: [
          { actions: 0, variety: "" },
        ] as ChartBarleySoftWheat[],
        chartSoftVarietyActions: [
          { actions: 0, variety: "" },
        ] as ChartBarleySoftWheat[],
        avgProtPerBarleyVar: [
          { actions: 0, variety: "", average: 0 },
        ] as AvgBarleyWheatChart[],
        avgProtPerWheatVar: [
          { actions: 0, variety: "", average: 0 },
        ] as AvgBarleyWheatChart[],
        allLocations: false,
        locationsSelected: [],
        rawRowsActivityTable: [],
        rawRowsPocketLabTable: [],
        pocketLabChart: initialState.pocketLabChart,
      };
    },

    resetChartAcivity(state) {
      return {
        ...state,
        chartActivity: initialState.chartActivity,
        dashboardNewActivityChart: initialState.dashboardNewActivityChart,
        countOfUsers: initialState.countOfUsers,
        pocketLabChart: initialState.pocketLabChart,
      };
    },

    logoutDashboard() {
      return initialState;
    },

    /**
     * @Charts
     */
  },

  extraReducers(builder) {
    builder
      .addCase(getPocketLabRecentVersion.pending, (state) => {
        state.loadingDashboardInfo = true;
        state.error = "";
      })
      .addCase(getPocketLabRecentVersion.fulfilled, (state, action) => {
        state.loadingDashboardInfo = false;
        state.pocketlabVersion = action.payload;
      })
      .addCase(getPocketLabRecentVersion.rejected, (state, action) => {
        state.loadingDashboardInfo = false;
        state.error = action.payload as string;
      });

    builder
      .addCase(fetchGeoLocDataDashboard.pending, (state) => {
        state.loadingDashboardInfo = true;
        state.error = "";
      })
      .addCase(fetchGeoLocDataDashboard.fulfilled, (state) => {
        state.loadingDashboardInfo = false;
      })
      .addCase(fetchGeoLocDataDashboard.rejected, (state, action) => {
        state.loadingDashboardInfo = false;
        state.error = action.payload as string;
      });

    builder
      .addCase(fetchActivityPocketLabDataDashboard2.pending, (state) => {
        state.loadingDashboardInfo = true;
        state.error = "";
      })
      .addCase(fetchActivityPocketLabDataDashboard2.fulfilled, (state) => {
        state.loadingDashboardInfo = false;
      })
      .addCase(
        fetchActivityPocketLabDataDashboard2.rejected,
        (state, action) => {
          state.loadingDashboardInfo = false;
          state.error = action.payload as string;
        }
      );

    builder
      .addCase(fetchDashboardLocationNames.pending, (state) => {
        state.loadingLocationsNames = true;
        state.error = "";
      })
      .addCase(fetchDashboardLocationNames.fulfilled, (state, action) => {
        state.loadingLocationsNames = false;
        state.locationsNames = action.payload;
      })
      .addCase(fetchDashboardLocationNames.rejected, (state, action) => {
        state.loadingLocationsNames = false;
        state.error = action.payload as string;
      });
  },
});
export const {
  setComesFromMap,
  setPocketLabChartData,
  setRowsPocketlabTable,
  setCounOfUserActivityTab,
  setRowsActivityTable,
  setNewActivityChart,
  fetchGeoLocDataSuccessActivityTranform,
  setVisibleRangeDashboardPicker,
  setLocationNameDashboardInput,
  setDateActivityDashboard,
  setPerBarleyVarietyData,
  fetchGeoLocDataSuccess,
  setPerSoftVarietyData,
  setAvgProtBarleyData,
  setMultipleLocations,
  setDashboardLoading,
  resetChartAcivity,
  resetDashboardState,
  setAvgProtWheatData,
  setDashboardInput,
  setAllSilos,
  logoutDashboard,
} = DashboardSlice.actions;
export const Dashboard = DashboardSlice.reducer;
