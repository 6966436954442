import { FC } from "react";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { State } from "../../../redux/slices";
import {
  AcqPredReq,
  LabelInstanceContainV2,
} from "../../../redux/actions/data/DataDeclarations";
import { getLocalizedTextByLocale } from "../../../utils/localized-text";
import SelectAnnotationValue from "./DataSelectAnnotation";
import { Comments } from "./DataSampleViewComments";
import { v4 as uuidv4 } from "uuid";

const DataSampleViewRight: FC = () => {
  const [t] = useTranslation("global");
  const ShowComments = false;
  const { sampleViewInfo, permissionCheckAnnotation } = useSelector(
    (state: State) => state.DataSampleViewReducer
  );
  const { locale } = useSelector((state: State) => state.LayoutReducer);
  const { isViewerOnly } = useSelector((state: State) => state.Authentication);

  const RealAnnotations = sampleViewInfo?.sampleLabelInstances;

  const annotationContent = RealAnnotations?.map(
    (labelInstances: LabelInstanceContainV2) => {
      const annotationContent1 = () => {
        if (labelInstances.labelTemplate.type.value !== "sample-comments") {
          const localizedTextAlpha =
            labelInstances?.labelTemplate?.configs[0]?.userFacingName?.localizedTexts?.map(
              (l) => ({
                name: l.translation,
                locale: l.locale.value,
              })
            );
          const labelName = getLocalizedTextByLocale(
            locale,
            localizedTextAlpha
          )?.name;

          const managingNullGeneratedByLabelTemplate = () => {
            if (!labelInstances?.labelTemplate?.configs[0]?.userFacingName) {
              return (
                <div
                  className="feat-content-annotation"
                  key={`${labelInstances.id}${uuidv4()}`}
                >
                  <span className="">{t("Data.table.Not_defined")}</span>
                </div>
              );
            } else {
              return (
                <div
                  className="feat-content-annotation"
                  key={`${labelInstances.id}${uuidv4()}`}
                >
                  {labelName}
                </div>
              );
            }
          };

          return managingNullGeneratedByLabelTemplate();
        }
      };
      return annotationContent1();
    }
  );

  const displayCorrectValue = (results: any) => {
    if (results) {
      if (results?.value?.length > 0) {
        return <div>{results?.value}</div>;
      }

      // if (results?.details[0]?.length > 0) {
      //   return results?.details?.map((s: any) => (
      //     <div key={`${s?.value}${uuidv4()}`}>{s}</div>
      //   ));
      // } <<=== leave here just if in any case is neccesary to fix something else

      if (results?.details) {
        return results?.details?.map((s: any) => (
          <div key={`${s?.value}${uuidv4()}`}>
            <span style={{ fontWeight: 600 }}>
              {s?.title ? `${s.title} :` : ""}
            </span>
            {s?.value}
          </div>
        ));
      }
    } else {
      return "";
    }
  };

  return (
    <div className="details-right-side">
      <div className="analyse">
        <div className="title-sections-sample-view">
          {t("Data.table.Analyse")}
        </div>
        {sampleViewInfo?.acquisitionPredictionRequests?.length >= 1 ? (
          sampleViewInfo?.acquisitionPredictionRequests?.map(
            (predictionRequest: AcqPredReq) => {
              const localizedTextAlpha =
                predictionRequest?.scenarioInstanceStep.userFacingShortNameText?.localizedTexts?.map(
                  (l) => ({
                    name: l.translation,
                    locale: l.locale.value,
                  })
                );

              const labelName = getLocalizedTextByLocale(
                locale,
                localizedTextAlpha
              )?.name;

              return (
                <div
                  className="analyse-card"
                  key={`${
                    predictionRequest.payload.predictionsV2?.shortResult
                      ? predictionRequest.payload.predictionsV2?.shortResult
                      : ""
                  }${uuidv4()}`}
                >
                  <div className="result-title">
                    {`${labelName} ${
                      predictionRequest.payload.predictionsV2?.shortResult
                        ? predictionRequest.payload.predictionsV2?.shortResult
                        : ""
                    }`}
                  </div>

                  {predictionRequest?.payload?.predictionsV2?.detailedResult?.map(
                    (results: any) => {
                      return (
                        <div
                          className="analyse-results"
                          key={`${results?.title}${uuidv4()}`}
                        >
                          <div className="result-1">{results?.title}</div>
                          <div className="result-2">
                            {displayCorrectValue(results)}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              );
            }
          )
        ) : (
          <Alert severity="warning" className="mt-4">
            {t("Data.SampleView.No_measures")}
          </Alert>
        )}
      </div>
      <div className="annotations">
        <div className="title-sections-sample-view">
          {t("Data.table.AnnotationName")}
        </div>
        <div className="annotation-container">
          <div key={""} className="annotations-result">
            <div className="annotations-result-values">
              <div className="result-annotation-1">
                {RealAnnotations?.length >= 1 ? annotationContent : ""}
              </div>
              <div className="result-annotation-2">
                {SelectAnnotationValue(permissionCheckAnnotation, t)}
              </div>
            </div>

            {RealAnnotations?.length === 0 ? (
              <Alert severity="warning">
                {t("Data.SampleView.No_annotations")}
              </Alert>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="commentaires">
        <div className="title-sections-sample-view">
          {!isViewerOnly && ShowComments && t("Data.SampleView.Comments")}
        </div>
        <div className="comments-container">{Comments(t)}</div>
      </div>
    </div>
  );
};

export default DataSampleViewRight;
