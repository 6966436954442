import React from "react";
import Chip from "@mui/material/Chip";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { GroupInfoData, UserData } from "../../redux/actions/users/userTypes";
import { DColumns } from "../../redux/actions/data/DataDeclarations";
import { v4 as uuidv4 } from "uuid";

const usersTabsContent = (users: any, t: (arg0: string) => string) => {
  const columnNames = {
    username: t("Fleet_users.table.Username"),
    firstname: t("Fleet_users.table.Firstname"),
    lastname: t("Fleet_users.table.Lastname"),
    groups: t("Fleet_users.table.Groups"),
    location: t("Fleet_users.table.Associated"),
  };

  const rawColumns = [
    {
      columnField: "username",
      sortable: true,
      width: 180,
    },
    {
      columnField: "firstname",
      sortable: true,
      width: 120,
    },

    {
      columnField: "lastname",
      sortable: true,
      width: 120,
    },
    {
      columnField: "groups",
      sortable: false,
      width: 400,
    },
    {
      columnField: "location",
      sortable: false,
      maxWidth: 200,
      width: 120,
    },
  ] as DColumns[];

  let columns: any[] = rawColumns.map((column: DColumns) => {
    if (column.columnField === "groups") {
      return {
        headerName: (columnNames as never)[column.columnField],
        field: column.columnField,
        width: 400,
        sortable: false,

        renderCell: (params: GridRenderCellParams) => {
          const groupList = params?.value;
          const groups = groupList?.map((p: string) => {
            return (
              <div key={`${p}${uuidv4()}`} className="d-flex">
                <Chip
                  label={p}
                  variant="outlined"
                  key={`${p}${uuidv4()}`}
                  className={`d-flex ml-1`}
                />
              </div>
            );
          });
          return (
            <div style={{ display: "flex", flexWrap: "wrap" }}>{groups}</div>
          );
        },
      };
    }

    return {
      field: column.columnField,
      headerName: (columnNames as never)[column.columnField],
      width: column.width,
      sortable: column.sortable,
    };
  });

  let usersRows = users?.data?.map((users: UserData) => {
    const renderG = () => {
      if (!users.groups) {
        return [t("Fleet_users.table.No_group")];
      } else {
        return users.groups.map((group: GroupInfoData) => group?.name);
      }
    };

    const rows = {
      id: users.id,
      username: users.username,
      firstname: users.firstname.toLowerCase(),
      lastname: users.lastname,
      groups: renderG(),
      location: users?.associatedGeoPoint?.name
        ? users?.associatedGeoPoint?.name
        : t("Fleet_users_locations.table.Automatic"),
    };

    return rows;
  });

  if (!columns) {
    columns = [];
  }
  if (!usersRows) {
    usersRows = [];
  }

  return {
    columns,
    usersRows,
  };
};

export default usersTabsContent;
