import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { State } from "../../../redux/slices";
import {
  setExportModalValues,
  setExportModalVisibility,
} from "../../../redux/slices/data/data";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setExportModal: (
      visible: boolean,
      dataExportModalValues: { email: string; typeOfExport: string }
    ) => {
      dispatch(setExportModalVisibility(visible));
      dispatch(
        setExportModalValues({
          ...dataExportModalValues,
          typeOfExport: "data_with_filters",
        })
      );
    },
  };
};

export const ExportButtonIcon = (t: (arg0: string) => string) => {
  const { dataExportModalValues } = useSelector(
    (state: State) => state.DataReducer
  );
  const { setExportModal } = SetActions();

  return (
    <div>
      <Tooltip title={t("Data.Export_all")} arrow>
        <Button
          onClick={() => setExportModal(true, dataExportModalValues)}
          sx={{ marginRight: "4px", textTransform: "none" }}
          size="medium"
          variant="contained"
        >
          <DownloadIcon sx={{ marginRight: "5px", fontWeight: 500 }} />
          <div className="mr-1" style={{ fontWeight: 600, fontSize: "15px" }}>
            {t("Data.table.Export")}
          </div>
        </Button>
      </Tooltip>
    </div>
  );
};

export const ExportButton = (t: (arg0: string) => string) => {
  const { dataExportModalValues } = useSelector(
    (state: State) => state.DataReducer
  );
  const { setExportModal } = SetActions();
  return (
    <div>
      <Button
        onClick={() => setExportModal(true, dataExportModalValues)}
        size="medium"
        variant="contained"
      >
        {t("Data.Export_current")}
      </Button>
    </div>
  );
};
