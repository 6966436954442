import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Export from "../../../assets/images/export.svg";
import Modal from "@mui/material/Modal";
import { useStyles } from "../../data/DataStyles";
import { Fade, Modal as ModalFile, Box, Button } from "@mui/material";
import {
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridFooterContainer,
  GridPagination,
  GridFilterModel,
} from "@mui/x-data-grid";
import projectsTableContent from "./InvidualProjectsFetchingColumn";
import { ThemeProvider } from "@mui/material/styles";
import { errorsGeneralModal } from "../../ErrorModal";
import themeInarix from "../../StylesMuiInarix";
import { withRouter } from "../../Helpers";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { State } from "../../../redux/slices";
import Layout from "../../layout/Layout";
import { fetchFilesProjects } from "../../../redux/actions/projects/ProjectsAsyncActions";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useNavigate, useParams } from "react-router-dom";
import { setNumberRowsUsers } from "../../../redux/slices/fleet/users";
import {
  setConfirmModal,
  setInfoConfirmModal,
  setTabNameLayout,
} from "../../../redux/slices/layout";
import { goBackFunction } from "../../userActions/IndividualAction";
import {
  initialState,
  resetProjectErrors,
  setFileValues,
  setFilters,
  setProjectsModal,
  setTableProjectsOptions,
} from "../../../redux/slices/fleet/projects";
import {
  ProjectsApplyFiltersComponent,
  ProjectsDisableFiltersComponents,
} from "../ApplyRemoveFilter";
import BodyProjects from "./UploadFile";
import NotFound from "../../NotFound";
import { ConfirmGeneralModal } from "../../ConfirmModal";
import { SuccessGeneralModal } from "../../SuccessModal";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    disableErrorProjects: () => {
      dispatch(resetProjectErrors());
    },

    resetFileNameDescription: () => {
      dispatch(setFileValues(initialState.fileInfo));
    },

    setTabName: (name: string) => dispatch(setTabNameLayout(name)),

    setProject: (token: string, id: number) => {
      dispatch(fetchFilesProjects({ token, id }));
    },
    openModal: () => {
      dispatch(setProjectsModal(true));
    },
    resetValuesProjects: () => {
      dispatch(setProjectsModal(false));
      dispatch(setFileValues(initialState.fileInfo));
    },

    setRowsNumber: (rows: number) => dispatch(setNumberRowsUsers(rows)),

    fetchNewPage: (token: string, id: number) =>
      dispatch(fetchFilesProjects({ token, id })),

    setNewPage: (
      tableOptions: {
        page: number;
        rowOptions: number;
        totalRows: number | null;
      },
      page: number,
      rowOptions: number
    ) => {
      dispatch(setTableProjectsOptions({ ...tableOptions, page, rowOptions }));
    },

    setFilters: (filters: GridFilterModel) => {
      dispatch(setFilters(filters));
    },

    resetConfirmModal: () => {
      dispatch(setConfirmModal(false));
      dispatch(setInfoConfirmModal(undefined));
    },

    resetPage: (
      token: string,
      id: number,
      tableOptions: {
        page: number;
        rowOptions: number;
        totalRows: number | null;
      }
    ) => {
      dispatch(setTableProjectsOptions({ ...tableOptions, page: 0 }));
      dispatch(fetchFilesProjects({ token, id }));
    },
  };
};

const CustomToolbar = () => {
  const [t] = useTranslation("global");
  const { applyFilter } = ProjectsApplyFiltersComponent(t);
  const { disableFilter } = ProjectsDisableFiltersComponents(t);
  const { filters } = useSelector((state: State) => state.ProjectsReducer);
  const { user } = useSelector((state: State) => state.Authentication);
  const token = user?.token ?? "";

  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      {applyFilter(filters as GridFilterModel, token)}
      {disableFilter(filters as GridFilterModel, token)}
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
};

const NewProjectsTable = (token: string, t: (arg0: string) => string) => {
  const {
    openModal,
    resetValuesProjects,
    fetchNewPage,
    setNewPage,
    resetPage,
    setFilters,
  } = SetActions();

  const { columns, projectsRowsSorted } = projectsTableContent(token);
  const { modal, loading, loadingUpload, tableOptions, projectId, filters } =
    useSelector((state: State) => state.ProjectsReducer);

  const { locale } = useSelector((state: State) => state.LayoutReducer);
  const totalRows = tableOptions.totalRows ? tableOptions.totalRows : 0;
  const page = tableOptions.page;
  const classes = useStyles();

  const paginationModel = {
    pageSize: tableOptions.rowOptions,
    page: tableOptions.page,
  };

  const handlePageChange = (page: number, rowOption: number) => {
    setNewPage(tableOptions, page, rowOption);
    fetchNewPage(token, projectId);
  };

  const onFilterChange = (model: GridFilterModel) => {
    setFilters(model);
  };

  const paginationToolbar = () => {
    return (
      <GridFooterContainer sx={{ alignItems: "baseline" }}>
        <div className="grid-foot-container">
          {page > 0 ? (
            <Button
              variant="contained"
              onClick={() => resetPage(token, projectId, tableOptions)}
              disabled={loading || page < 1}
            >
              {t("Data.table.Restart_page")}
            </Button>
          ) : (
            ""
          )}
          <GridPagination />
        </div>
      </GridFooterContainer>
    );
  };

  return (
    <div>
      <div className="individual-project-container container mt-5">
        <div />
        <div>
          <Button
            className="export-button"
            disabled={loading}
            onClick={() => openModal()}
            variant="contained"
          >
            {t("Projects.Table_project.Upload")}
          </Button>
        </div>
      </div>
      <Box sx={{ width: 1 }}>
        <ThemeProvider theme={themeInarix(locale)}>
          <DataGridPro
            pagination
            className="projects-table"
            rowHeight={78}
            loading={loading}
            paginationMode="server"
            filterMode="server"
            onPaginationModelChange={(newPage) => {
              handlePageChange(newPage.page, newPage.pageSize);
            }}
            onFilterModelChange={onFilterChange}
            filterModel={filters || undefined}
            rowCount={totalRows}
            slots={{ toolbar: CustomToolbar, footer: paginationToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            paginationModel={paginationModel}
            disableColumnSelector={true}
            rows={projectsRowsSorted}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            hideFooterPagination={false}
          />
        </ThemeProvider>
      </Box>
      <ModalFile open={modal} onClose={resetValuesProjects}>
        {BodyProjects(t, token)}
      </ModalFile>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalExport}
        open={loadingUpload}
        closeAfterTransition
      >
        <Fade in={loadingUpload}>
          <div className={classes.paperModal}>
            <h2 className="title-export">{t("Projects.Uploading")}</h2>
            <img src={Export} alt="export-button" />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export interface IndividualComponentProps {
  id: string;
}

const IndividualProject: FC<any> = () => {
  const [t] = useTranslation("global");
  const { id } = useParams();
  const {
    disableErrorProjects,
    setProject,
    resetFileNameDescription,
    setTabName,
    resetValuesProjects,
    resetConfirmModal,
  } = SetActions();

  const { errorFetchingProject, error, projectName, projectId, deletedFile } =
    useSelector((state: State) => state.ProjectsReducer);
  const { user, isViewerOnly } = useSelector(
    (state: State) => state.Authentication
  );

  const { confirmModal, sectionName, infoConfirmModal } = useSelector(
    (state: State) => state.LayoutReducer
  );

  const token = user?.token ?? "";
  const navigate = useNavigate();

  const disableError = () => {
    disableErrorProjects();
  };

  const errorsIndividualProjects = `${errorFetchingProject}${error}`;

  const bodyErrors = errorsGeneralModal(
    JSON.stringify(errorsIndividualProjects),
    disableError,
    t
  );

  const errorsProjects = errorFetchingProject ?? error;
  const successBodyModal = SuccessGeneralModal(t, sectionName);
  const bodyConfirmMOdal = ConfirmGeneralModal(
    token,
    t,
    sectionName,
    infoConfirmModal
  );

  const renderSimpleProject = (token: string, id: number) => {
    if (!errorFetchingProject) {
      setProject(token, id);
    }
  };

  useEffect(() => {
    resetValuesProjects();
    if (id !== projectId.toString()) {
      renderSimpleProject(token, parseInt(id as string));

      setTabName("/projects");
    }
    if (id === projectId.toString()) {
      window.addEventListener("beforeunload", function (e) {
        e.preventDefault();
        setTimeout(() => {
          renderSimpleProject(token, projectId);
          resetFileNameDescription();
          setTabName("/projects");
        }, 3000);
      });
    }
    resetConfirmModal();
  }, []);

  const globalComponentProjects = () => (
    <div>
      <div className="arrow-back-projects">
        {goBackFunction(navigate, t, "Projects.title", `/projects`)}
      </div>

      {NewProjectsTable(token, t)}
      <Modal open={!!errorsProjects}>{bodyErrors}</Modal>
      <Modal open={!!deletedFile}>{successBodyModal}</Modal>
      <Modal open={confirmModal}>{bodyConfirmMOdal}</Modal>
    </div>
  );

  return (
    <Layout
      title={!isViewerOnly ? (projectName as string) : ""}
      component={!isViewerOnly ? globalComponentProjects() : <NotFound />}
    />
  );
};

export default withRouter(IndividualProject);
