import { makeStyles } from "@mui/styles";

import MuiAccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { InarixMuiComponents } from "inarix-theme";
import { Button } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";

export const ButtonCancelModals = styled(Button)(
  () => InarixMuiComponents.modalsStylesComponents.ButtonCancelModalStyle
);

export const ButtonConfirmModals = styled(LoadingButton)(
  () => InarixMuiComponents.modalsStylesComponents.ButtonConfirmModalStyle
);

export const DataGridInarixProUsersActions = styled(DataGridPro)(
  () => InarixMuiComponents.tablesInarixStyles.DataGridProTableInarixStyle
);

export const AccordionSummaryInarix = styled(AccordionSummary)(
  () => InarixMuiComponents.tablesInarixStyles.AccordionSummaryInarixStyle
);

export const AccordionDetails = styled(MuiAccordionDetails)(
  () => InarixMuiComponents.tablesInarixStyles.AccordionDetailsInarixStyle
);

export const useStyles = makeStyles({
  root: {
    marginRight: "20px",
    marginLeft: "20px",
    maxHeight: "500px",
    height: "auto",
    overflow: "scroll",
    overflowX: "hidden",
    ["@media(max-width: 769px)"]: {},
    ["@media(max-height: 720px)"]: {
      height: "400px",
    },
  },
  root2: {
    marginRight: "50px",
    marginLeft: "20px",
    maxHeight: "500px",
    height: "auto",
    overflow: "hidden",
    overflowX: "hidden",
    ["@media(max-width: 769px)"]: {},

    ["@media(max-height: 720px)"]: {
      height: "400px",
    },
  },

  heading: {
    fontSize: "15px",
    fontWeight: 600,
    display: "flex",
  },

  chips: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: "0.5px",
    },
  },

  chipsActions: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: "0.5px",
    },
  },
});
