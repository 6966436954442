import * as React from "react";
import { State } from "../../../redux/slices";
import { useTranslation } from "react-i18next";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { FluxStandardAction } from "flux-standard-action";
import iconDropZone from "../../../assets/images/Featured-icon.png";
import { TextAreaNotes, TextFieldInputEditCreate } from "../OverviewStyles";
import {
  setNewValueMapUpdateCreateLocation,
  logEventsNewPosition,
  resetMapModifyLocation,
  setImgLocation,
  setLatitudeEditCreateLocation,
  setLongitudeEditCreateLocation,
  setViewport,
} from "../../../redux/slices/map/map";
import {
  GeoLocTypes,
  ValuesCreateEditLocation,
  Viewport,
} from "../../../redux/slices/map/mapTypes";
import {
  setConfirmModal,
  setInfoConfirmModal,
} from "../../../redux/slices/layout";

//Material
import { MuiTelInput } from "mui-tel-input";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { Alert, Button, FormControl, MenuItem, TextField } from "@mui/material";

//React map Gl
import mapboxgl from "mapbox-gl";
import Dropzone from "react-dropzone";

import MapboxGeocoder, { Result } from "@mapbox/mapbox-gl-geocoder";
import { fetchLocationMapbox } from "../../../redux/actions/map/mapAsyncActions";
import { inarixColors } from "inarix-theme";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    logEvent: (latitude: number, longitude: number) =>
      dispatch(logEventsNewPosition({ lng: longitude, lat: latitude })),

    setFileImgLocation: (img: File) => dispatch(setImgLocation(img)),

    setLatitudeEditGeoLoc: (latitude: number) =>
      dispatch(setLatitudeEditCreateLocation(latitude)),
    setLongitudeEditGeoLoc: (longitude: number) =>
      dispatch(setLongitudeEditCreateLocation(longitude)),

    setNewViewport: (viewport: Viewport) => dispatch(setViewport(viewport)),

    setNewValues: (values: ValuesCreateEditLocation) =>
      dispatch(setNewValueMapUpdateCreateLocation(values)),

    setSearchAddress: (text: string) => dispatch(fetchLocationMapbox({ text })),

    modifyCreateLocationToFalse: () => dispatch(resetMapModifyLocation()),

    showInfoToConfirmEditCreateLocation: (confirmModalInfo: any) => {
      dispatch(setConfirmModal(true));
      dispatch(setInfoConfirmModal(confirmModalInfo));
    },
  };
};

export const coordinatesGeocoder = function (query: string) {
  const matches = RegExp(
    /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
  ).exec(query);

  if (!matches) {
    return null;
  }

  function coordinateFeature(lng: number, lat: number) {
    return {
      center: [lng, lat],
      geometry: {
        type: "Point",
        coordinates: [lng, lat],
      },
      place_name: "Lat: " + lat + " Lng: " + lng,
      place_to_address: matches,
      place_type: ["coordinate"],
      properties: {},
      type: "Feature",
    };
  }

  const coord1 = Number(matches[1]);
  const coord2 = Number(matches[2]);
  const geocodes = [];

  if (coord1 < -90 || coord1 > 90) {
    geocodes.push(coordinateFeature(coord1, coord2));
  }

  if (coord2 < -90 || coord2 > 90) {
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  if (geocodes.length === 0) {
    geocodes.push(coordinateFeature(coord1, coord2));
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  return geocodes;
};

const ControlPanelEditLocation = () => {
  const [t] = useTranslation("global");
  const {
    setFileImgLocation,
    setLatitudeEditGeoLoc,
    setLongitudeEditGeoLoc,
    logEvent,
    modifyCreateLocationToFalse,
    setNewViewport,
    setNewValues,
    setSearchAddress,
    showInfoToConfirmEditCreateLocation,
  } = SetActions();

  const {
    eventsMarker,
    isGeoLocUpdatedLoading,
    typesToEditGeoLoc,
    errorFetchTypes,
    imgFile,
    longitudeEditCreateGeoLoc,
    newGeoLoc,
    latitudeEditCreateGeoLoc,
    imgLocationUrl,
    viewport,
    isUploadedError,
    isGeoLocUpdatedError,
    newValuesEditCreateLocation,
    createLocationActive,
    modifyLocation,
  } = useSelector((state: State) => state.MapReducer);

  const {
    name,
    type,
    primaryContactName,
    email,
    address,
    notes,
    telephone,
    remoteId,
  } = newValuesEditCreateLocation;

  const locale = localStorage.getItem("i18nextLng");
  const geoLocTypesNamesLocaleFr = typesToEditGeoLoc?.map(
    (types: GeoLocTypes) =>
      types.names.filter((defaultValue) => defaultValue.locale === "fr")
  );

  const geoLocTypesNamesLocaleUniversal = typesToEditGeoLoc?.map(
    (types: GeoLocTypes) =>
      types.names.filter((defaultValue) => defaultValue.locale === "en")
  );

  const optionsFr = geoLocTypesNamesLocaleFr?.map((content) =>
    content.map((contentInfo) => {
      if (contentInfo.typeId === newGeoLoc?.typeId) {
        return (
          <MenuItem
            key={contentInfo.id}
            disabled={true}
            defaultValue={contentInfo.name}
          >
            {contentInfo.name}
          </MenuItem>
        );
      }

      if (contentInfo.typeId !== newGeoLoc?.typeId) {
        return (
          <MenuItem key={contentInfo.id} value={contentInfo.typeId}>
            {contentInfo.name}
          </MenuItem>
        );
      }

      return "";
    })
  );

  const optionsUniversal = geoLocTypesNamesLocaleUniversal?.map((content) =>
    content.map((contentInfo) => {
      if (contentInfo.typeId === newGeoLoc?.typeId) {
        return (
          <MenuItem
            key={contentInfo.id}
            disabled={true}
            defaultValue={contentInfo.name}
          >
            {contentInfo.name}
          </MenuItem>
        );
      }

      if (contentInfo.typeId !== newGeoLoc?.typeId) {
        return (
          <MenuItem key={contentInfo.id} value={contentInfo.typeId}>
            {contentInfo.name}
          </MenuItem>
        );
      }
      return "";
    })
  );

  const manageOptionsLocale = () => {
    if (locale === "fr") {
      return optionsFr;
    } else {
      return optionsUniversal;
    }
  };

  const handleDrop = (acceptedFiles: File[]) =>
    acceptedFiles.map((file: File) => setFileImgLocation(file));

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    if (!type && createLocationActive) {
      window.alert(
        t("Fleet_overview.overview_panel.createNewPosition.alertType")
      );
    } else {
      showInfoToConfirmEditCreateLocation({
        name: name || newGeoLoc?.name,
        type: type || newGeoLoc?.typeId,
        primaryContactName: primaryContactName || newGeoLoc?.primaryContactName,
        email: email || newGeoLoc?.email,
        address: address || newGeoLoc?.address,
        notes: notes || newGeoLoc?.notes,
        telephone: telephone || newGeoLoc?.telephone,
        remoteId,
      });
    }
  };

  const geocoder = new MapboxGeocoder({
    accessToken: process.env.REACT_APP_MAPBOX_TOKEN as string,
    localGeocoder: coordinatesGeocoder as unknown as (
      query: string
    ) => Result[],
    zoom: 4,
    placeholder: t(
      `Fleet_overview.overview_panel.createNewPosition.input_search_loc`
    ),

    mapboxgl: mapboxgl as any,
    reverseGeocode: true,
  });

  React.useEffect(() => {
    geocoder.addTo("#geocoder");
    const results = document.getElementById("result");
    let searchResult = results?.innerText as void | FluxStandardAction<
      string,
      any,
      undefined
    >;

    geocoder.on("result", (e: any) => {
      searchResult =
        e.result.geometry.coordinates[1] < -90 ||
        e.result.geometry.coordinates[1] > 90
          ? console.log("to big")
          : setNewViewport({
              ...viewport,
              latitude: e.result.geometry.coordinates[1],
              longitude: e.result.geometry.coordinates[0],
              zoom: 10,
              transitionDuration: "auto",
            });
      e.result.geometry.coordinates[1] < -90 ||
      e.result.geometry.coordinates[1] > 90
        ? console.log("big")
        : logEvent(
            e.result.geometry.coordinates[1],
            e.result.geometry.coordinates[0]
          );
      e.result.geometry.coordinates[1] < -90 ||
      e.result.geometry.coordinates[1] > 90
        ? console.log("big")
        : setLatitudeEditGeoLoc(e.result.geometry.coordinates[1]);
      setLongitudeEditGeoLoc(e.result.geometry.coordinates[0]);
      logEvent(
        e.result.geometry.coordinates[1],
        e.result.geometry.coordinates[0]
      );
      setSearchAddress(
        `${e.result.geometry.coordinates[0]},${e.result.geometry.coordinates[1]}`
      );
    });

    geocoder.on("clear", () => {
      searchResult = console.log(`${searchResult}, clean`);
    });
  }, []);

  const handleChange = (newPhone: string) => {
    setNewValues({
      ...newValuesEditCreateLocation,
      telephone: newPhone,
    });
  };

  return (
    <div className="control-panel-create-loc">
      <div>
        <form onSubmit={submitHandler}>
          <Dropzone
            accept="image/jpeg,image/png,image/jpg"
            maxFiles={1}
            onDrop={handleDrop}
          >
            {({ getRootProps, getInputProps }: any) => (
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                {!imgFile ? (
                  <div>
                    <img src={iconDropZone} alt="dropzone" />
                    <div className="dropzone-info">
                      <span className="edit-position-span">
                        {t(
                          "Fleet_overview.overview_panel.createNewPosition.clickUpload"
                        )}
                      </span>
                      <span>
                        {t(
                          "Fleet_overview.overview_panel.createNewPosition.drag"
                        )}
                      </span>
                      <span>
                        {t(
                          "Fleet_overview.overview_panel.createNewPosition.imagesTypes"
                        )}
                      </span>
                      {imgLocationUrl ? (
                        <img
                          src={imgLocationUrl}
                          className="img-drop-zone-edit"
                          alt="geoloc"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  <span className="edit-position-span">{imgFile.name}</span>
                )}
              </div>
            )}
          </Dropzone>
          {isGeoLocUpdatedError || errorFetchTypes || isGeoLocUpdatedError ? (
            <Alert severity="warning" className="mb-2 mt-2">
              {JSON.stringify(isGeoLocUpdatedError || errorFetchTypes)}
              {JSON.stringify(isUploadedError)}
            </Alert>
          ) : (
            ""
          )}

          <div className="mt-5">
            {createLocationActive ? (
              <h5>
                <AddLocationAltIcon
                  sx={{
                    marginBottom: "4px",
                    marginRight: "10px",
                    color: inarixColors.main_inarix,
                  }}
                />
                {t(
                  "Fleet_overview.overview_panel.createNewPosition.createLocation"
                )}
              </h5>
            ) : (
              <h5>
                <EditIcon
                  sx={{
                    marginBottom: "4px",
                    marginRight: "10px",
                    color: inarixColors.main_inarix,
                  }}
                />
                {t(
                  "Fleet_overview.overview_panel.createNewPosition.editLocation"
                )}
              </h5>
            )}
            <div className="division-create-loc-edit">
              <LocationOnIcon className="icon-division-create-edit" />
              <span className="span-division-create-edit-loc">ID</span>
            </div>

            <FormControl fullWidth>
              <TextFieldInputEditCreate
                size="small"
                type="text"
                label={t(
                  "Fleet_overview.overview_panel.createNewPosition.newLocationName"
                )}
                required={createLocationActive}
                defaultValue={newGeoLoc?.locationName}
                onChange={(e) =>
                  setNewValues({
                    ...newValuesEditCreateLocation,
                    name: e.currentTarget.value,
                  })
                }
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                size="small"
                select
                label={t(
                  "Fleet_overview.overview_panel.createNewPosition.locationType"
                )}
                required={createLocationActive}
                defaultValue={"DEFAULT"}
                className="mb-4"
                onChange={(e) =>
                  setNewValues({
                    ...newValuesEditCreateLocation,
                    type: e.target.value,
                  })
                }
              >
                <MenuItem id={"DEFAULT"} value={"DEFAULT"} disabled={true}>
                  {t(
                    "Fleet_overview.overview_panel.createNewPosition.locationType"
                  )}
                </MenuItem>

                {manageOptionsLocale()}
              </TextField>
            </FormControl>

            <FormControl fullWidth>
              <TextAreaNotes
                id="outlined-textarea"
                label={t(
                  "Fleet_overview.overview_panel.createNewPosition.notes"
                )}
                defaultValue={newGeoLoc?.notes}
                multiline
                rows={3}
                onChange={(e) =>
                  setNewValues({
                    ...newValuesEditCreateLocation,
                    notes: e.currentTarget.value,
                  })
                }
              />
            </FormControl>

            <div className="division-create-loc-edit-contact">
              <PersonIcon className="icon-division-create-edit" />
              <span className="span-division-create-edit-loc">
                {t(
                  "Fleet_overview.overview_panel.createNewPosition.locationContact"
                )}
              </span>
            </div>

            <FormControl fullWidth>
              <TextFieldInputEditCreate
                size="small"
                label={t(
                  "Fleet_overview.overview_panel.createNewPosition.contactName"
                )}
                type="text"
                defaultValue={newGeoLoc?.primaryContactName}
                onChange={(e) =>
                  setNewValues({
                    ...newValuesEditCreateLocation,
                    primaryContactName: e.currentTarget.value,
                  })
                }
              />
            </FormControl>

            <FormControl fullWidth>
              <TextFieldInputEditCreate
                type="email"
                size="small"
                defaultValue={newGeoLoc?.email}
                label={t(
                  "Fleet_overview.overview_panel.createNewPosition.email"
                )}
                onChange={(e) =>
                  setNewValues({
                    ...newValuesEditCreateLocation,
                    email: e.currentTarget.value,
                  })
                }
              />
            </FormControl>

            <FormControl fullWidth>
              <MuiTelInput value={telephone} onChange={handleChange} />
            </FormControl>

            <div className="division-create-loc-edit-contact mt-">
              <MyLocationIcon className="icon-division-create-edit" />
              <span className="span-division-create-edit-loc">
                {t("Fleet_overview.overview_panel.createNewPosition.position")}
              </span>
            </div>

            <div id="geocoder" className="geocoder mb-3" />

            <div className="position-lat-long-inputs">
              <div className="mr-5">
                <span>
                  {t("Fleet_overview.overview_panel.changePosition.longitude")}
                </span>
                <input
                  placeholder={
                    eventsMarker ? `${eventsMarker?.lng?.toFixed(5)}` : ""
                  }
                  type="number"
                  step=".00001"
                  disabled
                  className="form-control mb-3"
                />
              </div>
              <div>
                <span>
                  {t("Fleet_overview.overview_panel.changePosition.latitude")}
                </span>
                <input
                  placeholder={
                    eventsMarker ? `${eventsMarker?.lat?.toFixed(5)}` : ""
                  }
                  type="number"
                  step=".00001"
                  disabled
                  className="form-control mb-3"
                />
              </div>
            </div>
          </div>

          {!isGeoLocUpdatedLoading ? (
            <div className="position-buttons-create">
              <Button
                className="btn-styles mr-3"
                variant="outlined"
                onClick={modifyCreateLocationToFalse}
              >
                {t("Fleet_overview.overview_panel.createNewPosition.cancel")}
              </Button>
              {modifyLocation ? (
                <Button
                  variant="contained"
                  type="submit"
                  size="large"
                  className="btn-styles"
                  disabled={
                    !name &&
                    !type &&
                    !remoteId &&
                    !primaryContactName &&
                    !email &&
                    !address &&
                    !notes &&
                    latitudeEditCreateGeoLoc === newGeoLoc?.latitude &&
                    longitudeEditCreateGeoLoc === newGeoLoc.longitude &&
                    !imgFile &&
                    !telephone
                  }
                >
                  {t("Fleet_overview.overview_panel.createNewPosition.save")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  type="submit"
                  size="large"
                  className="btn-styles"
                  disabled={!type}
                >
                  {t("Fleet_overview.overview_panel.createNewPosition.save")}
                </Button>
              )}
            </div>
          ) : (
            <div className="loading-icon">
              <i className="fas fa-spinner fa-pulse" />
            </div>
          )}
        </form>
      </div>
      <div className="solid-create-modify-loc" />

      <div>
        <div className="info-option" />
      </div>
    </div>
  );
};

export default React.memo(ControlPanelEditLocation);
