import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../modals/modalStyles";
import Chip from "@mui/material/Chip";

import { handlingSizeOptionsModal } from "../../Helpers";
import { ButtonCancelModals, ButtonConfirmModals } from "../UserTabsStyles";
import { State } from "../../../redux/slices";
import {
  setGroupId,
  setRemoveUsersModal,
} from "../../../redux/slices/fleet/users";
import { deleteMultipleUsersFromGroup } from "../../../redux/actions/users/userAsyncActions";
import {
  GroupInfoData,
  UserData,
} from "../../../redux/actions/users/userTypes";
import { ThunkDispatch } from "@reduxjs/toolkit";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setGroup: (value: number) => dispatch(setGroupId(value)),

    removeUsers: (token?: string) => {
      if (token) {
        dispatch(deleteMultipleUsersFromGroup(token));
      }
      setTimeout(
        () => {
          dispatch(setRemoveUsersModal(false));
          dispatch(setGroupId(0));
        },
        token ? 1500 : 200
      );
    },
  };
};

export const UserToRemoveFromGroup = (
  token: string,
  t: (arg0: string) => string
): JSX.Element => {
  const { users, usersSelected, groupId, loadingDelete } = useSelector(
    (state: State) => state.UsersReducer
  );

  const classes = useStyles();

  const { removeUsers, setGroup } = SetActions();

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    removeUsers(token);
  };

  const userIdChips = usersSelected.map((userId: string, i: number) => (
    <div key={userId}>
      {users.data.map((u: UserData) => {
        if (u.id === userId) {
          return (
            <div key={userId}>
              <Chip label={u.username} variant="outlined" className="mb-3" />
            </div>
          );
        }
        return "";
      })}
    </div>
  ));

  const userSelected = usersSelected.map((userId: string, i: number) =>
    users.data.map((u: UserData) => {
      if (u.id === userId) {
        return u.groups.flat();
      }
      return [];
    })
  );

  const userSelectedFlat = userSelected.flat();

  const filtered = userSelectedFlat.filter(function (
    el: GroupInfoData[] | undefined
  ) {
    return el !== undefined;
  });

  const unique = [...(new Set(filtered.flat()) as never)];

  const unique2 = unique.filter(
    (value: GroupInfoData, index, self) =>
      index ===
      self.findIndex(
        (t: GroupInfoData) => t.id === value.id && t.name === value.name
      )
  );

  const groupsOptions = unique2.map((group: GroupInfoData) => {
    return (
      <option key={group.id} value={group.id}>
        {group.name}
      </option>
    );
  });

  return (
    <div className={classes.confirmModal}>
      <h5 className="general-modal-title">
        {t("Fleet_users.modal_remove.Remove_users")}
      </h5>
      <br />
      <h6 className="mb-2">
        {t("Fleet_users.modal_remove.Users_to_remove")} : {userIdChips.length}
      </h6>
      <br />
      <div className={handlingSizeOptionsModal(userIdChips)}>{userIdChips}</div>
      <br />
      <h6 className="mb-3 mt-3">
        {t("Fleet_users.modal.Total_groups")}: {unique2.length}
      </h6>
      <select
        defaultValue={"DEFAULT"}
        className="form-select"
        style={{ width: 300, marginBottom: "20px", marginTop: "30px" }}
        onChange={(e) => setGroup(parseInt(e.currentTarget.value))}
        required
      >
        <option value="DEFAULT" disabled={true}>
          {t("Fleet_users.modal_remove.Select_group")}
        </option>
        {groupsOptions}
      </select>
      <hr />
      <br />

      <div className="buttons-position-options">
        <ButtonCancelModals
          data-cy="btn-cancel"
          type="button"
          onClick={() => removeUsers()}
          disabled={loadingDelete}
          size="large"
          variant="outlined"
          className="mr-2"
        >
          {t("Fleet_overview.overview_panel.deleteLocation.cancel")}
        </ButtonCancelModals>

        <ButtonConfirmModals
          type="submit"
          variant="contained"
          disabled={!groupId || loadingDelete}
          onClick={submitHandler}
        >
          {t("Fleet_users.modal_remove.Remove_from_group")}
        </ButtonConfirmModals>
      </div>
    </div>
  );
};
