import React from "react";
import { Button } from "@mui/material";
import alertIconRed from "../../src/assets/images/alert2.png";
/**
 *
 * @Actions
 */

export const errorsGeneralModal = (
  error: string,
  disableErrors: () => void,
  t: (arg0: string) => string
): JSX.Element => {


  const bodyDeleteLoc = (
    <section>
      <div className="errorModal">
        <div className="container-location-delete">
          <img src={alertIconRed} className="img-alert-delete" alt="alert-icon-red"/>
          <span className="delete-title">{t("Common_options.New_error")}</span>
          <span className="delete-location-text">{error}</span>
          <div className="delete-loc-buttons-position">
            <Button
              type="button"
              color="error"
              variant="contained"
              onClick={disableErrors}
            >
              Ok!
            </Button>
          </div>
        </div>
      </div>
    </section>
  );

  return bodyDeleteLoc;
};
