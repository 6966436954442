import axios from "axios";
import { API } from "../../../config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../configureStore";
import {
  fetchLocationsWithActivity,
  managingUndefined,
} from "../map/mapAsyncActions";
import { setModalLocations } from "../../slices/fleet/locations";

export const patchLocationName = createAsyncThunk(
  "LocationsReducer/patchLocationName",
  async (token: string, store) => {
    const { locationId, editLocation } = (store.getState() as RootState)
      .LocationsReducer;
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { name, type, primaryContactName, telephone, email, address, notes } =
      editLocation;

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios.patch(
        `${API}/users/organization-geo-point/${locationId}`,
        {
          name: managingUndefined(name),
          typeId: managingUndefined(parseInt(type)),
          primaryContactName: managingUndefined(primaryContactName),
          telephone: managingUndefined(telephone),
          email: managingUndefined(email),
          address: managingUndefined(address),
          notes: managingUndefined(notes),
          altitude: 0,
        },
        { headers }
      );

      store.dispatch(setModalLocations(false));
      store.dispatch(fetchLocationsWithActivity(token as unknown as string));
      return store.fulfillWithValue("success");
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);
