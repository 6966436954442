import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

import { Button, ListItemButton } from "@mui/material";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import DeleteIcon from "@mui/icons-material/Delete";
import { inarixColors, InarixMuiComponents } from "inarix-theme";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";

export const RangePickerButton = styled(Button)({
  background: inarixColors.contrast_inarix,
  borderRadius: "40px",
  width: "191px",
  height: "32px",
  fontSize: 12,
  color: inarixColors.primary_text_inarix,
  "&:hover": {
    backgroundColor: inarixColors.action_hover_inarix,
    border: `solid 0.5px`,
    boxShadow: "none",
  },
});

export const TetFieldDataSearch = styled(TextField)({
  background: inarixColors.contrast_inarix,
  height: "20px",
});
export const RangePickerButtonData = styled(Button)({
  background: inarixColors.contrast_inarix,
  borderRadius: "5px",
  width: "191px",
  height: "37px",
  fontSize: 12,
  border: `solid 1px #C0C0C0`,
  color: inarixColors.primary_text_inarix,
  "&:hover": {
    backgroundColor: inarixColors.action_hover_inarix,
    border: `solid 0.5px`,
    boxShadow: "none",
  },
});

export const ListItemButtonInarix = styled(ListItemButton)(() => ({
  cursor: "auto",
  height: "68px ",
}));

export const TableSideBar = styled(Table)(() => ({
  width: 350,
  height: 102,
  marginLeft: "15px",
  marginRight: "5px",
}));

export const PaperSideBar = styled(Paper)(() => ({
  boxShadow: "none",
  justifyContent: "center",
  display: "flex",
  marginTop: "17px",
}));

export const CalendarTodayIconInarix = styled(CalendarTodayIcon)(() => ({
  fontSize: "18px",
}));

export const MyLocationIconInarix = styled(MyLocationIcon)(() => ({
  fontSize: "18px",
}));

export const DeleteIconInarix = styled(DeleteIcon)(() => ({
  fontSize: "18px",
}));

export const TextFieldInputEditCreate = styled(TextField)(() => ({
  marginBottom: "30px",
}));

export const TextAreaNotes = styled(TextField)(() => ({
  width: "100%",
  marginBottom: "3px",
}));

export const ListSaveViews = styled(List)(() => ({
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "15px",
  marginRight: "15px",
}));

export const ButtonSaveViews = styled(Button)(() => ({
  width: "350px",
  textTransform: "none",
}));

export const TextFieldSearch = styled(TextField)(() => ({
  m: 1,
  paddingLeft: "15px",
  paddingRight: "15px",
  width: "40ch",
}));

export const ButtonCloseOverviewSideBars = styled(Button)(
  () => () =>
    InarixMuiComponents.mapOverviewComponentStyles.ButtonCloseOverviewSideBars
);

export const CloseIconInarix = styled(CloseIcon)(() => ({
  marginTop: "-2px",
}));

export const useStyles = makeStyles({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    backgroundColor: " transparent",
    width: 340,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  deleteLocModal: {
    position: "absolute",
    width: 400,
    minHeight: 268,
    backgroundColor: inarixColors.contrast_inarix,
    border: `2px solid ${inarixColors.contrast_inarix}`,
    borderRadius: "12px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "24px 24px  24px",
  },

  errorModal: {
    position: "absolute",
    backgroundColor: inarixColors.contrast_inarix,
    border: `2px solid ${inarixColors.contrast_inarix}`,
    borderRadius: "12px",
    top: "50%",
    left: "50%",

    transform: "translate(-50%, -50%)",
    padding: "24px 24px 24px",
    paddingBottom: "20px",
    height: "300px",
    overflow: "auto",
    overflowX: "hidden",
  },
  paper: {
    border: "1px solid",
    padding: "1px",
    backgroundColor: inarixColors.contrast_inarix,
    width: "330px",
  },

  paper2: {
    border: "1px solid",
    padding: "1px",
    backgroundColor: inarixColors.contrast_inarix,
    width: "120px",
    display: "flex",
    flexDirection: "column",
  },
});
