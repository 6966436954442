/**
 * @author David Roman <david@inarix.com>
 * @file Login component
 * @desc Created on 2023-08-17 11:28:44 am
 * @copyright Inarix
 */
import React, { FC, FormEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { State } from "../../redux/slices/index";

import {
  setUsername as loginSetUsername,
  setPassword as loginSetPassword,
  resetState as loginResetState,
  disableErrorLogin,
  setRememberMe,
  setEmailSent,
  setResetPasswordSuccess,
} from "../../redux/slices/authentication/authentication";

import { signIn as loginSignin } from "../../redux/actions/authentication/authentication";
import NewInarixIcon from "../../assets/images/Union.svg";

import Box from "@mui/material/Box";

import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import pjson from "../../../package.json";

import { LoadingCircularProgress } from "../Loading";
import { setLocale } from "../../redux/slices/layout";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";

const helpersLocalStorageItems = (
  password: string,
  username: string,
  rememberMe: boolean,
  emails: string[]
) => {
  localStorage.setItem("password", password);
  localStorage.setItem("username", username);
  localStorage.setItem("rememberme", `${rememberMe}`);
  if (!emails) {
    localStorage.setItem("emails", `[]`);
  }
};

const SetLocaleActions = () => {
  const dispatch = useDispatch();
  return {
    setLocaleLanguage: (locale: string) => dispatch(setLocale(locale)),
    setRememberMe: (remberme: boolean) => dispatch(setRememberMe(remberme)),
  };
};

type LoginFormProps = {
  submitHandler: (e: FormEvent) => void;
  checkBox: (value: boolean) => void;
  error: string;
  username: string;
  password: string;
  rememberMe: boolean;
  loading: boolean;
  setUsername: (value: string) => void;
  setPassword: (value: string) => void;
  errorAuthenticated: number | null;
  resetPaswordSuccess: boolean;
};

//  Login v2

export const SelectLanguage: FC<any> = () => {
  const [t, i18n] = useTranslation("global");
  const { locale } = useSelector((state: State) => state.LayoutReducer);
  const { setLocaleLanguage } = SetLocaleActions();
  const { setTranslation } = useSelector(
    (state: State) => state.Authentication
  );
  return (
    <Box
      id="language-label"
      sx={{
        width: "136px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "3px",
      }}
    >
      <FormControl
        sx={{
          borderTop: "red",
        }}
        size="small"
        fullWidth
      >
        <InputLabel>{t("Login.language")}</InputLabel>
        <Select value={locale} data-cy="select-languages" label="Language">
          <MenuItem
            data-cy="en"
            value={"en"}
            disabled={locale === "en"}
            onClick={() => {
              i18n.changeLanguage(setTranslation.Eng);
              setLocaleLanguage("en");
            }}
          >
            ENG
          </MenuItem>
          <MenuItem
            value={"fr"}
            data-cy="fr"
            disabled={locale === "fr"}
            onClick={() => {
              i18n.changeLanguage(setTranslation.Fr);
              setLocaleLanguage("fr");
            }}
          >
            FR
          </MenuItem>
          <MenuItem
            value={"es"}
            data-cy="es"
            disabled={locale === "es"}
            onClick={() => {
              i18n.changeLanguage(setTranslation.Es);
              setLocaleLanguage("es");
            }}
          >
            ESP
          </MenuItem>
          <MenuItem
            value={"de"}
            data-cy="de"
            disabled={locale === "de"}
            onClick={() => {
              i18n.changeLanguage(setTranslation.De);
              setLocaleLanguage("de");
            }}
          >
            DE
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const LoginForm: FC<LoginFormProps> = ({
  submitHandler,
  username,
  password,
  loading,
  rememberMe,
  setUsername,
  setPassword,
  checkBox,
  error,
  errorAuthenticated,
  resetPaswordSuccess,
}) => {
  const [t] = useTranslation("global");
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <div className="login-v2-container">
      <div className="login-form">
        <div className="container-info-login-alpha">
          <div className="select-language-div">
            <SelectLanguage />
          </div>
          <div className="inarix-icon-position">
            <img
              data-cy="brand-logo-container"
              src={NewInarixIcon}
              className="inarix-icon-design"
              alt="inarix-icon"
            />

            <div className="form-design">
              <h4 className="title-form-login">{t("Login.welcome")}</h4>
              <div className="subtitle-form-login">{t("Login.welcome2")}</div>
              <form className="form-login-data" onSubmit={submitHandler}>
                <TextField
                  fullWidth
                  size="small"
                  error={!!error}
                  data-cy="username"
                  className="input-user-pass"
                  label={t("Login.username")}
                  InputLabelProps={{ shrink: true }}
                  value={username}
                  onChange={(e) => setUsername(e.currentTarget.value)}
                  required
                />
                <br />
                <br />

                <TextField
                  fullWidth
                  size="small"
                  error={!!error}
                  helperText={error ? JSON.stringify(error) : undefined}
                  type={showPassword ? "text" : "password"}
                  data-cy="password"
                  className="input-user-pass"
                  label={t("Login.password")}
                  value={password}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {!showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required
                />

                <Link
                  id={"resetPassword"}
                  className="reset-password"
                  to={"/recoverPassword"}
                >
                  {t("Login.forgotPass")}
                </Link>
                <div className="remember-me">
                  <input
                    onChange={() => checkBox(rememberMe)}
                    id="remember-me-login"
                    data-cy="remember-me"
                    type="checkbox"
                    className="remember-me-checkbox"
                    checked={rememberMe}
                  />

                  <label
                    className="remember-me-text"
                    data-cy="remember-me-text"
                  >
                    {t("Login.remember")}
                  </label>
                </div>
                <div className="container-button-login nmt-3">
                  {!loading ? (
                    <Button
                      variant="contained"
                      data-cy="login"
                      type="submit"
                      className="button-login"
                      style={{ textTransform: "none" }}
                    >
                      {t("Login.login")}
                    </Button>
                  ) : (
                    <LoadingCircularProgress />
                  )}
                </div>
                <div className="snackbar-position">
                  <Snackbar
                    open={resetPaswordSuccess}
                    sx={{ position: "inherit" }}
                    message={t("Login.password_updated")}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="version-position">{pjson.version}</div>
      </div>
      <div className="img-form" />
    </div>
  );
};

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setUsername: (value: string) => dispatch(loginSetUsername(value)),
    setPassword: (value: string) => dispatch(loginSetPassword(value)),
    disableError: () => dispatch(disableErrorLogin()),
    setEmailSent: (setEmail: boolean) => dispatch(setEmailSent(setEmail)),
    signIn: () => {
      dispatch(loginSignin());
    },
    setLocaleLanguage: (locale: string) => dispatch(setLocale(locale)),
    resetState: () => dispatch(loginResetState()),
    setRememberme: (rememberme: boolean) => {
      dispatch(setRememberMe(rememberme));
    },
    setResetPasswordSuccess: (isSuccess: boolean) =>
      dispatch(setResetPasswordSuccess(isSuccess)),
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Login = () => {
  const rememberme = localStorage.getItem("rememberme");
  const passwordAlpha = localStorage.getItem("password");
  const usernameAlpha = localStorage.getItem("username");
  const emails = JSON.parse(localStorage.getItem("emails") as string);

  const {
    username,
    password,
    loading,
    rememberMe,
    error,
    errorAuthenticated,
    resetPaswordSuccess,
  } = useSelector((state: State) => state.Authentication);

  const language = localStorage.getItem("i18nextLng");
  const {
    setUsername,
    setPassword,
    signIn,
    setRememberme,
    setLocaleLanguage,
    resetState,
    setEmailSent,
    setResetPasswordSuccess,
  } = SetActions();

  const transformRememberme = rememberme === "true";
  const check = () => {
    if (!rememberMe) {
      return setRememberme(true);
    }
    return setRememberme(false);
  };

  if (resetPaswordSuccess) {
    setTimeout(() => {
      setResetPasswordSuccess(false);
    }, 5000);
  }
  useEffect(() => {
    resetState();
    setEmailSent(false);
    if (!transformRememberme) {
      setUsername("");
      setPassword("");
      setRememberme(false);
    } else {
      setUsername(usernameAlpha ?? "");
      setPassword(passwordAlpha ?? "");
      setRememberme(true);
    }

    setLocaleLanguage(language ?? "fr");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    signIn();
    helpersLocalStorageItems(password, username, rememberMe, emails);
  };

  return (
    <LoginForm
      submitHandler={submitHandler}
      error={error}
      username={username}
      password={password}
      loading={loading}
      setUsername={setUsername}
      setPassword={setPassword}
      rememberMe={rememberMe}
      checkBox={check}
      errorAuthenticated={errorAuthenticated}
      resetPaswordSuccess={resetPaswordSuccess}
    />
  );
};

export default Login;
