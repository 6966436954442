import React from 'react';
import moment from 'moment';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';

noDataToDisplay(Highcharts);

const ActivityMiniChart = (activitych: any, t: (arg0: string) => string) => {
  const dataLocActivity = activitych?.map((activityChart: any) => {
    const dateValue = new Date(activityChart?.date).toISOString();
    const dateMoment = moment(dateValue);
    const language = localStorage.getItem('i18nextLng');
    dateMoment.locale(language as string);

    return {
      date: dateMoment.format('l'),
      activity: parseInt(activityChart.count as string),
    };
  });

  const chartOptions: Options = {
    colors: ['#058DC7'],
    chart: {
      type: 'line',
    },
    loading: {
      hideDuration: 1000,
      showDuration: 1000,
    },

    title: {
      text: t('Dashboard.PocketLab_usage'),
    },

    series: [
      {
        name: t('Dashboard.activity'),
        data: dataLocActivity.map((u: any) => u.activity),
      } as any,
    ],
    yAxis: {
      title: {
        text: '',
      },
    },
    xAxis: {
      categories: dataLocActivity.map((u: any) => u.date),
      title: {},
    },
  };

  return (
    <HighchartsReact
      containerProps={{
        style: {
          width: '352px',
          height: '258px',
        },
      }}
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
};

export default ActivityMiniChart;
