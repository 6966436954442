import { FC } from "react";
import { State } from "../../../redux/slices";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  CreateNewSite,
  CreateNewSiteSetValues,
} from "../../../redux/slices/location/createLocation";

import { FluxStandardAction } from "flux-standard-action";
import React from "react";
import { coordinatesGeocoder } from "../../map/mapEditCreateDeleteLocations/MapEditCreateLocationSideBar";
import MapboxGeocoder, { Result } from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";
import { fetchLocationMapbox } from "../../../redux/actions/map/mapAsyncActions";
import AutoCompleteGroupsCreateLocations from "./AutoCompleteGroups";
// import "mapbox-gl/dist/mapbox-gl.css";
// import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setCreateSiteValues: (value: CreateNewSite) => {
      dispatch(CreateNewSiteSetValues(value));
    },

    setSearchAddress: (text: string) => dispatch(fetchLocationMapbox({ text })),
  };
};

export const StepTwoOptionB: FC<any> = () => {
  const [t, i18n] = useTranslation("global");
  const { createNewSite } = useSelector(
    (state: State) => state.CreateLocationReducer
  );

  const {
    group,
    identifier,
    latitude,
    longitude,
    siteAddress,
    siteName,
    createNewUsers,
  } = createNewSite;

  const { setCreateSiteValues, setSearchAddress } = SetActions();

  const geocoder = new MapboxGeocoder({
    accessToken: process.env.REACT_APP_MAPBOX_TOKEN as string,
    localGeocoder: coordinatesGeocoder as unknown as (
      query: string
    ) => Result[],
    zoom: 4,
    placeholder: t(
      `Fleet_overview.overview_panel.createNewPosition.input_search_loc`
    ),

    mapboxgl: mapboxgl as any,
    reverseGeocode: true,
  });

  React.useEffect(() => {
    geocoder.addTo("#geocoder");
    const results = document.getElementById("result") as any;
    let searchResult = results?.innerText as void | FluxStandardAction<
      string,
      any,
      undefined
    >;

    geocoder.on("result", (e: any) => {
      searchResult =
        e.result.geometry.coordinates[1] < -90 ||
        e.result.geometry.coordinates[1] > 90
          ? console.log("to big")
          : setCreateSiteValues({
              ...createNewSite,
              latitude: e.result.geometry.coordinates[1],
              longitude: e.result.geometry.coordinates[0],
            });
      e.result.geometry.coordinates[1] < -90 ||
      e.result.geometry.coordinates[1] > 90
        ? console.log("big")
        : setCreateSiteValues({
            ...createNewSite,
            latitude: e.result.geometry.coordinates[1],
            longitude: e.result.geometry.coordinates[0],
          });
      e.result.geometry.coordinates[1] < -90 ||
      e.result.geometry.coordinates[1] > 90
        ? console.log("big")
        : setCreateSiteValues({
            ...createNewSite,
            latitude: e.result.geometry.coordinates[1],
            longitude: e.result.geometry.coordinates[0],
          });
      setSearchAddress(
        `${e.result.geometry.coordinates[0]},${e.result.geometry.coordinates[1]}`
      );
    });

    geocoder.on("clear", () => {
      searchResult = console.log(`${searchResult}, clean`);
    });
  }, []);

  return (
    <div className="steps-container-create-loc">
      <div className="step-title">STEP 1</div>
      <div>
        <div className="step-title-create">Create a new site</div>
        <div className="create-site-form" style={{ background: "#fff" }}>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1 } }}
            noValidate
            autoComplete="off"
          >
            <div className="input-create-loc-form">
              <TextField
                inputProps={{ style: { height: "31.5px" } }}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Site name"
                value={siteName}
                variant="outlined"
                onChange={(e) =>
                  setCreateSiteValues({
                    ...createNewSite,
                    siteName: e.currentTarget.value,
                  })
                }
              />
            </div>
            <div className="container-address-form">
              <div className="container-address-input">
                <label className="form-label-input-address">Site address</label>
                <div id="geocoder" className="" />
                {/* <div id="geocoder" className="geocoder" /> */}
              </div>
              <span className="or-span-create-loc">or</span>
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={{
                  marginTop: "2.5px",
                  width: 150,
                  maxWidth: "calc(100% - 20px)",
                  marginRight: "24px",
                }}
                onChange={(e) =>
                  setCreateSiteValues({
                    ...createNewSite,
                    longitude: parseInt(e.currentTarget.value),
                  })
                }
                inputProps={{ style: { height: "31.5px" } }}
                value={longitude}
                label="Site longitude"
                variant="outlined"
                type="number"
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                type="number"
                inputProps={{ style: { height: "31.5px" } }}
                sx={{
                  marginTop: "2.5px",
                  width: 150,
                  maxWidth: "calc(100% - 20px)",
                }}
                onChange={(e) =>
                  setCreateSiteValues({
                    ...createNewSite,
                    latitude: parseInt(e.currentTarget.value),
                  })
                }
                value={latitude}
                label="Site latitude"
                variant="outlined"
              />
            </div>

            <div className="create-site-form-2">
              {AutoCompleteGroupsCreateLocations(t)}
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={{
                  width: 406,
                  maxWidth: "calc(100% - 20px)",
                  marginTop: "24px",
                }}
                inputProps={{ style: { height: "31.5px" } }}
                value={identifier}
                onChange={(e) =>
                  setCreateSiteValues({
                    ...createNewSite,
                    identifier: e.currentTarget.value,
                  })
                }
                label="Identifier (Facultative)"
                variant="outlined"
              />
              {/* <span className="span-create-loc-above-input">
                You can indicate an internal identifier to simplify the exchange
                with our customer service.
              </span> */}
            </div>
            <div>
              <div className="title-need-new-users">
                Do you need to create new users ?
              </div>
              <div className="option-create-users">
                <FormGroup sx={{ flexDirection: "inherit" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon />}
                      />
                    }
                    onChange={() =>
                      setCreateSiteValues({
                        ...createNewSite,
                        createNewUsers: true,
                      })
                    }
                    checked={!!createNewUsers}
                    label="yes"
                  />
                  <FormControlLabel
                    onChange={() =>
                      setCreateSiteValues({
                        ...createNewSite,
                        createNewUsers: false,
                      })
                    }
                    control={
                      <Checkbox
                        checked={!createNewUsers}
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon />}
                      />
                    }
                    label="no"
                  />
                </FormGroup>
              </div>
            </div>
          </Box>
        </div>
        <Button
          variant="contained"
          sx={{ textTransform: "none", marginTop: "24px" }}
        >
          Continue
        </Button>
      </div>
      <div className="background-components-fix" />
    </div>
  );
};

export default StepTwoOptionB;
