import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";

import { setSBExtraFiltersVisible } from "../../../redux/slices/data/data";
import FilterListIcon from "@mui/icons-material/FilterList";
import { inarixColors } from "inarix-theme";
import { State } from "../../../redux/slices";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setVisibleSBExtraFilters: (visible: boolean) => {
      dispatch(setSBExtraFiltersVisible(visible));
    },
  };
};

export const ExtraFiltersButton = (t: (arg0: string) => string) => {
  const { setVisibleSBExtraFilters } = SetActions();

  const { dataSBFilters } = useSelector((state: State) => state.DataReducer);
  const { filtersSelectedAfterFetch } = dataSBFilters;

  return (
    <div>
      <Button
        variant="outlined"
        onClick={() => setVisibleSBExtraFilters(true)}
        sx={{
          textTransform: "none",
          background: inarixColors.contrast_inarix,
          fontWeight: 500,
          borderColor: "#C0C0C0",
          "&:hover": {
            backgroundColor: inarixColors.action_hover_inarix,
            border: `solid 0.5px #000`,
            boxShadow: "none",
          },
        }}
      >
        <FilterListIcon sx={{ marginRight: "5px", fontWeight: 500 }} />
        <div className="mr-1" style={{ fontWeight: 600, fontSize: "13px" }}>
          {t("Data.Filter")}
        </div>
        {filtersSelectedAfterFetch >= 1 ? `(${filtersSelectedAfterFetch})` : ""}
      </Button>
    </div>
  );
};
