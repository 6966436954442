import { createSlice } from "@reduxjs/toolkit";

export interface CreateLocationStep {
  step: number;
  option: string;
}

export interface CreateNewSite {
  siteName: string;
  group: string[];
  siteAddress: string;
  longitude: number;
  latitude: number;
  identifier: string;
  countryName: string;
  createNewUsers: boolean;
}

export interface CreateLocationState {
  createLocationStep: CreateLocationStep;
  createNewSite: CreateNewSite;
}

export const initialState: CreateLocationState = {
  createLocationStep: {
    step: 0,
    option: "",
  },

  createNewSite: {
    siteName: "",
    group: [],
    siteAddress: "",
    longitude: 0,
    latitude: 0,
    identifier: "",
    countryName: "",
    createNewUsers: false,
  },
};

export const CreateLocationSlice = createSlice({
  name: "CreateLocationReducer",
  initialState,
  reducers: {
    setCreateLocationStep(state, action) {
      return {
        ...state,
        createLocationStep: action.payload,
      };
    },

    CreateNewSiteSetValues(state, action) {
      return {
        ...state,
        createNewSite: action.payload,
      };
    },
  },
});

export const { setCreateLocationStep, CreateNewSiteSetValues } =
  CreateLocationSlice.actions;

export const CreateLocation = CreateLocationSlice.reducer;
