import React from "react";

import { useTranslation } from "react-i18next";
import inputRemoteIdIcon from "../../assets/images/scenarioIcons/remoteId.png";
import declareLabelIcon from "../../assets/images/scenarioIcons/declaration.png";
import predictionIcon from "../../assets/images/scenarioIcons/prediction.png";
import collectPicturesIcon from "../../assets/images/scenarioIcons/collectPictures.png";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import {
  ComponentSlugs,
  ScenarioInstanceStep,
} from "../../redux/actions/actions/actionTypes";

const ActionInformationStep = ({ screenSlug }: any) => {
  const [t] = useTranslation("global");
  switch (screenSlug.name) {
    case ComponentSlugs.INPUT_REMOTE_ID:
      return (
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "40px",
          }}
        >
          <ListItemText
            primary={t("Fleet_actions.individual_action.remote_id")}
          />
          <ListItemIcon sx={{ marginLeft: "40px", marginRight: "9px" }}>
            <img
              className="img-input-remoteid"
              src={inputRemoteIdIcon}
              alt="inputRemoteIdIcon"
            />
          </ListItemIcon>
          <ListItemText primary={screenSlug.description} />
        </ListItem>
      );

    case ComponentSlugs.MULTI_DECLARE_LABEL:
      return (
        <div className="action-information-container mt-4 mb-3">
          <span className="action-information-style declare-label mr-3 mt-2">
            {t("Fleet_actions.individual_action.declare_label")}
          </span>
          <div className="multi-declare-label">
            <img
              className="multi-declare-label-image"
              src={declareLabelIcon}
              alt="declareLabelIcon"
            />
          </div>
        </div>
      );

    case ComponentSlugs.DECLARE_LABEL:
      return (
        <div className="multi-information-container-declare-label mb-3">
          <span>{screenSlug.description}</span>
        </div>
      );
    case ComponentSlugs.COLLECT_PICTURES:
      return (
        <div className="action-information-container pictures">
          <span className="action-information-style pictures-style mr-2">
            {t("Fleet_actions.individual_action.pictures_collection")}
          </span>
          <div className="icon-style-camera">
            <img
              className="image-style"
              src={collectPicturesIcon}
              alt="collectPicturesIcon"
            />
          </div>
          <div className="description-style-one">
            <span>{screenSlug.description}</span>
          </div>
        </div>
      );
    case ComponentSlugs.PREDICTION:
      return (
        <div className="multi-information-container-prediction">
          <span>{screenSlug.description}</span>
        </div>
      );
    case ComponentSlugs.MULTI_PREDICTION:
      return (
        <div className="action-information-container">
          <span className="action-information-style prediction-style mt-5">
            {t("Fleet_actions.individual_action.prediction")}
          </span>
          <div className="multi-prediction-div icon-style prediction-img">
            <img
              className="image-style"
              src={predictionIcon}
              alt="predictionIcon"
            />
          </div>
        </div>
      );
    case ComponentSlugs.COLLECT_PICTURES_EXTERNAL_DEVICE:
      return (
        <div className="external-device-div mt-4 mb-3">
          <div className="external-device-text">
            {t("Fleet_actions.individual_action.pictures_collection")}
          </div>
          <div className="external-device-img icon-style ">
            <img
              className=""
              src={collectPicturesIcon}
              alt="collectPicturesIcon"
            />
          </div>
          <div className="external-device-slug description-style ml-3">
            <span>{screenSlug.description}</span>
          </div>
        </div>
      );
    case ComponentSlugs.DEBUG_CAPTURE:
      return (
        <div className="action-information-container mt-4">
          <div className="action-information-style  mr-3">
            {t("Fleet_actions.individual_action.pictures_collection")}
          </div>
          <div className="icon-style">
            <img
              className="image-style"
              src={collectPicturesIcon}
              alt="collectPicturesIcon"
            />
          </div>
          <div className="description-style ml-3">
            <span>{screenSlug.description}</span>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export const separateInfo = (scenario: ScenarioInstanceStep[]): JSX.Element => {
  const stepName = [];
  const step = scenario?.map((x: ScenarioInstanceStep) => ({
    name: x.screenSlug,
    description: x.userFacingShortName ? x.userFacingShortName : x.description,
  }));

  let multipleDescription = {};
  let multiplePrediction = {};
  for (let x = 0; x < step?.length; x++) {
    if (step[x].name === ComponentSlugs.DECLARE_LABEL) {
      multipleDescription = {
        name: ComponentSlugs.MULTI_DECLARE_LABEL,
        description: step[x].description,
      };
      stepName.push(multipleDescription);
    }
    while (
      step[x].name === ComponentSlugs.DECLARE_LABEL &&
      step[x + 1] &&
      step[x + 1].name === ComponentSlugs.DECLARE_LABEL
    ) {
      multipleDescription = {
        name: ComponentSlugs.DECLARE_LABEL,
        description: step[x].description,
      };
      stepName.push(multipleDescription);
      x++;
    }
    if (step[x].name === ComponentSlugs.PREDICTION) {
      multiplePrediction = {
        name: ComponentSlugs.MULTI_PREDICTION,
        description: step[x].description,
      };
      stepName.push(multiplePrediction);
    }
    while (
      step[x].name === ComponentSlugs.PREDICTION &&
      step[x + 1] &&
      step[x + 1].name === ComponentSlugs.PREDICTION
    ) {
      multiplePrediction = {
        name: ComponentSlugs.PREDICTION,
        description: step[x].description,
      };
      stepName.push(multiplePrediction);
      x++;
    }
    stepName.push(step[x]);
  }

  return (
    <div>
      {stepName.map((label) => (
        <ActionInformationStep screenSlug={label} key={label} />
      ))}
    </div>
  );
};

export default separateInfo;
