import React from "react";
import { useDispatch } from "react-redux";

import { Button, Divider, FormControl } from "@mui/material";

import { TextFieldInputEditCreate } from "../OverviewStyles";
import { ViewsValues } from "../../../redux/actions/data/DataDeclarations";
import { useStyles } from "../../modals/modalStyles";
import {
  initialState,
  setModalViewsMap,
  setTypeOfActionMap,
  setViewValues,
} from "../../../redux/slices/map/map";
import { createNewView } from "../../../redux/actions/map/mapAsyncActions";
import { ThunkDispatch } from "@reduxjs/toolkit";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setViewValues: (viewValues: ViewsValues) =>
      dispatch(setViewValues(viewValues)),
    createView: (token: string) => {
      dispatch(createNewView(token));
      dispatch(setTypeOfActionMap("createMapView"));
    },
    setModalViews: (open: boolean) => dispatch(setModalViewsMap(open)),
  };
};

export const BodyViewsModal = (
  token: string,
  createViewLoading: boolean,
  viewMapValues: ViewsValues,
  t: (arg0: string) => string
): JSX.Element => {
  const classes = useStyles();

  const { setViewValues, createView, setModalViews } = SetActions();

  const submitHandler = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    createView(token);
  };

  const closeModal = () => {
    setModalViews(false);
    setViewValues(initialState.viewMapValues);
  };

  const { nameView } = viewMapValues;

  return (
    <div className={classes.confirmModal}>
      <div className="division-create-loc-edit">
        <span
          className="span-division-create-edit-loc"
          data-cy="title-modal-create-view"
        >
          {t("Data.views.Save_view")}
        </span>
      </div>
      <Divider />
      <div className="mb-5 mt-3" data-cy="description-modal-create-view">
        <span>{t("Data.views.View_modal_desc")}</span>
      </div>
      <form onSubmit={submitHandler}>
        <FormControl fullWidth>
          <TextFieldInputEditCreate
            data-cy="input-title-view"
            size="small"
            type="text"
            label={t("Data.views.View_name")}
            required
            value={viewMapValues.nameView}
            onChange={(e) =>
              setViewValues({
                ...viewMapValues,
                nameView: e.currentTarget.value,
              })
            }
          />
        </FormControl>
        <FormControl fullWidth>
          <TextFieldInputEditCreate
            data-cy="input-description-view"
            size="small"
            type="text"
            label={t("Data.views.View_description")}
            value={viewMapValues.viewDescription}
            onChange={(e) =>
              setViewValues({
                ...viewMapValues,
                viewDescription: e.currentTarget.value,
              })
            }
          />
        </FormControl>
        <div className="position-buttons-create">
          {!createViewLoading ? (
            <Button
              className="btn-styles mr-3"
              variant="outlined"
              onClick={closeModal}
              data-cy="cancel-button-view"
            >
              {t("Fleet_overview.overview_panel.createNewPosition.cancel")}
            </Button>
          ) : (
            ""
          )}
          {!createViewLoading ? (
            <Button
              variant="contained"
              type="submit"
              size="large"
              className="btn-styles"
              disabled={!nameView || createViewLoading}
              data-cy="save-button-view"
            >
              {t("Fleet_overview.overview_panel.createNewPosition.save")}
            </Button>
          ) : (
            <div className="loading-icon">
              <i className="fas fa-spinner fa-pulse" />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};
