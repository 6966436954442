import React from "react";
import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { AvgBarleyWheatChart } from "../../../redux/actions/dashboard/dashboardTypes";

const WheatVarietyRateChart = (
  avgProtWheat: AvgBarleyWheatChart[],
  t: (arg0: string) => string
) => {
  const chartOptions: Options = {
    colors: ["#50B432", "#058DC7"],
    chart: {
      type: "column",
    },
    title: {
      text: t("Dashboard.cereal_charts.protein_levels_variety"),
    },

    series: [
      {
        name: t("Dashboard.protein_rate"),
        data: avgProtWheat.map((u: any) => u.average),
      } as any,
    ],
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      categories: avgProtWheat.map((u: any) => u.variety),
    },
  };
  return (
    <HighchartsReact
      containerProps={{
        style: {
          width: "100%",
          marginBottom: "50px",
        },
      }}
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
};

export default WheatVarietyRateChart;
