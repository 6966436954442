import { createSlice } from "@reduxjs/toolkit";
import { GroupsInfo, UserInfo } from "../../actions/users/userTypes";
import {
  deleteMultipleUsersFromGroup,
  deleteUserFromGroup,
  fetchGroups,
  fetchUsersNewRequest,
  modifyAllUserToLoc,
  moveAllGroup,
} from "../../actions/users/userAsyncActions";

export interface UsersState {
  users: UserInfo;
  userId: string[];
  groups: GroupsInfo;
  groupId: number;
  loading: boolean;
  loadingDelete: boolean;
  msg: string;
  modal: boolean;
  modalToRemoveUser: boolean;
  error: string;
  usersSelected: string[];
  rowsNumber: number;
  overviewSearchText: string;
  userTabsRowsToSearch: any;
  userGroupsSelected: any;
  modalLocationUser: boolean;
  optionToModify: string;
  filterModelLocation: any;
}

export const initialState: UsersState = {
  users: { total: 0, limit: 0, skip: 0, data: [] },
  userId: [],
  groups: { total: 0, limit: 0, skip: 0, data: [] },
  groupId: 0,
  loading: false,
  loadingDelete: false,
  msg: "",
  modal: false,
  modalToRemoveUser: false,
  error: "",
  usersSelected: [],
  rowsNumber: 25,
  overviewSearchText: "",
  userTabsRowsToSearch: [],
  userGroupsSelected: [],
  modalLocationUser: false,
  optionToModify: "",
  filterModelLocation: undefined,
};

export const UserSlice = createSlice({
  name: "UsersReducer",
  initialState,
  reducers: {
    resetStateUserGroups() {
      return initialState;
    },
    cleanMessageNDError(state) {
      return {
        ...state,
        msg: "",
        error: "",
      };
    },
    setUserId(state, action) {
      return {
        ...state,
        userId: action.payload,
      };
    },
    setGroupId(state, action) {
      return {
        ...state,
        groupId: action.payload,
      };
    },
    disableUsersErrors(state) {
      return {
        ...state,
        error: "",
      };
    },
    setNumberRowsUsers(state, action) {
      return {
        ...state,
        rowsNumber: action.payload,
      };
    },
    setFilterLocUserTabs(state, action) {
      return {
        ...state,
        filterModelLocation: action.payload,
      };
    },
    setSelectionModelUsers(state, action) {
      return {
        ...state,
        usersSelected: action.payload,
      };
    },
    setUserModal(state, action) {
      return {
        ...state,
        modal: action.payload,
      };
    },
    setRemoveUsersModal(state, action) {
      return {
        ...state,
        modalToRemoveUser: action.payload,
      };
    },

    setLocationModalUsers(state, action) {
      return {
        ...state,
        modalLocationUser: action.payload,
      };
    },
    setOpenModalAddValues(state, action) {
      return {
        ...state,
        optionToModify: action.payload,
        modalLocationUser: true,
      };
    },
    setModalAddLocUsers(state, action) {
      return {
        ...state,
        modalLocationUser: action.payload,
      };
    },

    setFilterFromLocToUserTabs(state, action) {
      return {
        ...state,
        filterModelLocation: {
          items: [
            {
              field: "location",
              operator: "contains",
              value: action.payload,
            },
          ],
        },
      };
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchUsersNewRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsersNewRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchUsersNewRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    builder
      .addCase(fetchGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = action.payload;
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    builder
      .addCase(moveAllGroup.pending, (state) => {
        state.loading = true;
        state.msg = "";
      })
      .addCase(moveAllGroup.fulfilled, (state) => {
        state.loading = false;
        state.msg = "Success";
      })
      .addCase(moveAllGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    builder
      .addCase(deleteMultipleUsersFromGroup.pending, (state) => {
        state.loading = true;
        state.msg = "";
      })
      .addCase(deleteMultipleUsersFromGroup.fulfilled, (state) => {
        state.loading = false;
        state.msg = "Success";
      })
      .addCase(deleteMultipleUsersFromGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    builder
      .addCase(deleteUserFromGroup.pending, (state) => {
        state.loading = true;
        state.msg = "";
      })
      .addCase(deleteUserFromGroup.fulfilled, (state) => {
        state.loading = false;
        state.modalToRemoveUser = false;
        state.groupId = 0;
        state.msg = "Success";
      })
      .addCase(deleteUserFromGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    builder
      .addCase(modifyAllUserToLoc.pending, (state) => {
        state.loading = true;
        state.msg = "";
      })
      .addCase(modifyAllUserToLoc.fulfilled, (state) => {
        state.loading = false;
        state.modalLocationUser = false;
        state.msg = "Success";
      })
      .addCase(modifyAllUserToLoc.rejected, (state, action) => {
        state.loading = false;
        state.modalLocationUser = false;
        state.error = action.payload as string;
      });
  },
});

export const {
  disableUsersErrors,
  setNumberRowsUsers,
  setFilterLocUserTabs,
  setSelectionModelUsers,
  setUserModal,
  setUserId,
  setGroupId,
  cleanMessageNDError,
  setRemoveUsersModal,
  setLocationModalUsers,
  setOpenModalAddValues,
  setModalAddLocUsers,
  resetStateUserGroups,
  setFilterFromLocToUserTabs,
} = UserSlice.actions;

export const Users = UserSlice.reducer;
