import { inarixThemeMui } from "inarix-theme";
import { frFR, enUS, esES, deDE } from "@mui/x-data-grid";
import { createTheme, ThemeOptions } from "@mui/material/styles";

const setLanguage = (language: string) => {
  if (language === "fr") {
    return frFR;
  }

  if (language === "de") {
    return deDE;
  }
  if (language === "es") {
    return esES;
  } else {
    return enUS;
  }
};

const themeInarix = (language: string) =>
  createTheme(inarixThemeMui as ThemeOptions, setLanguage(language));

export default themeInarix;
