import React, { FormEvent } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  DataSBFilters,
  setExtraFiltersValuesAutocomplete,
} from "../../../redux/slices/data/data";
import { State } from "../../../redux/slices";
import { fetchSamples } from "../../../redux/actions/data/dataAsyncActions";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setInputValues: (value: string, dataSBFilters: DataSBFilters) => {
      dispatch(
        setExtraFiltersValuesAutocomplete({
          ...dataSBFilters,
          inputSearch: value,
        })
      );
    },

    fetchResults: (token: string) => {
      dispatch(fetchSamples({ token }));
    },
  };
};

const DataInputSearch = (t: (arg0: string) => string, token: string) => {
  const { setInputValues, fetchResults } = SetActions();
  const { dataSBFilters, dataSamplesLoading } = useSelector(
    (state: State) => state.DataReducer
  );
  const submitHandler = (e: FormEvent) => {
    fetchResults(token);
    e.preventDefault();
  };

  return (
    <Paper
      onSubmit={submitHandler}
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        maxWidth: "372px",
        minWidth: "150px",
        height: 37,
        border: `solid 1px #C0C0C0`,
        boxShadow: "none",
      }}
    >
      <IconButton disabled type="submit" sx={{ p: "10px" }} aria-label="menu">
        <SearchIcon />
      </IconButton>
      <InputBase
        value={dataSBFilters.inputSearch}
        onChange={(e) => setInputValues(e.target.value, dataSBFilters)}
        disabled={dataSamplesLoading}
        sx={{
          ml: 1,
          flex: 1,
          "& input::placeholder": {
            fontSize: "12px",
            fontStyle: "italic",
            fontWeight: 500,
          },
        }}
        placeholder={t("Data.Search_input")}
      />

      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <Button
        color="primary"
        type="submit"
        disabled={dataSamplesLoading}
        sx={{
          p: "10px",
          borderLeft: "1px solid #C0C0C0",
          height: 35,
          borderRadius: 0,
          textTransform: "none",
        }}
        aria-label="directions"
      >
        {t("Fleet_overview.search_sidebar.search_any")}
      </Button>
    </Paper>
  );
};

export default DataInputSearch;
