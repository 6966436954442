import React from "react";
import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ChartBarleySoftWheat } from "../../../redux/actions/dashboard/dashboardTypes";

const WheatVarietyChart = (
  chartSoftVarietyActions: ChartBarleySoftWheat[],
  t: (arg0: string) => string
) => {
  const chartOptions: Options = {
    colors: ["#7BB4EC", "#50B432"],
    chart: {
      type: "column",
    },
    title: {
      text: t("Dashboard.cereal_charts.distribution_by_variety"),
    },

    series: [
      {
        name: t("Dashboard.number_of_actions"),
        data: chartSoftVarietyActions.map(
          (u: ChartBarleySoftWheat) => u.actions
        ),
      } as any,
    ],
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      categories: chartSoftVarietyActions.map(
        (u: ChartBarleySoftWheat) => u.variety
      ),
    },
  };
  return (
    <HighchartsReact
      containerProps={{
        style: {
          width: "100%",
        },
      }}
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
};

export default WheatVarietyChart;
