import { CircularProgress } from '@mui/material';

export const LoadingCircularProgress = () => {
  return (
    <div className="loading-position mt-3">
      <CircularProgress />
    </div>
  );
};

export const LoadingSmallSpinner = () => {
  return (
    <div className="loading-icon">
      <i className="fas fa-spinner fa-pulse" />
    </div>
  );
};
