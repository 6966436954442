import { createSlice } from "@reduxjs/toolkit";
import { OrgGeoPointData } from "../../actions/locations/locationsTypes";
import {
  CountActivity,
  FetchUsersDataGeoP,
  DevicesActivity,
  GeoLocTypes,
  NewGeoLocation,
  ValuesCreateEditLocation,
  ViewsSavedData,
} from "./mapTypes";
import {
  createLocation,
  createNewView,
  deleteLocation,
  deleteView,
  fetchAllUsersIds,
  fetchGeoLocDataOverviewMini,
  fetchLocationImg,
  fetchLocationsWithActivity,
  fetchOrgGeoPointTypes,
  getMapViews,
  updateLocation,
} from "../../actions/map/mapAsyncActions";
import { ViewsValues } from "../../actions/data/DataDeclarations";
import { ChartActivityInfo } from "../../actions/dashboard/dashboardTypes";

export interface MarkerEvent {
  lat: number;
  lng: number;
}

export interface MapState {
  sidebarGeoLoc: boolean;
  locations: OrgGeoPointData[];
  locationError: string;
  locationsLoading: boolean;
  viewport: {
    latitude?: number;
    longitude?: number;
    zoom?: number;
    pitch?: number;
  };
  locationActive: OrgGeoPointData;
  locationActiveUsersIds: FetchUsersDataGeoP[];
  locationActiveLoading: boolean;
  locationActiveInfoError: string;
  locationActivity: CountActivity[] | null;
  locationActivityLoading: boolean;
  locationDateActivity: Date[];
  locationDevicesActivity: DevicesActivity[];
  locationDevicesActivityLoading: boolean;
  newGeoLoc: NewGeoLocation | undefined;

  modalEditLocation: boolean;
  imgFile: File | null;
  searchLocation: string;
  modifyLocation: boolean;
  eventsMarker: MarkerEvent | null;
  loadingUploadImgGL: boolean;
  isUploadedSuccess: boolean;
  isUploadedError: string;
  isEditingGeoLocPhoto: boolean;
  isEditingGeoLocValues: boolean;
  imgLocationUrl: string | null | undefined;
  imgLocationLoading: boolean;
  imgLocationError: string;
  typesToEditGeoLoc: GeoLocTypes[];
  loadTypes: boolean;
  errorFetchTypes: string;
  isGeoLocUpdated: boolean;
  isGeoLocUpdatedLoading: boolean;
  isGeoLocUpdatedError: string;

  coordEditLocation: { latitude: number | null; longitude: number | null };
  latitudeEditCreateGeoLoc: number | null;
  longitudeEditCreateGeoLoc: number | null;

  geoLocIdToModify: null | number;
  createLocationSuccess: boolean;
  createLocationError: string;
  createLocationLoading: boolean;
  createLocationActive: boolean;
  latitudeSecundaryCreate: string;
  longitudeSecundaryCreate: string;
  latitudeCreateLocation: number;

  deleteLocationModal: boolean;
  deleteLocationLoading: boolean;
  deleteLocationSuccess: boolean;
  deleteLocationError: string;
  errorFetchingDevicesActivity: string;

  newValuesEditCreateLocation: ValuesCreateEditLocation;
  typeOfAction: string;
  sideBarViews: boolean;

  // NewFeatures

  sidebarSearch: boolean;
  locationsSidebarSearch: any;
  loadingLocationsSidebarSearch: boolean;
  noResultsSearch: boolean;
  locationSidebarError: string;
  dateRangePickerShow: boolean;
  addressResults: string[];
  sidebarViews: boolean;

  mapView: {
    viewport: any;
    date: any;
    search: string;
    filters: any;
  };

  viewMapValues: ViewsValues;
  modalViewMap: boolean;
  createViewLoading: boolean;
  createdViewMapSuccess: boolean;
  error: string;
  viewsSaved: ViewsSavedData | null;
  loadingMapViews: boolean;
  isMapViewActivated: boolean;
  loadingMiniChartActivity: boolean;
  miniChartActivity: ChartActivityInfo[];
  loadingViews: boolean;
  deleteView: boolean;
  errorViews: string;
}

export const initialState: MapState = {
  sidebarGeoLoc: false,
  locations: [],
  locationsLoading: false,
  locationError: "",
  viewport: {
    latitude: 47.07035158972406,
    longitude: 2.4934122095812548,
    zoom: 5.4430067895195045,
  },
  locationActive: {
    remoteId: "",
    id: 0,
    locationId: 0,
    name: "",
    orgId: 0,
    altitude: 0,
    longitude: 0,
    latitude: 0,
    deletedAt: "",
    createdAt: "",
    updatedAt: "",
    hasActivity: "",
    unAckNotifications: "",
    primaryContactName: "",
    telephone: "",
    email: "",
    address: "",
    notes: "",
    typeId: 0,
    type: {
      id: 0,
      name: "",
      createdAt: "",
      updatedAt: "",
    },
    names: [],
  },
  locationActiveUsersIds: [],
  locationActiveLoading: false,
  locationActiveInfoError: "",
  locationActivity: null,
  locationActivityLoading: false,
  locationDateActivity: [
    new Date(new Date().setDate(new Date().getDate() - 30)),
    new Date(new Date().setDate(new Date().getDate())),
  ],
  locationDevicesActivity: [],
  locationDevicesActivityLoading: false,
  newGeoLoc: undefined,

  modalEditLocation: false,
  imgFile: null,
  searchLocation: "",
  modifyLocation: false,
  eventsMarker: null,

  loadingUploadImgGL: false,
  isUploadedSuccess: false,
  isUploadedError: "",
  isEditingGeoLocPhoto: false,
  isEditingGeoLocValues: false,
  imgLocationUrl: null,
  imgLocationLoading: false,
  imgLocationError: "",
  typesToEditGeoLoc: [],
  loadTypes: false,
  errorFetchTypes: "",
  isGeoLocUpdated: false,
  isGeoLocUpdatedLoading: false,
  isGeoLocUpdatedError: "",
  //
  coordEditLocation: { latitude: null, longitude: null },
  latitudeEditCreateGeoLoc: null,
  longitudeEditCreateGeoLoc: null,
  //
  geoLocIdToModify: null,
  createLocationSuccess: false,
  createLocationError: "",
  createLocationLoading: false,
  createLocationActive: false,
  latitudeSecundaryCreate: "",
  longitudeSecundaryCreate: "",
  latitudeCreateLocation: 0,
  deleteLocationModal: false,
  deleteLocationLoading: false,
  deleteLocationSuccess: false,
  deleteLocationError: "",
  errorFetchingDevicesActivity: "",
  newValuesEditCreateLocation: {
    name: "",
    type: "",
    primaryContactName: "",
    telephone: "+33",
    email: "",
    address: "",
    notes: "",
    remoteId: "",
    countryName: "",
  },
  typeOfAction: "",
  sideBarViews: false,

  // New features
  sidebarSearch: false,
  locationsSidebarSearch: [],
  loadingLocationsSidebarSearch: false,
  noResultsSearch: false,
  locationSidebarError: "",
  dateRangePickerShow: false,
  addressResults: [],
  sidebarViews: false,

  mapView: {
    viewport: {},
    date: [],
    search: "",
    filters: [],
  },

  viewMapValues: { nameView: "", viewDescription: "", email: "" },
  modalViewMap: false,
  createViewLoading: false,
  createdViewMapSuccess: false,
  error: "",

  viewsSaved: null,
  loadingMapViews: false,

  isMapViewActivated: false,
  loadingMiniChartActivity: false,
  miniChartActivity: [],
  loadingViews: false,
  deleteView: false,
  errorViews: "",
};

export const MapSlice = createSlice({
  name: "MapReducer",
  initialState,
  reducers: {
    setViewport(state, action) {
      return {
        ...state,
        viewport: action.payload,
      };
    },
    setGeoPointActive(state, action) {
      return {
        ...state,
        locationActive: action.payload,
      };
    },

    setNewLocationNewCluster(state, action) {
      return {
        ...state,
        newGeoLoc: action.payload,
      };
    },

    setSideBarGeoLocOpenClose(state, action) {
      return {
        ...state,
        sidebarGeoLoc: action.payload,
      };
    },

    overviewSetTrueFalseToModifyLocation(state, action) {
      return {
        ...state,
        modifyLocation: action.payload,
      };
    },

    logEventsNewPosition(state, action) {
      return {
        ...state,
        eventsMarker: action.payload,
      };
    },

    setModalDeleteLocation(state, action) {
      return {
        ...state,
        deleteLocationModal: action.payload,
      };
    },

    setLongitudeEditCreateLocation(state, action) {
      return {
        ...state,
        longitudeEditCreateGeoLoc: action.payload,
      };
    },
    setLatitudeEditCreateLocation(state, action) {
      return {
        ...state,
        latitudeEditCreateGeoLoc: action.payload,
      };
    },

    setCoordsEditLocation(state, action) {
      return {
        ...state,
        coordEditLocation: action.payload,
      };
    },

    setLocationActivitySideBar(state, action) {
      return {
        ...state,
        locationDevicesActivityLoading: false,
        locationDevicesActivity: action.payload,
      };
    },

    resetStateMapMessages(state) {
      return {
        ...state,
        deleteLocationSuccess: false,
        isGeoLocUpdated: false,
        createLocationSuccess: false,
        errorFetchTypes: "",
        deleteLocationError: "",
        disableErrorTiming: 0,
        createdViewMapSuccess: false,
      };
    },

    setMapModifyLocation(state) {
      return {
        ...state,
        modifyLocation: true,
      };
    },

    resetMapModifyLocation(state) {
      return {
        ...state,
        latitudeEditGeoLoc: null,
        longitudeEditGeoLoc: null,
        modifyLocation: false,
        sidebarGeoLoc: false,
        imgFile: null,
        createLocationActive: false,
        newValuesEditCreateLocation: initialState.newValuesEditCreateLocation,
        addressResults: [],
      };
    },

    setImgLocation(state, action) {
      return {
        ...state,
        imgFile: action.payload,
      };
    },

    setNewValueMapUpdateCreateLocation(state, action) {
      return {
        ...state,
        newValuesEditCreateLocation: action.payload,
      };
    },
    /**
     * @CreateLocation
     */

    createNewLocationActive(state) {
      return {
        ...state,
        createLocationActive: true,
        modifyLocation: false,
        latitudeEditGeoLoc: null,
        longitudeEditGeoLoc: null,
        sidebarGeoLoc: false,
        imgFile: null,
        newValuesEditCreateLocation: initialState.newValuesEditCreateLocation,
        sidebarSearch: false,
        sideBarViews: initialState.sideBarViews,
        isMapViewActivated: initialState.isMapViewActivated,
      };
    },

    /**
     * @CreateLocation
     */
    /****
     * @SearchSidebar
     */
    setSearchSidebarOpenClose(state, action) {
      return {
        ...state,
        sidebarSearch: action.payload,
      };
    },

    setOpenCloseRangeDatePickerMap(state, action) {
      return {
        ...state,
        dateRangePickerShow: action.payload,
      };
    },

    setSearchLocationMap(state, action) {
      return {
        ...state,
        searchLocation: action.payload,
      };
    },

    setResultsBoolean(state, action) {
      return {
        ...state,
        noResultsSearch: action.payload,
      };
    },

    setSearchLocation(state, action) {
      return {
        ...state,
        searchLocation: action.payload,
      };
    },
    searchLocationSuccess(state, action) {
      return {
        ...state,
        loadingLocationsSidebarSearch: false,
        locationSidebarError: "",
        locationsSidebarSearch: action.payload,
      };
    },

    setResetSidebarSearch(state) {
      return {
        ...state,
        sidebarSearch: false,
        locationsSidebarSearch: [],
        searchLocation: "",
      };
    },

    /****
     * @SearchSidebar
     */

    /**
     * @DatePickerMap
     */

    setOpenCloseRangeDatePicker(state, action) {
      return {
        ...state,
        dateRangePickerShow: action.payload,
      };
    },
    setDateActivity(state, action) {
      return {
        ...state,
        locationDateActivity: action.payload,
      };
    },
    /**
     * @DatePickerMap
     */

    /**
     *
     * @MapViews
     *
     */

    setSidebarViews(state, action) {
      return {
        ...state,
        sidebarViews: action.payload,
        sidebarSearch: false,
      };
    },

    setViewActive(state, action) {
      return {
        ...state,
        isMapViewActivated: action.payload,
        modifyLocation: false,
        createLocationActive: false,
      };
    },

    setViewValuesMap(state, action) {
      return {
        ...state,
        mapView: action.payload,
      };
    },

    setModalViewsMap(state, action) {
      return {
        ...state,
        modalViewMap: action.payload,
      };
    },

    setViewValues(state, action) {
      return {
        ...state,
        viewMapValues: action.payload,
      };
    },

    resetMapViewState(state) {
      return {
        ...state,
        sidebarSearch: false,
        locationsSidebarSearch: [],
        loadingLocationsSidebarSearch: false,
        noResultsSearch: false,
        locationSidebarError: "",
        dateRangePickerShow: false,
        addressResults: [],
        sidebarViews: false,
        isMapViewActivated: false,
        deleteView: false,
        mapView: {
          viewport: {},
          date: [],
          search: "",
          filters: [],
        },
        viewMapValues: { nameView: "", viewDescription: "", email: "" },
        modalViewMap: false,
        createViewLoading: false,
        createdViewMapSuccess: false,
        error: "",
        viewsSaved: null,
        loadingNewFeatures: false,
      };
    },

    /**
     *
     * @MapViews
     *
     */
    disableImgUrl(state) {
      return {
        ...state,
        imgLocationUrl: null,
      };
    },
    setTypeOfActionMap(state, action) {
      return {
        ...state,
        typeOfAction: action.payload,
      };
    },

    setSearchAddressResults(state, action) {
      return {
        ...state,
        addressResults: action.payload,
      };
    },

    setLocationNotification(state, action) {
      return {
        ...state,
        filters: {
          items: [
            {
              columnField: "orgGeoPointName",
              operatorValue: "=",
              id: 16758,
              value: action.payload,
            },
            {
              columnField: "nbUnAckNotifications",
              operatorValue: ">",
              id: 84840,
              value: "0",
            },
          ],
          linkOperator: "and",
        },
      };
    },

    resetStateMap() {
      return initialState;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchLocationsWithActivity.pending, (state) => {
        state.locationsLoading = true;
      })
      .addCase(fetchLocationsWithActivity.fulfilled, (state, action) => {
        state.locationsLoading = false;
        state.locations = action.payload;
      })
      .addCase(fetchLocationsWithActivity.rejected, (state, action) => {
        state.locationsLoading = false;
        state.locationError = action.payload as string;
      });

    builder
      .addCase(deleteLocation.pending, (state) => {
        state.deleteLocationLoading = true;
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        state.deleteLocationLoading = false;
        state.deleteLocationSuccess = true;
      })
      .addCase(deleteLocation.rejected, (state, action) => {
        state.deleteLocationLoading = false;
        state.deleteLocationSuccess = false;
        state.deleteLocationError = action.payload as string;
      });

    builder
      .addCase(fetchOrgGeoPointTypes.pending, (state) => {
        state.loadTypes = true;
      })
      .addCase(fetchOrgGeoPointTypes.fulfilled, (state, action) => {
        state.loadTypes = false;
        state.typesToEditGeoLoc = action.payload;
      })
      .addCase(fetchOrgGeoPointTypes.rejected, (state, action) => {
        state.loadTypes = false;
        state.errorFetchTypes = action.payload as string;
      });

    builder
      .addCase(updateLocation.pending, (state) => {
        state.isGeoLocUpdatedLoading = true;
      })
      .addCase(updateLocation.fulfilled, (state) => {
        state.isGeoLocUpdatedLoading = false;
        state.isGeoLocUpdated = true;
        state.modifyLocation = false;
        state.newValuesEditCreateLocation =
          initialState.newValuesEditCreateLocation;
      })
      .addCase(updateLocation.rejected, (state, action) => {
        state.isGeoLocUpdatedLoading = false;
        state.isGeoLocUpdatedError = action.payload as string;
      });

    builder
      .addCase(createLocation.pending, (state) => {
        state.createLocationLoading = true;
        state.createLocationSuccess = false;
      })
      .addCase(createLocation.fulfilled, (state) => {
        state.createLocationLoading = false;
        state.createLocationSuccess = true;
        state.createLocationActive = false;
        state.newValuesEditCreateLocation =
          initialState.newValuesEditCreateLocation;
      })
      .addCase(createLocation.rejected, (state, action) => {
        state.createLocationError = action.payload as string;
        state.createLocationLoading = false;
        state.createLocationSuccess = false;
      });

    builder
      .addCase(fetchLocationImg.pending, (state) => {
        state.imgLocationLoading = true;
      })
      .addCase(fetchLocationImg.fulfilled, (state, action) => {
        state.imgLocationLoading = false;
        state.imgLocationUrl = action.payload;
      })
      .addCase(fetchLocationImg.rejected, (state, action) => {
        state.imgLocationLoading = false;
        state.imgLocationError = action.payload as string;
      });

    builder
      .addCase(fetchAllUsersIds.pending, (state) => {
        state.locationDevicesActivityLoading = true;
        state.locationDevicesActivity = [];
      })
      .addCase(fetchAllUsersIds.fulfilled, (state) => {
        state.locationDevicesActivityLoading = false;
        state.locationActiveInfoError = "";
      })
      .addCase(fetchAllUsersIds.rejected, (state, action) => {
        state.locationDevicesActivityLoading = false;
        state.locationDevicesActivity = initialState.locationDevicesActivity;
        state.errorFetchingDevicesActivity = action.payload as string;
      });

    builder
      .addCase(getMapViews.pending, (state) => {
        state.loadingMapViews = true;
        state.error = "";
      })
      .addCase(getMapViews.fulfilled, (state, action) => {
        state.loadingMapViews = false;
        state.viewsSaved = action.payload;
      })
      .addCase(getMapViews.rejected, (state, action) => {
        state.loadingMapViews = false;
        state.error = action.payload as string;
      });

    builder
      .addCase(createNewView.pending, (state) => {
        state.createViewLoading = true;
        state.createdViewMapSuccess = false;
        state.error = "";
      })
      .addCase(createNewView.fulfilled, (state) => {
        state.createViewLoading = false;
        state.createdViewMapSuccess = true;
        state.error = "";
      })
      .addCase(createNewView.rejected, (state, action) => {
        state.createViewLoading = false;
        state.createdViewMapSuccess = false;
        state.error = action.payload as string;
      });

    builder
      .addCase(deleteView.pending, (state) => {
        state.loadingViews = true;
        state.deleteView = false;
        state.errorViews = "";
      })
      .addCase(deleteView.fulfilled, (state) => {
        state.loadingViews = false;
        state.deleteView = true;
        state.errorViews = "";
      })
      .addCase(deleteView.rejected, (state, action) => {
        state.loadingViews = false;
        state.errorViews = action.payload as string;
      });

    builder
      .addCase(fetchGeoLocDataOverviewMini.pending, (state) => {
        state.loadingMiniChartActivity = true;
        state.miniChartActivity = [];
      })
      .addCase(fetchGeoLocDataOverviewMini.fulfilled, (state, action) => {
        state.loadingMiniChartActivity = false;
        state.miniChartActivity = action.payload;
      })
      .addCase(fetchGeoLocDataOverviewMini.rejected, (state, action) => {
        state.loadingMiniChartActivity = false;
        state.miniChartActivity = [];
        state.error = action.payload as string;
      });
  },
});

export const {
  setViewport,
  setGeoPointActive,
  setNewLocationNewCluster,
  setSideBarGeoLocOpenClose,
  overviewSetTrueFalseToModifyLocation,
  logEventsNewPosition,
  setModalDeleteLocation,
  setLatitudeEditCreateLocation,
  setLongitudeEditCreateLocation,
  setLocationNotification,
  setCoordsEditLocation,
  resetStateMapMessages,
  setMapModifyLocation,
  resetMapModifyLocation,
  setImgLocation,
  setNewValueMapUpdateCreateLocation,
  setSearchAddressResults,
  setLocationActivitySideBar,
  setTypeOfActionMap,
  disableImgUrl,
  setSearchLocationMap,
  setOpenCloseRangeDatePickerMap,
  setSearchSidebarOpenClose,
  setResultsBoolean,
  searchLocationSuccess,
  setSearchLocation,
  setResetSidebarSearch,
  setOpenCloseRangeDatePicker,
  setDateActivity,
  createNewLocationActive,
  setSidebarViews,
  setViewActive,
  setViewValuesMap,
  setModalViewsMap,
  setViewValues,
  resetMapViewState,
  resetStateMap,
} = MapSlice.actions;
export const MapRed = MapSlice.reducer;
