import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "../DataStyles";
import { Autocomplete, Button, TextField } from "@mui/material";
import { LoadingCircularProgress } from "../../Loading";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";

import {
  EmailsSaved,
  initialState,
  setColumnsToExport,
  setEmailMenuList,
  setExportModalValues,
} from "../../../redux/slices/data/data";
import { State } from "../../../redux/slices";
import { CountdownLoaderExport } from "../DataIndex";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import tableDataColumns from "../DataFetchingColumns";
import { FN, RFN } from "../dataExtraFilters/DataColumnsSwitches";
import { newDataExport } from "../../../redux/actions/data/dataAsyncActions";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setEmailList: (email: EmailsSaved) => dispatch(setEmailMenuList(email)),
    setExportValues: (dataExportModalValues: {
      email: string;
      typeOfExport: string;
      typeOfExportSelected: string;
    }) => {
      dispatch(setExportModalValues(dataExportModalValues));
    },

    exportData: (token: string) => {
      dispatch(newDataExport({ token }));
    },

    setColumnsToExp: (columns: any) => {
      dispatch(setColumnsToExport(columns));
    },
  };
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const BodySuccessExporting = () => (
  <div className="loader-style-data-export-body-done">
    <div id="success_tic-data" className="success-modal-data-export">
      <div className="modal-content-success">
        <div className="page-body-data">
          <h1 className="h1-export-data-modal" style={{ textAlign: "center" }}>
            <div className="checkmark-circle-data">
              <div className="background-data" />
              <div className="checkmark-data draw-data" />
            </div>
          </h1>
        </div>
      </div>
    </div>
  </div>
);

const ManagingExportOptions = (
  typeOfExportSelected: string,
  closeModal: () => void,
  t: RFN<FN>
) => {
  if (typeOfExportSelected === "download") {
    return (
      <div className="export-loader-container-progress">
        <div className="loader-style-data-export">
          <CountdownLoaderExport seconds={1} />
        </div>

        <div className="modal-export-download-text">
          {t("Data.Prepare_to_export")}
        </div>
      </div>
    );
  }

  if (typeOfExportSelected === "download-step2") {
    return (
      <div className="step-2-container">
        <div className="export-loader-container">
          {BodySuccessExporting()}
          <div className="modal-export-download-text">
            {t("Data.Complete_file")}
          </div>
        </div>
        <div className="button-ok-done-export">
          <Button
            variant="outlined"
            sx={{ width: "95%", textTransform: "none" }}
            onClick={closeModal}
          >
            {t("Fleet_overview.search_sidebar.close")}
          </Button>
        </div>
      </div>
    );
  }

  if (typeOfExportSelected === "email") {
    return (
      <div className="step-2-container">
        <div className="export-loader-container">
          {BodySuccessExporting()}
          <div className="modal-export-email-text">{t("Data.Sent_file")}</div>
        </div>
        <div className="button-ok-done-export">
          <Button
            variant="outlined"
            sx={{ width: "95%", textTransform: "none" }}
            onClick={closeModal}
          >
            {t("Fleet_overview.search_sidebar.close")}
          </Button>
        </div>
      </div>
    );
  }
};

function CustomTabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const BodyExportModal = (
  token: string,
  loadingExport: boolean,
  rowsCount: number,
  setExportModal: (value: boolean) => void
): JSX.Element => {
  const [t] = useTranslation("global");
  const classes = useStyles();
  const { setExportValues, exportData, setColumnsToExp, setEmailList } =
    SetActions();
  const { columnsValues } = tableDataColumns(t, token);
  const {
    dataExportModalValues,
    columnsToExport,
    columnSwitches,
    emailMenuList,
  } = useSelector((state: State) => state.DataReducer);

  const emailList = JSON.parse(localStorage.getItem("emails") as any);

  const { typeOfExport, typeOfExportSelected } = dataExportModalValues;
  const names = columnsValues?.map((col) => col?.field);

  const selectAll = (name: string[]) => {
    const newArr = columnsToExport.map((el: any) => {
      if (el.name === name) {
        return { ...el, isSelected: true };
      } else return { ...el, isSelected: true };
    });

    setColumnsToExp(newArr);
  };

  const [value, setValue] = React.useState(0);
  const email = { email: dataExportModalValues.email };

  const addNewItem = (e: { email: string }) => {
    const newItem = e;
    const findEmail = emailMenuList.find(
      ({ email }) => email === newItem.email
    );
    if (!findEmail) {
      setEmailList([...emailMenuList, newItem] as any);
      localStorage.setItem(
        "emails",
        JSON.stringify([...emailMenuList, newItem] as any)
      );
    }
  };

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    setExportValues({
      ...dataExportModalValues,
      typeOfExport: value === 1 ? "data_with_filters" : "all_data",
      email: "",
    });

    if (value === 1) {
      setColumnsToExp(columnSwitches);
    } else {
      selectAll(names);
    }
  };

  const submitHandler = (event: React.SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    exportData(token);
    addNewItem(email);
  };

  const closeModal = () => {
    setExportModal(false);
    setColumnsToExp(columnSwitches);
    setExportValues(initialState.dataExportModalValues);
  };

  const ComboBox = () => {
    return (
      <Autocomplete
        disablePortal
        id="auto-complete-email"
        options={emailList.map((option: EmailsSaved) => option?.email)}
        sx={{ width: "100%" }}
        freeSolo
        disableClearable
        onChange={(e: any) => {
          setExportValues({
            ...dataExportModalValues,
            email: e.target.innerText,
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ width: "100%", marginBottom: "30px" }}
            type="email"
            InputProps={{
              ...params.InputProps,
            }}
            label={t("Fleet_overview.overview_panel.createNewPosition.email")}
            required={typeOfExport === "email"}
            onChange={(e: any) => {
              setExportValues({
                ...dataExportModalValues,
                email: e.currentTarget.value,
              });
            }}
          />
        )}
      />
    );
  };

  return (
    <div className={classes.exportModal}>
      <div className="export-title">
        <div>{t("Data.Export_data")}</div>
        <div className="divider-export-modal">
          <Divider variant="fullWidth" sx={{ borderStyle: "none" }} />
        </div>
      </div>
      {typeOfExportSelected.length === 0 ? (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              aria-label="basic tabs example"
              centered
            >
              <Tab
                sx={{ textTransform: "none", width: "50%", fontSize: "14px" }}
                label={t("Data.Filtered_data")}
                {...a11yProps(0)}
              />
              <Tab
                sx={{ textTransform: "none", width: "50%", fontSize: "14px" }}
                label={t("Data.All_data")}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <form onSubmit={submitHandler}>
              <div>
                <div className="data-export-info ">{t("Data.Export_info")}</div>
                <div className="data-filtered-first-section-styles">
                  {rowsCount >= 15000 ? (
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Alert
                        severity="error"
                        sx={{ fontSize: "12px" }}
                        icon={false}
                      >
                        {t("Data.To_big_export")}
                      </Alert>
                    </Stack>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="data-filtered-second-section-styles">
                  <div className="data-filtered-second-section-styles-email">
                    {t("Data.Send_by_email")}
                  </div>
                  <div className="data-filtered-second-section-styles-email-container">
                    {ComboBox()}
                  </div>
                </div>
                <div className="position-buttons-export">
                  {!loadingExport ? (
                    <Button
                      sx={{ textTransform: "none" }}
                      className="btn-styles mr-3"
                      variant="outlined"
                      onClick={closeModal}
                    >
                      {t(
                        "Fleet_overview.overview_panel.createNewPosition.cancel"
                      )}
                    </Button>
                  ) : (
                    ""
                  )}
                  {!loadingExport ? (
                    <Button
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      size="large"
                      className="btn-styles"
                      disabled={loadingExport || !dataExportModalValues.email}
                      type="submit"
                    >
                      {t("Common_options.Send")}
                    </Button>
                  ) : (
                    <LoadingCircularProgress />
                  )}
                </div>
              </div>
            </form>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <form onSubmit={submitHandler}>
              <div>
                <div className="data-export-info-all ">
                  {t("Data.All_data_info")}
                </div>
                <div className="data-filtered-first-section-styles">
                  {rowsCount >= 15000 ? (
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Alert
                        severity="error"
                        sx={{ fontSize: "12px" }}
                        icon={false}
                      >
                        {t("Data.To_big_export")}
                      </Alert>
                    </Stack>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="data-filtered-second-section-styles">
                  <div className="data-filtered-second-section-styles-email">
                    {t("Data.Send_by_email")}
                  </div>
                  <div className="data-filtered-second-section-styles-email-container">
                    {ComboBox()}
                  </div>
                </div>
                <div className="position-buttons-export">
                  {!loadingExport ? (
                    <Button
                      sx={{ textTransform: "none" }}
                      className="btn-styles mr-3"
                      variant="outlined"
                      onClick={closeModal}
                    >
                      {t(
                        "Fleet_overview.overview_panel.createNewPosition.cancel"
                      )}
                    </Button>
                  ) : (
                    ""
                  )}
                  {!loadingExport ? (
                    <Button
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      size="large"
                      className="btn-styles"
                      disabled={loadingExport || !dataExportModalValues.email}
                      type="submit"
                    >
                      {t("Common_options.Send")}
                    </Button>
                  ) : (
                    <LoadingCircularProgress />
                  )}
                </div>
              </div>
            </form>
          </CustomTabPanel>
        </Box>
      ) : (
        ManagingExportOptions(typeOfExportSelected, closeModal, t)
      )}
    </div>
  );
};
