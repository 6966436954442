import React, { FC } from "react";

import { Navigate } from "react-router-dom";

const PublicRoute: FC<any> = ({ user, redirectPath = "/", children }: any) => {
  if (user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default PublicRoute;
