import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useStyles } from "../modals/modalStyles";

import {
  isPortalVersionDifferent,
  setPortalVersion,
} from "../../redux/slices/authentication/authentication";
import { ButtonConfirmModals } from "../userGroups/UserTabsStyles";

import pjson from "../../../package.json";
const rememberme = localStorage.getItem("rememberme");

const SetActions = () => {
  const dispatch = useDispatch();
  return {
    logoutClickHandler: () => {
      dispatch(setPortalVersion(pjson.version));
      dispatch(isPortalVersionDifferent(false));
      localStorage.removeItem("access_token");
      if (rememberme === "false") {
        localStorage.removeItem("password");
        localStorage.removeItem("username");
      }
      setTimeout(() => {
        localStorage.removeItem("persist:root");
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }, 1000);
    },
  };
};

export const BodyPortalVersion = (): JSX.Element => {
  const [t] = useTranslation("global");
  const { logoutClickHandler } = SetActions();
  const classes = useStyles();
  return (
    <section>
      <div className={classes.confirmModal}>
        <div className="container-location-delete">
          <span className="general-modal-title mb-4">
            {t("AlertVersion.Detected")}
          </span>

          <div className="delete-loc-buttons-position">
            <ButtonConfirmModals
              type="submit"
              variant="contained"
              color="info"
              onClick={() => logoutClickHandler()}
            >
              OK
            </ButtonConfirmModals>
          </div>
        </div>
      </div>
    </section>
  );
};
