import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import useForkRef from "@mui/utils/useForkRef";

import { DateRange } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  DateRangePicker,
  DateRangePickerProps,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeFieldProps } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import {
  setDateActivity,
  setOpenCloseRangeDatePicker,
} from "../../../redux/slices/map/map";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { DashboardLocIdInfo } from "../../../redux/actions/dashboard/dashboardTypes";
import { State } from "../../../redux/slices";
import {
  fetchAllUsersIds,
  fetchGeoLocDataOverviewMini,
  fetchLocationsWithActivity,
} from "../../../redux/actions/map/mapAsyncActions";
import { CalendarTodayIconInarix, RangePickerButton } from "../OverviewStyles";

import de from "dayjs/locale/de";
import es from "dayjs/locale/es-mx";
import en from "dayjs/locale/en";
import fr from "dayjs/locale/fr";
import { manageFormat } from "../../dashboard/dashboardPanel/DashboardDateRangePicker";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    openRangeDate: () => dispatch(setOpenCloseRangeDatePicker(true)),
    closeRangeDate: () => dispatch(setOpenCloseRangeDatePicker(false)),
    setActivityDate: (date: Date[]) => {
      dispatch(setDateActivity(date));
    },
    fetchActivity: (token: string) =>
      dispatch(fetchLocationsWithActivity(token)),
    fetchAllUsersIdsGeo: (token: string) => dispatch(fetchAllUsersIds(token)),
    miniChartActivity: (token: string, id: DashboardLocIdInfo[]) =>
      dispatch(fetchGeoLocDataOverviewMini({ token, id })),
  };
};

export const localeSelected = (locale: string) => {
  if (locale === "fr") {
    return fr;
  }
  if (locale === "de") {
    return de;
  }

  if (locale === "es") {
    return es;
  } else {
    return en;
  }
};

interface DateRangeButtonFieldProps
  extends SingleInputDateRangeFieldProps<Dayjs> {
  openRangeDate?: any;
}

type DateRangeButtonFieldComponent = ((
  props: DateRangeButtonFieldProps & React.RefAttributes<HTMLDivElement>
) => React.JSX.Element) & { fieldType?: string };

const DateRangeButtonField = React.forwardRef(
  (props: DateRangeButtonFieldProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      openRangeDate,
      label,
      id,
      disabled,
      InputProps: { ref: containerRef } = {},
      inputProps: { "aria-label": ariaLabel } = {},
    } = props;

    const handleRef = useForkRef(ref, containerRef);

    return (
      <RangePickerButton
        endIcon={<CalendarTodayIconInarix />}
        id={id}
        disabled={disabled}
        ref={handleRef}
        aria-label={ariaLabel}
        onClick={() => openRangeDate()}
      >
        {label}
      </RangePickerButton>
    );
  }
) as DateRangeButtonFieldComponent;

DateRangeButtonField.fieldType = "single-input";

const ButtonDateRangePicker = React.forwardRef(
  (
    props: Omit<DateRangePickerProps<Dayjs>, "open" | "onOpen" | "onClose">,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { newGeoLoc, dateRangePickerShow } = useSelector(
      (state: State) => state.MapReducer
    );
    const { user } = useSelector((state: State) => state.Authentication);
    const token = user?.token ? user.token : "";
    const {
      openRangeDate,
      closeRangeDate,
      setActivityDate,
      fetchActivity,
      fetchAllUsersIdsGeo,
      miniChartActivity,
    } = SetActions();

    return (
      <DateRangePicker
        disableFuture={true}
        slots={{ field: DateRangeButtonField, ...props.slots }}
        slotProps={{ field: { openRangeDate } as any }}
        onChange={(newValue: DateRange<unknown>) => {
          setActivityDate(newValue as Date[]);
        }}
        onAccept={() => {
          fetchActivity(token);
          fetchAllUsersIdsGeo(token);
          if (newGeoLoc) {
            const ids = [{ name: newGeoLoc.name, id: newGeoLoc.id }];
            miniChartActivity(token, ids);
          }
        }}
        ref={ref}
        {...props}
        open={dateRangePickerShow}
        onClose={() => closeRangeDate()}
        onOpen={() => openRangeDate()}
      />
    );
  }
);

export default function DateRangePickerWithButtonField() {
  const { setActivityDate } = SetActions();
  const { locationDateActivity, modifyLocation, createLocationActive } =
    useSelector((state: State) => state.MapReducer);
  const { locale } = useSelector((state: State) => state.LayoutReducer);

  return (
    <LocalizationProvider
      adapterLocale={localeSelected(locale) as unknown as string}
      dateAdapter={AdapterDayjs}
    >
      <ButtonDateRangePicker
        label={`${dayjs(locationDateActivity[0]).format(
          manageFormat(locale)
        )} - ${dayjs(locationDateActivity[1]).format(manageFormat(locale))}`}
        value={[dayjs(locationDateActivity[0]), dayjs(locationDateActivity[1])]}
        disabled={modifyLocation || createLocationActive}
        onChange={(newValue: DateRange<unknown>) => {
          setActivityDate(newValue as Date[]);
        }}
      />
    </LocalizationProvider>
  );
}
