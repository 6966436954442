import moment from "moment";
import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import noDataToDisplay from "highcharts/modules/no-data-to-display";
import { useSelector } from "react-redux";
import { State } from "../../../redux/slices";
import { DataTranformedToCharts } from "../../../redux/actions/dashboard/dashboardAsyncActions";

noDataToDisplay(Highcharts);

const NewActivityChart = (t: (arg0: string) => string) => {
  const { dashboardNewActivityChart } = useSelector(
    (state: State) => state.DashboardReducer
  );

  const barley = dashboardNewActivityChart[0]?.map(
    (barley: DataTranformedToCharts) => {
      const dateValue = new Date(barley?.date).toISOString();
      const dateMoment = moment(dateValue);
      const language = localStorage.getItem("i18nextLng");
      dateMoment.locale(language as string);

      return {
        date: dateMoment.format("l"),
        activity: barley.count,
      };
    }
  );

  const wheat = dashboardNewActivityChart[1]?.map(
    (barley: DataTranformedToCharts) => {
      const dateValue = new Date(barley?.date).toISOString();
      const dateMoment = moment(dateValue);
      const language = localStorage.getItem("i18nextLng");
      dateMoment.locale(language as string);

      return {
        date: dateMoment.format("l"),
        activity: barley.count,
      };
    }
  );

  const chartOptions: Options = {
    colors: ["#FF8600", "#FDD136"],
    chart: {
      type: "area",
    },
    loading: {
      hideDuration: 1000,
      showDuration: 1000,
    },

    title: {
      text: t("Dashboard.activity_user_count.total_actions_per_specie"),
    },

    series: [
      {
        name: t("Cereals.barley"),
        data: barley.map((u: any) => parseInt(u.activity)),
      } as any,

      {
        name: t("Cereals.wheat"),
        data: wheat.map((u: any) => parseInt(u.activity)),
      } as any,
    ],

    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      categories: barley.map((u: any) => u.date),
      title: {},
    },
  };

  return (
    <HighchartsReact
      containerProps={{
        style: {
          width: "100%",
        },
      }}
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
};

export default NewActivityChart;
