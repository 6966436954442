import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../layout/Layout";
import { fetchLocationsWithActivity } from "../../redux/actions/map/mapAsyncActions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { State } from "../../redux/slices";
import {
  setConfirmModal,
  setInfoConfirmModal,
  setTabNameLayout,
} from "../../redux/slices/layout";
import {
  initialState,
  resetMapModifyLocation,
  resetMapViewState,
  resetStateMapMessages,
  setModalViewsMap,
  setResetSidebarSearch,
  setSidebarViews,
  setViewValues,
  setViewport,
} from "../../redux/slices/map/map";
import { Viewport } from "../../redux/slices/map/mapTypes";
import InarixMap from "./InarixMapComponent";
import { useTranslation } from "react-i18next";
import { SuccessGeneralModal } from "../SuccessModal";
import { Modal } from "@mui/material";
import { ConfirmGeneralModal } from "../ConfirmModal";
import OverviewUniversalPanel from "./mapFeaturesPanel/MapFeaturesPanel";
import ActionsSpeedDial from "./mapDial/MapDial";
import { fetchSamples } from "../../redux/actions/data/dataAsyncActions";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setTabName: (name: string) => dispatch(setTabNameLayout(name)),
    locationsInit: (token: string) =>
      dispatch(fetchLocationsWithActivity(token)),
    setNewViewport: (viewport: Viewport) => dispatch(setViewport(viewport)),

    fetchSamplesData: (token: string) => {
      dispatch(fetchSamples({ token }));
    },

    resetAllMapFeatures: () => {
      dispatch(resetMapModifyLocation());
      dispatch(setResetSidebarSearch());
      dispatch(resetStateMapMessages());
      dispatch(setInfoConfirmModal(undefined));
      dispatch(setConfirmModal(false));
      dispatch(setSidebarViews(false));
      dispatch(setModalViewsMap(false));
      dispatch(setViewValues(initialState.viewMapValues));
      dispatch(resetMapViewState());
    },
  };
};

const InarixMapComponent: FC = () => {
  const { locationsInit, setTabName, resetAllMapFeatures, fetchSamplesData } =
    SetActions();
  const { user, isViewerOnly } = useSelector(
    (state: State) => state.Authentication
  );
  const { sectionName, confirmModal, infoConfirmModal } = useSelector(
    (state: State) => state.LayoutReducer
  );
  const {
    deleteLocationSuccess,
    isGeoLocUpdated,
    typeOfAction,
    viewport,
    createLocationSuccess,
    createdViewMapSuccess,
    deleteView,
    createLocationActive,
  } = useSelector((state: State) => state.MapReducer);
  const [t] = useTranslation("global");
  const token = user?.token ?? "";

  const successBodyModal = SuccessGeneralModal(t, sectionName, typeOfAction);
  const bodyConfirmModal = ConfirmGeneralModal(
    token,
    t,
    sectionName,
    infoConfirmModal
  );

  useEffect(() => {
    resetAllMapFeatures();
    if (user) {
      setTabName("/");
      locationsInit(token);
    }
    fetchSamplesData(token);
    locationsInit(token);
  }, []);

  const globalComponentMap = () => (
    <>
      <div className="create-location">
        {!isViewerOnly || !createLocationActive
          ? ActionsSpeedDial(
              token,
              viewport.latitude as number,
              viewport.longitude as number,
              t,
              createLocationActive
            )
          : ""}
      </div>
      {InarixMap(t, token)}
      {OverviewUniversalPanel(t, token)}
      <Modal
        open={
          deleteLocationSuccess ||
          isGeoLocUpdated ||
          createLocationSuccess ||
          createdViewMapSuccess ||
          deleteView
        }
      >
        {successBodyModal}
      </Modal>
      <Modal open={confirmModal}>{bodyConfirmModal}</Modal>
    </>
  );

  return <Layout component={globalComponentMap()} />;
};

export default InarixMapComponent;
