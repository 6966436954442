import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotFound: FC = () => {
  const [t] = useTranslation("global");

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <h1 className="display-1 fw-bold">404</h1>
        <p className="fs-3">
          <span className="text-danger mr-3">Opps!</span>
          {t("not_found.page_not_found")}
        </p>
        <p className="lead"> {t("not_found.the_page")}</p>
        <Link to={"/"}> {t("not_found.home")}</Link>
      </div>
    </div>
  );
};

export default NotFound;
