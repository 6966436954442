import siloNoActivityNotif from "../../assets/images/mapIconsNoShadow/siloNotfNoAct.png";
import siloWithActivityNotif from "../../assets/images/mapIconsNoShadow/siloNotif.png";

import laboNoActivityNotif from "../../assets/images/mapIconsNoShadow/laboNoNotif.png";
import laboWithActivityNotif from "../../assets/images/mapIcons/laboWithActivityNotif.png";

import LaboIcon from "../../assets/images/mapIconsNoShadow/Group.png";
import SiloIcon from "../../assets/images/mapIconsNoShadow/SiloFilled.png";
import { NewGeoLocation } from "../../redux/slices/map/mapTypes";
import { OrgGeoPointData } from "../../redux/actions/locations/locationsTypes";

const silo = 1;
const labo = 2;

export const imgDefaultTypeLoc = (locationType?: number) => {
  if (locationType === silo) {
    return (
      <div className="icon-silo-no-activity-edit-addLoc">
        <img
          className="icon-individual-silo"
          src={SiloIcon}
          alt="silo-no-activity"
        />
      </div>
    );
  }
  if (locationType === labo) {
    return (
      <div className="icon-labo-no-activity-create-addLoc">
        <img
          className="icon-individual"
          src={LaboIcon}
          alt="labo-no-activity"
        />
      </div>
    );
  }
};

export const manageSiloImgWithoutShadows = (newGeoLoc: OrgGeoPointData) => {
  const activity = parseInt(newGeoLoc.hasActivity);
  const notifications = parseInt(newGeoLoc.unAckNotifications);

  if (newGeoLoc.typeId === silo && activity < 1 && notifications < 1) {
    return (
      <div className="icon-silo-no-activity">
        <img
          className="icon-individual-silo"
          src={SiloIcon}
          alt="silo-no-activity"
        />
      </div>
    );
  }

  if (newGeoLoc.typeId === silo && activity < 1 && notifications > 0) {
    return (
      <div className="div-icon-result-no-activity-notif">
        <img
          className="icon-result-size-silo-2"
          src={siloNoActivityNotif}
          alt="silo-no-activity"
        />
      </div>
    );
  }

  if (newGeoLoc.typeId === silo && activity > 0 && notifications < 1) {
    return (
      <div className="icon-silo-activity">
        <img
          className="icon-individual-silo"
          src={SiloIcon}
          alt="silo-activity"
        />
      </div>
    );
  }

  if (newGeoLoc.typeId === silo && activity > 0 && notifications > 0) {
    return (
      <div className="div-icon-result-silo">
        <img
          className="icon-result-size-silo"
          src={siloWithActivityNotif}
          alt="silo-activity"
        />
      </div>
    );
  }
};
export const manageLaboImgWithoutShadows = (newGeoLoc: OrgGeoPointData) => {
  const activity = parseInt(newGeoLoc.hasActivity);
  const notifications = parseInt(newGeoLoc.unAckNotifications);
  if (newGeoLoc.typeId === labo && activity < 1 && notifications < 1) {
    return (
      <div className="icon-labo-no-activity">
        <img
          className="icon-individual"
          src={LaboIcon}
          alt="labo-no-activity"
        />
      </div>
    );
  }

  if (newGeoLoc.typeId === labo && activity < 1 && notifications > 0) {
    return (
      <div className="div-icon-result">
        <img
          className="icon-result-size"
          src={laboNoActivityNotif}
          alt="labo-no-activity"
        />
      </div>
    );
  }

  if (newGeoLoc.typeId === labo && activity > 0 && notifications < 1) {
    return (
      <div className="icon-labo-activity">
        <img className="icon-individual" src={LaboIcon} alt="labo-activity" />
      </div>
    );
  }

  if (newGeoLoc.typeId === labo && activity > 0 && notifications > 0) {
    return (
      <div className="div-icon-result">
        <img
          className="icon-result-size-labo"
          src={laboWithActivityNotif}
          alt="labo-activity"
        />
      </div>
    );
  }
};

export const imgLocationSilo = (newGeoLoc: NewGeoLocation) => {
  const silo = 1;
  if (
    newGeoLoc.typeId === silo &&
    newGeoLoc.hasActivity < 1 &&
    newGeoLoc.unAckNotifications < 1
  ) {
    return "https://res.cloudinary.com/inarix/image/upload/v1651146376/inarix%20fleet%20map/Type_Silo_Size_Small_Badge_No_Active_No_Pop-up_No_wygusl.png";
  }

  if (
    newGeoLoc.typeId === silo &&
    newGeoLoc.hasActivity < 1 &&
    newGeoLoc.unAckNotifications > 0
  ) {
    return "https://res.cloudinary.com/inarix/image/upload/v1655112738/inarix%20fleet%20map/Type_Silo_Size_Large_Badge_Yes_Active_No_Pop-up_No_tsanq0.png";
  }

  if (
    newGeoLoc.typeId === silo &&
    newGeoLoc.hasActivity > 0 &&
    newGeoLoc.unAckNotifications < 1
  ) {
    return "https://res.cloudinary.com/inarix/image/upload/v1655117424/inarix%20fleet%20map/Avatar_lfcyor.png";
  }

  if (
    newGeoLoc.typeId === silo &&
    newGeoLoc.hasActivity > 0 &&
    newGeoLoc.unAckNotifications > 0
  ) {
    return "https://res.cloudinary.com/inarix/image/upload/v1655112719/inarix%20fleet%20map/Type_Silo_Size_Large_Badge_Yes_Active_Yes_Pop-up_No_sagbxm.png";
  }
};

export const imgLocationLabo = (newGeoLoc: NewGeoLocation) => {
  const labo = 2;

  if (
    newGeoLoc.typeId === labo &&
    newGeoLoc.hasActivity < 1 &&
    newGeoLoc.unAckNotifications < 1
  ) {
    return "https://res.cloudinary.com/inarix/image/upload/v1651145104/inarix%20fleet%20map/Type_Lab_Size_Small_Badge_No_Active_No_Pop-up_No_2_z3kt5y.png";
  }

  if (
    newGeoLoc.typeId === labo &&
    newGeoLoc.hasActivity < 1 &&
    newGeoLoc.unAckNotifications > 0
  ) {
    return "https://res.cloudinary.com/inarix/image/upload/v1655112671/inarix%20fleet%20map/Type_Lab_Size_Large_Badge_Yes_Active_No_Pop-up_No_puzpew.png";
  }

  if (
    newGeoLoc.typeId === labo &&
    newGeoLoc.hasActivity > 0 &&
    newGeoLoc.unAckNotifications < 1
  ) {
    return "https://res.cloudinary.com/inarix/image/upload/v1651145376/inarix%20fleet%20map/Type_Lab_Size_Small_Badge_No_Active_Yes_Pop-up_No_vhplp6.png";
  }

  if (
    newGeoLoc.typeId === labo &&
    newGeoLoc.hasActivity > 0 &&
    newGeoLoc.unAckNotifications > 0
  ) {
    return "https://res.cloudinary.com/inarix/image/upload/v1655112624/inarix%20fleet%20map/Avatar_utlav6.png";
  }
};
