import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Box, Button, Drawer, IconButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { State } from "../../../redux/slices";
import {
  setExtraFiltersValuesAutocomplete,
  setSBExtraFiltersVisible,
  DataSBFilters,
  setTableDataOptions,
  initialState,
  setDateDataActivity,
  setColumnsVisibilitySwitches,
  setColumnsToExport,
  setComesfromPageNot0,
} from "../../../redux/slices/data/data";
import { CropsOptions } from "./DataListCrops";
import AutocompleteDataExtraFiltersInput from "./DataAutocompleteSites";
import tableDataColumns from "../DataFetchingColumns";
import { DashboardLocIdInfo } from "../../../redux/actions/dashboard/dashboardTypes";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import { fetchSamples } from "../../../redux/actions/data/dataAsyncActions";
import { DColumns } from "../../../redux/actions/data/DataDeclarations";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setVisibleSBExtraFilters: (visible: boolean) => {
      dispatch(setSBExtraFiltersVisible(visible));
    },
    applyFilters: (token: string) => {
      dispatch(setTableDataOptions(initialState.tableOptions));
      dispatch(fetchSamples({ token }));
      dispatch(setSBExtraFiltersVisible(false));
    },

    resetFilters: (
      value: any,
      dataSBFilters: DataSBFilters,
      token: string,
      columns: any
    ) => {
      dispatch(setComesfromPageNot0(false));
      dispatch(setDateDataActivity(initialState.dataDateActivity));
      dispatch(setColumnsVisibilitySwitches(columns));
      dispatch(setColumnsToExport(columns));
      dispatch(setTableDataOptions(initialState.tableOptions));
      dispatch(
        setExtraFiltersValuesAutocomplete({
          ...dataSBFilters,
          cropsSelected: value,
          inputSearch: "",
          filtersLocation: undefined,
          filterActions: undefined,
          filtersCrops: undefined,
          filterUsers: undefined,
          filtersSelectedAfterFetch: 0,
          locations: { valuesSelected: [], allValuesSelected: false },
          users: {
            valuesSelected: [],
            allValuesSelected: false,
          },
          actions: {
            valuesSelected: [],
            allValuesSelected: false,
          },
        })
      );
      dispatch(fetchSamples({ token }));
    },
  };
};

const SidebarDataFilters = (
  t: (arg0: string) => string,
  token: string,
  locale: string
) => {
  const { columnsValues } = tableDataColumns(t, token);
  const { setVisibleSBExtraFilters, resetFilters, applyFilters } = SetActions();
  const {
    dataExtraFiltersSBVisible,
    dataSBFilters,
    dataSBFilterValues,
    dataSamplesLoading,
  } = useSelector((state: State) => state.DataReducer);
  const { locations, actions, users } = dataSBFilterValues;
  const { cropsSelected } = dataSBFilters;

  const columns =
    columnsValues[0] !== undefined
      ? columnsValues?.map((col: DColumns) => ({
          name: col.headerName ? col.headerName : "",
          id: col.field,
          isSelected: col.show,
        }))
      : [
          {
            name: "",
            id: "",
            isSelected: false,
          },
        ];

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  const disableAll = (id: number[]) => {
    const newArr = cropsSelected.map((el) => {
      if (el.id === id) {
        return { ...el, isSelected: false };
      } else return { ...el, isSelected: false };
    });
    resetFilters(newArr as any, dataSBFilters, token, columns);
  };

  const resetFilter = () => {
    disableAll(cropsSelected);
  };

  function PersistentDrawerLeft() {
    const theme = useTheme();

    const handleDrawerClose = () => {
      setVisibleSBExtraFilters(false);
    };

    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Drawer
          onClose={handleDrawerClose}
          sx={{
            width: 420,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 420,
              boxSizing: "border-box",
              position: "absolute",
              zIndex: 1,
              overflow: "auto",
              overflowX: "hidden",
            },
          }}
          variant="persistent"
          anchor="left"
          open={dataExtraFiltersSBVisible}
        >
          <DrawerHeader>
            <div className="title-container-sb">
              <h4>{t("Data_sidebar.Add_filter")}</h4>
            </div>

            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>

          <div className="data-extra-filters-container">
            <div className="by-crop mb-1">
              <div>
                <span className="titles-sb-features">{t("Data.Species")}</span>
              </div>
              {CropsOptions()}
            </div>

            <div className="autocomplete-sb-data">
              <div className="by-site">
                <div>
                  <span className="titles-sb-features">{t("Data.Sites")}</span>
                </div>
                <div className="by-site-container">
                  {AutocompleteDataExtraFiltersInput(
                    token,
                    "locations",
                    locations as DashboardLocIdInfo[],
                    dataSBFilters?.locations?.allValuesSelected,
                    dataSBFilters?.locations?.valuesSelected,
                    t
                  )}
                </div>
              </div>
              <div className="by-action">
                <div>
                  <span className="titles-sb-features">
                    {t("Data.Actions")}
                  </span>
                </div>
                <div className="by-site-container">
                  {AutocompleteDataExtraFiltersInput(
                    token,
                    "actions",
                    actions as DashboardLocIdInfo[],
                    dataSBFilters?.actions?.allValuesSelected,
                    dataSBFilters?.actions?.valuesSelected,
                    t
                  )}
                </div>
              </div>
              <div className="by-action">
                <div>
                  <span className="titles-sb-features">
                    {t("Fleet_users.table.My_users")}
                  </span>
                </div>
                <div className="by-site-container">
                  {AutocompleteDataExtraFiltersInput(
                    token,
                    "users",
                    users as DashboardLocIdInfo[],
                    dataSBFilters?.users?.allValuesSelected,
                    dataSBFilters?.users?.valuesSelected,
                    t
                  )}
                </div>
              </div>
            </div>

            <div className="button-position-sb-data">
              <div className="buttons-actions-data-sb">
                <Button
                  sx={{ width: "100%", textTransform: "none" }}
                  variant="outlined"
                  className="mt-3"
                  disabled={dataSamplesLoading}
                  onClick={resetFilter}
                  startIcon={<FilterListOffIcon />}
                >
                  {t("Common_options.Reset_filters")}
                </Button>
              </div>
              <div className="buttons-actions-data-sb">
                <Button
                  variant="contained"
                  disabled={dataSamplesLoading}
                  onClick={() => applyFilters(token)}
                  className="mt-3"
                  sx={{ width: "100%", textTransform: "none" }}
                >
                  {t("Common_options.Apply")}
                </Button>
              </div>
            </div>
          </div>
        </Drawer>
      </Box>
    );
  }

  return PersistentDrawerLeft();
};

export default SidebarDataFilters;
