import axios from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../configureStore";
import { API } from "../../../config";
import { setExtraFiltersValues } from "../../slices/data/data";
import { ScenarioData } from "../actions/actionTypes";

export const fetchSBLocationNames = createAsyncThunk(
  "DataReducer/fetchSBLocationNames",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { dataSBFilterValues } = (store.getState() as RootState).DataReducer;
    try {
      const response = await axios.get(
        `${API}/users/organization-geo-point?$select[]=name`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": `${locale}`,
          },
        }
      );

      const { data } = response;

      store.dispatch(
        setExtraFiltersValues({ ...dataSBFilterValues, locations: data.data })
      );
      return data.data;
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const fetchSBUserNames = createAsyncThunk(
  "DataReducer/fetchSBUserNames",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { dataSBFilterValues } = (store.getState() as RootState).DataReducer;
    try {
      const response = await axios.get(
        `${API}/users/user?$select[]=username&$select[]=id`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": `${locale}`,
          },
        }
      );

      const { data } = response;
      const usernames = data?.data?.map(
        (usernames: { username: string; id: number }) => ({
          name: usernames.username,
          id: usernames.id,
        })
      );
      store.dispatch(
        setExtraFiltersValues({ ...dataSBFilterValues, users: usernames })
      );
      return data.data;
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const fetchSBActionsNames = createAsyncThunk(
  "DataReducer/fetchSBActionsNames",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { dataSBFilterValues } = (store.getState() as RootState).DataReducer;
    try {
      const response = await axios.get(
        `${API}/core/scenario?isSystemScenario=false&deletedAt=null&statusConstantId[$in][]=34&statusConstantId[$in][]=35&$eager=[scopes.[group], statusConstant, name.[localizedTexts.[locale]], desc.[localizedTexts.[locale]]]`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": `${locale}`,
          },
        }
      );

      const { data } = response;

      const actionsValues = data.data.map((row: ScenarioData) => {
        const scenarioNameValue = () => {
          if (row.name) {
            let def = "";
            const arr = row.name.localizedTexts
              .map((c: any) => {
                if (locale === c.locale.value) {
                  return c.translation;
                }
                def = c.translation;
              })
              .filter((val: any) => null != val);
            if (!arr.length) {
              return [def].toString();
            } else {
              return arr.toString();
            }
          } else {
            return "Null";
          }
        };

        const tableRows = {
          name: scenarioNameValue().toLowerCase(),
          id: row.id,
        };
        return tableRows;
      });

      store.dispatch(
        setExtraFiltersValues({ ...dataSBFilterValues, actions: actionsValues })
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);
