import { useSelector } from "react-redux";
import { State } from "../../../redux/slices";
import moment from "moment";
import { LabelInstanceNewPortal } from "../../../redux/actions/data/DataDeclarations";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { v4 as uuidv4 } from "uuid";

export const Comments = (t: (arg0: string) => string) => {
  const { locale } = useSelector((state: State) => state.LayoutReducer);
  const { isViewerOnly } = useSelector((state: State) => state.Authentication);
  const { sampleViewInfo } = useSelector(
    (state: State) => state.DataSampleViewReducer
  );

  const commentsContentV2 = sampleViewInfo?.sampleLabelInstances?.map(
    (labelInstance: LabelInstanceNewPortal, indx: number) => {
      if (
        labelInstance.labelTemplate.slug === "sample-comments" &&
        labelInstance.labelTemplate.notifications &&
        labelInstance.labelTemplate.notifications.length > 0
      ) {
        const date = labelInstance?.createdAt;
        const sampleCrdAt = moment(date);
        sampleCrdAt.locale(locale);
        const createdAtModify = sampleCrdAt.format("l, LT");

        const unreadNotification = () => {
          if (
            labelInstance.labelTemplate.notifications &&
            labelInstance?.labelTemplate.notifications[0]?.readAt == null
          ) {
            return "card-notification-no-read";
          } else {
            return "card-notification-read";
          }
        };

        const correctButtonNotification = () => {
          if (
            labelInstance.labelTemplate.notifications &&
            labelInstance.labelTemplate.notifications[0]?.readAt == null
          ) {
            return (
              <Button className="read-unread-button" size="small">
                <MarkChatReadIcon className="mr-2" />
                {t("Data.SampleView.Mark_as_read")}
              </Button>
            );
          }
          if (labelInstance.labelTemplate.notifications) {
            if (labelInstance.labelTemplate.notifications[0].readAt) {
              return (
                <Button className="read-unread-button" size="small">
                  <MarkChatUnreadIcon className="mr-2" />
                  {t("Data.SampleView.Mark_as_unread")}
                </Button>
              );
            }
          }
        };

        return (
          <Card
            className={unreadNotification()}
            key={`${labelInstance.labelTemplate.slug}${uuidv4()}`}
          >
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {labelInstance?.labelTemplate.notifications[0]?.message}
              </Typography>
            </CardContent>
            <CardActions className="position-info-sampleview-notifications">
              <div className="general-info-notification-design">
                <span>{t("Data.SampleView.By")} </span>
                <span className="created-at-and-option-read">
                  {labelInstance?.labelTemplate.creatorId}
                </span>
                <span>
                  {t("Data.table.On")} {createdAtModify}
                </span>
              </div>

              {correctButtonNotification()}
            </CardActions>
          </Card>
        );
      }

      if (labelInstance.labelTemplate.slug === "sample-comments") {
        const date = labelInstance?.createdAt;
        const sampleCrdAt = moment(date);
        sampleCrdAt.locale(locale);
        const createdAtModify = sampleCrdAt.format("l, LT");

        return (
          <Card
            className="card-notification-read"
            key={`${labelInstance?.labelText}${uuidv4()}`}
          >
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {labelInstance?.labelText}
              </Typography>
            </CardContent>
            <CardActions className="position-info-sampleview-notifications">
              <div className="general-info-notification-design">
                <span>{t("Data.SampleView.By")} </span>
                <span className="created-at-and-option-read">
                  {labelInstance?.labelTemplate.creatorId}
                </span>
                <span>
                  {t("Data.table.On")} {createdAtModify}
                </span>
              </div>
            </CardActions>
          </Card>
        );
      }
    }
  );

  const createNewComment = () => {
    return (
      <Button
        className="read-unread-button mt-3"
        size="small"
        //onClick={() => setNewCommentModal(true)}
      >
        <AddCommentIcon className="mr-2" />
        {t("Data.SampleView.Add_comment")}
      </Button>
    );
  };

  const ShowComments = false;

  return (
    <div className="mb-5">
      {ShowComments ? "" : commentsContentV2}
      {!isViewerOnly && ShowComments ? createNewComment() : ""}
    </div>
  );
};
