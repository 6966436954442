import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export function FiltersDataTableObject(
  map: any,
  columnName: string,
  value: string[],
  operator: string
) {
  if (value.length > 0)
    return {
      column: map[columnName] || columnName,
      operator: columnName === "cerealName" ? undefined : operator,
      value,
    };
  else {
    return undefined;
  }
}

export function addDays(date: any, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export default function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export function ManageErrorString(error: string) {
  if (error) {
    return true;
  } else {
    return false;
  }
}

export const goBackFunction = (
  navigate: any,
  t: (arg0: string) => string,
  titleGoBack: string,
  historyPushTo: string,
  titleGoBack2?: string
) => {
  const goBackFunc = () => {
    navigate(historyPushTo);
  };

  return (
    <div className="arrow-back" onClick={() => goBackFunc()} aria-hidden>
      <ArrowBackIcon className="arrow-back-data-div" />
      {`${t(titleGoBack)}`}
      {titleGoBack2 ? `/${t(titleGoBack2)}` : ""}
    </div>
  );
};

export const changePosInArray = (arr: any[], from: any, to: any) => {
  arr.splice(to, 0, arr.splice(from, 1)[0]);
  return arr;
};
