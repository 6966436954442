import React from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { useStyles } from "../DataStyles";
import loadingViewSvg from "../../../assets/images/loading.svg";
import { State } from "../../../redux/slices";

export const BodyLoadingSampleViewModal = (): JSX.Element => {
  const { loadSampleDetails } = useSelector(
    (state: State) => state.DataSampleViewReducer
  );
  const classes = useStyles();

  const [t] = useTranslation("global");

  return (
    <div className="loading-views-div">
      <Fade in={loadSampleDetails}>
        <div className={classes.paperModal}>
          <h2 className="title-export">{t("Data.SampleView.load_sample")}</h2>
          <img src={loadingViewSvg} alt={loadingViewSvg} />
        </div>
      </Fade>
    </div>
  );
};
