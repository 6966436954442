import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { State } from "../../redux/slices";
import Map, {
  GeoJSONSource,
  Layer,
  LayerProps,
  Marker,
  Popup,
  Source,
  useMap,
} from "react-map-gl";
import {
  disableImgUrl,
  initialState,
  logEventsNewPosition,
  setGeoPointActive,
  setLatitudeEditCreateLocation,
  setLongitudeEditCreateLocation,
  setNewLocationNewCluster,
  setNewValueMapUpdateCreateLocation,
  setSearchSidebarOpenClose,
  setSideBarGeoLocOpenClose,
  setSidebarViews,
  setViewValues,
  setViewport,
} from "../../redux/slices/map/map";
import {
  MapEvent,
  NewGeoLocation,
  ValuesCreateEditLocation,
  Viewport,
} from "../../redux/slices/map/mapTypes";
import {
  clusterLayer,
  clusterCountLayer,
  unclusteredPointLayer,
  clusterCountNotificationLayer,
} from "./MapLayers";

import siloNoActivity from "../../assets/images/mapIcons/noAct.png";
import siloWithActivity from "../../assets/images/mapIcons/siloActivity.png";
import siloNoActivityNotif from "../../assets/images/mapIcons/siloNoActivityNotif.png";
import siloWithActivityNotif from "../../assets/images/mapIcons/siloActivityNotif.png";

import laboNoActivity from "../../assets/images/mapIcons/laboNoActivity.png";
import laboWithActivity from "../../assets/images/mapIcons/laboWithActivity.png";
import laboNoActivityNotif from "../../assets/images/mapIcons/laboNoActivityNotif.png";
import laboWithActivityNotif from "../../assets/images/mapIcons/laboWithActivityNotif.png";

import Pin from "./PinDraggable";
import { imgLocationLabo, imgLocationSilo } from "./MapHelpers";
import { CircularProgress } from "@mui/material";
import {
  hasActivityCluster,
  hasNotificationCluster,
  typeOfPoint,
  typeOfLabo,
  typeOfSilo,
} from "./mapComplements/TypeOfLocation";
import { OrgGeoPointData } from "../../redux/actions/locations/locationsTypes";

import SidebarLocation from "./sidebars/MapSideBar";
import ControlPanelEditLocation from "./mapEditCreateDeleteLocations/MapEditCreateLocationSideBar";
import {
  fetchAllUsersIds,
  fetchGeoLocDataOverviewMini,
  fetchLocationImg,
  fetchLocationMapbox,
} from "../../redux/actions/map/mapAsyncActions";
import SidebarSearch from "./sidebars/MapSearchLocationSideBar";
import SidebarViews from "./mapViews/MapViewsSidebar";
import { DashboardLocIdInfo } from "../../redux/actions/dashboard/dashboardTypes";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setNewValues: (values: ValuesCreateEditLocation) =>
      dispatch(setNewValueMapUpdateCreateLocation(values)),

    setSideBarLocationOpen: () => {
      dispatch(setSideBarGeoLocOpenClose(true));
    },
    setSideBarLocationClose: () => {
      dispatch(setSideBarGeoLocOpenClose(false));
    },

    setNewViewport: (viewport: Viewport) => dispatch(setViewport(viewport)),
    setGeoLocation: (geoLocation: OrgGeoPointData) =>
      dispatch(setGeoPointActive(geoLocation)),

    setGeo: (geoLocation: MapEvent) => {
      dispatch(setNewLocationNewCluster(geoLocation));
    },

    closeOpenSearchbar: (openClose: boolean) =>
      dispatch(setSearchSidebarOpenClose(openClose)),

    createViewToFalse: () => {
      dispatch(setSidebarViews(false));
      dispatch(setViewValues(initialState.viewMapValues));
    },

    fetchLocImg: (token: string, orgGeoId: number) =>
      dispatch(fetchLocationImg({ token, orgGeoId })),
    disableImg: () => dispatch(disableImgUrl()),

    logEvent: (logEvent: unknown) => dispatch(logEventsNewPosition(logEvent)),
    setSearchAddress: (text: string) => dispatch(fetchLocationMapbox({ text })),
    setLatitudeEditGeoLoc: (latitude: number) =>
      dispatch(setLatitudeEditCreateLocation(latitude)),

    setLongitudeEditGeoLoc: (longitude: number) =>
      dispatch(setLongitudeEditCreateLocation(longitude)),

    fetchAllUsersIdsGeo: (token: string) => dispatch(fetchAllUsersIds(token)),
    fetchGeoLocDataOverMini: (token: string, id: DashboardLocIdInfo[]) =>
      dispatch(fetchGeoLocDataOverviewMini({ token, id })),
  };
};

const countNotification = clusterCountNotificationLayer as LayerProps;
const layerClusters = clusterLayer;
const clusterCountLayers = clusterCountLayer as LayerProps;
const unclusteredPointLayers = unclusteredPointLayer || ({} as LayerProps);

const InarixMap = (t: (arg0: string) => string, token: string) => {
  const {
    setNewViewport,
    setGeo,
    setSideBarLocationOpen,
    setSideBarLocationClose,
    fetchLocImg,
    disableImg,
    logEvent,
    setSearchAddress,
    setLatitudeEditGeoLoc,
    setLongitudeEditGeoLoc,
    closeOpenSearchbar,
    createViewToFalse,
    fetchGeoLocDataOverMini,
    fetchAllUsersIdsGeo,
    setNewValues,
  } = SetActions();

  const onMove = useCallback((evt: any) => {
    setNewViewport(evt.viewState);
  }, []);

  const {
    locations,
    latitudeEditCreateGeoLoc,
    longitudeEditCreateGeoLoc,
    viewport,
    locationDateActivity,
    newGeoLoc,
    modifyLocation,
    imgLocationUrl,
    imgLocationLoading,
    createLocationActive,
    sidebarGeoLoc,
    locationsLoading,
    newValuesEditCreateLocation,
  } = useSelector((state: State) => state.MapReducer);

  const mapRef = useRef() as any;

  const manageValue = (value: string) => {
    if (value) {
      return value;
    } else {
      return "0";
    }
  };

  const defineTheGoodTypeOf = (
    id: number,
    hasActivity: number,
    notification: number
  ) => {
    const silo = 1;
    const labo = 2;

    if (id === silo) {
      return typeOfSilo(id, hasActivity, notification);
    }

    if (id === labo) {
      return typeOfLabo(id, hasActivity, notification);
    }
  };

  const pointsGeoJSON = React.useMemo(() => {
    return {
      type: "FeatureCollection",
      features: locations?.map((location) => ({
        type: "Feature",
        properties: {
          cluster: false,
          id: location.id,
          name: location.name,
          icon: defineTheGoodTypeOf(
            location.type?.id,
            parseInt(manageValue(location.hasActivity)),
            parseInt(manageValue(location.unAckNotifications))
          ),
          locationName: location.name,
          typeId: location.type?.id,
          latitude: location.latitude,
          longitude: location.longitude,
          remoteId: location.remoteId,
          hasActivity: parseInt(manageValue(location.hasActivity)),
          unAckNotifications: parseInt(
            manageValue(location.unAckNotifications)
          ),

          unAckNotificationsCluster: hasNotificationCluster(
            parseInt(manageValue(location.unAckNotifications))
          ),

          notifications: {
            count: parseInt(manageValue(location.unAckNotifications)),
          },

          hasActivityCluster: hasActivityCluster(
            parseInt(manageValue(location.hasActivity))
          ),

          primaryContactName: location.primaryContactName
            ? location.primaryContactName
            : "",
          telephone: location.telephone ? location.telephone : "",
          email: location.email ? location.email : "",
          address: location.address ? location.address : "",
          notes: location.notes ? location.notes : "",

          type: {
            id: location.type?.id,
            name: location.type?.name,
            createdAt: location.type?.createdAt,
            updatedAt: location.type?.updatedAt,
          },
        },
        geometry: {
          type: "Point",
          idLocation: location.id,
          nameLocation: location.name,
          coordinates: [location.longitude, location.latitude],
        },
      })),
    };
  }, [locations]);

  const handleImgLocation = () => {
    if (imgLocationLoading) {
      return "";
    }
    if (imgLocationUrl) {
      return <img src={imgLocationUrl} className="img-popup" alt="geoloc" />;
    } else {
      return "";
    }
  };

  const cardStyle = () => {
    if (imgLocationUrl) {
      return "card-popup-with-image";
    } else {
      return "card-popup";
    }
  };

  const noGeoLoc = () => {
    if (newGeoLoc && locationDateActivity.length >= 1) {
      return (
        <Popup
          latitude={newGeoLoc?.latitude ? newGeoLoc?.latitude : 0}
          longitude={newGeoLoc?.longitude ? newGeoLoc?.longitude : 0}
          closeOnClick={false}
          closeButton={false}
          className={cardStyle()}
        >
          <div>
            {handleImgLocation()}
            <div className="contain-popup-map">
              <span className="loc-name">{newGeoLoc.locationName}</span>
              <span className="loc-type-pop">
                {typeOfPoint(newGeoLoc.typeId)}
              </span>
            </div>
          </div>
        </Popup>
      );
    }
  };

  const onClick = (event: {
    features: { properties: any; geometry: any }[];
    lngLat: [latitude: number, longitude: number];
    ar: any;
  }) => {
    if (event.features[0]?.properties.cluster_id) {
      const feature = event.features[0];
      const clusterId = event.features[0]?.properties.cluster_id;
      const mapboxSource = mapRef.current.getSource(
        "orgGeoPoints"
      ) as GeoJSONSource;

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }

        mapRef.current.easeTo({
          center: feature.geometry.coordinates,
          zoom,
          duration: 500,
        });
      });
    }

    if (event.features[0]?.properties.id === undefined) {
      if (newGeoLoc) {
        setSideBarLocationClose();
      }
    }

    if (
      !modifyLocation &&
      !createLocationActive &&
      !event.features[0]?.properties.cluster_id &&
      event.features[0]?.properties
    ) {
      createViewToFalse();
      closeOpenSearchbar(false);
      setGeo(event.features[0]?.properties);
      setNewValues({
        ...newValuesEditCreateLocation,
        telephone:
          event.features[0]?.properties?.telephone.length > 2
            ? event.features[0]?.properties?.telephone
            : "+33",
      });
      fetchLocImg(token, event.features[0]?.properties.id);

      const ids = [
        {
          name: event.features[0]?.properties.name,
          id: event.features[0]?.properties.id,
        },
      ];
      fetchGeoLocDataOverMini(token, ids);
      if (newGeoLoc?.id) {
        setSideBarLocationOpen();
      }
    }

    if (
      !modifyLocation &&
      !createLocationActive &&
      event.features[0]?.properties !== undefined &&
      !event.features[0]?.properties.cluster_id
    ) {
      fetchAllUsersIdsGeo(token);
      setLatitudeEditGeoLoc(event.lngLat[1]);
      setLongitudeEditGeoLoc(event.lngLat[0]);
      mapRef.current?.flyTo({
        center: [
          event.features[0]?.properties.longitude,
          event.features[0]?.properties.latitude,
        ],
        duration: 2000,
      });
    }
  };

  const onHover = (event: {
    features: { properties: any; geometry: any }[];
    lngLat: [latitude: number, longitude: number];
  }) => {
    const _map = mapRef.current?.getMap();
    if (event.features[0]?.properties.cluster_id) {
      const clusterId = event.features[0]?.properties.cluster_id;
      if (clusterId) {
        return _map.getCanvas().style.cursor === "pointer";
      }
    }

    if (
      !modifyLocation &&
      !createLocationActive &&
      !event.features[0]?.properties.cluster_id &&
      event.features[0]?.properties.id !== undefined
    ) {
      _map.getCanvas().style.cursor = "pointer";

      setGeo(event.features[0]?.properties);
    }
    if (
      event.features[0]?.properties.id === undefined &&
      !modifyLocation &&
      !createLocationActive
    ) {
      _map.getCanvas().style.cursor = "";
      if (imgLocationUrl) {
        disableImg();
      }
      if (newGeoLoc) {
        setGeo(undefined as unknown as MapEvent);
      }
    }

    if (
      !modifyLocation &&
      !createLocationActive &&
      !event.features[0]?.properties.cluster_id &&
      event.features[0]?.properties.id !== undefined &&
      sidebarGeoLoc === true
    ) {
      return _map.getCanvas().style.cursor === "";
    }
  };

  const onMarkerDragStart = useCallback((event: any) => {
    logEvent(event.lngLat);
  }, []);

  const onMarkerDrag = useCallback((event: any) => {
    logEvent(event.lngLat);
    setLongitudeEditGeoLoc(event.lngLat.lng);
    setLatitudeEditGeoLoc(event.lngLat.lat);
  }, []);

  const onMarkerDragEnd = useCallback((event: any) => {
    logEvent(event.lngLat);
    setSearchAddress(`${event.lngLat.lng},${event.lngLat.lat}`);
  }, []);

  const manageErrorMapImage = (error: Error) => {
    if (error) throw error;
  };

  function MapImage() {
    const map = useMap() as any;
    const _map = map.current?.getMap();

    _map.loadImage(
      "https://res.cloudinary.com/inarix/image/upload/v1656949928/inarix%20fleet%20map/red_dot_keh0qy.png",
      (error: Error, image: File) => {
        manageErrorMapImage(error);
        if (!_map.hasImage("map-red-dot")) _map.addImage("map-red-dot", image);
      }
    );

    _map.loadImage(siloNoActivity, (error: Error, image: File) => {
      manageErrorMapImage(error);
      if (!_map.hasImage("map-silo")) _map.addImage("map-silo", image);
    });

    _map.loadImage(siloWithActivity, (error: Error, image: File) => {
      manageErrorMapImage(error);
      if (!_map.hasImage("map-silo-with-activity"))
        _map.addImage("map-silo-with-activity", image);
    });

    _map.loadImage(siloNoActivityNotif, (error: Error, image: File) => {
      manageErrorMapImage(error);
      if (!_map.hasImage("map-silo-notification"))
        _map.addImage("map-silo-notification", image);
    });

    _map.loadImage(siloWithActivityNotif, (error: Error, image: File) => {
      manageErrorMapImage(error);
      if (!_map.hasImage("map-silo-with-activity-notification"))
        _map.addImage("map-silo-with-activity-notification", image);
    });

    _map.loadImage(
      "https://res.cloudinary.com/dm8dxwvix/image/upload/v1645706696/Status_Selected_e2w9z4.png",
      (error: Error, image: File) => {
        manageErrorMapImage(error);
        if (!_map.hasImage("map-silo-with-activity-selected"))
          _map.addImage("map-silo-with-activity-selected", image);
      }
    );

    _map.loadImage(laboNoActivity, (error: Error, image: File) => {
      manageErrorMapImage(error);
      if (!_map.hasImage("map-lab")) _map.addImage("map-lab", image);
    });

    _map.loadImage(laboWithActivity, (error: Error, image: File) => {
      manageErrorMapImage(error);
      if (!_map.hasImage("map-lab-with-activity"))
        _map.addImage("map-lab-with-activity", image);
    });

    _map.loadImage(laboNoActivityNotif, (error: Error, image: File) => {
      manageErrorMapImage(error);
      if (!_map.hasImage("map-lab-notification"))
        _map.addImage("map-lab-notification", image);
    });

    _map.loadImage(laboWithActivityNotif, (error: Error, image: File) => {
      manageErrorMapImage(error);
      if (!_map.hasImage("map-lab-with-activity-notification"))
        _map.addImage("map-lab-with-activity-notification", image);
    });

    _map.loadImage(
      "https://res.cloudinary.com/inarix/image/upload/v1656684787/inarix%20fleet%20map/removal.ai__tmp-62bf00c1e23ed_vlygkq.png",
      (error: Error, image: File) => {
        manageErrorMapImage(error);
        if (!_map.hasImage("map-cluster-no-activity"))
          _map.addImage("map-cluster-no-activity", image);
      }
    );

    _map.loadImage(
      "https://res.cloudinary.com/dm8dxwvix/image/upload/v1643212127/activity-cluster_hzrcjl.png",
      (error: Error, image: File) => {
        manageErrorMapImage(error);
        if (!_map.hasImage("map-cluster-activity"))
          _map.addImage("map-cluster-activity", image);
      }
    );

    _map.loadImage(
      "https://res.cloudinary.com/dm8dxwvix/image/upload/v1643212664/general-cluster_erk6ga.png",
      (error: Error, image: File) => {
        manageErrorMapImage(error);
        if (!_map.hasImage("map-general-cluster"))
          _map.addImage("map-general-cluster", image);
      }
    );

    return null;
  }

  const functionToDefineOnClick = (e: any) => {
    if (!sidebarGeoLoc && modifyLocation) {
      return undefined;
    }
    if (sidebarGeoLoc && !modifyLocation && !createLocationActive) {
      onClick(e);
    }
    if (!sidebarGeoLoc && !modifyLocation && !createLocationActive) {
      onClick(e);
    }
  };

  const functionToDefineHover = (e: any) => {
    if (!sidebarGeoLoc && modifyLocation) {
      return undefined;
    }
    if (sidebarGeoLoc && !modifyLocation && !createLocationActive) {
      return undefined;
    }
    if (!sidebarGeoLoc && !modifyLocation && !createLocationActive) {
      onHover(e);
    }
  };

  const defineTheGoodImg = (newGeoLoc: NewGeoLocation) => {
    const silo = 1;
    const labo = 2;
    if (newGeoLoc.typeId === silo) {
      return imgLocationSilo(newGeoLoc);
    }

    if (newGeoLoc.typeId === labo) {
      return imgLocationLabo(newGeoLoc);
    }
  };

  return (
    <div className="map-styles">
      <Map
        {...viewport}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        reuseMaps={true}
        maxZoom={30}
        onMove={onMove}
        style={{ height: "100vh" }}
        onClick={functionToDefineOnClick}
        cursor=""
        onMouseMove={functionToDefineHover}
        mapStyle="mapbox://styles/davidroman66/cktk5aol15q8117mo7ju3guui"
        interactiveLayerIds={
          !modifyLocation && !createLocationActive
            ? ([unclusteredPointLayers?.id, clusterLayer?.id] as string[])
            : undefined
        }
        ref={mapRef}
      >
        <MapImage />

        {newGeoLoc &&
        sidebarGeoLoc &&
        !modifyLocation &&
        !createLocationActive ? (
          <Marker longitude={newGeoLoc.longitude} latitude={newGeoLoc.latitude}>
            <img
              src={defineTheGoodImg(newGeoLoc)}
              style={{ height: "60px" }}
              alt="newGeoLoc"
            />
          </Marker>
        ) : (
          ""
        )}
        {modifyLocation || createLocationActive ? (
          <Marker
            longitude={longitudeEditCreateGeoLoc as number}
            latitude={latitudeEditCreateGeoLoc as number}
            draggable
            onDragStart={onMarkerDragStart}
            onDrag={onMarkerDrag}
            onDragEnd={onMarkerDragEnd}
          >
            <Pin />
          </Marker>
        ) : (
          ""
        )}
        {locationsLoading ? (
          <Marker
            longitude={viewport.longitude as number}
            latitude={viewport.latitude as number}
          >
            <CircularProgress />
          </Marker>
        ) : (
          ""
        )}
        <div
          style={{
            top: 36,
            marginLeft: "97%",
            marginRight: "22px",
            position: "absolute",
          }}
        ></div>
        {!modifyLocation && !createLocationActive ? (
          <Source
            id="orgGeoPoints"
            type="geojson"
            data={pointsGeoJSON as any}
            cluster={true}
            clusterMaxZoom={30}
            clusterRadius={75}
            clusterProperties={{
              sum: [
                "+",
                ["get", "count", ["get", "notifications", ["properties"]]],
              ],
              has_notification: [
                "any",
                ["==", ["get", "unAckNotificationsCluster"], "notification"],
                "false",
              ],
              has_no_notification: [
                "any",
                ["==", ["get", "unAckNotificationsCluster"], "noNotification"],
                "false",
              ],
              has_activity: [
                "any",
                ["==", ["get", "hasActivityCluster"], "activity"],
                "false",
              ],
              has_no_activity: [
                "any",
                ["==", ["get", "hasActivityCluster"], "noActivity"],
                "false",
              ],
              only_activity: [
                "all",
                ["==", ["get", "hasActivityCluster"], "activity"],
                "false",
              ],
              only_no_activity: [
                "all",
                ["==", ["get", "hasActivityCluster"], "noActivity"],
                "false",
              ],
            }}
          >
            <Layer {...layerClusters} />
            <Layer {...unclusteredPointLayers} />
            <Layer {...clusterCountLayers} />
            <Layer {...countNotification} />
          </Source>
        ) : (
          ""
        )}
        {noGeoLoc()}
      </Map>
      {SidebarLocation(t, token)}
      {SidebarSearch(t, token)}
      {SidebarViews(t, token)}

      {modifyLocation ? <ControlPanelEditLocation /> : ""}
      {createLocationActive ? <ControlPanelEditLocation /> : ""}
    </div>
  );
};

export default InarixMap;
