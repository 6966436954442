import { ThemeProvider } from "@mui/material/styles";
import { Box, Modal } from "@mui/material";

import {
  DataGridPro,
  GridPaginationModel,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import themeInarix from "../StylesMuiInarix";
import UserLocationsColumns from "./UserLocationsFetchingColumns";
import { SuccessGeneralModal } from "../SuccessModal";
import { UserLocationsBody } from "./UsersEditInfoBody";
import { OrgGeoPointData } from "../../redux/actions/locations/locationsTypes";
import { ValuesCreateEditLocation } from "../../redux/slices/map/mapTypes";
import { useSelector } from "react-redux";
import { State } from "../../redux/slices";

export function UserLocationsTable(
  t: (arg0: string) => string,
  token: string,
  setRowsNumber: (value: GridPaginationModel) => void,
  msg: string,
  sectionName: string,
  currentLocation: OrgGeoPointData | null,
  editLocationNewValues: ValuesCreateEditLocation
) {
  const { locale } = useSelector((state: State) => state.LayoutReducer);
  const { loading, modal, loadingPatch } = useSelector(
    (state: State) => state.LocationsReducer
  );

  const successBodyModal = SuccessGeneralModal(t, sectionName);
  const bodyUserLocations = UserLocationsBody(
    t,
    token,
    currentLocation,
    editLocationNewValues,
    loading,
    loadingPatch
  );

  const customToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  const { columns, locationsRows } = UserLocationsColumns(t);

  return (
    <Box sx={{ height: 500, width: 1 }}>
      <ThemeProvider theme={themeInarix(locale)}>
        <DataGridPro
          className="table-styles-alone"
          disableColumnSelector={true}
          rows={locationsRows}
          columns={columns as any}
          rowHeight={78}
          loading={loading}
          pagination
          onPaginationModelChange={(newPageSize) => setRowsNumber(newPageSize)}
          slots={{ toolbar: customToolbar }}
          pageSizeOptions={[25, 50, 100]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
        />
      </ThemeProvider>
      <Modal open={modal}>{bodyUserLocations}</Modal>
      <Modal open={!!msg}>{successBodyModal}</Modal>
    </Box>
  );
}
