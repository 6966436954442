import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridFilterModel } from "@mui/x-data-grid-pro";

import Button from "@mui/material/Button";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { useStyles } from "../data/DataStyles";
import {
  initialState,
  setFilters,
  setTableProjectsOptions,
} from "../../redux/slices/fleet/projects";
import { State } from "../../redux/slices";
import { fetchFilesProjects } from "../../redux/actions/projects/ProjectsAsyncActions";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setNewPage: (
      tableOptions: {
        page: number;
        rowOptions: number;
        totalRows: number | null;
      },
      page: number
    ) => {
      dispatch(setTableProjectsOptions({ ...tableOptions, page }));
    },

    setFilters: (filters: GridFilterModel) => dispatch(setFilters(filters)),

    removeFilters: () => {
      dispatch(setFilters(initialState.filters));
      dispatch(setTableProjectsOptions(initialState.tableOptions));
    },

    fetchNewPage: (token: string, id: number) =>
      dispatch(fetchFilesProjects({ token, id: 8 })),
  };
};

export const ProjectsApplyFiltersComponent = (t: (arg0: string) => string) => {
  const classes = useStyles();
  const { projectId, tableOptions } = useSelector(
    (state: State) => state.ProjectsReducer
  );
  const { fetchNewPage, setNewPage } = SetActions();

  const applyFilter = (filters: GridFilterModel, token: string) => {
    const applyFilterFunction = () => {
      setNewPage(tableOptions, 0);
      fetchNewPage(token, projectId);
    };
    const enabled =
      filters?.items &&
      filters?.items.length > 0 &&
      filters?.items.every((x) => !!x.value);
    return (
      <Button
        className={classes.applyFilter}
        disabled={!enabled}
        onClick={applyFilterFunction}
      >
        <PlaylistAddIcon />
        {t("Data.table.Apply_filter")}
      </Button>
    );
  };

  return {
    applyFilter,
  };
};

export const ProjectsDisableFiltersComponents = (
  t: (arg0: string) => string
) => {
  const { projectId } = useSelector((state: State) => state.ProjectsReducer);
  const classes = useStyles();
  const { removeFilters, fetchNewPage } = SetActions();

  const disableFilter = (filters: GridFilterModel, token: string) => {
    const disableFilterFunction = () => {
      removeFilters();
      fetchNewPage(token, projectId);
    };

    return (
      <Button className={classes.applyFilter} onClick={disableFilterFunction}>
        <PlaylistRemoveIcon />
        {t("Data.table.Disable_filter")}
      </Button>
    );
  };

  return {
    disableFilter,
  };
};
