import { ThemeProvider } from "@mui/material/styles";
import { Modal, IconButton, Tooltip } from "@mui/material";
import { DataGridInarixProUsersActions } from "../userGroups/UserTabsStyles";
import LabelIcon from "@mui/icons-material/Label";
import themeInarix from "../StylesMuiInarix";

import {
  GridPaginationModel,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { inarixColors } from "inarix-theme";
import { ConfirmGeneralModal } from "../ConfirmModal";
import { SuccessGeneralModal } from "../SuccessModal";
import { BodyActionsGroup } from "./UserActionsBodyMove";
import { errorsGeneralModal } from "../ErrorModal";
import { State } from "../../redux/slices";
import { useSelector } from "react-redux";
import ActionsUsersContent from "./FetchingColumnsRowsActions";

const NewActionsTable = (
  t: (arg0: string) => string,
  setActionsModal: (value: boolean) => void,
  setScenario: (e: any) => void,
  setRowsNumber: (rowsSize: GridPaginationModel) => void,
  removeActionsError: () => void
) => {
  const { user } = useSelector(
    (state: State) => state.Authentication
  );
  const token = user?.token ?? "";
  const {
    scenariosId,
    modal,
    loading,
    error,
    msgSuccess,
  } = useSelector((state: State) => state.ActionsReducer);
  const { locale, layoutActive, infoConfirmModal, confirmModal } = useSelector(
    (state: State) => state.LayoutReducer
  );

  const { actionsRowsTransform, columns } = ActionsUsersContent(token);

  const bodyScenarioMove = BodyActionsGroup(token);
  const successBodyModal = SuccessGeneralModal(t, "/actions");
  const bodyErrors = errorsGeneralModal(
    JSON.stringify(error),
    removeActionsError,
    t
  );
  const bodyConfirmMOdal = ConfirmGeneralModal(
    token,
    t,
    "/actions",
    infoConfirmModal
  );

  const customToolbar = () => {
    return (
      <GridToolbarContainer
        data-cy="grid-toolbar-tables"
        className="grid-toolbar-styles"
        style={{
          background:
            scenariosId.length > 0 ? inarixColors.tables_action_color : "",
        }}
      >
        <GridToolbarQuickFilter />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />

        {scenariosId.length > 0 ? (
          <Tooltip title={t("Fleet_actions.modal.Add_scenarios")} arrow>
            <IconButton
              aria-label="add"
              color="primary"
              size="small"
              onClick={() => setActionsModal(true)}
            >
              <LabelIcon data-cy="label-icon" />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}
      </GridToolbarContainer>
    );
  };

  const tableStyleLayout = () => {
    if (!layoutActive) {
      return "table-styles";
    } else {
      return "table-styles-open-layout";
    }
  };

  return (
    <div style={{ height: 640, width: "100%" }}>
      <ThemeProvider theme={themeInarix(locale)}>
        <DataGridInarixProUsersActions
          className={tableStyleLayout()}
          rowHeight={100}
          loading={loading || actionsRowsTransform.length < 1}
          pagination
          disableColumnSelector={true}
          rows={actionsRowsTransform}
          columns={columns}
          slots={{ toolbar: customToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          pageSizeOptions={[25, 50, 100]}
          onPaginationModelChange={(newPageSize) => setRowsNumber(newPageSize)}
          checkboxSelection
          rowSelectionModel={scenariosId}
          onRowSelectionModelChange={(e) => {
            setScenario(e);
          }}
        />
      </ThemeProvider>
      <Modal open={!!error}>{bodyErrors}</Modal>
      <Modal open={confirmModal}>{bodyConfirmMOdal}</Modal>
      <Modal open={!!msgSuccess}>{successBodyModal}</Modal>
      <Modal open={modal}>{bodyScenarioMove}</Modal>
    </div>
  );
};

export default NewActionsTable;
