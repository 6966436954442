import React from "react";
import { Alert, Button } from "@mui/material";
import { useStyles } from "../OverviewStyles";
import { State } from "../../../redux/slices";
import { useTranslation } from "react-i18next";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { LoadingCircularProgress } from "../../Loading";
import alertIconRed from "../../../assets/images/alert2.png";
import { deleteLocation } from "../../../redux/actions/map/mapAsyncActions";
import {
  resetStateMapMessages,
  setModalDeleteLocation,
} from "../../../redux/slices/map/map";

/**
 *
 * @Actions
 */

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    closeModal: () => {
      dispatch(setModalDeleteLocation(false));
    },
    removeLocation: (token: string, locationId: number) => {
      dispatch(deleteLocation({ token, locationId }));
    },

    resetMessages: () => dispatch(resetStateMapMessages()),
  };
};

export const DeleteLocBody = (token: string): JSX.Element => {
  const [t] = useTranslation("global");

  const { newGeoLoc, deleteLocationError, deleteLocationLoading } = useSelector(
    (state: State) => state.MapReducer
  );

  const classes = useStyles();

  const { closeModal, removeLocation, resetMessages } = SetActions();

  if (deleteLocationError) {
    setTimeout(() => {
      resetMessages();
    }, 5000);
  }

  const bodyDeleteLoc = (
    <section>
      <div className={classes.deleteLocModal}>
        <div className="container-location-delete">
          {deleteLocationError ? (
            <Alert severity="warning" className="alert-style-deleteLoc">
              {JSON.stringify(deleteLocationError)}
            </Alert>
          ) : (
            ""
          )}
          <img
            src={alertIconRed}
            className="img-alert-delete"
            alt="alertIconRed"
          />
          <span className="delete-title">
            {t("Fleet_overview.overview_panel.deleteLocation.deleteLocation")}
          </span>
          <span className="delete-location-text">
            {t(
              "Fleet_overview.overview_panel.deleteLocation.deleteLocationText"
            )}
          </span>
          {newGeoLoc && !deleteLocationLoading ? (
            <div className="delete-loc-buttons-position">
              <Button
                type="button"
                onClick={closeModal}
                variant="outlined"
                disabled={deleteLocationLoading}
                className="btn-delete-loc mr-2"
              >
                {t("Fleet_overview.overview_panel.deleteLocation.cancel")}
              </Button>

              <Button
                type="button"
                color="error"
                variant="contained"
                onClick={() => removeLocation(token, newGeoLoc.id)}
                disabled={deleteLocationLoading}
                className="btn-delete-loc"
              >
                {t("Fleet_overview.overview_panel.deleteLocation.delete")}
              </Button>
            </div>
          ) : (
            <div className="loading-icon mt-2 mb-2">
              {LoadingCircularProgress()}
            </div>
          )}
        </div>
      </div>
    </section>
  );

  return bodyDeleteLoc;
};
