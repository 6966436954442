/**
 * @author David Roman <david@inarix.com>
 * @file Description
 * @desc Created on 2021-04-09 5:23:38 pm
 * @copyright Inarix
 */
//export const API = 'http://192.168.1.19:3030'; // Guillaume house
//export const API = 'http://25.38.191.216:3030'; // hamachi
export const API = process.env.REACT_APP_API;
/**
 *  I will use only to test Fetching Data FAKEAPI is the local DB
 */
export const FAKEAPI = 'http://localhost:4000';
