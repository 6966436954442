import jwt from 'jsonwebtoken';
import { TokenPayload } from '../redux/actions/authentication/authentication';

/**
 * If the current time is greater than or equal to the expiration time of the token, then the token is
 * expired
 * @param {string} token - The token to check
 * @returns A boolean value.
 */
export function isTokenExpired(token?: string | null): boolean {
  if (!token) {
    return true;
  }

  const dateNow = new Date();
  const payload = jwt.decode(token) as TokenPayload | null;

  if (!payload) {
    return true;
  }

  const { expires } = payload;
 
  const expiresTime = new Date(expires);
  return expiresTime.getTime() <= dateNow.getTime();
}
