import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import { Box, Paper, FormControlLabel, Divider } from "@mui/material";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { ListboxComponent } from "../../data/dataExtraFilters/DataAutocompleteSites";
import { DashboardLocIdInfo } from "../../../redux/actions/dashboard/dashboardTypes";
import { State } from "../../../redux/slices";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    fetchGroups: (token: string, typeOfAutocomplete: string) => {
      console.log("hola");
    },
  };
};

const dataOptions = [
  { name: "example", id: 1234 },
  { name: "example", id: 124 },
];

const loading = false;

const AutoCompleteGroupsCreateLocations = (t: (arg0: string) => string) => {
  const [groups, setSelectAll] = React.useState({
    allValuesSelected: false,
    valuesSelected: [] as any,
  });

  const transform = dataOptions?.map((c: DashboardLocIdInfo) => c);

  return (
    <Autocomplete
      sx={{
        height: "57px",
      }}
      disabled={loading}
      id="group-autocomplete"
      inputValue=""
      multiple
      disableListWrap={true}
      options={dataOptions}
      disableCloseOnSelect
      ListboxComponent={ListboxComponent}
      value={groups.valuesSelected as any}
      getOptionLabel={(option: DashboardLocIdInfo) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_e, value, reason) => {
        if (reason === "clear" || reason === "removeOption")
          setSelectAll({
            allValuesSelected: false,
            valuesSelected: [],
          });

        if (reason === "selectOption" && value.length === dataOptions.length)
          setSelectAll({
            allValuesSelected: true,
            valuesSelected: transform as any,
          });

        setSelectAll({ allValuesSelected: false, valuesSelected: value });

        if (reason === "selectOption" && dataOptions.length === 0)
          console.log("");
      }}
      renderInput={(params: { InputLabelProps: any }) => {
        return (
          <TextField
            {...params}
            id="group-autocomplete"
            label="Group"
            variant="outlined"
            sx={{
              width: 406,
              maxWidth: "calc(100% - 20px)",
              marginBottom: "24px",
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      }}
      renderOption={(props, option, { selected }) =>
        [props, option.name, selected] as React.ReactNode
      }
      PaperComponent={(paperProps) => {
        const { children, ...restPaperProps } = paperProps;
        return (
          <Paper {...restPaperProps}>
            <Box onMouseDown={(e) => e.preventDefault()} pl={1.5} py={0.5}>
              <FormControlLabel
                onClick={(e) => {
                  if (dataOptions.length >= 1) {
                    e.preventDefault();
                    !groups.allValuesSelected
                      ? setSelectAll({
                          allValuesSelected: true,
                          valuesSelected: transform,
                        })
                      : setSelectAll({
                          allValuesSelected: false,
                          valuesSelected: [],
                        });
                  }
                }}
                disabled={dataOptions.length === 0}
                label={t("Dashboard.setAllLocations")}
                control={
                  <Checkbox
                    disabled={dataOptions.length === 0}
                    id="select-all-checkbox"
                    checked={groups.allValuesSelected}
                  />
                }
              />
            </Box>
            <Divider />
            {children}
          </Paper>
        );
      }}
      renderGroup={(params) => params as unknown as React.ReactNode}
    />
  );
};

export default AutoCompleteGroupsCreateLocations;
