import React from "react";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { cleanMessageNDError } from "../redux/slices/fleet/users";
import { setUserDeviceMessageSuccess } from "../redux/slices/fleet/devices";
import {
  cleanUserLocationMessage,
  initialState,
  setNewValuesUpdateLocation,
} from "../redux/slices/fleet/locations";
import {
  resetMapViewState,
  setModalViewsMap,
  resetStateMapMessages,
} from "../redux/slices/map/map";
import { removeSuccessMsg } from "../redux/slices/fleet/actions";
import { resetDeletedFile } from "../redux/slices/fleet/projects";
import { setConfirmModal, setInfoConfirmModal } from "../redux/slices/layout";

const SetActions = () => {
  const dispatch = useDispatch();
  return {
    removeMessageUsers: () => dispatch(cleanMessageNDError()),
    removeMessageDeviceToFalse: () =>
      dispatch(setUserDeviceMessageSuccess(false)),
    removeMessageLocations: () => {
      dispatch(setNewValuesUpdateLocation(initialState.editLocation));
      dispatch(cleanUserLocationMessage());
    },
    removeMessageActions: () => {
      dispatch(removeSuccessMsg());
    },

    removeMessageMap: () => {
      dispatch(resetStateMapMessages());
    },

    resetViewValues: () => {
      dispatch(setModalViewsMap(false));
    },
    resetMapValues: () => {
      dispatch(resetMapViewState());
    },
    resetDeletedFile: () => {
      dispatch(resetDeletedFile());
    },
    resetConfirmModal: () => {
      dispatch(setConfirmModal(false));
      dispatch(setInfoConfirmModal(undefined));
    },
  };
};

export const SuccessGeneralModal = (
  t: (arg0: string) => string,
  tabSection: string,
  typeOfAction?: string
): JSX.Element => {
  const {
    resetDeletedFile,
    removeMessageUsers,
    removeMessageDeviceToFalse,
    removeMessageLocations,
    removeMessageActions,
    removeMessageMap,
    resetMapValues,
    resetViewValues,
    resetConfirmModal,
  } = SetActions();
  const confirmFunction = () => {
    if (tabSection === "/projects") {
      resetDeletedFile();
      resetConfirmModal();
    }
    if (tabSection === "/users") {
      removeMessageUsers();
    }
    if (tabSection === "/devices") {
      removeMessageDeviceToFalse();
    }

    if (tabSection === "/locations") {
      removeMessageLocations();
    }
    if (tabSection === "/actions") {
      removeMessageActions();
    }

    if (tabSection === "/map" || "/") {
      removeMessageMap();
    }

    if (
      (tabSection === "/" && typeOfAction === "editLocation") ||
      (tabSection === "/" && typeOfAction === "createLocation")
    ) {
      removeMessageMap();
    }

    if (tabSection === "/" && typeOfAction === "createMapView") {
      resetViewValues();
    }

    if (tabSection === "/" && typeOfAction === "deleteView") {
      resetMapValues();
    }
  };

  const successBodyModal = (
    <section>
      <div id="success_tic" className="success-modal">
        <div className="modal-content-success">
          <div className="page-body">
            <h1 style={{ textAlign: "center" }}>
              <div className="checkmark-circle">
                <div className="background" />
                <div className="checkmark draw" />
              </div>
            </h1>
            <div className="button-success-modal">
              <span className="alert-info">
                {t("Fleet_users.alert.Saved_1")}
              </span>
              <Button variant="outlined" onClick={() => confirmFunction()}>
                ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  return successBodyModal;
};
