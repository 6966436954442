import { createSlice } from "@reduxjs/toolkit";
import {
  GroupScopes,
  OrganizationsUserGroups,
  ResponseScenarioFleet,
  RowsFromListScenario,
  ScenarioInstance,
  ScenarioInstanceStep,
} from "../../actions/actions/actionTypes";
import { CerealType } from "../../actions/data/DataDeclarations";
import {
  fetchScenarioInstance,
  deleteScenarioFromGroup,
  moveAllGroup,
} from "../../actions/actions/actionsAsyncActions";
import { listGroups } from "../../actions/actions/actionsAsyncScenarioAssignment";

export interface ActionsState {
  actions: ResponseScenarioFleet | null;
  actionsRows: RowsFromListScenario[] | null;
  scenariosId: number[];
  individualAction: null | ScenarioInstance;
  individualActionsSteps: [ScenarioInstanceStep[]];
  loadingSimpleAction: boolean;
  groupId: number;
  groups: OrganizationsUserGroups | null;
  scenarioOnGroup: any;
  error: string;
  errorMove: string;
  errorFetchIndividual: string;
  loading: boolean;
  loadingFetchGroup: boolean;
  msgSuccess: string;
  modal: boolean;
  cerealType: CerealType;
  actionId: number;
  lastScenarioInstanceId: number | undefined;
  usersActionsRowsToSearch: any[];
  groupsScopes: GroupScopes[];
}

export const initialState: ActionsState = {
  actions: null,
  actionsRows: [],
  groups: null,
  individualAction: null,
  individualActionsSteps: [[]],
  loadingSimpleAction: false,
  scenariosId: [],
  groupId: 0,
  scenarioOnGroup: null,
  error: "",
  errorMove: "",
  errorFetchIndividual: "",
  loading: false,
  loadingFetchGroup: false,
  msgSuccess: "",
  modal: false,
  cerealType: {
    id: 0,
    names: [],
    cerealTypeId: 0,
    name: "",
    locale: "",
    ownerOrgId: 1,
    createdAt: "",
    updatedAt: "",
    iconBackgroundHexColor: "#ffff",
    text: {},
  },
  actionId: 0,
  lastScenarioInstanceId: undefined,
  usersActionsRowsToSearch: [],
  groupsScopes: [],
};

export const ActionsSlice = createSlice({
  name: "ActionsReducer",
  initialState,
  reducers: {
    disableActionsErrors(state) {
      return {
        ...state,
        error: "",
        errorFetchIndividual: "",
        errorMove: "",
      };
    },
    setGroupId(state, action) {
      return {
        ...state,
        groupId: action.payload,
      };
    },

    removeSuccessMsg(state) {
      return {
        ...state,
        msgSuccess: initialState.msgSuccess,
      };
    },
    setModal(state, actions) {
      return {
        ...state,
        modal: actions.payload,
      };
    },
    setActionsRows(state, actions) {
      return {
        ...state,
        actionsRows: actions.payload,
      };
    },
    setScenariosId(state, actions) {
      return {
        ...state,
        scenariosId: actions.payload,
      };
    },
    setScenarioNames(state) {},
    setCerealInfo(state, action) {},
    setCerealNames(state, actions) {
      return {
        ...state,
        cerealTypes: actions.payload,
      };
    },

    setLastScenarioInstanceId(state, action) {
      return {
        ...state,
        lastScenarioInstanceId: action.payload,
      };
    },

    setStepsBySlug(state, actions) {
      return {
        ...state,
        individualActionsSteps: actions.payload,
      };
    },

    setActionId(state, actions) {
      return {
        ...state,
        actionId: actions.payload,
      };
    },

    setGroupsScopes(state, actions) {
      return {
        ...state,
        groupsScopes: actions.payload,
      };
    },

    initialStateActions() {
      return initialState;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchScenarioInstance.pending, (state) => {
        state.errorFetchIndividual = "";
        state.loadingSimpleAction = true;
      })
      .addCase(fetchScenarioInstance.fulfilled, (state, actions) => {
        state.loadingSimpleAction = false;
        state.individualAction = actions.payload;
      })
      .addCase(fetchScenarioInstance.rejected, (state, actions) => {
        state.loadingSimpleAction = false;
        state.errorFetchIndividual = actions.payload as string;
      });

    builder
      .addCase(listGroups.pending, (state) => {
        state.loadingFetchGroup = true;
      })
      .addCase(listGroups.fulfilled, (state, actions) => {
        state.loadingFetchGroup = false;
        state.groups = actions.payload;
      })
      .addCase(listGroups.rejected, (state, actions) => {
        state.loadingFetchGroup = false;
        state.error = actions.payload as string;
      });

    builder
      .addCase(deleteScenarioFromGroup.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteScenarioFromGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.msgSuccess = action.payload as unknown as string;
      })
      .addCase(deleteScenarioFromGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    builder
      .addCase(moveAllGroup.pending, (state) => {
        state.loading = true;
      })
      .addCase(moveAllGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.msgSuccess = action.payload as unknown as string;
      })
      .addCase(moveAllGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const {
  setLastScenarioInstanceId,
  setStepsBySlug,
  setActionId,
  initialStateActions,
  setScenarioNames,
  setCerealNames,
  setCerealInfo,
  setActionsRows,
  setScenariosId,
  setModal,
  removeSuccessMsg,
  setGroupId,
  disableActionsErrors,
  setGroupsScopes,
} = ActionsSlice.actions;

export const Actions = ActionsSlice.reducer;
