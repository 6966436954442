import React from "react";
import { Button } from "@mui/material";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import Menu from "@mui/material/Menu";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ColumnSwitches from "../dataExtraFilters/DataColumnsSwitches";
import { inarixColors } from "inarix-theme";

export const ColumnsButton = (t: (arg0: string) => string, token: string) => {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button
            variant="outlined"
            startIcon={<ViewColumnIcon />}
            sx={{
              textTransform: "none",
              background: inarixColors.contrast_inarix,
              borderColor: "#C0C0C0",
              "&:hover": {
                backgroundColor: inarixColors.action_hover_inarix,
                border: `solid 0.5px #000`,
                boxShadow: "none",
              },
            }}
            {...bindTrigger(popupState)}
          >
            {t("Data.Display")}
          </Button>
          <Menu {...bindMenu(popupState)} sx={{ width: "100%", gap: "24px" }}>
            <div className="columns-container-switches">
              {ColumnSwitches(t, token)}
            </div>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};
