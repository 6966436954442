import { FC, useEffect } from "react";
import Layout from "../layout/Layout";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/slices";
import { ThunkDispatch } from "@reduxjs/toolkit";
import jwt from "jsonwebtoken";

import {
  setConfirmModal,
  setInfoConfirmModal,
  setTabNameLayout,
} from "../../redux/slices/layout";
import {
  disableActionsErrors,
  initialState,
  setModal,
  setScenariosId,
} from "../../redux/slices/fleet/actions";
import NewActionsTable from "./UserActionsTable";
import ActionsUsersContent from "./FetchingColumnsRowsActions";
import { setNumberRowsUsers } from "../../redux/slices/fleet/users";
import { GridPaginationModel } from "@mui/x-data-grid";
import ActionsAccordion from "./UserActionsAccordion";
import {
  listGroups,
  listScenarios,
} from "../../redux/actions/actions/actionsAsyncScenarioAssignment";
import NotFound from "../NotFound";
import { TokenPayload } from "../../redux/actions/authentication/authentication";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    initGroupsRows: (token: string, orgId: number) => {
      dispatch(setTabNameLayout("/actions"));
      dispatch(setScenariosId(initialState.scenariosId));
      dispatch(setModal(false));
      dispatch(setInfoConfirmModal(undefined));
      dispatch(setConfirmModal(false));
      dispatch(disableActionsErrors());
      dispatch(listScenarios({ token, orgId }));
      dispatch(listGroups(token));
    },
    disableErrors: () => dispatch(disableActionsErrors()),
    setActionModal: (modal: boolean) => dispatch(setModal(modal)),
    setScenarioIds: (scenariosId: string[]) =>
      dispatch(setScenariosId(scenariosId)),

    setRowsNumber: (rows: GridPaginationModel) =>
      dispatch(setNumberRowsUsers(rows)),
  };
};

const GlobalComponets = () => {
  const [t] = useTranslation("global");
  const { user } = useSelector((state: State) => state.Authentication);
  const token = user?.token ?? "";
  const { groups, loadingFetchGroup } = useSelector(
    (state: State) => state.ActionsReducer
  );

  const { actionsRowsTransform } = ActionsUsersContent(token);

  const { setActionModal, setScenarioIds, setRowsNumber, disableErrors } =
    SetActions();
  return (
    <div className="table-container-actions">
      {NewActionsTable(
        t,
        setActionModal,
        setScenarioIds,
        setRowsNumber,
        disableErrors
      )}

      {ActionsAccordion(
        token,
        t,
        actionsRowsTransform,
        groups,
        loadingFetchGroup
      )}
    </div>
  );
};

const UsersActions: FC<any> = () => {
  const [t] = useTranslation("global");

  const { user, isViewerOnly } = useSelector(
    (state: State) => state.Authentication
  );
  const token = user?.token ?? "";
  const { realmId }: TokenPayload = jwt.decode(token) as TokenPayload;
  const { initGroupsRows } = SetActions();

  useEffect(() => {
    initGroupsRows(token, realmId);
  }, []);

  return (
    <Layout
      title={!isViewerOnly ? t("Fleet_actions.table.My_actions") : ""}
      component={!isViewerOnly ? GlobalComponets() : <NotFound />}
    />
  );
};

export default UsersActions;
