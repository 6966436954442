/**
 * @author David Roman <david@inarix.com>
 * @file Private Route authentication
 * @desc
 * @copyright Inarix
 */
import React, { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute: FC<any> = ({
  user,
  redirectPath = "/login",
  children,
}: any) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
};

export default PrivateRoute;
