import { createSlice } from "@reduxjs/toolkit";
import { DeviceData } from "../../actions/devices/devicesTypes";
import { fetchDevices } from "../../actions/devices/devicesAsyncAction";

export interface DeviceState {
  devices: DeviceData[];
  deviceId: string;
  deviceName: string;
  error: string;
  errorPatch: number;
  loading: boolean;
  loadingPatch: boolean;
  msg: boolean;
  modal: boolean;
  devicesRows: any[];
  devicesRowsInit: any[];
}

export const initialState: DeviceState = {
  devices: [],
  deviceId: "",
  deviceName: "",
  error: "",
  errorPatch: 0,
  loading: false,
  loadingPatch: false,
  msg: false,
  modal: false,
  devicesRows: [],
  devicesRowsInit: [],
};

export const DevicesSlice = createSlice({
  name: "DevicesReducer",
  initialState,
  reducers: {
    disableErrorSuccessDevices(state) {
      return {
        ...state,
        msg: false,
        error: "",
        errorPatch: 0,
      };
    },
    setUserDeviceId(state, action) {
      return {
        ...state,
        deviceId: action.payload,
      };
    },
    setNewDeviceName(state, action) {
      return {
        ...state,
        deviceName: action.payload,
      };
    },
    setDevicesModal(state, action) {
      return {
        ...state,
        modal: action.payload,
      };
    },
    setUserDeviceMessageSuccess(state, action) {
      return {
        ...state,
        deviceName: "",
        deviceId: "",
        msg: action.payload,
      };
    },

    resetUserDeviceError(state) {
      return {
        ...state,
        errorPatch: 0,
      };
    },
    cleanGeneralDeviceError(state) {
      return {
        ...state,
        error: "",
      };
    },
    resetDevicesState() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDevices.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(fetchDevices.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          devices: action.payload,
        };
      })
      .addCase(fetchDevices.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.payload as string,
        };
      });
  },
});

export const {
  setUserDeviceId,
  setNewDeviceName,
  setDevicesModal,
  setUserDeviceMessageSuccess,
  resetUserDeviceError,
  cleanGeneralDeviceError,
  resetDevicesState,
  disableErrorSuccessDevices,
} = DevicesSlice.actions;

export const Devices = DevicesSlice.reducer;
