import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Canvas from "./NewIcon";
import { useNavigate, useParams } from "react-router-dom";

import List from "@mui/material/List";
import { State } from "../../redux/slices";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  setActionId,
  setLastScenarioInstanceId,
} from "../../redux/slices/fleet/actions";
import {
  RowsFromListScenario,
  ScenarioData,
  ScenarioInstanceStep,
} from "../../redux/actions/actions/actionTypes";
import Layout from "../layout/Layout";
import separateInfo from "./ActionsSeparateInfo";
import { fetchScenarioInstance } from "../../redux/actions/actions/actionsAsyncActions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { withRouter } from "../Helpers";
import NotFound from "../NotFound";
import { Button } from "@mui/material";
import { inarixColors } from "inarix-theme";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setIndividualActionResponse: (actionId: number) => {
      dispatch(setActionId(actionId));
    },

    etLastScenarioIntanceStep: (scenarioId: number | null) => {
      dispatch(setLastScenarioInstanceId(scenarioId as number));
    },
    setIndividualActionResponseData: (
      token: string,
      scenarioInstanceId: number
    ) => {
      dispatch(fetchScenarioInstance({ token: token, id: scenarioInstanceId }));
    },
  };
};

export const goBackFunction = (
  navigate: any,
  t: (arg0: string) => string,
  titleGoBack: string,
  historyPushTo: string
) => {
  const goBackFunc = () => {
    navigate(historyPushTo);
  };

  return (
    <div className="go-back-data-div">
      <Button
        variant="text"
        startIcon={<ArrowBackIcon />}
        sx={{
          color: inarixColors.primary_text_inarix,
          textTransform: "lowercase",
          "&:hover": {
            color: inarixColors.main_inarix,
          },
        }}
        onClick={() => goBackFunc()}
      >
        {t(titleGoBack)}
      </Button>
    </div>
  );
};
const createIcon = (actions: ScenarioData) => {
  const iconSaltNumber = actions.scenarioIcon.iconSalt;
  const hashSlug = actions.scenarioIcon.hashSlug;
  const background = actions.scenarioIcon.background;
  const foreground = actions.scenarioIcon.foreground;

  return (
    <Canvas
      iconSalt={iconSaltNumber}
      slug={hashSlug}
      background={background}
      foregroundColor={foreground}
    />
  );
};

const IndividualActionContent = (
  navigate: any,
  actions: any,
  actionId: number,
  steps: [ScenarioInstanceStep[]]
) => {
  const { loadingSimpleAction, errorFetchIndividual } = useSelector(
    (state: State) => state.ActionsReducer
  );
  const searchInAction = actions?.find(
    (element: ScenarioData) => element.id === actionId
  );

  const step1 = steps?.map((scenarios: ScenarioInstanceStep[]) =>
    scenarios?.map((action: ScenarioInstanceStep) => action)
  );

  const stepConvert = step1?.flat();

  if (!actions || !searchInAction) {
    navigate(`/actions`);
  }

  const managingErrorFetchAction = () => {
    if (!errorFetchIndividual) {
      return separateInfo(stepConvert);
    } else
      return (
        <div className="containter-skeleton">
          {JSON.stringify(errorFetchIndividual)}
        </div>
      );
  };

  return actions?.map((actions: ScenarioData) => {
    if (actionId === actions.id) {
      return (
        <div key={actions.id}>
          <List
            sx={{ width: 560, bgcolor: "background.paper" }}
            className="container-action-sample"
          >
            <div className="">
              <div className="title-card-action mt-5 mb-5">
                {createIcon(actions)}
              </div>
              <div className="title-card-action">
                <h2>{actions.name}</h2>
                <span>{actions.description}</span>
                <span className="cereal-name-action">{actions.cerealName}</span>
              </div>
            </div>
            <div className="managing-fetch-action">
              {!loadingSimpleAction ? (
                managingErrorFetchAction()
              ) : (
                <div>
                  <Stack spacing={2}>
                    <Skeleton variant="text" />
                    <Skeleton variant="rectangular" width={310} height={318} />
                  </Stack>
                </div>
              )}
            </div>
          </List>
        </div>
      );
    }
  });
};

const UsersIndividualAction: FC<any> = () => {
  const [t] = useTranslation("global");
  const { setIndividualActionResponse, setIndividualActionResponseData } =
    SetActions();

  const { actionsRows, actionId, individualActionsSteps } = useSelector(
    (state: State) => state.ActionsReducer
  );

  const { id } = useParams();
  const { user, isViewerOnly } = useSelector(
    (state: State) => state.Authentication
  );
  const token = user?.token ?? "";
  const navigate = useNavigate();

  const searchInAction = actionsRows?.find(
    (element: RowsFromListScenario) => element.id === actionId
  );

  const lastId = searchInAction?.lastScenarioInstanceId;

  useEffect(() => {
    setIndividualActionResponse(parseInt(id as string));
    setIndividualActionResponseData(token, lastId as number);
  }, []);

  const globalComponentIndividualAction = () => (
    <div>
      {goBackFunction(navigate, t, "Fleet_layout.Actions", `/actions`)}
      <div className="div-conainer-info">
        {IndividualActionContent(
          navigate,
          actionsRows,
          actionId,
          individualActionsSteps
        )}
      </div>
      <div className="rest-of-component" />
    </div>
  );

  return (
    <Layout
      component={
        !isViewerOnly ? globalComponentIndividualAction() : <NotFound />
      }
    />
  );
};

export default withRouter(UsersIndividualAction);
