import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import { State } from "../../../redux/slices";
import { v4 as uuidv4 } from "uuid";
import { ThunkDispatch } from "redux-thunk";
import { setOpenCloseModalPhotos } from "../../../redux/slices/data/dataSampleView";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setOpenCloseModalPhotos: (visible: boolean) => {
      dispatch(setOpenCloseModalPhotos(visible));
    },
  };
};

const CancelButton = () => {
  const { setOpenCloseModalPhotos } = SetActions();
  return (
    <button
      className="cancel-button"
      onClick={() => setOpenCloseModalPhotos(false)}
    >
      X
    </button>
  );
};

const CarouselContainer = (photos: [{ signedRequest: string }]) => {
  return (
    <Carousel>
      {photos.map((photo) => (
        <Carousel.Item
          key={`${photo.signedRequest}${uuidv4()}`}
          interval={1000000}
        >
          <div className="container-carousel">
            <img
              src={photo.signedRequest}
              className="d-block w-50"
              style={{ marginLeft: "25%", maxHeight: "800px" }}
              alt="..."
            />
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
export const BodyCarouselPhotos = (): JSX.Element => {
  const { photos } = useSelector((state: State) => state.DataSampleViewReducer);

  return (
    <div className="carousel-component-container">
      {CancelButton()}
      {CarouselContainer(photos)}
    </div>
  );
};
