import React from "react";
import { useDispatch } from "react-redux";
import { FormControl } from "@mui/material";

import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  ButtonCancelModals,
  ButtonConfirmModals,
} from "../userGroups/UserTabsStyles";
import { useStyles } from "../modals/modalStyles";
import {
  TextAreaNotes,
  TextFieldInputEditCreate,
} from "../overview/OverviewStyles";
import { OrgGeoPointData } from "../../redux/actions/locations/locationsTypes";
import { ValuesCreateEditLocation } from "../../redux/slices/map/mapTypes";
import {
  initialState,
  setModalLocations,
  setNewValuesUpdateLocation,
  setUserLocationId,
  setUserLocationName,
} from "../../redux/slices/fleet/locations";
import { patchLocationName } from "../../redux/actions/locations/locationsAsyncActions";
import { MuiTelInput } from "mui-tel-input";
/**
 *
 * @Actions
 */

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    closeModal: () => {
      dispatch(setModalLocations(false));
      dispatch(setUserLocationName(null));
      dispatch(setUserLocationId(0));
      dispatch(setNewValuesUpdateLocation(initialState.editLocation));
    },

    patchName: (token: string) => {
      dispatch(patchLocationName(token));
    },

    setNewValues: (values: ValuesCreateEditLocation) =>
      dispatch(setNewValuesUpdateLocation(values)),
  };
};

export const UserLocationsBody = (
  t: (arg0: string) => string,
  token: string,
  currentLocation: OrgGeoPointData | null,
  editLocationNewValues: ValuesCreateEditLocation,
  loading: boolean,
  loadingPatch: boolean
): JSX.Element => {
  const { closeModal, patchName, setNewValues } = SetActions();

  const classes = useStyles();

  const { name, type, primaryContactName, email, address, notes, telephone } =
    editLocationNewValues;

  const handleChange = (newPhone: string) => {
    setNewValues({
      ...editLocationNewValues,
      telephone: newPhone,
    });
  };

  const changeName = (e: React.SyntheticEvent<EventTarget>): void => {
    e.preventDefault();
    patchName(token);
  };

  const bodyUserLocation = (
    <section>
      <div className={classes.confirmModal}>
        <form onSubmit={changeName}>
          <div>
            <h3 data-cy="edit-location-title" className="title-modal">
              {t("Fleet_users_locations.Modal.Title")}
            </h3>
            <br />
            <FormControl fullWidth>
              <TextFieldInputEditCreate
                data-cy="new-location-name"
                size="small"
                type="text"
                label={t(
                  "Fleet_overview.overview_panel.createNewPosition.newLocationName"
                )}
                className="mt-3"
                defaultValue={currentLocation?.name}
                onChange={(e) =>
                  setNewValues({
                    ...editLocationNewValues,
                    name: e.currentTarget.value,
                  })
                }
              />
            </FormControl>

            <FormControl fullWidth>
              <TextAreaNotes
                data-cy="new-location-notes"
                id="outlined-textarea"
                label={t(
                  "Fleet_overview.overview_panel.createNewPosition.notes"
                )}
                multiline
                rows={3}
                defaultValue={currentLocation?.notes}
                onChange={(e) =>
                  setNewValues({
                    ...editLocationNewValues,
                    notes: e.currentTarget.value,
                  })
                }
              />
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <TextFieldInputEditCreate
                data-cy="new-location-contact-name"
                size="small"
                label={t("Fleet_users_locations.Modal.contactName")}
                type="text"
                defaultValue={currentLocation?.primaryContactName}
                onChange={(e) =>
                  setNewValues({
                    ...editLocationNewValues,
                    primaryContactName: e.currentTarget.value,
                  })
                }
              />
            </FormControl>

            <FormControl fullWidth>
              <TextFieldInputEditCreate
                data-cy="new-location-email"
                type="email"
                size="small"
                label={t(
                  "Fleet_overview.overview_panel.createNewPosition.email"
                )}
                defaultValue={currentLocation?.email}
                onChange={(e) =>
                  setNewValues({
                    ...editLocationNewValues,
                    email: e.currentTarget.value,
                  })
                }
              />
            </FormControl>

            <FormControl fullWidth className="mb-5">
              <MuiTelInput value={telephone} onChange={handleChange} />
            </FormControl>
          </div>

          <div className="buttons-position-options">
            <ButtonCancelModals
              data-cy="btn-cancel"
              type="button"
              onClick={closeModal}
              disabled={loadingPatch}
              size="large"
              variant="outlined"
              className="mr-2"
            >
              {t("Fleet_overview.overview_panel.deleteLocation.cancel")}
            </ButtonCancelModals>

            <ButtonConfirmModals
              data-cy="btn-confirm"
              type="submit"
              variant="contained"
              disabled={
                loading ||
                (!name &&
                  !type &&
                  !primaryContactName &&
                  !email &&
                  !address &&
                  !notes && !telephone) ||
                loadingPatch
              }
            >
              {t("Fleet_users_locations.Modal.Button")}
            </ButtonConfirmModals>
          </div>
        </form>
      </div>
    </section>
  );

  return bodyUserLocation;
};
