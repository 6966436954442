import { createSlice } from "@reduxjs/toolkit";
import { DashboardLocIdInfo } from "../../actions/dashboard/dashboardTypes";
import { DataRow, DColumns } from "../../actions/data/DataDeclarations";
import { GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import {
  fetchSamples,
  newDataExport,
} from "../../actions/data/dataAsyncActions";

export type IdDataSBFilters = number | string | undefined;

export interface DataSBFilterValues {
  name: string;
  id: IdDataSBFilters;
}

export type Column2 = {
  name: string;
  localizedName?: string; // translated name
  filterOperators?: ("=" | "!=" | "<" | ">")[]; // allowed operators for filtering
  valueType: string; // 'string' or 'number' most of the time
  defaultHide: boolean; // display the column at first?
  alwaysHide?: boolean;
  hideable: boolean;
  sortable: boolean;
  isStatic: boolean;
  useShortResult?: boolean;
  isAnnotation: boolean;
};

export type DataTableRow = {
  pastActionId: string;
  pastActionCreationDate: string;
  sampleId: string;
  sampleRemoteId?: string;
  geoPointId?: number;
  geoPointName?: string;
  deviceName: string;
  username: string;
  actionId: string;
  actionName: string;
  cerealId: number;
  cerealName: string;
  userFacingDeletion: boolean;
  userFacingNonValidated: boolean;
  userHiddenSuspiciousData: boolean;
  payloads: {
    // the complete payload array, values of the expPred sections are also copied at the root of the object
    id: string;
    expPred: Record<string, string>;
    shortResult: string;
    displayStyle: string;
  }[];
  targetSample: {
    // here are the annotations (see next section
    id: string;
    annotations: {
      id: string;
      value: string;
      lt: {
        id: string;
        configs: {
          id: string;
          scopeOrgId: null | number;
          scopeScenarioId: null | number;
          scopeScenarioStepId: null | number;
          userFacingNameTextId: number;
        }[];
      };
    }[];
  };
};

export interface DataSBFiltersValues {
  locations: [
    {
      name: string;
      id: IdDataSBFilters;
    }
  ];
  actions: [
    {
      name: string;
      id: IdDataSBFilters;
    }
  ];

  users: [
    {
      name: string;
      id: IdDataSBFilters;
    }
  ];
}

export interface TableFilters {
  column: string;
  operator: string | undefined;
  values: string | string[];
  value: string | string[];
}

interface DetailsColumn {
  field: string;
  operators: string[];
  valueType: string;
  static: boolean;
  hideable: boolean;
  show: boolean;
  sorteable: boolean;
  alwaysHide: boolean;
  isAnnotation: boolean;
}

export interface EmailsSaved {
  email: string;
}

export interface DataSBFilters {
  inputSearch: string;
  cropsSelected: any[];
  locations: {
    valuesSelected: DashboardLocIdInfo[];
    allValuesSelected: boolean;
  };
  actions: {
    valuesSelected: DashboardLocIdInfo[];
    allValuesSelected: boolean;
  };
  users: {
    valuesSelected: DashboardLocIdInfo[];
    allValuesSelected: boolean;
  };
  filtersCrops: TableFilters | undefined;
  filtersLocation: TableFilters | undefined;
  filterActions: TableFilters | undefined;
  filterUsers: TableFilters | undefined;
  filterDate1: TableFilters | undefined;
  filterDate2: TableFilters | undefined;
  filtersSelectedAfterFetch: number;
}

export interface ColumnSwitchesTypes {
  name: string;
  id: string;
  isSelected: boolean;
}

export interface ViewsResults {
  total: number;
  limit: number;
  skip: number;
  data: any[];
}

export interface DataState {
  dataDateActivity: Date[];
  dataDateRangePickerVisible: boolean;
  dataExportModalVisible: boolean;
  dataExtraFiltersSBVisible: boolean;
  dataColumnsVisible: boolean;
  dataSBFilters: DataSBFilters;
  dataSBFilterValues: DataSBFiltersValues;
  columns: DColumns[];
  detailsColumn: DetailsColumn[];
  idColumn: DetailsColumn[];
  dataRows: DataRow[];
  columnsSearch: string;
  filterMap: Record<keyof DataTableRow, keyof DataTableRow>;
  columnsVisibility: GridColumnVisibilityModel;
  columnSwitches: any;
  columnsToExport: any;
  dataSamples: any;
  successExport: boolean;
  dataSamplesLoading: boolean;
  errorDataTable: string;
  tableOptions: {
    page: number;
    rowOptions: number;
    totalRows: number | null;
  };

  dataExportModalValues: {
    email: string;
    typeOfExport: string;
    typeOfExportSelected: string;
  };
  comesFromPageNot0: boolean;
  isRenderingSwitches: boolean;
  itRendersFromViews: boolean;
  viewValues: any;
  emailMenuList: EmailsSaved[];
  views: {
    viewsResults: ViewsResults;
    viewsModal: boolean;
    loadingViews: boolean;
    errorViews: string;
    viewCreated: boolean;
  };
}

export const initialState: DataState = {
  dataDateActivity: [
    new Date(new Date().setDate(new Date().getDate() - 7)),
    new Date(new Date().setDate(new Date().getDate())),
  ],
  dataDateRangePickerVisible: false,
  dataExportModalVisible: false,
  dataExtraFiltersSBVisible: false,
  dataColumnsVisible: false,
  dataSBFilters: {
    inputSearch: "",
    cropsSelected: [],
    filtersCrops: undefined,
    filtersLocation: undefined,
    filterActions: undefined,
    filterUsers: undefined,
    filterDate1: undefined,
    filterDate2: undefined,
    filtersSelectedAfterFetch: 0,
    locations: { valuesSelected: [], allValuesSelected: false },
    actions: {
      valuesSelected: [],
      allValuesSelected: false,
    },
    users: {
      valuesSelected: [],
      allValuesSelected: false,
    },
  },

  dataSBFilterValues: {
    locations: [{ name: "", id: undefined }],
    actions: [{ name: "", id: undefined }],
    users: [{ name: "", id: undefined }],
  },
  detailsColumn: [
    {
      field: "details",
      operators: [],
      valueType: "string",
      static: true,
      hideable: true,
      show: true,
      sorteable: false,
      alwaysHide: false,
      isAnnotation: false,
    },
  ],
  idColumn: [
    {
      field: "id",
      operators: [],
      valueType: "string",
      static: true,
      hideable: true,
      show: true,
      sorteable: false,
      alwaysHide: true,
      isAnnotation: false,
    },
  ],
  columns: [],
  dataRows: [],
  columnsSearch: "",
  filterMap: {} as any,
  columnsVisibility: {},
  columnSwitches: [],
  columnsToExport: [],
  dataSamplesLoading: false,
  dataSamples: null,
  successExport: false,
  errorDataTable: "",
  dataExportModalValues: {
    email: "",
    typeOfExport: "",
    typeOfExportSelected: "",
  },
  tableOptions: {
    page: 0,
    rowOptions: 25,
    totalRows: null,
  },
  comesFromPageNot0: false,
  isRenderingSwitches: false,
  itRendersFromViews: false,
  viewValues: undefined,
  emailMenuList: [],
  views: {
    viewsResults: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
    },
    viewsModal: false,
    loadingViews: false,
    errorViews: "",
    viewCreated: false,
  },
};

export const DataSlice = createSlice({
  name: "DataReducer",
  initialState,
  reducers: {
    setEmailMenuList(state, actions) {
      return { ...state, emailMenuList: actions.payload };
    },
    setViewsValues(state, actions) {
      return { ...state, views: actions.payload };
    },
    presetViewValues(state, actions) {
      return { ...state, viewValues: actions.payload };
    },
    isRenderingActionFromViews(state, actions) {
      return { ...state, itRendersFromViews: actions.payload };
    },
    setRenderSwitches(state, actions) {
      return { ...state, isRenderingSwitches: actions.payload };
    },
    setComesfromPageNot0(state, actions) {
      return { ...state, comesFromPageNot0: actions.payload };
    },

    disableErrorsDataTable(state) {
      return { ...state, errorDataTable: "" };
    },
    setFilterMapValues(state, action) {
      return {
        ...state,
        filterMap: action.payload,
      };
    },
    setTableDataOptions(state, action) {
      return {
        ...state,
        tableOptions: action.payload,
      };
    },

    setColumnsToExport(state, action) {
      return { ...state, columnsToExport: action.payload };
    },
    setColumnsVisibilitySwitches(state, action) {
      return { ...state, columnSwitches: action.payload };
    },

    setDataRows(state, action) {
      return { ...state, dataRows: action.payload };
    },
    setColumns(state, action) {
      return { ...state, columns: action.payload };
    },

    setColumnsSearch(state, action) {
      return { ...state, columnsSearch: action.payload };
    },

    setColumnVisibilityModel(state, action) {
      return { ...state, columnsVisibility: action.payload };
    },

    setDateDataActivity(state, action) {
      return {
        ...state,
        dataDateActivity: action.payload,
      };
    },

    setVisibleDataRangeDatePicker(state, action) {
      return {
        ...state,
        dataDateRangePickerVisible: action.payload,
      };
    },

    setExportModalVisibility(state, action) {
      return {
        ...state,
        dataExportModalVisible: action.payload,
      };
    },

    setExportModalValues(state, action) {
      return {
        ...state,
        dataExportModalValues: action.payload,
      };
    },

    setDataColumnsVisible(state, action) {
      return {
        ...state,
        dataColumnsVisible: action.payload,
      };
    },
    setSBExtraFiltersVisible(state, action) {
      return {
        ...state,
        dataExtraFiltersSBVisible: action.payload,
      };
    },
    setExtraFiltersValuesAutocomplete(state, action) {
      return {
        ...state,
        dataSBFilters: action.payload,
      };
    },

    setExtraFiltersValues(state, action) {
      return {
        ...state,
        dataSBFilterValues: action.payload,
      };
    },

    logoutData() {
      return initialState;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchSamples.pending, (state) => {
        state.dataSamplesLoading = true;
        state.errorDataTable = "";
      })
      .addCase(fetchSamples.fulfilled, (state, actions) => {
        state.dataSamplesLoading = false;
        state.dataSamples = actions.payload;
      })
      .addCase(fetchSamples.rejected, (state, actions) => {
        state.dataSamplesLoading = false;
        state.errorDataTable = actions.payload as string;
      });
    builder
      .addCase(newDataExport.pending, (state) => {
        state.dataSamplesLoading = true;
        state.errorDataTable = "";
      })
      .addCase(newDataExport.fulfilled, (state) => {
        state.dataSamplesLoading = false;
        state.successExport = true;
      })
      .addCase(newDataExport.rejected, (state, actions) => {
        state.dataSamplesLoading = false;
        state.successExport = false;
        state.errorDataTable = actions.payload as string;
      });
  },
});

export const {
  setEmailMenuList,
  setViewsValues,
  isRenderingActionFromViews,
  presetViewValues,
  setRenderSwitches,
  setComesfromPageNot0,
  setColumnsToExport,
  disableErrorsDataTable,
  setFilterMapValues,
  setTableDataOptions,
  setColumnsVisibilitySwitches,
  setDataRows,
  setColumns,
  setDateDataActivity,
  setVisibleDataRangeDatePicker,
  setExportModalVisibility,
  setExportModalValues,
  setSBExtraFiltersVisible,
  setExtraFiltersValuesAutocomplete,
  setExtraFiltersValues,
  setDataColumnsVisible,
  setColumnVisibilityModel,
  setColumnsSearch,
  logoutData,
} = DataSlice.actions;

export const Data = DataSlice.reducer;
