/**
 * @author David Roman <david@inarix.com>
 * @file Login component
 * @desc Created on 2023-08-17 11:28:44 am
 * @copyright Inarix
 */
import React, { FC, FormEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { State } from "../../redux/slices/index";
import validator from "validator";

import {
  setUsername as loginSetUsername,
  setPassword as loginSetPassword,
  resetState as loginResetState,
  disableErrorLogin,
  setRememberMe,
  setEmailSent,
  setResetPasswordSuccess,
  setNewPassword,
} from "../../redux/slices/authentication/authentication";

import { signIn as loginSignin } from "../../redux/actions/authentication/authentication";
import NewInarixIcon from "../../assets/images/Union.svg";

import { Button, IconButton, InputAdornment, TextField } from "@mui/material";

import pjson from "../../../package.json";

import { LoadingCircularProgress } from "../Loading";
import { setLocale } from "../../redux/slices/layout";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { SelectLanguage } from "./Login";

const SetActionsAlpha = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setNewPassword: (newPassword: string) =>
      dispatch(setNewPassword(newPassword)),
  };
};

const helpersLocalStorageItems = (
  password: string,
  username: string,
  rememberMe: boolean,
  emails: string[]
) => {
  localStorage.setItem("password", password);
  localStorage.setItem("username", username);
  localStorage.setItem("rememberme", `${rememberMe}`);
  if (!emails) {
    localStorage.setItem("emails", `[]`);
  }
};

type LoginFormProps = {
  submitHandler: (e: FormEvent) => void;
  checkBox: (value: boolean) => void;
  error: string;
  username: string;
  password: string;
  rememberMe: boolean;
  loading: boolean;
  setUsername: (value: string) => void;
  setPassword: (value: string) => void;
  errorAuthenticated: number | null;
  resetPaswordSuccess: boolean;
};

//  Login v2

const ActivateAccount: FC<LoginFormProps> = ({
  submitHandler,
  username,
  password,
  loading,
  rememberMe,
  setUsername,
  setPassword,
  checkBox,
  error,
  errorAuthenticated,
  resetPaswordSuccess,
}) => {
  const [t] = useTranslation("global");
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorInput, setErrorInput] = React.useState(false);
  const { setNewPassword } = SetActionsAlpha();
  const navigate = useNavigate();

  const { newPassword } = useSelector((state: State) => state.Authentication);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const validate = (value: string) => {
    setNewPassword(value);
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrorInput(false);
      setErrorMessage(t("Recover_pass.is_strong"));
    } else {
      setErrorInput(true);
      setErrorMessage(t("Recover_pass.is_not_strong"));
    }
  };

  if (resetPaswordSuccess) {
    navigate(`/login`);
  }

  return (
    <div className="login-v2-container">
      <div className="login-form">
        <div className="select-language-div">
          <SelectLanguage />
        </div>
        <div className="inarix-icon-position">
          <img
            data-cy="brand-logo-container"
            src={NewInarixIcon}
            className="inarix-icon-design"
            alt="inarix-icon"
          />

          <div className="form-design">
            <h4 className="title-form-login">{t("Login.Welcome_portal")}</h4>
            <div className="subtitle-form-login">
              {t("Login.activate_account")}
            </div>
            <form className="form-login-data" onSubmit={submitHandler}>
              <TextField
                fullWidth
                size="small"
                type={showPassword ? "text" : "password"}
                data-cy="password"
                className="input-user-pass mt-4"
                label={t("Recover_pass.your_new")}
                value={newPassword}
                onChange={(e) => validate(e.currentTarget.value)}
                error={errorInput}
                helperText={errorMessage}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {!showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
              <span className="info-password">
                {t("Recover_pass.password_desc")}
              </span>
              <div className="buttons-container-recover">
                {!loading ? (
                  <Button
                    variant="contained"
                    data-cy="login"
                    type="submit"
                    disabled={errorInput || newPassword.length < 8}
                    className="mt-4 button-recover-pass"
                    style={{ textTransform: "none" }}
                  >
                    {t("Recover_pass.save_log")}
                  </Button>
                ) : (
                  <LoadingCircularProgress />
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="version-position">{pjson.version}</div>
      </div>
      <div className="img-form" />
    </div>
  );
};

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setUsername: (value: string) => dispatch(loginSetUsername(value)),
    setPassword: (value: string) => dispatch(loginSetPassword(value)),
    disableError: () => dispatch(disableErrorLogin()),
    setEmailSent: (setEmail: boolean) => dispatch(setEmailSent(setEmail)),
    signIn: () => {
      dispatch(loginSignin());
    },
    setLocaleLanguage: (locale: string) => dispatch(setLocale(locale)),
    resetState: () => dispatch(loginResetState()),
    setRememberme: (rememberme: boolean) => {
      dispatch(setRememberMe(rememberme));
    },
    setResetPasswordSuccess: (isSuccess: boolean) =>
      dispatch(setResetPasswordSuccess(isSuccess)),
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Activate = () => {
  const rememberme = localStorage.getItem("rememberme");
  const passwordAlpha = localStorage.getItem("password");
  const usernameAlpha = localStorage.getItem("username");
  const emails = JSON.parse(localStorage.getItem("emails") as string);

  const {
    username,
    password,
    loading,
    rememberMe,
    error,
    errorAuthenticated,
    resetPaswordSuccess,
  } = useSelector((state: State) => state.Authentication);

  const language = localStorage.getItem("i18nextLng");
  const {
    setUsername,
    setPassword,
    signIn,
    setRememberme,
    disableError,
    setLocaleLanguage,
    resetState,
    setEmailSent,
    setResetPasswordSuccess,
  } = SetActions();

  const transformRememberme = Boolean(rememberme);

  const check = () => {
    if (!rememberMe) {
      return setRememberme(true);
    }
    return setRememberme(false);
  };

  if (error || errorAuthenticated) {
    setTimeout(() => {
      disableError();
    }, 5000);
  }

  if (resetPaswordSuccess) {
    setTimeout(() => {
      setResetPasswordSuccess(false);
    }, 5000);
  }
  useEffect(() => {
    resetState();
    setEmailSent(false);
    if (!transformRememberme) {
      setUsername("");
      setPassword("");
      setRememberme(false);
    } else {
      setUsername(usernameAlpha ?? "");
      setPassword(passwordAlpha ?? "");
      setRememberme(true);
    }

    setLocaleLanguage(language ?? "fr");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    signIn();
    helpersLocalStorageItems(password, username, rememberMe, emails);
  };

  return (
    <ActivateAccount
      submitHandler={submitHandler}
      error={error}
      username={username}
      password={password}
      loading={loading}
      setUsername={setUsername}
      setPassword={setPassword}
      rememberMe={rememberMe}
      checkBox={check}
      errorAuthenticated={errorAuthenticated}
      resetPaswordSuccess={resetPaswordSuccess}
    />
  );
};

export default Activate;
