import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/slices";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { StepZero } from "./steps/StepZeroCreateLoc";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  CreateLocationStep,
  setCreateLocationStep,
} from "../../redux/slices/location/createLocation";
import StepTwoOptionB from "./steps/StepOneCreateLocB";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setSteps: (value: CreateLocationStep) => {
      dispatch(setCreateLocationStep(value));
    },
  };
};

const MenuCreateLocation = (
  createLocationStep: CreateLocationStep,
  t: (arg0: string) => string,
  setSteps: (value: CreateLocationStep) => void
) => {
  return (
    <div className="top-options-create-locations">
      <div className="spacer-div-back-button">
        {createLocationStep.step === 0 ? (
          <div />
        ) : (
          <Button
            sx={{
              fontSize: "14px",
              textTransform: "none",
              color: "#232128",
            }}
            onClick={() =>
              setSteps({
                ...createLocationStep,
                step: createLocationStep.step - 1,
              })
            }
            startIcon={<ArrowBackIcon />}
            variant="text"
          >
            {t("Common_options.Back")}
          </Button>
        )}
      </div>
      <div className="create-loc-title"> {t("Create_site.Create_site")}</div>
      <div>
        <Button
          sx={{
            fontSize: "14px",
            textTransform: "none",
            color: "#232128",
          }}
          variant="text"
          endIcon={<CloseIcon />}
        >
          {t("Common_options.Cancel")}
        </Button>
      </div>
    </div>
  );
};

const CreateLocation: FC<any> = () => {
  const [t, i18n] = useTranslation("global");
  const { setSteps } = SetActions();
  const { createLocationStep } = useSelector(
    (state: State) => state.CreateLocationReducer
  );

  return (
    <div className="create-sites-container">
      {MenuCreateLocation(createLocationStep, t, setSteps)}
      {createLocationStep.step === 0 ? <StepZero /> : ""}
      {createLocationStep.step === 1 && createLocationStep.option === "b" ? (
        <StepTwoOptionB />
      ) : (
        ""
      )}
    </div>
  );
};

export default CreateLocation;
