import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import moment from "moment";
import "moment/min/locales";

import GetAppIcon from "@mui/icons-material/GetApp";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  GridFilterInputValueProps,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { DColumns } from "../../../redux/actions/data/DataDeclarations";
import { State } from "../../../redux/slices";
import { downloadFileProjects } from "../../../redux/actions/projects/ProjectsAsyncActions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { ProjectFile } from "../../../redux/actions/projects/projectsTypes";
import { localeSelected } from "../../map/mapActivityRangePicker/MapActivityRangePicker";
import dayjs from "dayjs";
import { IconButton, Tooltip } from "@mui/material";
import { inarixColors } from "inarix-theme";
import {
  setConfirmModal,
  setInfoConfirmModal,
} from "../../../redux/slices/layout";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    downloadFile: (token: string, projectFileId: string) =>
      dispatch(downloadFileProjects({ token, projectFileId })),

    prepareValuesToRemoveFile: (info: any) => {
      dispatch(setConfirmModal(true));
      dispatch(setInfoConfirmModal(info));
    },
  };
};

const DateInputValue = (props: GridFilterInputValueProps) => {
  const [t] = useTranslation("global");
  const { locale } = useSelector((state: State) => state.LayoutReducer);
  const { filters } = useSelector((state: State) => state.ProjectsReducer);
  const { item, applyValue } = props;
  const datePicked = moment(item.value);
  datePicked.locale(locale);

  const handleDateChange = (date: Date) => {
    applyValue({ ...item, value: date });
  };

  return (
    <LocalizationProvider
      adapterLocale={localeSelected(locale) as unknown as string}
      dateAdapter={AdapterDayjs}
    >
      <Grid
        container
        sx={{
          justifyContent: "space-around",
        }}
      >
        <DatePicker
          label={t("Projects.Table_project.select_date")}
          slotProps={{ textField: { variant: "standard" } }}
          value={filters.items.length > 0 ? dayjs(item.value) : null}
          onChange={(newValue: any) => {
            handleDateChange(newValue as Date);
          }}
        />
      </Grid>
    </LocalizationProvider>
  );
};

const ProjectsTableContent = (token: string) => {
  const [t] = useTranslation("global");

  const { downloadFile, prepareValuesToRemoveFile } = SetActions();
  const { projectsInfo } = useSelector((state: State) => state.ProjectsReducer);

  const columnNames = {
    userFileName: t("Projects.Table_project.File_name"),
    createdAt: t("Projects.Table_project.Date_upload"),
    id: t("Projects.Table_project.Download"),
  };

  const rawColumns = [
    {
      columnField: "userFileName",
      sortable: false,
    },
    {
      columnField: "createdAt",
      operators: [">", "<", "=", "=<"],
      sortable: true,
    },

    {
      columnField: "id",
      sortable: true,
    },
  ] as DColumns[];

  let columns: any[] = rawColumns?.map((column: DColumns) => {
    if (column.columnField === "createdAt") {
      return {
        headerName: (columnNames as never)[column.columnField],
        field: column.columnField,
        width: column.width,

        filterable: column.operators.length > 0,
        sortable: column.sortable,
        static: column.static,

        filterOperators: column.operators?.map((x) => ({
          label: x,
          value: x,

          getApplyFilterFn: (filterItem: any) => {
            if (
              !filterItem.columnField ||
              !filterItem.value ||
              !filterItem.operatorValue
            ) {
              return null;
            }
            return (params: { value: any }): boolean => {
              return Number(params.value) >= Number(filterItem.value);
            };
          },
          InputComponent: DateInputValue,
          InputComponentProps: { type: "date" },
        })),

        renderCell: (params: GridRenderCellParams) => {
          const createdAt = params.value;
          const deviceAllotmentdate = moment(createdAt);
          const language = localStorage.getItem("i18nextLng");
          deviceAllotmentdate.locale(language as string);
          const time = deviceAllotmentdate.format("l, LTS");

          const timeC = () => <span>{time}</span>;
          return timeC();
        },
      };
    }

    if (column.columnField === "id") {
      return {
        headerName: (columnNames as never)[column.columnField],
        field: column.columnField,
        flex: 1,
        minWidth: 50,
        sortable: false,
        filterable: false,

        renderCell: (params: GridRenderCellParams) => {
          const projectId = params.value;

          const chipIdDevice = () => {
            const idConsole = () => downloadFile(token, projectId);
            const deleteProject = () =>
              prepareValuesToRemoveFile({
                projectFileId: projectId,
                name: params.row.userFileName,
              });
            return (
              <div>
                <Tooltip title={t("Common_options.Download")} arrow>
                  <IconButton
                    sx={{
                      color: inarixColors.main_inarix,
                      background: "#ffff",
                      boxShadow: `0 5px 15px -5px ${inarixColors.box_shadow_components}`,
                      marginRight: "5px",
                      "&:hover": {
                        color: inarixColors.light_inarix,
                      },
                    }}
                    onClick={idConsole}
                  >
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title={t("Common_options.Delete")} arrow>
                  <IconButton
                    sx={{
                      color: inarixColors.modal_danger_color,
                      background: "#ffff",
                      boxShadow: `0 5px 15px -5px ${inarixColors.box_shadow_components}`,
                      marginRight: "5px",
                      "&:hover": {
                        color: inarixColors.notification_color,
                      },
                    }}
                    onClick={() => deleteProject()}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
            );
          };
          return chipIdDevice();
        },
      };
    }

    return {
      field: column.columnField,
      headerName: (columnNames as never)[column.columnField]
        ? (columnNames as never)[column.columnField]
        : column.columnName,
      flex: 1,
      minWidth: 50,
      sortable: column.sortable,
      filterable: false,
    };
  });

  const projectsRows = projectsInfo?.map((projects: ProjectFile) => {
    const rows = {
      userFileName: projects.userFileName,
      createdAt: projects.createdAt,
      id: projects.id,
    };

    return rows;
  });

  let projectsRowsSorted = projectsRows?.toSorted(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  if (!columns) {
    columns = [];
  }

  if (!projectsRowsSorted) {
    projectsRowsSorted = [];
  }

  return {
    columns,
    projectsRowsSorted,
  };
};

export default ProjectsTableContent;
