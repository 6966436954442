import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import Chip from "@mui/material/Chip";

import { useStyles } from "../../modals/modalStyles";
import { handlingSizeOptionsModal } from "../../Helpers";

import { ButtonCancelModals, ButtonConfirmModals } from "../UserTabsStyles";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { State } from "../../../redux/slices";
import { UserData } from "../../../redux/actions/users/userTypes";
import {
  setGroupId,
  setLocationModalUsers,
} from "../../../redux/slices/fleet/users";

import {
  fetchUsersNewRequest,
  modifyAllUserToLoc,
} from "../../../redux/actions/users/userAsyncActions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { OrgGeoPointData } from "../../../redux/actions/locations/locationsTypes";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    listUsersInfo: (token: string) => dispatch(fetchUsersNewRequest(token)),

    closeModal: () => {
      dispatch(setLocationModalUsers(false));
      setTimeout(() => {
        dispatch(setGroupId(0));
      }, 1000);
    },

    setIdLocationGroupId: (id: number) => {
      dispatch(setGroupId(id));
    },

    modifyUserLocation: (token: string) => {
      dispatch(modifyAllUserToLoc(token));
    },
  };
};

export const BodyUserLocation = (
  token: string,
  typeOfRequest: string,
  locations: any
): JSX.Element => {
  const { users, groups, usersSelected, groupId, loading } = useSelector(
    (state: State) => state.UsersReducer
  );

  const addLoc = "addLoc";
  const removeLoc = "removeLoc";
  const editLoc = "editLoc";

  const [t] = useTranslation("global");
  const { closeModal, setIdLocationGroupId, modifyUserLocation } = SetActions();

  const classes = useStyles();

  const title = () => {
    if (typeOfRequest === addLoc) return t("Fleet_users.options.add_loc_user");

    if (typeOfRequest === removeLoc)
      return t("Fleet_users.options.remove_loc_user");

    if (typeOfRequest === editLoc) return t("Fleet_users.options.edit");
  };

  const moveButton = () => {
    if (typeOfRequest === addLoc) return t("Common_options.Add");

    if (typeOfRequest === removeLoc) return t("Common_options.Remove");

    if (typeOfRequest === editLoc) return t("Common_options.Edit");
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    closeModal();
    modifyUserLocation(token);
  };

  const userIdChips = usersSelected.map((userId: string) => (
    <div key={userId}>
      {users.data.map((u: UserData) => {
        if (u.id === userId) {
          return (
            <div key={userId}>
              <Chip label={u.username} variant="outlined" className="mb-3" />
            </div>
          );
        }
        return "";
      })}
    </div>
  ));

  const locationsOptionsAutocomplete = locations;

  return (
    <div className={classes.confirmModal}>
      <h5 data-cy="modal-move-loc-userusers" className="general-modal-title">
        {title()} :
      </h5>

      <br />

      <div
        data-cy="modal-move-users-chips"
        className={handlingSizeOptionsModal(userIdChips)}
      >
        {userIdChips}
      </div>
      <br />
      <h6 data-cy="modal-move-users-total-groups" className="mb-3">
        {t("Fleet_users.options.locations")}: {groups.total}
      </h6>

      <br />

      <Autocomplete
        disablePortal
        data-cy="btn-select"
        id="combo-box-demo"
        options={
          locationsOptionsAutocomplete || []
        }
        getOptionLabel={(option: OrgGeoPointData) => option.name}
        sx={{ width: 300 }}
        disabled={typeOfRequest === removeLoc}
        onChange={(e, value) => setIdLocationGroupId(value?.id as number)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("Fleet_users.options.select_location")}
          />
        )}
      />
      <br />
      <hr />

      <div className="buttons-position-options">
        <ButtonCancelModals
          data-cy="btn-cancel"
          type="button"
          onClick={closeModal}
          disabled={loading}
          size="large"
          variant="outlined"
          className="mr-2"
        >
          {t("Fleet_overview.overview_panel.deleteLocation.cancel")}
        </ButtonCancelModals>

        <ButtonConfirmModals
          data-cy="btn-confirm"
          type="submit"
          variant="contained"
          disabled={
            (!groupId && typeOfRequest !== removeLoc) ||
            loading ||
            usersSelected.length < 1
          }
          onClick={(e: any) => submitHandler(e)}
        >
          {moveButton()}
        </ButtonConfirmModals>
      </div>
    </div>
  );
};
