import * as React from "react";
import { inarixColors } from "inarix-theme";

function Pin() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="-80 -100 200 200"
      style={{
        fill: inarixColors.main_inarix,
        stroke: "none",
        marginLeft: "-15px",
        marginTop: "-25px",
      }}
    >
      <circle cx="0" cy="20" r="70" fill={inarixColors.main_inarix} />
    </svg>
  );
}

export default React.memo(Pin);
