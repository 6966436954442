import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../layout/Layout";
import { useTranslation } from "react-i18next";
import { UserLocationsTable } from "./UserLocationTable";
import { State } from "../../redux/slices";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { setNumberRowsUsers } from "../../redux/slices/fleet/users";
import { GridPaginationModel } from "@mui/x-data-grid";
import { Modal } from "@mui/material";
import {
  cleanUserLocationMessage,
  initialState,
  setModalLocations,
  setNewValuesUpdateLocation,
} from "../../redux/slices/fleet/locations";
import { errorsGeneralModal } from "../ErrorModal";
import { fetchLocationsWithActivity } from "../../redux/actions/map/mapAsyncActions";
import { setTabNameLayout } from "../../redux/slices/layout";
import NotFound from "../NotFound";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    locationsInit: (token: string) => {
      dispatch(fetchLocationsWithActivity(token));
      dispatch(setTabNameLayout("/locations"));
      dispatch(setNewValuesUpdateLocation(initialState.editLocation));
      dispatch(setModalLocations(false));
      dispatch(cleanUserLocationMessage());
    },
    setRowsNumber: (rows: GridPaginationModel) =>
      dispatch(setNumberRowsUsers(rows)),
    disableError: () => dispatch(cleanUserLocationMessage()),
  };
};

const UserLocations: FC = () => {
  const [t] = useTranslation("global");
  const { setRowsNumber, disableError, locationsInit } = SetActions();
  const { user, isViewerOnly } = useSelector(
    (state: State) => state.Authentication
  );
  const { sectionName } = useSelector((state: State) => state.LayoutReducer);
  const { error, msg, locationCurrentName, editLocation } = useSelector(
    (state: State) => state.LocationsReducer
  );

  const token = user?.token ?? "";
  const bodyErrors = errorsGeneralModal(JSON.stringify(error), disableError, t);

  useEffect(() => {
    locationsInit(token);
  }, []);

  const globalComponentsUserLocations = () => (
    <>
      <div className="fleet-table-container">
        <div>
          {UserLocationsTable(
            t,
            token,
            setRowsNumber,
            msg,
            sectionName,
            locationCurrentName,
            editLocation
          )}
        </div>
      </div>
      <Modal open={!!error}>{bodyErrors}</Modal>
    </>
  );

  return (
    <Layout
      title={!isViewerOnly ? t("Fleet_users_locations.My_locations") : ""}
      component={!isViewerOnly ? globalComponentsUserLocations() : <NotFound />}
    />
  );
};

export default UserLocations;
