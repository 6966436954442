import { FC } from "react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { setOpenCloseModalPhotos } from "../../../redux/slices/data/dataSampleView";
import { State } from "../../../redux/slices";
import { v4 as uuidv4 } from "uuid";
import { getLocalizedTextByLocale } from "../../../utils/localized-text";
import moment from "moment";
import { useTranslation } from "react-i18next";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setOpenCloseModalPhotos: (visible: boolean) => {
      dispatch(setOpenCloseModalPhotos(visible));
    },
  };
};

const DataSampleViewLeft: FC = () => {
  const [t] = useTranslation("global");
  const { photos, sampleViewInfo } = useSelector(
    (state: State) => state.DataSampleViewReducer
  );
  const { locale } = useSelector((state: State) => state.LayoutReducer);
  const { setOpenCloseModalPhotos } = SetActions();

  const names = sampleViewInfo?.scenarioInstance?.scenario.cerealType.names;

  const creator = sampleViewInfo?.creator ? (
    <span className="creator-action-details">
      {sampleViewInfo.creator?.username}
    </span>
  ) : (
    <span className="creator-action-details">
      {t("Data.SampleView.No_user_info")}
    </span>
  );
  const createdAt = moment(sampleViewInfo?.createdAt);
  createdAt.locale(locale);
  const createdAtModify = createdAt.format("l, LT");
  const locationName = sampleViewInfo?.geoPoint?.name
    ? sampleViewInfo?.geoPoint?.name
    : t("Data.table.No_geo_point");
  const deviceName = sampleViewInfo?.device?.orgInternalId;

  return (
    <div className="details-left-side">
      <div className="origine">
        <div className="title-sections-sample-view">{t("Data.Origin")}</div>
        <div className="action-container">
          <div
            className="action-card"
            style={{
              backgroundColor:
                sampleViewInfo?.scenarioInstance?.scenario.cerealType
                  .iconBackgroundHexColor,
            }}
          >
            <div className="scenario-cereal-type-name">
              {getLocalizedTextByLocale(locale, names)?.name}
            </div>
            <div className="scenario-info-card">
              {sampleViewInfo?.scenarioInstance?.scenario?.name},
              {sampleViewInfo?.scenarioInstance?.scenario?.description}
            </div>
          </div>
          <div className="action-info">
            {creator}
            <br />
            {createdAtModify}
            <br />
            <span>{locationName}</span>
            <br />
            <span>{deviceName}</span>
          </div>
        </div>
      </div>

      <div className="photos-container">
        <div className="title-sections-sample-view">{t("Data.Photos")}</div>

        <div className="photos-actions">
          {photos.map((photo: { signedRequest: string }) => (
            <button
              key={`${photo.signedRequest}${uuidv4()}`}
              className="photos-actions-2"
              onClick={() => setOpenCloseModalPhotos(true)}
            >
              <img
                className="photo-style-cereal"
                src={photo.signedRequest}
                alt="cereal"
              />
              <div className="overlay">
                <div className="text">
                  <ZoomInIcon fontSize="large" />
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DataSampleViewLeft;
