import { FC, FormEvent } from "react";

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";
import { GroupInfoData, UserData } from "../../redux/actions/users/userTypes";
import { AccordionSummaryInarix, useStyles } from "./UserTabsStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { inarixColors } from "inarix-theme";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import { TokenPayload } from "../../redux/actions/authentication/authentication";

import jwt from "jsonwebtoken";
import { useDispatch } from "react-redux";
import {
  setConfirmModal,
  setInfoConfirmModal,
} from "../../redux/slices/layout";
import { setGroupId } from "../../redux/slices/fleet/users";
import { skeletonGroupsActionsAccordions } from "../Helpers";

type ChipFormProps = {
  id: string | number;
  username: string;
  submitHandler: (e: FormEvent) => void;
};

const SetActions = () => {
  const dispatch = useDispatch();
  return {
    prepareValuesToRemoveUser: (info: any, groupId: number) => {
      dispatch(setConfirmModal(true));
      dispatch(setInfoConfirmModal(info));
      dispatch(setGroupId(groupId));
    },
  };
};

export const ChipBody: FC<ChipFormProps> = ({
  id,
  username,
  submitHandler,
}: ChipFormProps) => {
  return (
    <div key={id}>
      <div>
        <Chip
          size="small"
          label={username}
          variant="outlined"
          onDelete={submitHandler}
        />
      </div>
    </div>
  );
};

const GroupAccordion = (
  token: string,
  t: (arg0: string) => string,
  users: any,
  groups: any,
  loading: boolean,
  loadingDelete: boolean
) => {
  const { prepareValuesToRemoveUser } = SetActions();
  const { realmId }: TokenPayload = jwt.decode(token) as TokenPayload;

  const uniqueGroups: GroupInfoData[] = [];
  users.data.forEach((user: UserData) => {
    user.groups?.forEach((group: GroupInfoData) => {
      if (
        !uniqueGroups.some(
          (uniqueGroups: GroupInfoData) => uniqueGroups?.name === group?.name
        )
      ) {
        uniqueGroups.push(group);
      }
    });
  });

  const group = users?.data?.map((i: UserData) =>
    i.groups?.map((groupInfoA: GroupInfoData) => groupInfoA?.name)
  );
  const filterGroups = group.flat();
  const filtered = filterGroups.filter(function (el: string | null) {
    return el != null;
  });
  const unique = [...(new Set(filtered) as never)];

  const classes = useStyles();
  const usersInGroups = unique.map((o: string) =>
    users.data.filter((user: UserData) =>
      user.groups?.some((group: GroupInfoData) => group?.name === o)
    )
  );

  const stylesLength = () => {
    if (usersInGroups.length < 5) {
      return classes.root2;
    } else {
      return classes.root;
    }
  };

  const nomGroup = uniqueGroups.map((group: GroupInfoData) => group?.name);

  const hasAdminsRights = (groupInfo: GroupInfoData) => {
    if (
      groupInfo?.hasAdminAccessRi ||
      groupInfo?.hasAdminAccessRights === true
    ) {
      return (
        <Tooltip title={t("Fleet_users.icon-description.Admin")} arrow>
          <IconButton
            size="medium"
            sx={{ color: inarixColors.primary_text_inarix }}
          >
            <AdminPanelSettingsIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const hasPortalRights = (groupInfo: GroupInfoData) => {
    if (
      groupInfo?.hasPortalAccessR ||
      groupInfo?.hasPortalAccessRights === true
    ) {
      return (
        <Tooltip title={t("Fleet_users.icon-description.Portal")} arrow>
          <IconButton
            size="medium"
            sx={{ color: inarixColors.primary_text_inarix }}
          >
            <PersonalVideoIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const hasAppRight = (groupInfo: GroupInfoData) => {
    if (groupInfo?.hasAppAccessRigh || groupInfo?.hasAppAccessRights === true) {
      return (
        <Tooltip title={t("Fleet_users.icon-description.PocketLab")} arrow>
          <IconButton
            size="medium"
            sx={{ color: inarixColors.primary_text_inarix }}
          >
            <SmartphoneIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const iconBucket = (groupInfo: GroupInfoData) => (
    <div className="icon-wrap">
      {hasAdminsRights(groupInfo)}
      {hasAppRight(groupInfo)}
      {hasPortalRights(groupInfo)}
    </div>
  );

  const newUniqueGroups = uniqueGroups?.filter((n) => n);

  return (
    <div className={stylesLength()}>
      {!loading || !loadingDelete
        ? newUniqueGroups.map((groupInfoB: GroupInfoData, ind: number) => {
            return (
              <div key={groupInfoB.id}>
                <Accordion data-cy="groups-accordion">
                  <AccordionSummaryInarix
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <AccordionActions className={classes.heading}>
                      {groupInfoB?.name} ({usersInGroups[ind]?.length})
                      {iconBucket(groupInfoB)}
                    </AccordionActions>
                  </AccordionSummaryInarix>
                  <AccordionDetails>
                    <AccordionActions className={classes.chips}>
                      {usersInGroups[ind]?.map((user: UserData) => {
                        return (
                          <div key={user.id}>
                            <ChipBody
                              id={user.id}
                              username={user.username}
                              submitHandler={() =>
                                prepareValuesToRemoveUser(
                                  { user, groups: groupInfoB.name },
                                  groupInfoB.id
                                )
                              }
                            />
                          </div>
                        );
                      })}
                    </AccordionActions>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })
        : skeletonGroupsActionsAccordions()}

      {groups.data.map((groups: GroupInfoData, ind: number) => {
        if (
          groups.name !== nomGroup.find((d: string) => d === groups.name) &&
          groups.orgId === realmId
        )
          return (
            <div key={groups.id}>
              <Accordion>
                <AccordionSummaryInarix
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <AccordionActions className={classes.heading}>
                    {groups.name}
                    <span className="ml-2"> (0)</span> {iconBucket(groups)}
                  </AccordionActions>
                </AccordionSummaryInarix>
              </Accordion>
            </div>
          );
      })}
    </div>
  );
};

export default GroupAccordion;
