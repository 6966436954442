import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { Button, FormControl, TextField } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  ColumnSwitchesTypes,
  setColumnVisibilityModel,
  setColumnsSearch,
  setColumnsToExport,
  setColumnsVisibilitySwitches,
  setRenderSwitches,
} from "../../../redux/slices/data/data";
import { State } from "../../../redux/slices";
import tableDataColumns from "../DataFetchingColumns";
import { DColumns } from "../../../redux/actions/data/DataDeclarations";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setColumnVModel: (model: GridColumnVisibilityModel) => {
      dispatch(setColumnVisibilityModel(model));
    },
    setColumnSearch: (value: string) => {
      dispatch(setColumnsSearch(value));
    },

    setColumnsVisibility: (columns: any) => {
      dispatch(setColumnsVisibilitySwitches(columns));
      dispatch(setColumnsToExport(columns));
    },

    SetRenderingSwitches: (isRendering: boolean) =>
      dispatch(setRenderSwitches(isRendering)),
  };
};

export type FN = (arg: string) => string;
export type RFN<F extends FN> = Readonly<FN> & F;

export default function ColumnSwitches(t: RFN<FN>, token: string) {
  const { columnsSearch, columnSwitches, isRenderingSwitches } = useSelector(
    (state: State) => state.DataReducer
  );
  const { columnsValues, columnSwitchesValues } = tableDataColumns(t, token);

  const {
    setColumnVModel,
    setColumnSearch,
    setColumnsVisibility,
    SetRenderingSwitches,
  } = SetActions();
  const [run, setRun] = React.useState(false);

  React.useEffect(() => {
    if (!isRenderingSwitches) {
      setTimeout(() => {
        setRun(true);
        setTimeout(() => {
          setRun(false);
        }, 1000);
      }, 3000);
    } else {
      return () => {};
    }

    if (!isRenderingSwitches) {
      setColumnsVisibility(
        columnsValues[0] !== undefined
          ? columnsValues?.map((col: DColumns) => ({
              name: col.headerName ? col.headerName : col.field,
              id: col.field,
              isSelected: col.show,
              isAnnotation: col.isAnnotation,
            }))
          : [
              {
                name: "",
                id: "",
                isSelected: false,
                isAnnotation: false,
              },
            ]
      );
    }

    if (columnSwitchesValues.length > 2) {
      SetRenderingSwitches(true);
    }
  }, [run]);

  const names =
    columnsValues[0] !== undefined
      ? columnsValues.map((col) => col.field)
      : [""];

  const changeStatus = (name: string) => {
    const newArr = columnSwitches.map((el: ColumnSwitchesTypes) => {
      if (el.id === name) {
        return { ...el, isSelected: !el.isSelected };
      } else return { ...el, isSelected: el.isSelected };
    });

    const columnsVisibilityModelTranform = newArr.map(
      (col: ColumnSwitchesTypes) => {
        const visibility = {
          col: col.id,
          show: col.isSelected,
        };
        return visibility;
      }
    );
    const columnsVisibilityModel = columnsVisibilityModelTranform.reduce(
      (obj: any, item: any) => Object.assign(obj, { [item.col]: item.show }),
      {}
    );
    setColumnVModel(columnsVisibilityModel);
    setColumnsVisibility(newArr);
  };

  const disableAll = (name: string[]) => {
    const newArr = columnSwitches.map((el: any) => {
      if (el.name === name) {
        return { ...el, isSelected: false };
      } else return { ...el, isSelected: false };
    });

    const columnsVisibilityModelTranform = newArr.map((col: any) => {
      const visibility = {
        col: col.id,
        show: false,
      };
      return visibility;
    });
    const columnsVisibilityModel = columnsVisibilityModelTranform.reduce(
      (obj: any, item: any) => Object.assign(obj, { [item.col]: false }),
      {}
    );
    setColumnVModel(columnsVisibilityModel);
    setColumnsVisibility(newArr);
  };

  const selectAll = (name: string[]) => {
    const newArr = columnSwitches.map((el: any) => {
      if (el.name === name) {
        return { ...el, isSelected: true };
      } else return { ...el, isSelected: true };
    });

    const columnsVisibilityModelTranform = newArr.map((col: any) => {
      const visibility = {
        col: col.id,
        show: true,
      };
      return visibility;
    });
    const columnsVisibilityModel = columnsVisibilityModelTranform.reduce(
      (obj: any, item: any) => Object.assign(obj, { [item.col]: true }),
      {}
    );
    setColumnVModel(columnsVisibilityModel);
    setColumnsVisibility(newArr);
  };

  return (
    <FormControl sx={{ width: "100%" }} component="fieldset">
      <TextField
        value={columnsSearch}
        onChange={(e) => setColumnSearch(e.target.value)}
        placeholder={t("Data.Search_columns")}
        sx={{
          width: "100%",
          marginBottom: "20px",
          fontStyle: "italic",
        }}
      />
      <div className="new-buttons-switches">
        <Button
          variant="text"
          sx={{
            textTransform: "none",
          }}
          onClick={() => disableAll(names)}
        >
          {t("Data.Hide_all")}
        </Button>
        <Button
          variant="text"
          sx={{
            textTransform: "none",
          }}
          onClick={() => selectAll(names)}
        >
          {t("Data.Show_all")}
        </Button>
      </div>
      <FormGroup
        className="switches-data-container"
        sx={{ width: "100%", display: "flex", flexWrap: "nowrap" }}
      >
        {columnSwitches
          ?.filter((columns: ColumnSwitchesTypes) => {
            if (columnsSearch === "") {
              return columns;
            } else if (
              columns.name?.toLowerCase().includes(columnsSearch.toLowerCase())
            ) {
              return columnsSearch;
            }
            return "";
          })
          .map((col: any) => {
            return (
              <FormControlLabel
                key={col.id}
                control={
                  <Switch
                    size="small"
                    key={col.id}
                    checked={col.isSelected}
                    onChange={() => changeStatus(col.id)}
                    inputProps={{ "aria-label": "controlled" }}
                    name={col.name}
                  />
                }
                label={col.name}
              />
            );
          })}
      </FormGroup>
    </FormControl>
  );
}
