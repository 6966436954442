
import makeStyles from '@mui/styles/makeStyles';
import { inarixColors } from 'inarix-theme';

export const useStyles = makeStyles({
  confirmModal: {
    position: 'absolute',
    width: 400,
    backgroundColor: inarixColors.contrast_inarix,
    border: `2px solid ${inarixColors.contrast_inarix}`,
    borderRadius: '4px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '24px 24px  24px',
  },
});
