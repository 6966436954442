import React, { useRef, useEffect, FC } from "react";
import Avatar from "@mui/material/Avatar";
export interface Dict<T> {
  [key: string]: T;
}

const cacheInput: Dict<string> = {};
const cacheDrawable: Dict<ImageData> = {};

function oneOrZero(h: number, mask: number): number {
  return (h & mask) !== 0 ? 1 : 0;
}

function generateHash(input: string): number | null {
  const attempts = 50;
  let result = null;
  let candidate = input;

  for (const z of [...(new Array(attempts).keys() as any)]) {
    candidate += String.fromCharCode(z);
    const n = candidate.length;
    let h = 0; // integer hash

    for (const i of [...(new Array(n).keys() as any)]) {
      h = (31 * h + candidate[i].charCodeAt(0)) % 0b0111111111111111;
    }
    result = h;

    let b = 1;

    b *= oneOrZero(h, 0b0000000000011111); // check column 1
    b *= oneOrZero(h, 0b0000001111100000); // check column 2
    b *= oneOrZero(h, 0b0111110000000000); // check column 3
    b *= oneOrZero(h, 0b0000010000100001); // check row 1
    b *= oneOrZero(h, 0b0000100001000010); // check row 2
    b *= oneOrZero(h, 0b0001000010000100); // check row 3
    b *= oneOrZero(h, 0b0010000100001000); // check row 4
    b *= oneOrZero(h, 0b0100001000010000); // check row 5

    if (b === 1) break;
  }

  return result;
}

function createCode(input: string): string {
  if (cacheInput[input]) {
    return cacheInput[input];
  }

  let hash = generateHash(input) as number;
  let code = "";

  for (const _ of [...(Array(15).keys() as any)]) {
    const bit = hash & 1;
    code += bit;
    hash = hash >> 1;
  }

  code += code.substr(5, 5);
  code += code.substr(0, 5);

  cacheInput[input] = code;

  return code;
}

function createCodeFromSaltAndSlug(iconSalt: number, slug: string): string {
  return createCode((iconSalt + 1).toString() + slug);
}

const getColorIndicesForCoord = (x: number, y: number, width: number) => {
  const red = y * (width * 4) + x * 4;
  return [red, red + 1, red + 2, red + 3];
};

function createIcon(
  code: string,
  imgData: ImageData,
  foreground: any
): ImageData {
  if (cacheDrawable[code]) {
    return cacheDrawable[code];
  }

  for (const i of [...(Array(5).keys() as any)]) {
    for (const j of [...(Array(5).keys() as any)]) {
      const color = parseInt(code[i * 5 + j]);
      const [r, g, b, a] = getColorIndicesForCoord(i, j, 5);
      const hex2rgba = (hex: any, alpha = 1) => {
        const [r, g, b] = hex
          .match(/\w\w/g)
          .map((x: string) => parseInt(x, 16));
        return { r: `${r}`, g: `${g}`, b: `${b}`, a: `${alpha}` };
      };

      const rValue = hex2rgba(foreground).r;
      const gValue = hex2rgba(foreground).g;
      const bValue = hex2rgba(foreground).b;
      const aValue = hex2rgba(foreground).a;
      imgData.data[r] = color * parseInt(rValue);
      imgData.data[g] = color * parseInt(gValue);
      imgData.data[b] = color * parseInt(bValue);
      imgData.data[a] = color * parseInt(aValue) * 255;
    }
  }

  cacheDrawable[code] = imgData;
  return imgData;
}

const Canvas: FC<any> = (values: {
  iconSalt: number;
  slug: string;
  actionId: number;
  background: string;
  foregroundColor: string;
}) => {
  const canvasRef = useRef(null);

  function draw() {
    const canvas = document.createElement("canvas");
    const ctx = canvas?.getContext("2d");

    canvas.id = values.slug;
    canvas.width = 5;
    canvas.height = 5;

    const imgData = ctx?.getImageData(0, 0, 5, 5);

    const code = createCodeFromSaltAndSlug(values.iconSalt, values?.slug);
    const iconData = createIcon(
      code,
      imgData as ImageData,
      values.foregroundColor
    );

    ctx?.putImageData(iconData, 0, 0);

    const tmp = new Image();
    tmp.src = canvas.toDataURL();

    canvas.width = 40;
    canvas.height = 40;

    ctx?.clearRect(0, 0, canvas.width, canvas.height);
    ctx!.imageSmoothingEnabled = false;

    ctx?.scale(4, 4);
    ctx?.drawImage(tmp, 2.5, 2.3);

    const new_image_url = canvas.toDataURL();

    return new_image_url;
  }

  useEffect(() => {
    const render = () => {
      draw();
    };

    render();
    setTimeout(() => {
      render();
    }, 2000);
  }, []);

  return (
    <div
      className="new-icon-actions"
      style={{
        backgroundColor: values.background,
      }}
    >
      <Avatar src={draw()} ref={canvasRef} />
    </div>
  );
};

export default Canvas;
