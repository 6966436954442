import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Alert } from "@mui/lab";

import img1 from "../../assets/imagesProjects/1.jpeg";
import img2 from "../../assets/imagesProjects/2.jpg";
import img3 from "../../assets/imagesProjects/3.jpg";
import img4 from "../../assets/imagesProjects/4.jpg";
import img5 from "../../assets/imagesProjects/5.jpg";
import img6 from "../..//assets/imagesProjects/6.jpg";
import img7 from "../../assets/imagesProjects/7.jpg";
import img8 from "../..//assets/imagesProjects/8.jpeg";
import img9 from "../../assets/imagesProjects/9.jpg";
import img10 from "../..//assets/imagesProjects/10.jpg";

import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { ThunkDispatch } from "@reduxjs/toolkit";
import Layout from "../layout/Layout";
import { setTabNameLayout } from "../../redux/slices/layout";
import { LoadingCircularProgress } from "../Loading";
import { State } from "../../redux/slices";
import {
  fetchFilesProjects,
  fetchProjects,
} from "../../redux/actions/projects/ProjectsAsyncActions";
import {
  Project,
  ProjectsResponse,
} from "../../redux/actions/projects/projectsTypes";
import {
  disableProjectsError,
  initialState,
  setProjectId,
  setProjectName,
} from "../../redux/slices/fleet/projects";
import NotFound from "../NotFound";

const projectImages = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
];

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    disableError: () => dispatch(disableProjectsError()),
    fetchProjectsTotal: (token: string) => dispatch(fetchProjects(token)),
    setTabName: (name: string) => dispatch(setTabNameLayout(name)),
    setProject: (token: string, id: number, navigate: any) => {
      dispatch(setProjectName(initialState.projectName));
      dispatch(setProjectId(id));
      dispatch(fetchFilesProjects({ token, id }));
      if (id) {
        navigate(`/projects/${id}`);
      }
    },
  };
};

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    width: 345,
    marginRight: "20px",
    marginTop: "15px",
  },
  media: {
    height: 140,
  },
});

const showImg = (projects: Project) => {
  return projectImages[projects.userFacingImageNumber % projectImages.length];
};

const Cards = (
  projects: ProjectsResponse | null,
  history: any,
  token: string,
  t: (arg0: string) => string,
  loading: boolean
) => {
  const { setProject } = SetActions();

  const classes = useStyles();
  if (projects?.data.length === 0 || !projects) {
    return (
      <div>
        <div className="no-projects-alert">
          <Alert severity="info" className="mt-3">
            {t("Projects.No_projects")}...
          </Alert>
        </div>
      </div>
    );
  } else {
    return (
      <div className="cards-container">
        {!loading ? (
          projects?.data.map((projectsData: Project) => (
            <div key={projectsData.id}>
              <Card className={classes.root}>
                <CardActionArea
                  onClick={() => setProject(token, projectsData.id, history)}
                >
                  <CardMedia
                    className={classes.media}
                    image={showImg(projectsData)}
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {projectsData.customerFacingName}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => setProject(token, projectsData.id, history)}
                  >
                    {t("Projects.Details")}
                  </Button>
                </CardActions>
              </Card>
            </div>
          ))
        ) : (
          <LoadingCircularProgress />
        )}
        <div className="rest-of-component" />
      </div>
    );
  }
};

const Projects: FC = () => {
  const [t] = useTranslation("global");
  const { user, isViewerOnly } = useSelector(
    (state: State) => state.Authentication
  );
  const { projects, error, loading } = useSelector(
    (state: State) => state.ProjectsReducer
  );
  const { fetchProjectsTotal, disableError, setTabName } = SetActions();
  const history = useNavigate();
  const token = user?.token ?? "";

  if (error) {
    setTimeout(() => {
      disableError();
    }, 10000);
  }

  useEffect(() => {
    setTabName("/projects");
    fetchProjectsTotal(token);
  }, []);

  return (
    <div>
      <Layout
        title={!isViewerOnly ? t("Projects.my_project") : ""}
        component={
          !isViewerOnly ? (
            Cards(projects, history, token, t, loading)
          ) : (
            <NotFound />
          )
        }
      />
    </div>
  );
};

export default Projects;
