import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";

import {
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridFilterModel,
  GridPaginationModel,
} from "@mui/x-data-grid";

import { DataGridInarixProUsersActions } from "./UserTabsStyles";
import themeInarix from "../StylesMuiInarix";
import { IconButton, Modal, Tooltip } from "@mui/material";
import { errorsGeneralModal } from "../ErrorModal";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import { inarixColors } from "inarix-theme";
import { BodyUserGroup } from "./userEditionsModals/UsersTabsBody";
import { UserToRemoveFromGroup } from "./userEditionsModals/UsersTabsRemoveUsersBody";
import { ConfirmGeneralModal } from "../ConfirmModal";
import { SuccessGeneralModal } from "../SuccessModal";
import { BodyUserLocation } from "./userEditionsModals/UsersAssociatedLocation";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/slices";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  initialState,
  setFilterLocUserTabs,
} from "../../redux/slices/fleet/users";
import usersTabsContent from "./UsersTabsFetchingColumns";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setFilters: (filters: GridFilterModel) => {
      dispatch(setFilterLocUserTabs(filters));
    },
  };
};

const NewUsersTable = (
  t: (arg0: string) => string,
  removeUsersError: () => void,
  setRowsNumber: (rowsSize: GridPaginationModel) => void,
  setSelectionModel: (e: any) => void,
  openUserModal: (open: boolean) => void,
  setRemoveUsersModal: (e: any) => void,
  setValuesToEditLoc: (value: string) => void
) => {
  const { user } = useSelector((state: State) => state.Authentication);
  const { layoutActive, locale, confirmModal, infoConfirmModal, sectionName } =
    useSelector((state: State) => state.LayoutReducer);
  const token = user?.token ? user.token : "";
  const bodyUserMove = BodyUserGroup(token, t);
  const bodyUsersDelete = UserToRemoveFromGroup(token, t);
  const successBodyModal = SuccessGeneralModal(t, sectionName);
  const bodyConfirmMOdal = ConfirmGeneralModal(
    token,
    t,
    sectionName,
    infoConfirmModal
  );
  const { locations } = useSelector((state: State) => state.MapReducer);

  const {
    users,
    error,
    loading,
    loadingDelete,
    usersSelected,
    modal,
    modalToRemoveUser,
    msg,
    modalLocationUser,
    optionToModify,
  } = useSelector((state: State) => state.UsersReducer);

  const { columns, usersRows } = usersTabsContent(users, t);

  const bodyUsersLoc = BodyUserLocation(token, optionToModify, locations);
  const { setFilters } = SetActions();
  const { filterModelLocation } = useSelector(
    (state: State) => state.UsersReducer
  );

  const tableStyleLayout = () => {
    if (!layoutActive) {
      return "table-styles";
    } else {
      return "table-styles-open-layout";
    }
  };

  const onFilterChange = (e: any) => {
    setFilters(e);
  };

  const bodyErrors = errorsGeneralModal(
    JSON.stringify(error),
    removeUsersError,
    t
  );

  const customToolbar = () => {
    return (
      <GridToolbarContainer
        data-cy="grid-toolbar-tables"
        style={{
          background:
            usersSelected.length > 0 ? inarixColors.tables_action_color : "",
        }}
      >
        <GridToolbarQuickFilter />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <div className="remove-add-something">
          {usersSelected.length > 0 ? (
            <>
              <Tooltip title={t("Fleet_users.modal.Add_users")} arrow>
                <IconButton
                  onClick={() => openUserModal(true)}
                  color="primary"
                  size="small"
                >
                  <PersonAddIcon data-cy="person-add-icon" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("Fleet_users.modal_remove.Remove_users")} arrow>
                <IconButton
                  onClick={() => setRemoveUsersModal(true)}
                  size="small"
                  color="primary"
                >
                  <PersonRemoveIcon data-cy="person-remove-icon" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("Fleet_users.options.add_loc_user")} arrow>
                <IconButton
                  onClick={() => setValuesToEditLoc("addLoc")}
                  size="small"
                  color="primary"
                >
                  <AddLocationIcon data-cy="person-add-loc-icon" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("Fleet_users.options.remove_loc_user")} arrow>
                <IconButton
                  onClick={() => setValuesToEditLoc("removeLoc")}
                  size="small"
                  color="primary"
                >
                  <LocationOffIcon data-cy="person-remove-loc-icon" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("Fleet_users.options.edit")} arrow>
                <IconButton
                  onClick={() => setValuesToEditLoc("editLoc")}
                  size="small"
                  color="primary"
                >
                  <EditLocationIcon data-cy="person-edit-loc-icon" />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            ""
          )}
        </div>
      </GridToolbarContainer>
    );
  };

  const managingGilterChange = () => {
    if (filterModelLocation?.items?.length >= 1) {
      onFilterChange(initialState.filterModelLocation);
    }
  };

  return (
    <div style={{ height: 640, width: "100%" }}>
      <ThemeProvider data-cy="user-groups-table" theme={themeInarix(locale)}>
        <DataGridInarixProUsersActions
          className={tableStyleLayout()}
          rowHeight={100}
          loading={loading || loadingDelete}
          pagination
          disableColumnSelector={true}
          rows={usersRows}
          columns={columns}
          slots={{ toolbar: customToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          pageSizeOptions={[25, 50, 100]}
          filterModel={filterModelLocation || undefined}
          onPaginationModelChange={(newPageSize) => setRowsNumber(newPageSize)}
          checkboxSelection
          rowSelectionModel={usersSelected}
          onFilterModelChange={managingGilterChange}
          onRowSelectionModelChange={(e) => {
            setSelectionModel(e);
          }}
        />
      </ThemeProvider>
      <Modal open={!!error}>{bodyErrors}</Modal>
      <Modal open={modal}>{bodyUserMove}</Modal>
      <Modal open={modalToRemoveUser}>{bodyUsersDelete}</Modal>
      <Modal open={confirmModal}>{bodyConfirmMOdal}</Modal>
      <Modal open={!!msg}>{successBodyModal}</Modal>
      <Modal open={modalLocationUser}>{bodyUsersLoc}</Modal>
    </div>
  );
};

export default NewUsersTable;
