import React from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";

import loadingViewSvg from "../../assets/images/loading.svg";

import { State } from "../../redux/slices";
import { useStyles } from "../data/DataStyles";

export const BodyLoadingDashboardModal = (): JSX.Element => {
  const { loadingDashboardInfo } = useSelector(
    (state: State) => state.DashboardReducer
  );
  const classes = useStyles();
  const [t] = useTranslation("global");

  return (
    <div className="loading-views-div">
      <Fade in={loadingDashboardInfo}>
        <div className={classes.paperModal}>
          <h2 className="title-export">{`${t(
            "Common_options.Loading"
          )}${"..."}`}</h2>
          <img src={loadingViewSvg} alt="loadingViewSvg" />
        </div>
      </Fade>
    </div>
  );
};
