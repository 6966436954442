import { LayerProps } from "react-map-gl";

export const clusterLayerIcons = {
  id: "clusters2",
  type: "symbol",
  source: "orgGeoPoints",
  filter: ["has", "point_count"],
  layout: {
    "icon-image": [
      "case",
      ["all", ["get", "has_activity"], ["get", "has_no_activity"]],
      "map-general-cluster",
      ["get", "only_no_activity"],
      "map-cluster-no-activity",
      ["get", "only_activity"],
      "map-general-cluster",
      "map-general-cluster",
    ],
    "icon-size": 0.8,
    "icon-allow-overlap": true,
  },
};

export const clusterLayerNotification = {
  id: "clusters2",
  type: "symbol",
  source: "orgGeoPoints",
  filter: ["has", "point_count"],
  layout: {
    "icon-image": [
      "case",
      [
        "all",
        ["get", "has_activity"],
        ["get", "has_no_activity"],
        ["get", "has_notification"],
      ],
      "map-cluster-no-activity",
      ["get", "has_notification"],
      "map-cluster-no-activity",
      ["get", "only_no_activity"],
      "",
      ["get", "only_activity"],
      "",
      "",
    ],
    "icon-size": 0.8,
    "icon-allow-overlap": true,
  },
};

export const clusterLayer: LayerProps = {
  id: "clusters",
  type: "circle",
  source: "orgGeoPoints",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": [
      "case",
      ["all", ["get", "has_activity"], ["get", "has_no_activity"]],
      "#FEC84B",
      ["get", "only_no_activity"],
      "#D0D5DD",
      ["get", "only_activity"],
      "#FEC84B",
      "#FEC84B",
    ],
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 300, 40],
  },
};

export const clusterCountBg = {
  id: "clusters-count-bg",
  type: "circle",
  source: "orgGeoPoints",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": "#51bbd6",
    "circle-radius": 8,
    "circle-translate": [-15, -15],
  },
};

export const clusterCountNotificationLayer = {
  id: "cluster-count2",
  type: "symbol",
  source: "orgGeoPoints",
  filter: ["has", "point_count"],

  layout: {
    "text-field": ["get", "sum"],
    "text-transform": "uppercase",
    "text-letter-spacing": 0.05,
    "text-variable-anchor": ["top-left"],
    "text-size": 12.5,
    "text-radial-offset": [
      "step",
      ["get", "point_count"],
      0.8,
      100,
      1.7,
      300,
      2,
    ],
    "text-ignore-placement": true,

    "icon-image": [
      "case",
      ["<", ["get", "sum"], 1],
      ["image", ""],
      ["image", "map-red-dot"],
    ],

    "icon-ignore-placement": true,
    "icon-anchor": "top-left",
    "icon-offset": [
      "step",
      ["get", "point_count"],
      ["literal", [2, 2]],
      100,
      ["literal", [18, 18]],
      300,
      ["literal", [25, 25]],
    ],

    "icon-size": 0.5,
    "icon-allow-overlap": true,
  },
  paint: {
    "text-color": ["case", ["<", ["get", "sum"], 1], "transparent", "white"],
  },
};

export const clusterCountLayer = {
  id: "cluster-count",
  type: "symbol",
  source: "orgGeoPoints",
  filter: ["has", "point_count"],

  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 12,
  },
};

export const unclusteredPointLayer = {
  id: "unclustered-point",
  type: "symbol",
  source: "orgGeoPoints",
  filter: ["!", ["has", "point_count"]],
  layout: {
    "icon-image": ["get", "icon"],
    "icon-size": 0.45,
  },
  paint: { "icon-color": "#fff" },
} as LayerProps;
