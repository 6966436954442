import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Chip from "@mui/material/Chip";
import { useStyles } from "../../modals/modalStyles";
import { handlingSizeOptionsModal } from "../../Helpers";
import { ButtonCancelModals, ButtonConfirmModals } from "../UserTabsStyles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { State } from "../../../redux/slices";

import {
  setGroupId,
  setUserId,
  setUserModal,
} from "../../../redux/slices/fleet/users";
import { moveAllGroup } from "../../../redux/actions/users/userAsyncActions";

import {
  GroupInfoData,
  UserData,
} from "../../../redux/actions/users/userTypes";
import { ThunkDispatch } from "@reduxjs/toolkit";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setGroup: (value: number) => dispatch(setGroupId(value)),
    moveTogroupAction: (token: string) => dispatch(moveAllGroup(token)),

    addUsers: (token?: string) => {
      if (token) {
        dispatch(moveAllGroup(token) as any);
      }
      dispatch(setUserId([]));
      dispatch(setUserModal(false));
      setTimeout(
        () => {
          dispatch(dispatch(setGroupId([])));
          dispatch(setGroupId(0));
        },
        token ? 1500 : 200
      );
    },
  };
};

export const BodyUserGroup = (
  token: string,
  t: (arg0: string) => string
): JSX.Element => {
  const { users, groups, usersSelected, groupId, loading } = useSelector(
    (state: State) => state.UsersReducer
  );

  const classes = useStyles();
  const { addUsers, setGroup } = SetActions();

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    addUsers(token);
  };

  const userIdChips = usersSelected.map((userId: string) => (
    <div key={userId}>
      {users.data.map((u: UserData) => {
        if (u.id === userId) {
          return (
            <div key={userId}>
              <Chip label={u.username} variant="outlined" className="mb-3" />
            </div>
          );
        }
        return "";
      })}
    </div>
  ));

  const groupsOptionsAutocomplete = groups.data;

  return (
    <div className={classes.confirmModal}>
      <h5 data-cy="modal-move-users" className="general-modal-title">
        {t("Fleet_users.modal.Add_users")}
      </h5>
      <br />
      <div
        data-cy="modal-move-users-chips"
        className={handlingSizeOptionsModal(userIdChips)}
      >
        {userIdChips}
      </div>
      <br />
      <h6 data-cy="modal-move-users-total-groups" className="mb-3 mt-3">
        {t("Fleet_users.modal.Total_groups")}: {groups.total}
      </h6>

      <Autocomplete
        disablePortal
        data-cy="btn-select"
        id="combo-box-demo"
        options={groupsOptionsAutocomplete}
        getOptionLabel={(option: GroupInfoData) => option.name}
        sx={{ width: 300, marginBottom: "20px", marginTop: "30px" }}
        onChange={(e, value) => setGroup(parseInt(value?.id))}
        renderInput={(params) => (
          <TextField {...params} label={t("Fleet_users.modal.Select_group")} />
        )}
      />

      <hr />
      <br />
      <div className="buttons-position-options">
        <ButtonCancelModals
          data-cy="btn-cancel"
          type="button"
          onClick={() => addUsers()}
          disabled={loading}
          size="large"
          variant="outlined"
          className="mr-2"
        >
          {t("Fleet_overview.overview_panel.deleteLocation.cancel")}
        </ButtonCancelModals>

        <ButtonConfirmModals
          loading={loading}
          data-cy="btn-confirm"
          type="submit"
          variant="contained"
          disabled={!groupId || loading}
          onClick={submitHandler}
        >
          {t("Fleet_users.modal.Move_to_group")}
        </ButtonConfirmModals>
      </div>
    </div>
  );
};
