import { FC, useEffect } from "react";
import Layout from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/slices";
import {
  fetchGroups,
  fetchUsersNewRequest,
} from "../../redux/actions/users/userAsyncActions";
import {
  initialState,
  setConfirmModal,
  setInfoConfirmModal,
  setTabNameLayout,
} from "../../redux/slices/layout";
import newUsersTable from "./UserTable";
import { useTranslation } from "react-i18next";

import {
  cleanMessageNDError,
  disableUsersErrors,
  setModalAddLocUsers,
  setNumberRowsUsers,
  setOpenModalAddValues,
  setRemoveUsersModal,
  setSelectionModelUsers,
  setUserModal,
} from "../../redux/slices/fleet/users";
import { GridPaginationModel } from "@mui/x-data-grid-pro";
import GroupAccordion from "./UserGroupAccordion";
import { ThunkDispatch } from "@reduxjs/toolkit";
import NotFound from "../NotFound";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    listUsersInfo: (token: string) => {
      dispatch(fetchUsersNewRequest(token));
      dispatch(fetchGroups(token));
      dispatch(setTabNameLayout("/users"));
      dispatch(setSelectionModelUsers([]));
    },
    removeUsersError: () => dispatch(disableUsersErrors()),

    setRowsNumber: (rows: GridPaginationModel) =>
      dispatch(setNumberRowsUsers(rows)),

    setSelectionModel: (users: SelectionMode[]) => {
      dispatch(setSelectionModelUsers(users));
    },
    openUserModal: (open: boolean) => dispatch(setUserModal(open)),
    openUserRemoveModal: (open: boolean) => dispatch(setRemoveUsersModal(open)),

    setValuesToEditLoc: (values: string) => {
      dispatch(setOpenModalAddValues(values));
    },

    closeAllModals: () => {
      dispatch(setUserModal(false));
      dispatch(dispatch(cleanMessageNDError()));
      dispatch(setInfoConfirmModal(initialState.infoConfirmModal));
      dispatch(setConfirmModal(false));
      dispatch(setRemoveUsersModal(false));
      dispatch(setModalAddLocUsers(false));
    },
  };
};

const UserGroups: FC<any> = () => {
  const [t] = useTranslation("global");
  const {
    listUsersInfo,
    removeUsersError,
    setRowsNumber,
    setSelectionModel,
    openUserModal,
    openUserRemoveModal,
    setValuesToEditLoc,
    closeAllModals,
  } = SetActions();

  const { user, isViewerOnly } = useSelector(
    (state: State) => state.Authentication
  );
  const { users, loading, loadingDelete, groups } = useSelector(
    (state: State) => state.UsersReducer
  );

  const token = user?.token ? user.token : "";

  const globalComponets = () => {
    return (
      <div className="table-container-actions">
        {newUsersTable(
          t,
          removeUsersError,
          setRowsNumber,
          setSelectionModel,
          openUserModal,
          openUserRemoveModal,
          setValuesToEditLoc
        )}
        {GroupAccordion(token, t, users, groups, loading, loadingDelete)}
      </div>
    );
  };

  useEffect(() => {
    listUsersInfo(token);
    closeAllModals();
    setRowsNumber({ page: 1, pageSize: 25 });
  }, []);

  return (
    <Layout
      title={!isViewerOnly ? t("Fleet_users.table.My_users") : ""}
      component={!isViewerOnly ? globalComponets() : <NotFound />}
    />
  );
};

export default UserGroups;
