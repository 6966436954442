import * as React from "react";
import Checkbox from "@mui/material/Checkbox";

import { FormControl, FormControlLabel, FormGroup } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  ColumnSwitchesTypes,
  DataSBFilters,
  DataTableRow,
  setExtraFiltersValuesAutocomplete,
} from "../../../redux/slices/data/data";
import { State } from "../../../redux/slices";
import { FiltersDataTableObject } from "../dataHelpers/DataProgress";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setcropsSelected: (
      value: any,
      dataSBFilters: DataSBFilters,
      map: Record<keyof DataTableRow, keyof DataTableRow>
    ) => {
      const values = value?.map((l: ColumnSwitchesTypes) => {
        if (l.isSelected) {
          return l.id;
        }
      });

      const filtered = values.filter((n: number[]) => n);

      dispatch(
        setExtraFiltersValuesAutocomplete({
          ...dataSBFilters,
          cropsSelected: value,
          filtersCrops: FiltersDataTableObject(map, "cerealName", filtered, ""),
        })
      );
    },
  };
};

export const CropsOptions = () => {
  const { setcropsSelected } = SetActions();
  const { dataSBFilters, filterMap } = useSelector(
    (state: State) => state.DataReducer
  );
  const { cropsSelected } = dataSBFilters;

  const changeStatus = (id: number) => {
    const newArr = cropsSelected.map((el) => {
      if (el.id === id) {
        return { ...el, isSelected: !el.isSelected };
      } else return { ...el, isSelected: el.isSelected };
    });
    setcropsSelected(newArr as any, dataSBFilters, filterMap);
  };

  return (
    <FormControl className="crops-container ml-2" component="fieldset">
      <FormGroup>
        {cropsSelected.map((value) => {
          return (
            <FormControlLabel
              key={value.id}
              control={
                <Checkbox
                  checked={value.isSelected}
                  onChange={() => changeStatus(value.id)}
                  name={value.name}
                />
              }
              label={value.name}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};
