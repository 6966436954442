import React from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  initialState,
  setConfirmModal,
  setInfoConfirmModal,
} from "../redux/slices/layout";
import {
  deleteUserFromGroup,
  fetchGroups,
  fetchUsersNewRequest,
} from "../redux/actions/users/userAsyncActions";
import { State } from "../redux/slices";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { deleteScenarioFromGroup } from "../redux/actions/actions/actionsAsyncActions";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import EditIcon from "@mui/icons-material/Edit";
import { inarixColors } from "inarix-theme";
import {
  createLocation,
  deleteView,
  updateLocation,
} from "../redux/actions/map/mapAsyncActions";
import { typeOfPoint } from "./map/mapComplements/TypeOfLocation";
import { imgDefaultTypeLoc } from "./map/MapHelpers";
import jwt from "jsonwebtoken";
import { TokenPayload } from "../redux/actions/authentication/authentication";
import alertIconRed from "../assets/images/alert2.png";
import { deleteProject } from "../redux/actions/projects/ProjectsAsyncActions";

/**
 *
 * @Actions
 */

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    closeConfirmModal: () => {
      dispatch(setConfirmModal(false));
      dispatch(setInfoConfirmModal(initialState.infoConfirmModal));
    },

    updateGeoLocation: (
      token: string,
      geoLocId: number,
      latitude?: number,
      longitude?: number
    ) => dispatch(updateLocation({ token, geoLocId, latitude, longitude })),

    removeUser: (arg: { token: string; userId: string; groupId: number }) => {
      dispatch(deleteUserFromGroup(arg));
      setTimeout(() => {
        dispatch(fetchGroups(arg.token));
        dispatch(fetchUsersNewRequest(arg.token));
      }, 500);
    },

    deleteScenario: (arg: {
      token: string;
      scenarioId: number;
      orgId: number;
    }) => {
      dispatch(deleteScenarioFromGroup(arg));
    },

    createNewLoc: (arg: { token: string; realmId: number }) =>
      dispatch(createLocation(arg)),

    deleteView: (token: string) => dispatch(deleteView(token)),

    deleteFile: (token: string, projectFileId: string) =>
      dispatch(deleteProject({ token, projectFileId })),
  };
};

export const ConfirmGeneralModal = (
  token: string,
  t: (arg0: string) => string,
  tabSection: string,
  info?: any
): JSX.Element => {
  const {
    closeConfirmModal,
    removeUser,
    deleteScenario,
    updateGeoLocation,
    createNewLoc,
    deleteView,
    deleteFile,
  } = SetActions();
  const { infoConfirmModal } = useSelector(
    (state: State) => state.LayoutReducer
  );

  const { groupId } = useSelector((state: State) => state.UsersReducer);
  const {
    typeOfAction,
    modifyLocation,
    newGeoLoc,
    latitudeEditCreateGeoLoc,
    longitudeEditCreateGeoLoc,
    createLocationActive,
    sidebarViews,
  } = useSelector((state: State) => state.MapReducer);
  const { realmId }: TokenPayload = jwt.decode(token) as TokenPayload;

  const confirmFunction = () => {
    if (tabSection === "/projects") {
      deleteFile(token, infoConfirmModal.projectFileId);
    }

    if (tabSection === "/users") {
      removeUser({ token, userId: infoConfirmModal.user.id, groupId: groupId });
    }
    if (tabSection === "/actions") {
      deleteScenario({
        token,
        scenarioId: infoConfirmModal.user.id,
        orgId: realmId,
      });
    }

    if (
      tabSection === "/" &&
      typeOfAction === "editLocation" &&
      modifyLocation &&
      !createLocationActive &&
      newGeoLoc
    ) {
      updateGeoLocation(
        token,
        newGeoLoc?.id,
        latitudeEditCreateGeoLoc as number,
        longitudeEditCreateGeoLoc as number
      );
    }

    if (
      tabSection === "/" &&
      typeOfAction === "createLocation" &&
      !modifyLocation &&
      createLocationActive
    ) {
      createNewLoc({ token, realmId });
    }

    if (tabSection === "/" && typeOfAction === "deleteView") {
      deleteView(token);
    }
  };

  const backgroundStyle = (tabSection: string, typeOfAction: string) => {
    if (
      tabSection === "/users" ||
      tabSection === "/actions" ||
      tabSection === "/projects" ||
      (tabSection === "/" && typeOfAction === "deleteView")
    ) {
      return inarixColors.modal_danger_color;
    } else {
      return inarixColors.main_inarix;
    }
  };

  const backgroundStyleModalHeader = (
    tabSection: string,
    typeOfAction: string
  ) => {
    if (
      tabSection === "/users" ||
      tabSection === "/actions" ||
      tabSection === "/projects" ||
      (tabSection === "/" && typeOfAction === "deleteView")
    ) {
      return "#fff";
    } else {
      return inarixColors.main_inarix;
    }
  };

  const showTheRightIcon = (tabSection: string, typeOfAction: string) => {
    if (
      tabSection === "/users" ||
      tabSection === "/actions" ||
      tabSection === "/projects" ||
      (tabSection === "/" && typeOfAction === "deleteView")
    ) {
      return (
        <img
          src={alertIconRed}
          className="img-alert-delete-confirm-modal"
          alt="alertIconRed"
        />
      );
    }

    if (tabSection === "/" && typeOfAction === "editLocation") {
      return (
        <p className="text-center">
          <EditIcon sx={{ fontSize: 50, color: inarixColors.main_inarix }} />
        </p>
      );
    }

    if (tabSection === "/" && typeOfAction === "createLocation") {
      return (
        <p className="text-center">
          <AddLocationAltIcon
            sx={{ fontSize: 50, color: inarixColors.main_inarix }}
          />
        </p>
      );
    }
    return "";
  };

  const showTheRightValuesToEditAlpha = () => {
    if (tabSection === "/projects") {
      return (
        <>
          <p style={{ fontWeight: 600 }}> {info?.name}</p>
        </>
      );
    }
    if (tabSection === "/users" || tabSection === "/actions") {
      return (
        <>
          <p style={{ fontWeight: 600 }}> {info?.user?.username}</p>
          <p> {t("Fleet_users.modal_remove.Remove_from_group")} :</p>
          <p style={{ fontWeight: 600 }}>{info?.groups ? info.groups : ""}</p>
        </>
      );
    }

    if (tabSection === "/" && typeOfAction === "deleteView") {
      return (
        <div className="d-flex">
          <span>{t("Data.views.View_name")} :</span>
          <p style={{ fontWeight: 600 }}>{info?.viewName}</p>
        </div>
      );
    }
  };

  const contentEditCreateLoc = () => (
    <div>
      <p>
        {t("Fleet_overview.overview_panel.createNewPosition.newLocationName")}:{" "}
        <span style={{ fontWeight: 600 }}>{info?.name}</span>
      </p>
      {info?.type ? (
        <p className="type-locatins-styles-container">
          {t("Fleet_overview.overview_panel.createNewPosition.locationType")}:
          <span style={{ marginRight: "10px" }} />{" "}
          <span style={{ fontWeight: 600 }}> {typeOfPoint(info?.type)}</span>
          {imgDefaultTypeLoc(info.type)}
        </p>
      ) : (
        ""
      )}
      <p>
        {t("Fleet_overview.overview_panel.createNewPosition.contactName")}:{" "}
        <span style={{ fontWeight: 600 }}>{info?.primaryContactName}</span>
      </p>
      <p>
        {t("Fleet_overview.overview_panel.createNewPosition.email")}:{" "}
        <span style={{ fontWeight: 600 }}>{info?.email}</span>
      </p>
      <p>
        {t("Fleet_overview.overview_panel.createNewPosition.address")}:{" "}
        <span style={{ fontWeight: 600 }}>{info?.address}</span>
      </p>
      <p>
        {t("Fleet_overview.overview_panel.createNewPosition.notes")}:{" "}
        <span style={{ fontWeight: 600 }}>{info?.notes}</span>
      </p>
      <p>
        {t("Fleet_overview.overview_panel.createNewPosition.telephone")}:{" "}
        <span style={{ fontWeight: 600 }}>{info?.telephone}</span>
      </p>

      <p>
        {t("Fleet_overview.overview_panel.createNewPosition.remote_id_input")}:
        <span style={{ fontWeight: 600 }}>{info?.remoteId}</span>
      </p>
    </div>
  );

  const displayTheGoodValue = () => {
    switch (typeOfAction) {
      case "editLocation":
        return tabSection === "/" ? contentEditCreateLoc() : "";

      case "createLocation":
        return tabSection === "/" ? contentEditCreateLoc() : "";

      default:
        return "";
    }
  };

  const contentNewConfirmModal = () => {
    return (
      <div className="confirmModal2">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              backgroundColor: backgroundStyleModalHeader(
                tabSection,
                typeOfAction
              ),
            }}
          >
            <p className="heading"></p>
          </div>

          <div className="modal-body">
            <div className="row">
              <div className="col-3">
                <p></p>
                {showTheRightIcon(tabSection, typeOfAction)}
              </div>

              <div className="col-9">
                <h5 className="delete-title">
                  {t("Fleet_users.alert_delete.Sure")}
                </h5>
                <p
                  style={{
                    fontSize: "18px",
                    color: inarixColors.modal_danger_color,
                  }}
                >
                  {modifyLocation || createLocationActive || sidebarViews
                    ? ""
                    : t("Common_options.Edit")}

                  {tabSection === "/users" ||
                  tabSection === "/actions" ||
                  tabSection === "/projects" ||
                  (tabSection === "/" && typeOfAction === "deleteView")
                    ? `/ ${t("Common_options.Remove")}`
                    : ""}
                </p>
                {showTheRightValuesToEditAlpha()}
                {displayTheGoodValue()}
              </div>
            </div>
          </div>

          <div className="modal-footer justify-content-sm-around">
            <Button variant="outlined" onClick={() => closeConfirmModal()}>
              {t("Common_options.Cancel")}
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={confirmFunction}
              style={{
                backgroundColor: backgroundStyle(tabSection, typeOfAction),
              }}
            >
              {t("Common_options.Save")}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const bodyConfirmMOdal = contentNewConfirmModal();

  return bodyConfirmMOdal;
};
