import React from "react";
import { useDispatch } from "react-redux";
import Chip from "@mui/material/Chip";
import { TextField } from "@mui/material";
import { DeviceData } from "../../redux/actions/devices/devicesTypes";
import { patchDeviceName } from "../../redux/actions/devices/devicesAsyncAction";
import {
  resetUserDeviceError,
  setDevicesModal,
  setNewDeviceName,
  setUserDeviceId,
} from "../../redux/slices/fleet/devices";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  ButtonCancelModals,
  ButtonConfirmModals,
} from "../userGroups/UserTabsStyles";
import { useStyles } from "../modals/modalStyles";

/**
 *
 * @Actions
 */

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    closeModal: () => {
      dispatch(setDevicesModal(false));
      dispatch(setUserDeviceId(""));
    },

    setDeviceId: (id: string) => {
      dispatch(setUserDeviceId(id));
      dispatch(setDevicesModal(true));
    },

    setDeviceNewName: (value: string) => dispatch(setNewDeviceName(value)),

    patchName: (token: string) => {
      dispatch(patchDeviceName(token));
    },

    disableError: () => dispatch(resetUserDeviceError()),
  };
};

export const UserDevicesBody = (
  t: (arg0: string) => string,
  token: string,
  devices: DeviceData[],
  deviceId: string,
  deviceName: string,
  errorPatch: number,
  loadingPatch: boolean
): JSX.Element => {
  const { closeModal, setDeviceNewName, patchName, disableError } =
    SetActions();

  const classes = useStyles();

  const changeName = (e: React.SyntheticEvent<EventTarget>): void => {
    e.preventDefault();
    patchName(token);
  };

  const errorDuplicate = () => {
    if (errorPatch === 400) {
      setTimeout(() => {
        disableError();
      }, 30000);
      return (
        <div className="alert alert-info">
          <strong> Ups..! </strong>{" "}
          {t("Fleet_users_devices.Alert.Error_duplicate")}
        </div>
      );
    }
  };

  const deviceInfo = devices.map((devices: DeviceData) => {
    if (devices.id === deviceId) {
      if (!devices.orgInternalId) {
        return (
          <Chip
            key={devices.id}
            label={t("Fleet_users_devices.table.Empty_device")}
          />
        );
      } else {
        return (
          <div key={devices.id}>
            <span>
              {t("Fleet_users_devices.Modal.Current_name")} {""}
              <Chip label={devices.orgInternalId} />
            </span>
          </div>
        );
      }
    }
    return "";
  });

  const bodyUserDevices = (
    <section>
      <div className={classes.confirmModal}>
        <form onSubmit={changeName}>
          <div>
            <h5 data-cy="title-edit-device" className="title-modal">
              {t("Fleet_users_devices.Modal.Title")}
            </h5>

            <div className="subtitles-styles">
              <span data-cy="chip-devices" className="subtitle-modal">
                {t("Fleet_users_devices.Modal.Subtitle")}{" "}
                <Chip label={deviceId.slice(0, 8)} variant="outlined" />
              </span>
              <span className="mb-3">{deviceInfo}</span>
              {errorDuplicate()}
            </div>
            <TextField
              data-cy="input-text-device-name"
              sx={{ width: "350px" }}
              name="deviceName"
              autoComplete="off"
              value={deviceName}
              onChange={(e) => setDeviceNewName(e.currentTarget.value)}
              label={t("Fleet_users_devices.Modal.Input_tx")}
              className="mt-4 mb-4"
              required
            />
          </div>

          <div className="buttons-position-options">
            <ButtonCancelModals
              data-cy="btn-cancel"
              type="button"
              onClick={closeModal}
              disabled={loadingPatch}
              size="large"
              variant="outlined"
              className="mr-2"
            >
              {t("Fleet_overview.overview_panel.deleteLocation.cancel")}
            </ButtonCancelModals>

            <ButtonConfirmModals
              type="submit"
              variant="contained"
              disabled={
                deviceName.length < 3 || deviceName.length > 50 || loadingPatch
              }
            >
              {t("Fleet_users_devices.Modal.Button")}
            </ButtonConfirmModals>
          </div>
        </form>
      </div>
    </section>
  );

  return bodyUserDevices;
};
