const silo = 1;
const lab = 2;

export const typeOfSilo = (
  id: number,
  hasActivity: number,
  notification: number
) => {
  if (id === silo && hasActivity < 1 && notification < 1) {
    return "map-silo";
  }

  if (id === silo && hasActivity < 1 && notification > 0) {
    return "map-silo-notification";
  }

  if (id === silo && hasActivity > 0 && notification < 1) {
    return "map-silo-with-activity";
  }

  if (id === silo && hasActivity > 0 && notification > 0) {
    return "map-silo-with-activity-notification";
  }
};

export const typeOfLabo = (
  id: number,
  hasActivity: number,
  notification: number
) => {
  if (id === lab && hasActivity < 1 && notification < 1) {
    return "map-lab";
  }

  if (id === lab && hasActivity < 1 && notification > 0) {
    return "map-lab-notification";
  }

  if (id === lab && hasActivity > 0 && notification < 1) {
    return "map-lab-with-activity";
  }

  if (id === lab && hasActivity > 0 && notification > 0) {
    return "map-lab-with-activity-notification";
  }
};

export const hasActivityCluster = (activity: number) => {
  if (activity > 0) {
    return "activity";
  }

  if (activity < 1) {
    return "noActivity";
  }
};

export const hasNotificationCluster = (notification: number) => {
  if (notification > 0) {
    return "notification";
  }

  if (notification < 1) {
    return "noNotification";
  }
};

export const typeOfPoint = (type?: number) => {
  if (type === silo) {
    return "Silo";
  }
  if (type === lab) {
    return "Labo";
  }
};
