export interface Scenario {
  background: string;
  foreground: string;
  hashSlug: string;
  iconSalt: number;
  id: number;
  name: string;
  description?: string;
  status: number;
  creatorId: string;
  ownerOrgId: number;
  archivedAt?: Date;
  deletedAt?: Date;
  updatedAt: Date;
  createdAt: Date;
}

export interface ScenarioData {
  details: ScenarioInfoOnGroup;
  scenarioIcon: Scenario;
  scenarioDescription: string;
  scenarioName: string;
  cerealType: any;
  id: number;
  name: any;
  desc: any;
  scopes: any;
  slug: string;
  iconSalt: number;
  cerealTypeId: number;
  lastScenarioInstanceId: number;
  description: string;
  status: number;
  creatorId: string;
  ownerOrgId: number;
  archivedAt: null | string;
  orderIndex: number;
  deletedAt: null | string;
  isSystemScenario: boolean;
  scenarioInfo?: ScenarioInfoData;
  cerealName?: string;
  createdAt: string;
  updatedAt: string;
  statusConstantId?: null | string;
  map: any;
}

export interface ScenarioInfoData {
  id: number;
  generalName: string;
  slug: string;
  iconForegroundHexColor: string;
  iconBackgroundHexColor: string;
  createdAt: string;
  updatedAt: string;
}
export interface OrganizationUserGroup {
  id: number;
  name: string;
  orgId: number;
  isAutoGenerated: boolean;
  hasAdminRights: boolean;
  hasPortalAccessRights: boolean;
  hasAppAccessRights: boolean;
  scenario?: ScenarioInfoOnGroup[];
  deletedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  length: any;
}
export interface OrganizationsUserGroups {
  total: number;
  limit: number;
  skip: number;
  data: OrganizationUserGroup[];
}

export interface ResponseScenarioFleet {
  total: number;
  limit: number;
  skip: number;
  data: ScenarioData[];
}

export interface ScenarioInfoOnGroup {
  id: number;
  groupId: number;
  scenarioId: number;
  deletedAt: boolean;
  createdAt: string;
  updatedAt: string;
}

/// Individual Action

export interface ScenarioInstance {
  id: number;
  scenarioId: number;
  creatorId: string;
  ownerOrgId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  steps: string[];
}

export interface ScenarioInstanceStep {
  id: number;
  slug: string;
  screenSlug: string;
  description: string;
  userFacingShortName: string;
  userFacingInstruction: any;
  config: any;
  hidden: boolean;
  labelTemplateId: number;
  predictionPostProcessingRuleId: number;
  updatedAt: Date;
  createdAt: Date;
}

export interface IndividualComponentProps {
  id: string;
}

export enum ComponentSlugs {
  INPUT_REMOTE_ID = "remote-id-input",
  COLLECT_PICTURES = "pictures-collection",
  DECLARE_LABEL = "declare-label",
  PREDICTION = "prediction",
  COLLECT_PICTURES_EXTERNAL_DEVICE = "pictures-collection-external-device",
  MULTI_DECLARE_LABEL = "multi-declare-label",
  MULTI_PREDICTION = "multi-prediction",
  DEBUG_CAPTURE = "debug-capture",
}

export interface Common {
  createdAt: string;
  updatedAt: string | null;
}
export interface OrganizationUserGroup2 extends Common {
  id: number;
  name: string;
  orgId: number;
  isAutoGenerated: boolean;
  hasAdminAccessRights: boolean;
  hasPortalAccessRights: boolean;
  hasAppAccessRights: boolean;
  deletedAt: string | null;
  generatedWithTemplateIds: number[] | null;
}
export interface ResponseBody {
  total: number;
  limit: number;
  skip: number;
  data: OrganizationUserGroup2[];
}

export interface GroupsInRowsFromListScenario {
  createdAt: string;
  deletedAt: string | null;
  generatedWithTemplateIds: number[];
  hasAdminAccessRights: boolean;
  hasAppAccessRights: boolean;
  hasPortalAccessRights: boolean;
  id: number;
  isAutoGenerated: boolean;
  name: string;
  orgId: number;
  updatedAt: string;
}

export interface RowsFromListScenario {
  scenarioName: string;
  id: number;
  lastScenarioInstanceId: number;
  scenarioDescription: string;
  cerealName: string;
  status: number;
  group: GroupsInRowsFromListScenario[];
  scenarioIcon: {
    background: string;
    foreground: string;
    hashSlug: string;
    iconSalt: number;
    name: string;
  };
}

export interface GroupScopes {
  id: string;
  scenarioId: number;
  scopeOrgId: number;
  scopeOrgTypeConstantId: null | number | string;
  scopeGroupId: number;
  hasBetaVersionAccess: boolean;
  scenarioAccessibleDirectly: boolean;
  scenarioAccessibleThroughChaining: boolean;
  deletedAt: null | string;
  createdAt: string;
  updatedAt: string;
}

export interface GroupScopes {
  id: string;
  scenarioId: number;
  scopeOrgId: number;
  scopeOrgTypeConstantId: null | number | string;
  scopeGroupId: number;
  hasBetaVersionAccess: boolean;
  scenarioAccessibleDirectly: boolean;
  scenarioAccessibleThroughChaining: boolean;
  deletedAt: null | string;
  createdAt: string;
  updatedAt: string;
}

//242
