import React from "react";
import { State } from "../../redux/slices";
import { useTranslation } from "react-i18next";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import {
  setGroupId,
  initialState,
  setModal,
} from "../../redux/slices/fleet/actions";
import jwt from "jsonwebtoken";

import {
  OrganizationUserGroup,
  RowsFromListScenario,
} from "../../redux/actions/actions/actionTypes";

import {
  ButtonCancelModals,
  ButtonConfirmModals,
} from "../userGroups/UserTabsStyles";
import { handlingSizeOptionsModal } from "../Helpers";

import { useStyles } from "../modals/modalStyles";
import Chip from "@mui/material/Chip";
import { moveAllGroup } from "../../redux/actions/actions/actionsAsyncActions";
import { TokenPayload } from "../../redux/actions/authentication/authentication";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    closeModal: () => {
      dispatch(setModal(false));
      dispatch(setGroupId(initialState.groupId));
    },
    moveTogroupAction: (token: string, orgId: number) =>
      dispatch(moveAllGroup({ token, orgId })),
    setGroup: (value: number) => dispatch(setGroupId(value)),
  };
};

export const BodyActionsGroup = (token: string): JSX.Element => {
  const { actionsRows, groups, scenariosId, groupId, loading } = useSelector(
    (state: State) => state.ActionsReducer
  );

  const [t] = useTranslation("global");
  const classes = useStyles();
  const { realmId }: TokenPayload = jwt.decode(token) as TokenPayload;

  const { setGroup, moveTogroupAction, closeModal } = SetActions();

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    moveTogroupAction(token, realmId);
  };

  const scenariosChips = scenariosId.map((scenarioId: number) => (
    <div key={scenarioId}>
      {actionsRows?.map((scenario: RowsFromListScenario) => {
        if (scenario.id === scenarioId) {
          return (
            <div key={scenario.id}>
              <Chip
                label={scenario.scenarioName}
                variant="outlined"
                className="mb-3"
              />
            </div>
          );
        }
        return "";
      })}
    </div>
  ));

  const groupsOptions = groups?.data?.map((group: OrganizationUserGroup) => {
    return (
      <option key={group.id} value={group.id}>
        {group.name}
      </option>
    );
  });

  return (
    <div className={classes.confirmModal}>
      <h5 data-cy="modal-move-actions" className="title-modal">
        {t("Fleet_actions.modal.Add_scenarios")}
      </h5>
      <h6 data-cy="modal-move-actions-title" className="mb-4 mt-2">
        {t("Fleet_actions.modal.Scenarios_to_move")} : {scenariosChips.length}
      </h6>
      <div
        data-cy="modal-move-actions-chips"
        className={handlingSizeOptionsModal(scenariosChips)}
      >
        {scenariosChips}
      </div>

      <h6 data-cy="modal-move-actions-total-groups">
        {t("Fleet_users.modal.Total_groups")}: {groups?.total}
      </h6>

      <select
        data-cy="select-groups-list"
        defaultValue={"DEFAULT"}
        className="form-select mb-3 mt-5"
        onChange={(e) => setGroup(parseInt(e.currentTarget.value))}
        required
      >
        <option value="DEFAULT" disabled={true}>
          {t("Fleet_users.modal.Select_group")}
        </option>
        {groupsOptions}
      </select>
      <div className="buttons-position-options mt-5">
        <ButtonCancelModals
          data-cy="btn-cancel"
          type="button"
          onClick={closeModal}
          disabled={loading}
          size="large"
          variant="outlined"
          className="mr-2"
        >
          {t("Fleet_overview.overview_panel.deleteLocation.cancel")}
        </ButtonCancelModals>

        <ButtonConfirmModals
          data-cy="btn-confirm"
          type="submit"
          variant="contained"
          disabled={groupId === 0 || loading}
          onClick={submitHandler}
        >
          {t("Fleet_users.modal.Move_to_group")}
        </ButtonConfirmModals>
      </div>
    </div>
  );
};
