import { useSelector } from "react-redux";
import { State } from "../../../redux/slices";
import { GridColDef } from "@mui/x-data-grid";
import { Column } from "../../../redux/actions/data/DataDeclarations";
import moment from "moment";

const TableDashboardColumns = (t: (arg0: string) => string) => {
  const { rawRowsActivityTable, rawRowsPocketLabTable } = useSelector(
    (state: State) => state.DashboardReducer
  );
  const { locale } = useSelector((state: State) => state.LayoutReducer);

  const columnNames = {
    username: t("Fleet_overview.table.users"),
    geoLocation: t("Dashboard.activityTable.site"),
    siteName: t("Dashboard.activityTable.site"),
    numberOfActions: t("Dashboard.number_of_actions"),
    totalBarleyActions: t("Dashboard.activityTable.barley_actions"),
    totalWheatActions: t("Dashboard.activityTable.wheat_actions"),
    lastActionAt: t("Dashboard.lastActionAt"),
  };

  const rawColumnsActivity = [
    {
      name: "id",
      valueType: "string",
      hidden: true,
      hideable: true,
      sortable: false,
      isStatic: true,
      alwaysHide: true,
    },
    {
      name: "username",
      valueType: "string",
      hidden: false,
      hideable: true,
      sortable: false,
      isStatic: true,
      alwaysHide: false,
    },
    {
      name: "geoLocation",
      valueType: "string",
      hidden: false,
      hideable: true,
      sortable: false,
      isStatic: true,
      alwaysHide: false,
    },
    {
      name: "numberOfActions",
      valueType: "string",
      hidden: false,
      hideable: true,
      sortable: true,
      isStatic: true,
      alwaysHide: false,
    },
    {
      name: "totalBarleyActions",
      valueType: "string",
      hidden: false,
      hideable: true,
      sortable: false,
      isStatic: true,
      alwaysHide: false,
    },
    {
      name: "totalWheatActions",
      valueType: "string",
      hidden: false,
      hideable: true,
      sortable: false,
      isStatic: true,
      alwaysHide: false,
    },
  ] as Column[];

  const rawColumnsPocketLab = [
    {
      name: "id",
      valueType: "string",
      hidden: true,
      hideable: true,
      sortable: false,
      isStatic: true,
      alwaysHide: true,
    },
    {
      name: "version",
      valueType: "string",
      hidden: false,
      hideable: true,
      sortable: true,
      isStatic: true,
      alwaysHide: false,
    },
    {
      name: "username",
      valueType: "string",
      hidden: false,
      hideable: true,
      sortable: false,
      isStatic: true,
      alwaysHide: false,
    },
    {
      name: "siteName",
      valueType: "string",
      hidden: false,
      hideable: true,
      sortable: false,
      isStatic: true,
      alwaysHide: false,
    },

    {
      name: "lastActionAt",
      valueType: "string",
      hidden: false,
      hideable: true,
      sortable: false,
      isStatic: true,
      alwaysHide: false,
    },
  ] as Column[];

  const columns = rawColumnsActivity.map((columns: Column) => ({
    field: columns.name,
    headerName: columns.localizedName ? columns.localizedName : columns.name,
    disableColumnMenu: true,
    show: columns.hidden === false,
    description: "",
    sorteable: columns.sortable,
    width: 200,
    operators: columns.filterOperators ? columns.filterOperators : [],
    static: columns.isStatic,
    alwaysHide: columns.alwaysHide ? columns.alwaysHide : false,
    isAnnotation: columns.isAnnotation ? columns.isAnnotation : false,
  }));

  const columnsPocketLab = rawColumnsPocketLab.map((columns: Column) => ({
    field: columns.name,
    headerName: columns.localizedName ? columns.localizedName : columns.name,
    disableColumnMenu: true,
    show: columns.hidden === false,
    description: "",
    sorteable: columns.sortable,
    width: 200,
    operators: columns.filterOperators ? columns.filterOperators : [],
    static: columns.isStatic,
    alwaysHide: columns.alwaysHide ? columns.alwaysHide : false,
    isAnnotation: columns.isAnnotation ? columns.isAnnotation : false,
  }));

  let columnsValues: any[] = columns.map((column: any) => {
    return {
      field: column.field,
      headerName: (columnNames as never)[column.field],
      width: 250,
      sortable: column.sorteable,
      show: column.show,
      disableColumnMenu: true,
      filterable: false,
      alwaysHide: column.alwaysHide,
      isAnnotation: column.isAnnotation,
    } as GridColDef;
  });

  let pocketLabColumnsValues: any[] = columnsPocketLab.map((column: any) => {
    if (column.field === "version") {
      return {
        field: column.field,
        headerName: (columnNames as never)[column.field],
        width: 100,
        sortable: column.sorteable,
        show: column.show,
        disableColumnMenu: true,
        filterable: false,
        alwaysHide: column.alwaysHide,
        isAnnotation: column.isAnnotation,
      };
    }

    return {
      field: column.field,
      headerName: (columnNames as never)[column.field],
      width: 250,
      sortable: column.sorteable,
      show: column.show,
      disableColumnMenu: true,
      filterable: false,
      alwaysHide: column.alwaysHide,
      isAnnotation: column.isAnnotation,
    } as GridColDef;
  });

  let dashResults = rawRowsActivityTable.map((action: any) => {
    // build row generically based on fetched columns
    const row = columnsValues.reduce((obj: any, col: any) => {
      if (col.field === "undefined") {
        obj[col.field] = "";
      } else {
        obj[col.field] = action[col.field];
      }
      return obj;
    }, {});

    row["id"] = action.id;

    return row;
  });

  let pocketLabResults = rawRowsPocketLabTable.map((action: any) => {
    // build row generically based on fetched columns

    const createdAt = moment(action?.lastActionAt);
    createdAt.locale(locale);
    const createdAtModify = createdAt.format("l, LT");

    const row = pocketLabColumnsValues.reduce((obj: any, col: any) => {
      if (col.field === "undefined") {
        obj[col.field] = "";
      } else if (col.field === "lastActionAt") {
        obj[col.field] = createdAtModify;
      } else {
        obj[col.field] = action[col.field];
      }
      return obj;
    }, {});

    row["id"] = action.id;

    return row;
  });

  const columnsFilter = columnsValues?.filter(
    (columns: any) => !columns.alwaysHide
  );

  const columnsFilterPocketLab = pocketLabColumnsValues?.filter(
    (columns: any) => !columns.alwaysHide
  );

  if (!columnsValues) {
    columnsValues = [];
  }

  if (!dashResults) {
    dashResults = [];
  }

  return {
    pocketLabColumnValues: columnsFilterPocketLab,
    columnsValues: columnsFilter,
    pocketLabResults,
    dashResults,
  };
};

export default TableDashboardColumns;
