import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Layout from "../layout/Layout";
import { IconButton, Modal, Tooltip } from "@mui/material";
import { State } from "../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { setTabNameLayout } from "../../redux/slices/layout";
import DateRangePickerWithButtonField from "./dashboardPanel/DashboardDateRangePicker";
import AutocompleteDashboardInput from "./dashboardPanel/DashboardAutoComplete";

import {
  setAllSilos,
  setComesFromMap,
  setDashboardInput,
  setMultipleLocations,
  setVisibleRangeDashboardPicker,
} from "../../redux/slices/dashboard/dashboard";
import BarleyVarietyChart from "./charts/BarleyVaritiesChart";
import BarleyVarietyRateChart from "./charts/BarleyVarietyProteinRateChart";
import WheatVarietyChart from "./charts/WheatVarietyChart";
import WheatVarietyRateChart from "./charts/WheatVarietyProteinRateChart";
import { BodyLoadingDashboardModal } from "./DashboardModalLoading";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DashboardLocIdInfo } from "../../redux/actions/dashboard/dashboardTypes";
import NewActivityChart from "./charts/NewActivityChart";

import { DashboardTable } from "./charts/DashboardTable";
import { PocketLabTable } from "./charts/PocketLabTable";
import PocketLabChart from "./charts/PocketLabChart";
import {
  fetchActivityPocketLabDataDashboard,
  fetchActivityPocketLabDataDashboard2,
  fetchDashboardLocationNames,
  fetchGeoLocDataDashboard,
  getPocketLabRecentVersion,
  totalActionsWB,
} from "../../redux/actions/dashboard/dashboardAsyncActions";
import { ThunkDispatch } from "@reduxjs/toolkit";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { v4 as uuidv4 } from "uuid";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setInputDashboardValues: (values: string) => {
      dispatch(setDashboardInput(values));
    },
    setOpenCloseDataRangePicker: (openClose: boolean) => {
      dispatch(setVisibleRangeDashboardPicker(openClose));
    },

    fetchActivityAll: (
      token: string,
      locations: DashboardLocIdInfo[],
      comesFromMap: boolean
    ) => {
      if (!comesFromMap) {
        dispatch(fetchDashboardLocationNames(token));
        dispatch(setMultipleLocations(locations));
        dispatch(getPocketLabRecentVersion(token));
        dispatch(setAllSilos(true));

        setTimeout(() => {
          dispatch(fetchGeoLocDataDashboard({ token }));
          dispatch(fetchActivityPocketLabDataDashboard({ token }));
          dispatch(fetchActivityPocketLabDataDashboard2({ token }));
        }, 500);
      }

      if (comesFromMap) {
        setTimeout(() => {
          dispatch(setComesFromMap(false));
        }, 1000);
      }
    },

    setTabName: (name: string) => dispatch(setTabNameLayout(name)),
  };
};

export interface InfoTitle {
  title: string;
  count: number;
  fontColor: string;
  icon: boolean;
  type: string;
}

const Dashboard: any = () => {
  const [t] = useTranslation("global");
  const { setTabName, fetchActivityAll } = SetActions();
  const { locale } = useSelector((state: State) => state.LayoutReducer);
  const {
    chartBarleyVarietyActions,
    chartSoftVarietyActions,
    avgProtPerBarleyVar,
    avgProtPerWheatVar,
    allLocations,
    loadingDashboardInfo,
    locationsSelected,
    countOfUsers,
    pocketlabVersion,
    locationsNames,
    comesFromMap,
  } = useSelector((state: State) => state.DashboardReducer);
  const bodyModalLoading = BodyLoadingDashboardModal();

  const [value, setValue] = React.useState(t("Dashboard.activity"));

  const chartValuesColors = {
    blue: "#3478f6",
    green: "#2E7D32",
    red: "#ED6C02",
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const { user } = useSelector((state: State) => state.Authentication);
  const token = user?.token ?? "";

  useEffect(() => {
    setTabName("/dashboard");
  }, []);

  const managingTitleDashboard = () => {
    if (locationsSelected?.length === 0 && !allLocations) {
      return t("Fleet_layout.Dashboard");
    }

    if (locationsSelected?.length === 1) {
      return locationsSelected[0]?.name;
    }
    if (locationsSelected?.length > 1) {
      return t("Dashboard.multiLocations");
    }

    if (allLocations) {
      return t("Dashboard.allLocations");
    }
  };

  const dashboardOptions = [
    t("Dashboard.activity"),
    t("Cereals.pocketLab"),
    t("Cereals.barley"),
    t("Cereals.wheat"),
  ];

  const manageWheatBarleyTitleCount = (title: string, count: number) => {
    return (
      <div className="total-actions-dashboard">
        <div className="number-action-title">{title} </div>
        <div className="number-action-count">{count}</div>
      </div>
    );
  };

  const information = [
    {
      title: t("Dashboard.activity_user_count.total_users"),
      count: countOfUsers.totalUsers,
      fontColor: chartValuesColors.blue,
      icon: false,
      type: "total",
    },
    {
      title: t("Dashboard.activity_user_count.active_users"),
      count: countOfUsers.countOfUsersActive,
      fontColor: chartValuesColors.green,
      icon: true,
      type: "active",
    },
    {
      title: t("Dashboard.activity_user_count.inactive_users"),
      count: countOfUsers.countOfUsersInactive,
      fontColor: chartValuesColors.red,
      icon: true,
      type: "inactive",
    },
  ];

  // Temporarily disable

  // const informationCharts = [
  //   {
  //     title: t("Dashboard.cereal_charts.number_action_measure_variety"),
  //     count: 50,
  //     fontColor: chartValuesColors.blue,
  //   },
  //   {
  //     title: t("Dashboard.cereal_charts.number_action_measure_protein"),
  //     count: 60,
  //     fontColor: chartValuesColors.blue,
  //   },
  // ];

  const manageCountInfoAndTitleColor = (information: InfoTitle[]) => {
    const showTooltip = (icon: boolean, type: string) => {
      if (icon && type === "active") {
        return (
          <Tooltip title={t("Dashboard.counts.active")} placement="right">
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        );
      }
      if (icon && type === "inactive") {
        return (
          <Tooltip title={t("Dashboard.counts.inactive")} placement="right">
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };

    return (
      <div className="container-results-charts-activity">
        {information.map((info: InfoTitle) => (
          <div key={`${info.title}${uuidv4()}`}>
            <div className="format-results">
              <div className="title-container-results">
                {info.title}
                {showTooltip(info.icon, info.type)}
              </div>
            </div>
            <div
              className="number-action-count mb-3"
              style={{ color: info.fontColor }}
            >
              {info.count}
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Temporarily disable

  const manageCountInfoAndTitleColorSingle = (information: InfoTitle) => {
    return (
      <div className="container-results-charts-activity">
        <div>
          <div className="format-results">
            <div className="title-container-results">{information.title}</div>
          </div>
          <div
            className="number-action-count mb-3"
            style={{ color: information.fontColor }}
          >
            {information.count}
          </div>
        </div>
      </div>
    );
  };

  const activitySection = () => (
    <div>
      <div className="container-charts-with-result-activity">
        {manageCountInfoAndTitleColor(information)}
        {NewActivityChart(t)}
      </div>
      <div className="dashboard-table-container">
        <div className="title-dashboard-table">
          {t("Fleet_layout.Users_groups")}
        </div>
        {DashboardTable(t, locale)}
      </div>
    </div>
  );

  const pocketLabSection = () => {
    return (
      <div>
        <div className="container-last-version">
          <div className="version-pocketlab-title-tab">
            {t("Dashboard.version")}
          </div>
          {pocketlabVersion ? (
            <div className="version-container-number">
              <div>{t("Dashboard.last_version")} : </div>
              <div className="chip-version-dashboard">{pocketlabVersion}</div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="pocketlab-container-section">
          {PocketLabTable(t, locale)}
          <div>{PocketLabChart(t)}</div>
        </div>
      </div>
    );
  };
  const wheatSection = () => (
    <div>
      {manageWheatBarleyTitleCount(
        t("Dashboard.total_actions_wheat"),
        totalActionsWB(chartSoftVarietyActions)
      )}
      <div className="container-charts-with-result">
        {WheatVarietyChart(chartSoftVarietyActions, t)}
      </div>
      <div className="container-charts-with-result">
        {WheatVarietyRateChart(avgProtPerWheatVar, t)}
      </div>
    </div>
  );

  const barleySection = () => (
    <div>
      {manageWheatBarleyTitleCount(
        t("Dashboard.total_actions_barley"),
        totalActionsWB(chartBarleyVarietyActions)
      )}
      <div className="container-charts-with-result">
        {BarleyVarietyChart(chartBarleyVarietyActions, t)}
      </div>
      <div className="container-charts-with-result">
        {BarleyVarietyRateChart(avgProtPerBarleyVar, t)}
      </div>
    </div>
  );

  useEffect(() => {
    fetchActivityAll(token, locationsNames, comesFromMap);
  }, []);

  const globalCharts = () => (
    <div className="background-dashboard">
      <div className="input-date-picker-dashboard-container">
        <div className="mr-3">{DateRangePickerWithButtonField()}</div>
        {AutocompleteDashboardInput(token, t)}
      </div>
      <div className="container-tabs" data-cy="dashboard">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                {dashboardOptions.map((options) => (
                  <Tab
                    sx={{ width: "300.75px", marginLeft: "80px" }}
                    key={options}
                    label={options}
                    value={options}
                  />
                ))}
              </TabList>
            </Box>
            {dashboardOptions.map((options, key) => {
              if (options === t("Dashboard.activity")) {
                return (
                  <TabPanel
                    sx={{ marginBottom: "10px" }}
                    key={key}
                    value={options}
                  >
                    {activitySection()}
                  </TabPanel>
                );
              }

              if (options === t("Cereals.pocketLab")) {
                return (
                  <TabPanel sx={{ padding: 0 }} key={key} value={options}>
                    {pocketLabSection()}
                  </TabPanel>
                );
              }
              if (options === t("Cereals.wheat")) {
                return (
                  <TabPanel
                    sx={{ marginBottom: "10px" }}
                    key={key}
                    value={options}
                  >
                    {wheatSection()}
                  </TabPanel>
                );
              }
              if (options === t("Cereals.barley")) {
                return (
                  <TabPanel
                    sx={{ marginBottom: "10px" }}
                    key={key}
                    value={options}
                  >
                    {barleySection()}
                  </TabPanel>
                );
              }
              return "";
            })}
          </TabContext>
        </Box>
      </div>
      <Modal open={loadingDashboardInfo || false}>{bodyModalLoading}</Modal>
      <div className="rest-of-component-dashboard" />
    </div>
  );

  return <Layout title={managingTitleDashboard()} component={globalCharts()} />;
};

export default Dashboard;
