import { useDispatch, useSelector } from "react-redux";
import {
  ButtonCloseOverviewSideBars,
  ButtonSaveViews,
  CloseIconInarix,
  DeleteIconInarix,
  ListItemButtonInarix,
  ListSaveViews,
} from "../OverviewStyles";

import { Alert, Modal } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import jwt from "jsonwebtoken";

import { State } from "../../../redux/slices";
import {
  initialState,
  resetMapViewState,
  setDateActivity,
  setModalViewsMap,
  setSearchLocationMap,
  setSidebarViews,
  setTypeOfActionMap,
  setViewActive,
  setViewValuesMap,
  setViewport,
} from "../../../redux/slices/map/map";
import { TokenPayload } from "../../../redux/actions/authentication/authentication";
import { SavedConfigViews } from "../../../redux/actions/data/DataDeclarations";
import {
  SaveViewsMapValues,
  Viewport,
} from "../../../redux/slices/map/mapTypes";

import {
  fetchAllUsersIds,
  fetchLocationsWithActivity,
} from "../../../redux/actions/map/mapAsyncActions";

import { LoadingCircularProgress } from "../../Loading";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { BodyViewsModal } from "./MapViewsModalBody";

import {
  setConfirmModal,
  setInfoConfirmModal,
} from "../../../redux/slices/layout";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    sideBarViewsClose: () => {
      dispatch(resetMapViewState());
    },
    setSidebarViews: (open: boolean) => dispatch(setSidebarViews(open)),
    setViewsValues: (values: SaveViewsMapValues) =>
      dispatch(setViewValuesMap(values)),
    openCloseViewsModal: (openClose: boolean) =>
      dispatch(setModalViewsMap(openClose)),

    setNewViewport: (viewport: Viewport) => dispatch(setViewport(viewport)),
    setActivityDate: (date: Date[]) => {
      dispatch(setDateActivity(date));
    },
    fetchAllUsersIdsGeo: (token: string) => dispatch(fetchAllUsersIds(token)),
    locationsInit: (token: string) =>
      dispatch(fetchLocationsWithActivity(token)),
    setSearchLocation: (value: string) => dispatch(setSearchLocationMap(value)),

    isTheViewActive: (isActive: boolean) => dispatch(setViewActive(isActive)),

    showInfoToConfirmDeleteView: (confirmModalInfo: any) => {
      dispatch(setConfirmModal(true));
      dispatch(setInfoConfirmModal(confirmModalInfo));
      dispatch(setTypeOfActionMap("deleteView"));
    },

    resetViewOverviewMap: () => {
      dispatch(resetMapViewState());
    },
  };
};
const widthSideMenu = (
  sidebarSearch: boolean,
  modifyLocation: boolean,
  sidebarViews: boolean
) => {
  if (!sidebarSearch && !modifyLocation && sidebarViews) {
    return "side-nav";
  }

  if (!sidebarViews || (!sidebarSearch && !modifyLocation && !sidebarViews)) {
    return "new-side-bar-off";
  }
};

const SidebarViews = (t: (arg0: string) => string, token: string) => {
  const {
    sideBarViewsClose,
    setViewsValues,
    openCloseViewsModal,
    setNewViewport,
    setActivityDate,
    fetchAllUsersIdsGeo,
    locationsInit,
    setSearchLocation,
    isTheViewActive,
    resetViewOverviewMap,
    showInfoToConfirmDeleteView,
  } = SetActions();

  const {
    sidebarSearch,
    sidebarViews,
    mapView,
    modalViewMap,
    viewsSaved,
    loadingMapViews,
    error,
    createViewLoading,
    viewMapValues,
    modifyLocation,
    viewport,
    locationDateActivity,
    searchLocation,
  } = useSelector((state: State) => state.MapReducer);

  const { userId }: TokenPayload = jwt.decode(token) as TokenPayload;

  const bodyModalViewsMap = BodyViewsModal(
    token,
    createViewLoading,
    viewMapValues,
    t
  );

  const getItems = viewsSaved?.data ? viewsSaved?.data : [];
  const items = [...getItems];

  const viewsSorted = items?.toSorted(
    (a, b) =>
      new Date(b.createdAt)?.getTime() - new Date(a.createdAt)?.getTime()
  );

  const setAllValuesView = () => {
    setViewsValues({
      ...mapView,
      viewport: viewport,
      date: locationDateActivity || [],
      search: searchLocation || "",
      filters: [],
    });
    openCloseViewsModal(true);
  };

  const setView = (viewsConfig: SavedConfigViews) => {
    setActivityDate(viewsConfig?.config?.date);
    isTheViewActive(true);
    setTimeout(() => {
      locationsInit(token);
      fetchAllUsersIdsGeo(token);
    }, 1000);

    setNewViewport({
      latitude: viewsConfig?.config?.viewport?.latitude,
      longitude: viewsConfig?.config?.viewport?.longitude,
      zoom: viewsConfig?.config?.viewport?.zoom,
    });

    if (viewsConfig?.config?.search) {
      setSearchLocation(viewsConfig?.config?.search);
    }
  };

  const resetView = () => {
    resetViewOverviewMap();
    setActivityDate(initialState.locationDateActivity);
    locationsInit(token);
    fetchAllUsersIdsGeo(token);
    setNewViewport({
      ...initialState.viewport,
      latitude: initialState.viewport.latitude,
      longitude: initialState.viewport.longitude,
    });
    setSearchLocation("");
    isTheViewActive(false);
  };

  const sidebarViewsComponent = () => {
    return (
      <div
        id="left"
        className={widthSideMenu(sidebarSearch, modifyLocation, sidebarViews)}
      >
        <ButtonCloseOverviewSideBars
          onClick={sideBarViewsClose}
          variant="text"
          startIcon={<CloseIconInarix />}
          data-cy="close-button-views"
        >
          {t("Fleet_overview.search_sidebar.close")}
        </ButtonCloseOverviewSideBars>

        <div className="title-search" data-cy="title-views">
          <span>{t("Fleet_overview.overview_panel.chips.My_views")}</span>
        </div>
        <div className="divider-search-sidebar" />

        <span className="description-views-sidebar">
          {t("Data.views.View_modal_desc")}
        </span>

        {error && (
          <Alert severity="warning" className="mb-5 mt-5 mr-2 ml-2">
            {t("Fleet_users.table.Error")} : {JSON.stringify(error)}
          </Alert>
        )}
        <div
          className="button-position-views-sidebar"
          data-cy="button-save-view"
        >
          <ButtonSaveViews
            size="large"
            variant="contained"
            onClick={setAllValuesView}
          >
            {t("Fleet_overview.overview_panel.views.save_current_view")}
          </ButtonSaveViews>
        </div>
        <ListSaveViews aria-label="main">
          <div>
            <ListItemButtonInarix>
              <ListItemText
                onClick={() => resetView()}
                data-cy="reset-view"
                primary={t(
                  "Fleet_overview.overview_panel.views.reset_view_map"
                )}
                secondary={t(
                  "Fleet_overview.overview_panel.views.reset_view_description"
                )}
                className="list-item-text-views"
              />
            </ListItemButtonInarix>
            <div className="divider-search-sidebar-locations" />
          </div>
        </ListSaveViews>
        {!loadingMapViews ? (
          <ListSaveViews aria-label="main">
            {viewsSorted.map((views: SavedConfigViews) => {
              if (views.config.viewport) {
                return (
                  <div key={views.id}>
                    <ListItemButtonInarix>
                      <ListItemText
                        onClick={() => setView(views)}
                        primary={views?.name}
                        secondary={views?.description}
                        data-cy={views.name}
                        className="list-item-text-views"
                      />
                      <ListItemIcon
                        className="list-item-icon-views"
                        data-cy={`${views.name}-delete`}
                        onClick={() =>
                          showInfoToConfirmDeleteView({
                            viewName: views.name,
                            viewId: views.id,
                            userId,
                          })
                        }
                      >
                        <DeleteIconInarix />
                      </ListItemIcon>
                    </ListItemButtonInarix>
                    <div className="divider-search-sidebar-locations" />
                  </div>
                );
              }
            })}
          </ListSaveViews>
        ) : (
          <LoadingCircularProgress />
        )}
        <Modal open={modalViewMap}>{bodyModalViewsMap}</Modal>
      </div>
    );
  };

  return sidebarViewsComponent();
};

export default SidebarViews;
