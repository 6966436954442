import React from "react";
import { State } from "../../../redux/slices";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

import { SingleInputDateRangeFieldProps } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  DateRange,
  DateRangePicker,
  DateRangePickerProps,
} from "@mui/x-date-pickers-pro";
import useForkRef from "@mui/utils/useForkRef";

import de from "dayjs/locale/de";
import es from "dayjs/locale/es-mx";
import en from "dayjs/locale/en";
import fr from "dayjs/locale/fr";

import { RangePickerButtonData } from "../../map/OverviewStyles";
import { CalendarTodayIconInarix } from "../../overview/OverviewStyles";
import {
  setDateActivityDashboard,
  setVisibleRangeDashboardPicker,
} from "../../../redux/slices/dashboard/dashboard";
import {
  fetchActivityPocketLabDataDashboard,
  fetchActivityPocketLabDataDashboard2,
  fetchGeoLocDataDashboard,
} from "../../../redux/actions/dashboard/dashboardAsyncActions";
import { DashboardLocIdInfo } from "../../../redux/actions/dashboard/dashboardTypes";
import { useTranslation } from "react-i18next";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    openRangeDate: () => dispatch(setVisibleRangeDashboardPicker(true)),
    closeRangeDate: () => dispatch(setVisibleRangeDashboardPicker(false)),
    setActivityDateDashboard: (date: Date[]) =>
      dispatch(setDateActivityDashboard(date)),

    fetchActivity: (token: string, id: DashboardLocIdInfo[]) => {
      dispatch(fetchActivityPocketLabDataDashboard({ token, id }));
      dispatch(fetchActivityPocketLabDataDashboard2({ token, id }));
      dispatch(fetchGeoLocDataDashboard({ token, id }));
    },
  };
};

interface DateRangeButtonFieldProps
  extends SingleInputDateRangeFieldProps<Dayjs> {
  openRangeDate?: any;
}

type DateRangeButtonFieldComponent = ((
  props: DateRangeButtonFieldProps & React.RefAttributes<HTMLDivElement>
) => React.JSX.Element) & { fieldType?: string };

export const manageFormat = (locale: string) => {
  if (locale === "fr") {
    return "DD/MM/YYYY";
  }
  if (locale === "es") {
    return "DD/MM/YYYY";
  }
  if (locale === "de") {
    return "DD/MM/YYYY";
  } else {
    return "MM/DD/YYYY";
  }
};

const DateRangeButtonField = React.forwardRef(
  (props: DateRangeButtonFieldProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      openRangeDate,
      label,
      id,
      disabled,
      InputProps: { ref: containerRef } = {},
      inputProps: { "aria-label": ariaLabel } = {},
    } = props;

    const handleRef = useForkRef(ref, containerRef);

    return (
      <RangePickerButtonData
        endIcon={<CalendarTodayIconInarix />}
        id={id}
        disabled={disabled}
        ref={handleRef}
        aria-label={ariaLabel}
        onClick={() => openRangeDate()}
      >
        {label}
      </RangePickerButtonData>
    );
  }
) as DateRangeButtonFieldComponent;
DateRangeButtonField.fieldType = "single-input";

const ButtonDateRangePicker = React.forwardRef(
  (
    props: Omit<DateRangePickerProps<Dayjs>, "open" | "onOpen" | "onClose">,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [t] = useTranslation("global");
    const { dateRangePickerDashboard, locationsSelected } = useSelector(
      (state: State) => state.DashboardReducer
    );

    const { user } = useSelector((state: State) => state.Authentication);
    const token = user?.token ? user.token : "";
    const {
      closeRangeDate,
      setActivityDateDashboard,
      fetchActivity,
      openRangeDate,
    } = SetActions();

    return (
      <DateRangePicker
        slots={{ field: DateRangeButtonField, ...props.slots }}
        slotProps={{ field: { openRangeDate } as any }}
        disableFuture={true}
        onChange={(newValue: DateRange<unknown>) => {
          setActivityDateDashboard(newValue as Date[]);
        }}
        onAccept={() => {
          locationsSelected.length === 0
            ? alert(t("Dashboard.Select_loc"))
            : fetchActivity(token, locationsSelected);
        }}
        ref={ref}
        {...props}
        open={dateRangePickerDashboard}
        onClose={() => closeRangeDate()}
        onOpen={() => openRangeDate()}
      />
    );
  }
);

export default function DateRangePickerWithButtonField() {
  const { setActivityDateDashboard } = SetActions();
  const { locationDateActivityDashboard } = useSelector(
    (state: State) => state.DashboardReducer
  );
  const { locale } = useSelector((state: State) => state.LayoutReducer);

  const localeSelected = () => {
    if (locale === "fr") {
      return fr;
    }
    if (locale === "de") {
      return de;
    }

    if (locale === "es") {
      return es;
    } else {
      return en;
    }
  };

  return (
    <LocalizationProvider
      adapterLocale={localeSelected() as unknown as string}
      dateAdapter={AdapterDayjs}
    >
      <ButtonDateRangePicker
        label={`${dayjs(locationDateActivityDashboard[0]).format(
          manageFormat(locale)
        )} - ${dayjs(locationDateActivityDashboard[1]).format(
          manageFormat(locale)
        )}`}
        value={[
          dayjs(locationDateActivityDashboard[0]),
          dayjs(locationDateActivityDashboard[1]),
        ]}
        onChange={(newValue: DateRange<unknown>) => {
          setActivityDateDashboard(newValue as Date[]);
        }}
      />
    </LocalizationProvider>
  );
}
