import * as React from "react";

import { useSelector } from "react-redux";

import { LabelInstanceNewPortal } from "../../../redux/actions/data/DataDeclarations";
import { State } from "../../../redux/slices";

import ImageIcon from "@mui/icons-material/Image";

export type AnnotationOptions = { dataValue: string; userValue: string };

export default function SelectAnnotationValue(
  permissionCheckAnnotation: boolean,
  t: (arg0: string) => string
) {
  const { sampleViewInfo } = useSelector(
    (state: State) => state.DataSampleViewReducer
  );

  const RealAnnotations = sampleViewInfo?.sampleLabelInstances;

  const annotationContent2 = () => {
    if (RealAnnotations?.length >= 1) {
      return RealAnnotations?.map(
        (labelInstances: LabelInstanceNewPortal, indx: number) => {
          const buttonPop = () =>
            // <button
            //   className="edit-annotation-but"
            //   onClick={() =>
            //     setModalEditAnnotation(
            //       true,
            //       labelInstancesV2?.id,
            //       annotationSelectedValuesToEdit,
            //       labelInstancesV2
            //     )
            //   }
            // >
            //   {t("Data.SampleView.annotations.edit")}
            // </button>
            "";

          if (labelInstances.labelTemplate.slug !== "sample-comments") {
            let subDict = labelInstances?.generatedByLabelTemplateConfig
              ?.substitutionDict as any;
            const continuousAnnotation = "continuous";
            const categoricalAnnotation = "categorical";
            const freetextAnnotation = "freetext";
            const imgfileAnnotation = "img/file";

            if (!!subDict) {
              subDict = Object.keys(subDict).reduce((p: any, key) => {
                p[subDict[key]] = key;
                return p;
              }, {});
            }

            const handlingLabel = () => {
              if (
                labelInstances.labelTemplate.type.value === imgfileAnnotation
              ) {
                return (
                  <span className="feat-content-results img-content-annotation">
                    <ImageIcon sx={{ fontSize: "20px", marginTop: "-2px" }} />
                    {permissionCheckAnnotation ? buttonPop() : ""}
                  </span>
                );
              }

              if (
                labelInstances.labelTemplate.type.value === freetextAnnotation
              ) {
                return (
                  <span className="feat-content-results">
                    {labelInstances.labelText}
                    {permissionCheckAnnotation ? buttonPop() : ""}
                  </span>
                );
              }

              if (
                labelInstances.labelTemplate.type.value ===
                categoricalAnnotation
              ) {
                return (
                  <span className="feat-content-results">
                    {!!subDict && !!subDict[labelInstances.labelText as string]
                      ? subDict[labelInstances.labelText as string]
                      : labelInstances.labelText}
                    {permissionCheckAnnotation ? buttonPop() : ""}
                  </span>
                );
              }

              if (
                labelInstances.labelTemplate.type.value === continuousAnnotation
              ) {
                return (
                  <span className="feat-content-results">
                    {labelInstances.labelValue}
                    {labelInstances.labelTemplate.unit
                      ? ` (${labelInstances.labelTemplate.unit})`
                      : ""}
                    {permissionCheckAnnotation ? buttonPop() : ""}
                  </span>
                );
              }
              if (
                labelInstances.labelTemplate.type.value ===
                  continuousAnnotation &&
                Object.keys(
                  labelInstances.labelTemplate.configs[0].substitutionDict
                ).length > 0
              ) {
                return (
                  <span className="feat-content-results">
                    {labelInstances.labelValue}
                    {permissionCheckAnnotation ? buttonPop() : ""}
                  </span>
                );
              } else {
                return (
                  <span className="feat-content-results">
                    {permissionCheckAnnotation ? buttonPop() : ""}
                  </span>
                );
              }
            };

            return handlingLabel();
          }
        }
      );
    } else {
      return "";
    }
  };

  return annotationContent2();
}
